import { message } from 'antd';
import useSWR from 'swr';
import { makeApiCallAdmin } from '../../../api';

function useNonReportingAssets() {
  const { data: nonReportingAssets, error, revalidate } = useSWR(
    '/Dashboard/NoneReporting',
    fetchNonReportingAssets
  );

  return { nonReportingAssets, loading: !nonReportingAssets && !error, revalidate };
}

async function fetchNonReportingAssets(url) {
  try {
    const { data = [] } = await makeApiCallAdmin(url);
    return data;
  } catch (e) {
    message.error(e.message);
  }
}

export default useNonReportingAssets;
