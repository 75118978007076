import { message } from 'antd';
import React from 'react';
import { makeApiCall } from '../api';
import { loadState } from '../localStorage';

export const FleetContext = React.createContext({});

export class FleetProvider extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      context: {
        asset: {},
        assets: [],
        assetsCopy: [],
        nodesWithChildren: [],
        loading: false,
        foldersLoading: false,
        showAddAssetModal: true,
        buttonLoading: false,
        folders: [
          { NodeDes: 'Abuja', NodeId: '1' },
          { NodeDes: 'Abuja', NodeId: '2' },
          { NodeDes: 'Abuja', NodeId: '3' },
        ],
        moveModalFolders: [],
        getFolders: this.getFolders,
        searchAssets: this.searchAssets,
        createFolder: this.createFolder,
        updateFolder: this.updateFolder,
        deleteFolder: this.deleteFolder,
        getNodeFolders: this.getNodeFolders,
        filterAssetsByInitial: this.filterAssetsByInitial,
        updateFolderWithAssets: this.updateFolderWithAssets,
        updateFleetProviderState: this.updateFleetProviderState,
      },
    };
  }
  componentDidMount() {
    this.getFolders();
  }
  getFolders = (global = true) => {
    let data = loadState();
    if (Boolean(data)) {
      const { clientId } = loadState().user;
      const url = `Node/Default/List/${clientId}`;
      if (global) {
        this.setState(({ context }) => ({
          context: {
            ...context,
            loading: true,
          },
        }));
      }
      return makeApiCall(url)
        .then((data) => {
          const { Nodes } = data;
          if (global) {
            this.setState(({ context }) => ({
              context: {
                ...context,
                folders: Nodes,
                loading: false,
                moveModalFolders: Nodes,
              },
            }));
            this.getNodeChildren(Nodes);
          } else {
            this.setState(({ context }) => ({
              context: {
                ...context,
                moveModalFolders: Nodes,
              },
            }));
          }
          return Nodes;
        })
        .catch((error) => {
          this.setState(({ context }) => ({
            context: {
              ...context,
              loading: false,
              folders: [],
            },
          }));
        });
    }
  };
  getNodeChildren = (data) => {
    const { clientId } = loadState().user;
    const { nodesWithChildren } = this.state.context;
    if (data.length > 0 && data.length > nodesWithChildren.length) {
      data.forEach((value) => {
        makeApiCall(`Node/Children/Folder/List/${value.NodeId}/${clientId}`)
          .then((data) => {
            this.setState(({ context }) => ({
              context: {
                ...context,
                nodesWithChildren: [
                  { ...data, NodeId: value.NodeId, NodeDesc: value.NodeDesc },
                  ...context.nodesWithChildren,
                ],
              },
            }));
          })
          .catch((error) => {
            message.error('An error occured. Unable to get assets');
          });
      });
    }
  };
  createFolder = (values, props, callback) => {
    let data = loadState();
    if (Boolean(data)) {
      const { clientId } = data.user;
      this.setState(({ context }) => ({
        context: {
          ...context,
          buttonLoading: true,
        },
      }));
      makeApiCall('Node/Create', 'POST', {}, { ...values, ClientId: clientId })
        .then((data) => {
          this.setState(({ context }) => ({
            context: {
              ...context,
              buttonLoading: false,
            },
          }));
          const { Status } = data;
          if (Status.StatusCode === '00') {
            props.resetForm();
            callback();
            message.success(Status.Description);
            this.getFolders();
          } else {
            message.error(Status.Description);
          }
        })
        .catch((error) => {
          this.setState(({ context }) => ({
            context: {
              ...context,
              buttonLoading: false,
            },
          }));
          message.error('An error occured. Unable to create folder');
        });
    }
  };
  updateFolder = (values, props, callback) => {
    let data = loadState();
    if (Boolean(data)) {
      const { clientId } = data.user;
      this.setState(({ context }) => ({
        context: {
          ...context,
          buttonLoading: true,
        },
      }));
      makeApiCall('Node/Update', 'POST', {}, { ...values, ClientId: clientId })
        .then((data) => {
          this.setState(({ context }) => ({
            context: {
              ...context,
              buttonLoading: false,
            },
          }));
          const { StatusCode, Description } = data;
          if (StatusCode === '00') {
            props.resetForm();
            callback();
            message.success(Description);
            this.getFolders();
          } else {
            message.error(Description);
          }
        })
        .catch((error) => {
          this.setState(({ context }) => ({
            context: {
              ...context,
              buttonLoading: false,
            },
          }));
          message.error('An error occured. Unable to create folder');
        });
    }
  };
  deleteFolder = (values) => {
    let data = loadState();
    if (Boolean(data)) {
      const { clientId } = data.user;
      this.setState(({ context }) => ({
        context: {
          ...context,
          buttonLoading: true,
        },
      }));
      makeApiCall('Node/Delete', 'POST', {}, { ...values, ClientId: clientId })
        .then((data) => {
          this.setState(({ context }) => ({
            context: {
              ...context,
              buttonLoading: false,
            },
          }));
          const { StatusCode, Description } = data;
          if (StatusCode === '00') {
            message.success(Description);
            this.getFolders();
            this.props.getAllOrphanAssets(true);
          } else {
            message.error(Description);
          }
        })
        .catch((error) => {
          this.setState(({ context }) => ({
            context: {
              ...context,
              buttonLoading: false,
            },
          }));
          message.error('An error occured. Unable to create folder');
        });
    }
  };
  updateFolderWithAssets = (values, callback) => {
    let data = loadState();
    if (Boolean(data)) {
      const { clientId } = data.user;
      this.setState(({ context }) => ({
        context: {
          ...context,
          buttonLoading: true,
        },
      }));
      return makeApiCall('Node/Update/Children', 'POST', {}, { ...values, ClientId: clientId })
        .then((data) => {
          this.setState(({ context }) => ({
            context: {
              ...context,
              buttonLoading: false,
            },
          }));
          if (data.StatusCode === '00') {
            message.success(data.Description);
            callback();
            this.getFolders();
            this.props.getAllOrphanAssets(true);
          } else {
            message.error(data.Description);
          }
          return data;
        })
        .catch((error) => {
          this.setState(({ context }) => ({
            context: {
              ...context,
              buttonLoading: false,
            },
          }));
          message.error('An error occured. Unable to update folder');
        });
    }
  };
  getNodeFolders = (id, isModal = false) => {
    let data = loadState();
    if (Boolean(data)) {
      const { clientId } = data.user;
      const url = `Node/Children/Folder/List/${id}/${clientId}`;
      this.setState(({ context }) => ({
        context: {
          ...context,
          foldersLoading: true,
        },
      }));
      return makeApiCall(url)
        .then((data) => {
          const { Nodes } = data;
          if (isModal) {
            if (Nodes.length > 0) {
              this.setState(({ context }) => ({
                context: {
                  ...context,
                  moveModalFolders: Nodes,
                  foldersLoading: false,
                },
              }));
            } else {
              this.setState(({ context }) => ({
                context: {
                  ...context,
                  foldersLoading: false,
                },
              }));
              message.warning('Folder has no sub-folder');
            }
          } else {
            if (Nodes.length > 0) {
              this.setState(({ context }) => ({
                context: {
                  ...context,
                  folders: Nodes,
                  foldersLoading: false,
                },
              }));
            } else {
              this.setState(({ context }) => ({
                context: {
                  ...context,
                  foldersLoading: false,
                },
              }));
              message.warning('Folder has no sub-folder');
            }
          }
          return Nodes;
        })
        .catch((error) => message.error('An error occured'));
    }
  };
  updateFleetProviderState = (value, stateItem) => {
    this.setState(({ context }) => ({
      context: {
        ...context,
        [stateItem]: value,
      },
    }));
  };
  filterAssetsByInitial = (initial) => {
    const { assets, assetsCopy } = this.state.context;
    if (initial !== '') {
      const filteredAssets = assets.filter((asset) =>
        asset.Name.toLowerCase().startsWith(initial.toLowerCase())
      );
      this.setState(({ context }) => ({
        context: {
          ...context,
          assets: filteredAssets,
        },
      }));
    } else {
      this.setState(({ context }) => ({
        context: {
          ...context,
          assets: assetsCopy,
        },
      }));
    }
  };
  searchAssets = (e) => {
    let search = e.target.value.toLowerCase();
    const { assets, assetsCopy } = this.state.context;
    if (search !== '') {
      const filteredAssets = assets.filter((asset) => asset.Name.toLowerCase().includes(search));
      this.setState(({ context }) => ({
        context: {
          ...context,
          assets: filteredAssets,
        },
      }));
    } else {
      this.setState(({ context }) => ({
        context: {
          ...context,
          assets: assetsCopy,
        },
      }));
    }
  };
  render() {
    return (
      <FleetContext.Provider value={this.state.context}>
        <FleetContext.Consumer>{this.props.children}</FleetContext.Consumer>
      </FleetContext.Provider>
    );
  }
}
