import isEmpty from "lodash/isEmpty";
import isEqual from "lodash/isEqual";
import React from "react";
import markerAnimate from "../../utils/markerAnimate";
import { violationColors } from "../design-system/colors";
import { silver } from "../pages/Fleet/SilverMapStlye";

var $ = window.$;
var google = window.google;

class FleetInsightMap extends React.Component {
	state = {
		map: null,
		marker: null,
		location: {},
		locationsCopy: [],
		timeoutId: null,
		polyline: null,
		geofenseMarkers: [],
		violations: {},
	};

	componentDidMount() {
		this.onScriptLoad();
	}

	componentDidUpdate(prevProps) {
		if (!isEqual(prevProps.play, this.props.play) && this.props.play) {
			let { currentLocationMarker } = this.state;
			currentLocationMarker.setMap(null);
			this.props.playFunc(this.playCallback);
		}
		if (
			!isEqual(prevProps.locations, this.props.locations) &&
			this.props.showVehicleRoute
		) {
			let { currentLocationMarker } = this.state;
			if (currentLocationMarker) {
				currentLocationMarker.setMap(null);
			}
		}
		if (
			!isEqual(prevProps.locations, this.props.locations) &&
			!this.props.showVehicleRoute &&
			this.props.stop
		) {
			let { currentLocationMarker, marker } = this.state;
			this.hidePolyline();
			this.hideLocationsMarkers();
			if (marker) marker.setMap(null);
			if (currentLocationMarker) {
				currentLocationMarker.setPosition(this.props.position);
			}
		}
		if (!isEqual(prevProps.showPolyline, this.props.showPolyline)) {
			if (this.props.showPolyline && this.props.showVehicleRoute) {
				this.drawPolyline();
			} else if (!this.props.showPolyline) {
				this.hidePolyline();
			}
		}
		if (!isEqual(prevProps.showIdling, this.props.showIdling)) {
			if (this.props.showIdling && this.props.showVehicleRoute) {
				this.showViolationMarkers("idling");
			} else {
				this.hideViolationMarkers("idling");
			}
		}
		if (!isEqual(prevProps.showOverspeeding, this.props.showOverspeeding)) {
			if (this.props.showOverspeeding && this.props.showVehicleRoute) {
				this.showViolationMarkers("overspeeding");
			} else {
				this.hideViolationMarkers("overspeeding");
			}
		}
		if (!isEqual(prevProps.showHarshBraking, this.props.showHarshBraking)) {
			if (this.props.showHarshBraking && this.props.showVehicleRoute) {
				this.showViolationMarkers("harshBraking");
			} else {
				this.hideViolationMarkers("harshBraking");
			}
		}
		if (
			!isEqual(
				prevProps.showHarshAcceleration,
				this.props.showHarshAcceleration
			)
		) {
			if (this.props.showHarshAcceleration && this.props.showVehicleRoute) {
				this.showViolationMarkers("harshAcceleration");
			} else {
				this.hideViolationMarkers("harshAcceleration");
			}
		}
		if (!isEqual(prevProps.showSos, this.props.showSos)) {
			if (this.props.showSos && this.props.showVehicleRoute) {
				this.showViolationMarkers("sos");
			} else {
				this.hideViolationMarkers("sos");
			}
		}
		if (!isEqual(prevProps.showNoEntryZone, this.props.showNoEntryZone)) {
			if (this.props.showNoEntryZone && this.props.showVehicleRoute) {
				this.showViolationMarkers("noEntryZone");
			} else {
				this.hideViolationMarkers("noEntryZone");
			}
		}
		if (!isEqual(prevProps.showNoExitZone, this.props.showNoExitZone)) {
			if (this.props.showNoExitZone && this.props.showVehicleRoute) {
				this.showViolationMarkers("noExitZone");
			} else {
				this.hideViolationMarkers("noExitZone");
			}
		}
		if (!isEqual(prevProps.showZoneOverspeed, this.props.showZoneOverspeed)) {
			if (this.props.showZoneOverspeed && this.props.showVehicleRoute) {
				this.showViolationMarkers("zoneOverspeed");
			} else {
				this.hideViolationMarkers("zoneOverspeed");
			}
		}
		if (!isEqual(prevProps.showGeofence, this.props.showGeofence)) {
			if (this.props.showGeofence) {
				this.showGeofenseMarkers();
			} else {
				this.hideGeofenseMarkers();
			}
		}
		if (!isEqual(prevProps.selectedLocation, this.props.selectedLocation)) {
			if (this.props.selectedLocation) {
				this.showSelectedLocationMarker(this.props.selectedLocation);
			} else {
				this.hideSelectedLocationMarker();
			}
		}
	}

	onScriptLoad = async () => {
		const {
			vehicleInformation: { bodyColour, vehecleType },
		} = this.props;

		window.google.maps.Marker.prototype.animateTo = markerAnimate;

		const carType = vehecleType ? vehecleType.toLowerCase() : "car";

		const carColor = bodyColour ? bodyColour.toLowerCase() : "car";

		const map = new window.google.maps.Map(document.getElementById("map"), {
			zoom: 19,
			center: this.getLocationLatLng(this.props.currentLocation),
			mapTypeId: window.google.maps.MapTypeId.ROADMAP,
			disableDefaultUI: true,
			zoomControl: true,
			styles: silver,
		});
		const infoWindow = new google.maps.InfoWindow({ maxWidth: 400 });
		const currentLocationMarker = new window.google.maps.Marker({
			map: map,
			position: this.getLocationLatLng(this.props.currentLocation),
			icon: {
				url: `/static/img/cars/${carType}/${carColor}.png`,
				anchor: new google.maps.Point(42.5, 60),
				size: new google.maps.Size(150, 150),
			},
		});
		currentLocationMarker.addListener("click", () =>
			this.openInfoWindow({ marker: currentLocationMarker })
		);
		const vehicleRoute = await this.props.locations.map(
			(trip) =>
				new google.maps.LatLng(Number(trip.latitude), Number(trip.longitude))
		);
		const geofenseMarkers = this.createGeofenseMarkers(this.props.geofenceList);
		if (this.props.showVehicleRoute) {
			const locationsWithViolation = this.props.locations.filter(
				(l) =>
					l.idling ||
					l.overspeed ||
					l.harshAcceleration ||
					l.harshBreak ||
					l.sos ||
					l.noEntryZone ||
					l.noExitZone ||
					l.zoneOverspeed
			);
			const idling = locationsWithViolation
				.filter((l) => l.idling)
				.map(({ latitude, longitude }) =>
					this.createViolationMarker({
						color: violationColors.idling,
						location: { latitude, longitude },
					})
				);
			const overspeeding = locationsWithViolation
				.filter((l) => l.overspeed)
				.map(({ latitude, longitude }) =>
					this.createViolationMarker({
						color: violationColors.overspeed,
						location: { latitude, longitude },
					})
				);
			const harshAcceleration = locationsWithViolation
				.filter((l) => l.harshAcceleration)
				.map(({ latitude, longitude }) =>
					this.createViolationMarker({
						color: violationColors.harshAcceleration,
						location: { latitude, longitude },
					})
				);
			const harshBraking = locationsWithViolation
				.filter((l) => l.harshBreak)
				.map(({ latitude, longitude }) =>
					this.createViolationMarker({
						color: violationColors.harshBreak,
						location: { latitude, longitude },
					})
				);
			const sos = locationsWithViolation
				.filter((l) => l.sos)
				.map(({ latitude, longitude }) =>
					this.createViolationMarker({
						color: violationColors.sos,
						location: { latitude, longitude },
					})
				);
			const noEntryZone = locationsWithViolation
				.filter((l) => l.noEntryZone)
				.map(({ latitude, longitude }) =>
					this.createViolationMarker({
						color: violationColors.noEntryZone,
						location: { latitude, longitude },
					})
				);
			const noExitZone = locationsWithViolation
				.filter((l) => l.noExitZone)
				.map(({ latitude, longitude }) =>
					this.createViolationMarker({
						color: violationColors.noExitZone,
						location: { latitude, longitude },
					})
				);
			const zoneOverspeed = locationsWithViolation
				.filter((l) => l.zoneOverspeed)
				.map(({ latitude, longitude }) =>
					this.createViolationMarker({
						color: violationColors.zoneOverspeed,
						location: { latitude, longitude },
					})
				);

			this.setState({
				violations: {
					overspeeding,
					harshBraking,
					harshAcceleration,
					sos,
					idling,
					zoneOverspeed,
					noExitZone,
					noEntryZone,
				},
			});
		}
		this.setState({
			map,
			infoWindow,
			vehicleRoute,
			geofenseMarkers,
			currentLocationMarker,
		});
		const heading = this.props.currentLocation.heading;
		google.maps.event.addListenerOnce(map, "tilesloaded", function() {
			$(`img[src="/static/img/cars/${carType}/${carColor}.png"]`).css({
				transform: "rotate(" + heading + "deg)",
				"transform-origin": "42.5px 60px",
			});
		});
		if (
			this.props.showPolyline &&
			this.props.locations.length > 0 &&
			this.props.showVehicleRoute
		) {
			this.drawPolyline();
			this.showLocationsMarkers();
			this.createVehicleMarker();
		}
	};

	createVehicleMarker = () => {
		const {
			vehicleInformation: { bodyColour, vehecleType },
		} = this.props;

		const carType = vehecleType ? vehecleType.toLowerCase() : "car";
		const carColor = bodyColour ? bodyColour.toLowerCase() : "car";

		const { marker, vehicleRoute } = this.state;

		if (marker === null) {
			let vechicleMarker = new google.maps.Marker({
				position: vehicleRoute[0],
				map: this.state.map,
				icon: {
					url: `/static/img/cars/${carType}/${carColor}.png`,
					anchor: new google.maps.Point(42.5, 60),
					size: new google.maps.Size(150, 150),
				},
			});
			vechicleMarker.addListener("click", () =>
				this.openInfoWindow({ marker: vechicleMarker })
			);
			this.setState({
				marker: vechicleMarker,
			});
			const heading = this.props.currentLocation.heading;
			$(`img[src="/static/img/cars/${carType}/${carColor}.png"]`).css({
				transform: "rotate(" + heading + "deg)",
				"transform-origin": "42.5px 60px",
			});
		}
	};

	createViolationMarker = ({ color, location }) => {
		return new google.maps.Marker({
			position: this.getLocationLatLng(location),
			icon: {
				path: google.maps.SymbolPath.CIRCLE,
				fillColor: color,
				strokeColor: color,
				scale: 8,
				strokeWeight: 1,
				fillOpacity: 1,
			},
		});
	};

	showSelectedLocationMarker = (location) => {
		const { selectedViolationMarker, map } = this.state;
		const position = this.getLocationLatLng(location);
		if (!selectedViolationMarker) {
			const marker = new google.maps.Marker({
				position,
				icon: {
					url: "https://maps.google.com/mapfiles/ms/icons/blue-dot.png",
				},
				map,
			});
			marker.addListener("mouseover", async ({ latLng }) => {
				this.closeInfoWindow();
				const address = await this.props.getAddress(latLng.lat(), latLng.lng());
				this.openInfoWindow({ marker, autoClose: false, address });
			});
			marker.addListener("mouseout", this.closeInfoWindow);
			this.setState({ selectedViolationMarker: marker });
		} else {
			selectedViolationMarker.setOptions({
				map,
				position,
			});
		}
	};

	hideSelectedLocationMarker = () => {
		const { selectedViolationMarker } = this.state;
		if (selectedViolationMarker) {
			selectedViolationMarker.setMap(null);
		}
	};

	showViolationMarkers = (markerName) => {
		const { map } = this.state;
		const markersList = this.state.violations[markerName];
		if (map && !isEmpty(markersList)) {
			markersList.forEach((marker) => {
				marker.setMap(map);
			});
		}
	};

	hideViolationMarkers = (markerName) => {
		const markersList = this.state.violations[markerName];
		if (!isEmpty(markersList)) {
			markersList.forEach((marker) => {
				marker.setMap(null);
			});
		}
	};

	drawPolyline = () => {
		const { map, vehicleRoute } = this.state;
		if (map && vehicleRoute && vehicleRoute.length > 0) {
			/**
			 * Make the entire vehicle routes (polyline) visible on the map
			 * by using the LatLngBounds and extending it with each route
			 * location
			 */
			//initialize bounds
			const bounds = new google.maps.LatLngBounds();
			//extend bounds
			vehicleRoute.forEach((route) => {
				bounds.extend(route);
			});
			//fit map to bounds
			map.fitBounds(bounds);

			const path = new google.maps.Polyline({
				path: vehicleRoute,
				strokeColor: "#007ace",
				strokeOpacity: 2.0,
				strokeWeight: 2,
				map: map,
			});
			this.setState({
				polyline: path,
			});
			//hide current location marker
			const { currentLocationMarker } = this.state;
			this.hideMapMarker(currentLocationMarker);
		}
	};

	showPolyline = () => {
		const { polyline, map } = this.state;
		if (polyline && map) {
			polyline.setMap(map);
		} else if (!polyline) {
			this.drawPolyline();
		}
	};

	hidePolyline = () => {
		const { polyline } = this.state;
		if (polyline) {
			polyline.setMap(null);
		}
	};

	showLocationsMarkers = () => {
		const {
			startLocationMarker,
			endLocationMarker,
			map,
			vehicleRoute,
		} = this.state;
		if (!startLocationMarker || !endLocationMarker) {
			const newStartLocationMarker = new google.maps.Marker({
				position: vehicleRoute[0],
				map: map,
				icon: {
					url: "https://maps.google.com/mapfiles/ms/icons/green-dot.png",
				},
			});

			const newEndLocationMarker = new google.maps.Marker({
				position: vehicleRoute[vehicleRoute.length - 1],
				map: map,
				icon: {
					url: "https://maps.google.com/mapfiles/ms/icons/red-dot.png",
				},
			});

			[newStartLocationMarker, newEndLocationMarker].forEach((marker) => {
				marker.addListener("mouseover", async ({ latLng }) => {
					this.closeInfoWindow();
					const address = await this.props.getAddress(
						latLng.lat(),
						latLng.lng()
					);
					this.openInfoWindow({ marker, autoClose: false, address });
				});
				marker.addListener("mouseout", this.closeInfoWindow);
			});

			this.setState({
				startLocationMarker: newStartLocationMarker,
				endLocationMarker: newEndLocationMarker,
			});
		} else {
			startLocationMarker.setMap(map);
			endLocationMarker.setMap(map);
		}
	};

	hideLocationsMarkers = () => {
		const { startLocationMarker, endLocationMarker } = this.state;
		if (startLocationMarker && endLocationMarker) {
			startLocationMarker.setMap(null);
			endLocationMarker.setMap(null);
		}
	};

	getLocationLatLng = (location) => {
		return new google.maps.LatLng(
			Number(location.latitude),
			Number(location.longitude)
		);
	};

	playCallback = (location) => {
		const {
			stop,
			setCurrentLocation,
			vehicleInformation: { bodyColour, vehecleType },
		} = this.props;

		const carType = vehecleType ? vehecleType.toLowerCase() : "car";
		const carColor = bodyColour ? bodyColour.toLowerCase() : "car";

		let { marker } = this.state;

		if (stop) {
			marker.setPosition(this.getLocationLatLng(location));
		} else {
			try {
				marker.animateTo(this.getLocationLatLng(location));
				$(`img[src="/static/img/cars/${carType}/${carColor}.png"]`).css({
					transform: "rotate(" + location.heading + "deg)",
					"transform-origin": "42.5px 60px",
				});
			} catch (error) {
				marker.setPosition(this.getLocationLatLng(location));
			}
		}
		setCurrentLocation(location);
	};

	createGeofenseMarkers = (geofenceList) => {
		return geofenceList
			.filter(({ coordinate }) => !!coordinate)
			.map(({ coordinate }) => {
				const geofencePaths = coordinate.map((location) =>
					this.getLocationLatLng(location)
				);
				return new window.google.maps.Polygon({
					paths: geofencePaths,
					strokeColor: "#FF0000",
					strokeOpacity: 1,
					strokeWeight: 1,
					fillColor: "#FF0000",
					fillOpacity: 0.35,
				});
			});
	};

	showGeofenseMarkers = () => {
		const { geofenseMarkers, map } = this.state;
		geofenseMarkers.forEach((marker) => marker.setMap(map));
	};

	hideGeofenseMarkers = () => {
		const { geofenseMarkers } = this.state;
		geofenseMarkers.forEach((marker) => marker.setMap(null));
	};

	hideMapMarker = (marker) => {
		if (marker) marker.setMap(null);
	};

	openInfoWindow = async ({
		marker,
		autoClose = true,
		duration = 5000,
		address,
	}) => {
		const { infoWindow, infoWindowTimer, map } = this.state;
		await this.updateInfoWindowAddress(address);
		if (infoWindowTimer) {
			clearTimeout(infoWindowTimer);
		} else {
			infoWindow.open(map, marker);
		}
		if (autoClose) {
			const newInfoWindowTimer = setTimeout(this.closeInfoWindow, duration);
			this.setState({ infoWindowTimer: newInfoWindowTimer });
		}
	};

	updateInfoWindowAddress = async (address) => {
		const { infoWindow } = this.state;
		if (!address) {
			const { latitude, longitude } = this.props.currentLocation;
			const newAddress = await this.props.getAddress(latitude, longitude);
			infoWindow.setContent(
				`<p style="margin: 0;font-weight: bold;">${newAddress}</p>`
			);
			return;
		}
		infoWindow.setContent(
			`<p style="margin: 0;font-weight: bold;">${address}</p>`
		);
	};

	closeInfoWindow = () => {
		const { infoWindow } = this.state;
		if (infoWindow) {
			infoWindow.close();
		}
		this.setState({ infoWindowTimer: null });
	};

	render() {
		return (
			<div
				id="map"
				style={{
					height: "100%",
				}}
			/>
		);
	}
}

FleetInsightMap.defaultProps = {
	onMapLoad: (map) => console.log(map),
};

export default FleetInsightMap;
