import { Input } from 'antd';
import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';
import { black, gray, red, white } from '../design-system/colors';
import { Label, Text } from './Text';

const { TextArea } = Input;

const StyledAppTextArea = styled.div`
  textarea {
    border-radius: 0;
    font-size: 16px;
    padding-top: 8px;
    padding-bottom: 8px;
    border: 1px solid ${props => props.border};
    margin-top: ${props => props.marginTop || `20px`};
    color: ${props => (props.color ? props.color : black)};
    background-color: ${props => (props.bgColor ? props.bgColor : gray.ui01)};

    &:focus {
      border: 1px solid ${props => props.border};
      box-shadow: none;
    }

    &:hover {
      border: 1px solid ${props => props.border};
      box-shadow: none;
    }
  }
`;

export const AppTextArea = props => {
  return (
    <StyledAppTextArea border={props.border} bgColor={props.bgColor} marginTop={props.marginTop}>
      {props.label && <Label>{props.label}</Label>}
      <TextArea {...props} />
      {props.error && (
        <Text className="" fontSize="12px" marginTop="4px" color={red.primary}>
          {props.errorMessage}
        </Text>
      )}
      {props.helpText && (
        <Text className="" fontSize="12px" marginTop="4px" color={gray.primary}>
          {props.helpText}
        </Text>
      )}
    </StyledAppTextArea>
  );
};

const TextAreaContainer = styled.textarea`
  display: block;
  width: 100%;
  outline: none;
  border-radius: 0;
  background-color: ${props => (props.bgColor ? props.bgColor : gray.ui01)};
  box-shadow: none;
  margin-top: ${props => props.marginTop};
  font-size: 16px;
  padding: 12px;
  border: 1px solid ${props => props.border};
  color: ${props => (props.color ? props.color : black)};
  &:focus {
    background-color: ${white};
    border: 1px solid ${props => (props.border ? props.border : gray.light)};
  }
  &:disabled {
    background-color: ${gray.ui01};
  }
`;

export const TextAreaComponent = ({
  marginTop,
  disabled,
  name,
  placeholder,
  label,
  rows,
  cols,
  color,
  border,
  bgColor
}) => (
  <React.Fragment>
    {label && <Label>{label}</Label>}
    <TextAreaContainer
      marginTop={marginTop}
      name={name}
      disabled={disabled}
      placeholder={placeholder}
      rows={rows}
      cols={cols}
      color={color}
      border={border}
      bgColor={bgColor}
    />
  </React.Fragment>
);

TextAreaComponent.defaultProps = {
  marginTop: '20px',
  name: '',
  disabled: false,
  placeholder: 'Placeholder',
  rows: 5,
  cols: 5
};

TextAreaComponent.propTypes = {
  marginTop: PropTypes.string,
  name: PropTypes.string,
  disabled: PropTypes.bool,
  placeholder: PropTypes.string,
  rows: PropTypes.number,
  cols: PropTypes.number
};
