import { Col, Row } from 'antd';
import * as React from 'react';
import DriverBehavior from './DriverBehavior';
import HarshDriving from './HarshDriving';
import HearbeatCheck from './HearbeatCheck';
import Idling from './Idling';
import NonReportingAssets from './NonReportingAssets';
import OverSpeedingAssets from './OverSpeedingAssets';
import ReportingAssets from './ReportingAssets';
import SeatbeltViolations from './SeatbeltViolations';
import SOS from './SOS';
import { ViolationsContainer } from './style';

function Analytics() {
  return (
    <div className="Dashboard">
      <div className="Dashboard__content">
        <HearbeatCheck />
        <div className="Dashboard__content__charts">
          <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
            <Col xl={{ span: 8 }} lg={{ span: 8 }} md={{ span: 24 }}>
              <ReportingAssets />
            </Col>
            <Col xl={{ span: 8 }} lg={{ span: 8 }} md={{ span: 24 }}>
              <DriverBehavior />
            </Col>
          </Row>
        </div>
        <ViolationsContainer>
          <HarshDriving />
          <NonReportingAssets />
          <SOS />
          <Idling />
          <SeatbeltViolations />
          <OverSpeedingAssets />
        </ViolationsContainer>
      </div>
    </div>
  );
}

export default Analytics;
