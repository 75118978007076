import { Input } from 'antd';
import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';
import { black, gray, red, white } from '../design-system/colors';
import { Label, Text } from './Text';

const generateSize = size => {
  if (size === 'large') {
    return `height: 60px; padding: 0 30px;`;
  }
  if (size === 'small') {
    return `height: 32px; padding: 0 12px;`;
  }
  return `height: 40px; padding: 0 20px;`;
};

const InputContainer = styled.input`
  display: block;
  width: 100%;
  outline: none;
  border-radius: 0;
  background-color: ${gray.ui01};
  border: none;
  box-shadow: none;
  margin-top: ${props => props.marginTop};
  font-size: 16px;
  color: ${black};
  font-size: 16px;
  ${props => generateSize(props.size)};
  &:focus {
    background-color: ${white};
    border: 1px solid ${gray.light};
  }
  &:disabled {
    background-color: ${gray.ui01};
  }
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
`;

export const InputComponent = ({
  size,
  marginTop,
  disabled,
  name,
  type,
  placeholder,
  label,
  onChange,
  value,
  error,
  errorMessage,
  helpText
}) => (
  <React.Fragment>
    {label && <Label style={{ paddingBottom: '4px' }}>{label}</Label>}
    <InputContainer
      size={size}
      marginTop={marginTop}
      name={name}
      type={type}
      disabled={disabled}
      placeholder={placeholder}
      onChange={onChange}
      value={value}
    />
    {error && (
      <Text className="" fontSize="12px" marginTop="4px" color={red.primary}>
        {errorMessage}
      </Text>
    )}
    {helpText && (
      <Text className="" fontSize="12px" marginTop="4px" color={gray.primary}>
        {helpText}
      </Text>
    )}
  </React.Fragment>
);

InputComponent.defaultProps = {
  size: '',
  marginTop: '20px',
  name: '',
  type: '',
  disabled: false,
  placeholder: 'Placeholder'
};

InputComponent.propTypes = {
  size: PropTypes.string,
  marginTop: PropTypes.string,
  name: PropTypes.string,
  type: PropTypes.string,
  disabled: PropTypes.bool,
  placeholder: PropTypes.string
};

const AppInputContainer = styled.div`
  margin-top: ${props => props.marginTop || '20px'};
  .ant-input-affix-wrapper {
    border-color: ${props => props.borderColor || black};
    &:focus,
    &:hover {
      border-color: ${props => props.borderColor || black};
    }
  }
  .ant-input {
    border-color: ${props => props.borderColor || black};
    border-radius: 0;
    padding: 0 11px;
    color: ${black};
    &:focus,
    &:hover {
      outline: none;
      box-shadow: none;
      border-color: ${props => props.borderColor || black};
    }
  }
  .ant-input-group-addon {
    border-radius: 0;
    border: 1px solid;
    border-color: ${props => props.borderColor || black};
    background-color: white;
    border-right: 0;
  }
  label {
    padding-bottom: 4px;
  }
  .ant-input-affix-wrapper:hover .ant-input:not(.ant-input-disabled) {
    border-color: ${props => props.borderColor || black};
  }
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  input::placeholder {
    text-transform: capitalize !important;
  }
  input::-moz-placeholder {
    text-transform: capitalize !important;
  }
  input::-webkit-input-placeholder {
    text-transform: capitalize !important;
  }
  input::-ms-input-placeholder {
    text-transform: capitalize !important;
  }
`;

export const AppInput = props => (
  <AppInputContainer
    borderColor={props.borderColor}
    marginTop={props.marginTop}
    className={props.className}
  >
    {props.label && <Label>{props.label}</Label>}
    <Input {...props} />
    {props.error && (
      <Text fontSize="12px" marginTop="4px" color={red.primary}>
        {props.errorMessage}
      </Text>
    )}
    {props.helpText && (
      <Text className="" fontSize="12px" marginTop="4px" color={gray.primary}>
        {props.helpText}
      </Text>
    )}
  </AppInputContainer>
);

AppInput.defaultProps = {
  errorMessage: 'This field is required'
};
