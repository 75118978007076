import React from "react";
import { Redirect, Switch, Route } from "react-router-dom";
import { AlertRoutes } from "./Alerts/routes";
import { AuthenticationRoutes } from "./Authentication/routes";
import BillingRoutes from "./Billing/routes";
import { DashboardRoutes } from "./Dashboard/routes";
import { AppProvider } from "./DataProvider";
import { FleetRoutes } from "./Fleet/routes";
import { ReportRoutes } from "./Reports/routes";
import ContactPage from "./Contact";
import { SettingsRoutes } from "./Settings/routes";

export const GlobalRoutes = () => (
	<Switch>
		<AppProvider>
			{AuthenticationRoutes}
			<Switch>
				<Redirect key="redirect" exact from="/" to="/dashboard/analytics" />
				{DashboardRoutes}
				{FleetRoutes}
				{AlertRoutes}
				{SettingsRoutes}
				{ReportRoutes}
				{BillingRoutes}
				<Route exact path="/contact" component={ContactPage} />
			</Switch>
		</AppProvider>
	</Switch>
);
