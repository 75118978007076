import { message } from 'antd';
import * as React from 'react';
import { makeApiCall } from '../api';
import { TripsPage } from '../components/pages/Trips/TripsPage';
import { useProfile } from '../utils/hooks';

export function Trips(props) {
  const { clientId, email } = useProfile();

  const deleteTrip = async (id) => {
    const url = `Trip/Delete/${id}/${clientId}`;
    try {
      let data = await makeApiCall(url, 'post');
      if (data.StatusCode === '00') {
        message.success(data.Description);
      } else {
        throw new Error(data.Description);
      }
    } catch (error) {
      message.error(error.message);
    }
  };

  const updateTrip = async (payload) => {
    try {
      let data = await makeApiCall(
        'Trip/Update',
        'post',
        {},
        { ...payload, ClientId: clientId, LoginUserId: email }
      );
      if (data.StatusCode === '00') {
        message.success(data.Description);
        return data.Description;
      } else {
        throw new Error(data.Description);
      }
    } catch (error) {
      message.error(error.message);
    }
  };

  const updateTripStatus = async (payload, callback) => {
    const url = `Trip/Update/Status`;
    try {
      let data = await makeApiCall(url, 'post', {}, { ...payload, ClientId: clientId });
      if (data.StatusCode === '00') {
        message.success(data.Description);
        callback();
      } else {
        throw new Error(data.Description);
      }
    } catch (error) {
      message.error(error.message);
    }
  };

  return <TripsPage updateTrip={updateTrip} deleteTrip={deleteTrip} />;
}
