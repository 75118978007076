import debounce from 'lodash/debounce';
import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { AppContext } from '../DataProvider';
import { FleetProvider } from '../Fleet/FleetProvider';
import Alerts from './Alerts';
import Contacts from './Contacts';
import Incidents from './Incidents';
import ResolvedIncidents from './ResolvedIncidents';

export class AlertsIndex extends React.Component {
  static contextType = AppContext;

  searchIncidents = debounce((search) => {
    const { incidents, incidentsCopy, updateState } = this.context;
    if (search !== '') {
      const filteredIncidents = incidents.filter(
        ({ AssetName, Description }) =>
          AssetName.toLowerCase().includes(search) || Description.toLowerCase().includes(search)
      );
      updateState(filteredIncidents, 'incidents');
    } else {
      updateState(incidentsCopy, 'incidents');
    }
  }, 500);

  render() {
    const {
      match: { path },
    } = this.props;
    const { updateState, alerts } = this.context;
    return (
      <FleetProvider updateState={updateState}>
        {({ nodesWithChildren }) => (
          <Switch>
            <Route
              exact
              path={`${path}/incidents`}
              render={(props) => (
                <Incidents
                  {...props}
                  searchIncidents={(e) => this.searchIncidents(e.target.value.toLowerCase())}
                />
              )}
            />
            <Route
              exact
              path={`${path}/incidents/resolved`}
              render={(props) => (
                <ResolvedIncidents
                  {...props}
                  searchIncidents={(e) => this.searchIncidents(e.target.value.toLowerCase())}
                />
              )}
            />
            <Route
              exact
              path={`${path}/alerts`}
              render={(props) => (
                <Alerts
                  {...props}
                  alerts={alerts}
                  updateState={updateState}
                  nodesWithChildren={nodesWithChildren}
                />
              )}
            />
            <Route exact path={`${path}/contacts`} component={Contacts} />
          </Switch>
        )}
      </FleetProvider>
    );
  }
}
