import React from 'react';
import styled from 'styled-components';
import { Upload, Icon } from 'antd';

const FileUploadContainer = styled.div`
  margin-top: ${props => props.marginTop};
  margin-bottom: ${props => props.marginBottom};

  .content-section {
    padding: 16px 32px;
    max-width: ${props => props.contentWidth};
    margin: 0 auto;
  }
`;

const Dragger = Upload.Dragger;

export const FileUpload = props => {
  return (
    <FileUploadContainer
      marginBottom={props.marginBottom}
      marginTop={props.marginTop}
      contentWidth={props.contentWidth}
    >
      <Dragger {...props}>
        <div className="content-section">
          <p className="ant-upload-drag-icon">
            <Icon type="inbox" />
          </p>
          <p className="ant-upload-text">
            Click or drag file to this area to upload
          </p>
          <p className="ant-upload-hint">
            Support for a single or bulk upload. Strictly prohibit from
            uploading company data or other band files
          </p>
        </div>
      </Dragger>
    </FileUploadContainer>
  );
};
