import React, { Component } from 'react';
import { HashRouter } from 'react-router-dom';
import { GlobalRoutes } from './routes';
import './index.css';

class App extends Component {
  render() {
    return (
      <HashRouter>
        <GlobalRoutes />
      </HashRouter>
    );
  }
}

export default App;
