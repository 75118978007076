import { message } from 'antd';
import useSWR from 'swr';
import { makeApiCallAdmin } from '../../api';

function useUnassignedDevices() {
  const { data: devices, error, revalidate } = useSWR('/Device/Assign/0', fetchUnassignedDevices);

  return { devices, loading: !devices && !error, revalidate };
}

async function fetchUnassignedDevices(url) {
  try {
    const { data = [] } = await makeApiCallAdmin(url);
    return data;
  } catch (e) {
    message.error(e.message);
  }
}

export default useUnassignedDevices;
