import { Button, Divider, Icon, message, Popconfirm } from 'antd';
import moment from 'moment';
import React from 'react';
import useSWR from 'swr';
import { makeApiCallAdmin } from '../../api';
import { gray, red } from '../../components/design-system/colors';
import { ButtonComponent } from '../../components/primitives/Buttons';
import { AppModal, ToggleModal } from '../../components/primitives/Modal';
import { AppTable } from '../../components/primitives/Table';
import { Heading, Text } from '../../components/primitives/Text';
import NewCostPlanForm from './NewCostPlanForm';

function CostPlans({ match: { path } }) {
  const { data: costPlans = [], error, revalidate } = useSWR('/CostPlanAgent', fetchCostPlans);

  const costProfilesTableColumn = [
    { title: 'Cost Plan Name', dataIndex: 'costPlanName' },
    { title: 'Description', dataIndex: 'costPlanDesc' },
    {
      title: 'Date Created',
      dataIndex: 'entryDate',
      render: (date) => moment(date).format('DD-MMM-YYYY'),
    },
    {
      dataIndex: 'costPlanId',
      render: (costPlanId, costPlan) => (
        <>
          <ToggleModal>
            {(showModal, onOpenModal, onCloseModal) => (
              <>
                <AppModal title="Update Cost Plan" visible={showModal} onCancel={onCloseModal}>
                  <NewCostPlanForm
                    costPlan={costPlan}
                    close={() => {
                      onCloseModal();
                      revalidate();
                    }}
                    isEdit
                  />
                </AppModal>
                <Button onClick={onOpenModal} type="link">
                  Edit
                </Button>
              </>
            )}
          </ToggleModal>
          <Divider type="vertical" />
          <Popconfirm
            title="Are you sure you want to delete this cost plan?"
            onConfirm={async () => {
              await deleteCostPlan(costPlanId);
              revalidate();
            }}
            okText="Delete"
            cancelText="Cancel"
            icon={<Icon type="question-circle-o" style={{ color: 'red' }} />}
          >
            <Button type="link">
              <Text color={red.primary}>Delete</Text>
            </Button>
          </Popconfirm>
        </>
      ),
    },
  ];

  return (
    <div className="mb-16 max-w-screen-xl">
      <div className="flex">
        <Heading>Cost Plans</Heading>
        <ToggleModal>
          {(showModal, onOpenModal, onCloseModal) => (
            <>
              <AppModal title="Create Cost Plan" visible={showModal} onCancel={onCloseModal}>
                <NewCostPlanForm
                  close={() => {
                    onCloseModal();
                    revalidate();
                  }}
                />
              </AppModal>
              <ButtonComponent appearance="blue" className="ml-6" onClick={onOpenModal}>
                Create Cost Plan
              </ButtonComponent>
            </>
          )}
        </ToggleModal>
      </div>
      <div className="max-w-screen-lg">
        <AppTable
          hasBorder
          border_color={gray.light}
          rowKey="costPlanId"
          loading={!costPlans && !error}
          dataSource={costPlans}
          columns={costProfilesTableColumn}
          marginTop="50px"
          pagination={costPlans.length > 10 ? { pageSize: 10 } : false}
        />
      </div>
    </div>
  );
}

async function fetchCostPlans() {
  try {
    const { data = [] } = await makeApiCallAdmin(`/CostPlanAgent`);
    return data;
  } catch (e) {
    message.error(e.message);
  }
}

async function deleteCostPlan(costPlanId) {
  try {
    await makeApiCallAdmin(`/CostPlanAgent/${costPlanId}`, { method: 'delete' });
    message.success('Cost plan deleted successfully');
  } catch (e) {
    message.error(e.message);
  }
}

export default CostPlans;
