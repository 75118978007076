import addThousandSeparator from './addThousandSeparator';

/**
 * Format number as currency by adding thousand separators and including the naira currency symbol
 * Example: formatAsCurrency(1200) // ₦ 1,200.00
 * @param {number} number - number to be formatted
 * @param {number} decimals - number of fractional digits to return
 */
function formatAsCurrency(number, decimals = 2) {
  return `₦ ${addThousandSeparator(number, decimals)}`;
}

export default formatAsCurrency;
