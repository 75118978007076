import { Skeleton } from 'antd';
import { isEmpty } from 'lodash';
import * as React from 'react';
import { Doughnut } from 'react-chartjs-2';
import { Heading } from '../components/primitives/Text';
import { useDriverBehavior } from '../utils/hooks';

function ReportingAssets() {
  const { driverBehavior = {}, loading } = useDriverBehavior();

  return (
    <>
      <Heading>Reporting Assets</Heading>
      <Skeleton active loading={loading} paragraph={{ rows: 6 }}>
        <div className="doughnut-chart">
          {isEmpty(driverBehavior) ? (
            <p>Data unavailable</p>
          ) : (
            <Doughnut
              data={{
                labels: ['Reporting', 'Non-Reporting'],
                datasets: [
                  {
                    backgroundColor: ['#ffab4f', '#1f8efa'],
                    data: [driverBehavior.assetsReporting, driverBehavior.assetsNotReporting],
                    borderWidth: 1,
                  },
                ],
              }}
              width={500}
              height={250}
              options={{
                maintainAspectRatio: true,
                cutoutPercentage: 60,
              }}
              legend={{
                position: 'right',
                labels: {
                  boxWidth: 20,
                  usePointStyle: true,
                  padding: 35,
                },
              }}
            />
          )}
        </div>
      </Skeleton>
    </>
  );
}

export default ReportingAssets;
