import { Steps } from "antd";
import React from "react";
import styled from "styled-components";
import AssetForm from "../../../Fleet/AssetForm";
import { gray, green } from "../../design-system/colors";
import { customFonts } from "../../design-system/fonts";
import {
	ButtonComponent,
	EmptyButtonComponent,
} from "../../primitives/Buttons";
import { FileUpload } from "../../primitives/FileUpload";
import { AppModal, ToggleModal } from "../../primitives/Modal";
import { Heading, Text } from "../../primitives/Text";
import { AddOrganizationNameForm } from "./forms";

const Step = Steps.Step;

const AddAssetModalContent = styled.div`
	margin-top: 80px;
	margin-bottom: 100px;
	.header-section {
		h1 {
			margin: 0;
		}
	}
	.main-content {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		text-align: center;
		margin-top: 40px;
	}

	.organization-name__section {
		width: 540px;

		form {
			input {
				text-align: center;
			}
		}
	}

	.steps-section {
		max-width: 300px;
		margin: 0 auto;
		margin-top: 20px;
	}

	.asset-creation__success {
		button {
			width: 200px;
			margin: 0 auto;
			margin-top: 40px;
		}
	}
`;

const headingText = {
	0: "Set up your account and start tracking your vehicles",
	1: "Add the assets you would like to track in real time.",
};

export class AddAssetModal extends React.Component {
	state = {
		step: this.props.userType === 2 ? 0 : 1,
		loading: false,
		AlreadyExists: [],
	};
	saveOrganizationName = (values) => {
		debugger;
		this.setState({
			loading: true,
		});
		if (values) {
			this.props.updateClientInfo(values).then((data) => {
				this.setState({
					step: 1,
					loading: false,
				});
			});
		}
	};
	createAssetBulkUpload = ({ onSuccess, onFailure, file }, callback) => {
		this.props
			.createAssetBulkUpload(file, callback)
			.then((data) => {
				const { AlreadyExists } = data;
				if (AlreadyExists.length > 0) {
					this.setState({
						AlreadyExists,
					});
				}
				onSuccess(data, file);
			})
			.catch((err) => {
				onFailure(err);
			});
	};
	render() {
		const {
			model,
			assets,
			userType,
			success,
			loading,
			getModel,
			assetTypes,
			createAsset,
			updateState,
			manufacturers,
			getModelLoading,
			onAssetCreationDone,
		} = this.props;
		const { step, AlreadyExists } = this.state;
		return (
			<ToggleModal showModal={assets.length === 0}>
				{(showModal, onOpenModal, onCloseModal) => (
					<AppModal
						visible={showModal}
						onCancel={() => {
							onCloseModal();
							updateState(false, "showAddAssetModal");
						}}
						width="1400px"
						maskClosable={false}
						closable={true}
					>
						<AddAssetModalContent>
							<div className="header-section">
								<Heading fontSize="20px" align="center">
									{headingText[step]}
								</Heading>
								{userType === 2 && (
									<div className="steps-section">
										<Steps current={step}>
											<Step />
											<Step />
										</Steps>
									</div>
								)}
							</div>
							<div className="main-content">
								{step === 0 && userType === 2 && (
									<AddOrganizationName
										loading={this.state.loading}
										onSubmit={this.saveOrganizationName}
									/>
								)}
								{step === 1 && (
									<AddAssetSection
										model={model}
										success={success}
										loading={loading}
										getModel={getModel}
										onCancel={onCloseModal}
										assetTypes={assetTypes}
										createAsset={createAsset}
										updateState={updateState}
										AlreadyExists={AlreadyExists}
										manufacturers={manufacturers}
										getModelLoading={getModelLoading}
										onAssetCreationDone={onAssetCreationDone}
										createAssetBulkUpload={this.createAssetBulkUpload}
									/>
								)}
							</div>
						</AddAssetModalContent>
					</AppModal>
				)}
			</ToggleModal>
		);
	}
}

const AddAssetSectionContainer = styled.div`
	.add-asset__sections {
		display: flex;
		justify-content: space-between;
		margin-top: 80px;
		.add-asset__section {
			display: flex;
			flex-direction: column;

			&.left {
				width: 340px;
			}
			&.right {
				width: ${(props) =>
					props.section && props.section === "form" ? "500px" : "340px"};
			}

			p {
				margin-bottom: 0;
			}
			.action-button {
				width: 50%;
				margin-left: auto;
				margin-right: auto;
			}
		}
		.divider {
			width: 1px;
			height: 380px;
			border: ${(props) =>
				props.section ? "none" : `1px dashed ${gray.faint}`};
			margin: ${(props) =>
				props.section && props.section === "form" ? "0 80px" : "0 154px"};
		}
		.upload-section {
			h1 {
				font-family: ${customFonts.circular}!important;
				font-weight: bold;
			}
			&__header {
				display: flex;
				justify-content: space-between;
				align-items: center;
			}
		}
		.asset-form {
			text-align: left;
		}
		.done-button {
			text-align: left;
			margin: 8px 0;
			width: 100px;
			padding: 8px;
			transition: all 0.3s ease-in-out;

			&:hover {
				font-weight: bold;
				color: ${green.primary};
			}

			span {
				padding-left: 8px;
			}
		}
	}
`;

class AddAssetSection extends React.Component {
	state = {
		section: "",
	};

	showSection = (section) => {
		this.setState({
			section,
		});
	};

	createAsset = (values, callback) => {
		this.props.createAsset(values, callback);
	};
	onSuccess = () => {
		const { updateState, onCancel } = this.props;
		onCancel();
		updateState(false, "showAddAssetModal");
	};
	render() {
		const { section } = this.state;
		const {
			success,
			AlreadyExists = [],
			onAssetCreationDone,
			createAssetBulkUpload,
		} = this.props;
		return (
			<AddAssetSectionContainer section={section}>
				<div className="add-asset__icon">
					<img src="/static/img/asset-icons.svg" alt="assets" />
				</div>
				<div className="add-asset__sections">
					{success ? (
						<AssetCreationSuccess onClick={this.onSuccess} />
					) : section && section === "upload" ? (
						<div className="upload-section">
							<div className="upload-section__header">
								<Heading fontSize="20px" align="left">
									Upload file
								</Heading>
								<EmptyButtonComponent onClick={() => this.showSection("")}>
									Cancel
								</EmptyButtonComponent>
							</div>
							<FileUpload
								contentWidth="500px"
								customRequest={(data) =>
									createAssetBulkUpload(data, onAssetCreationDone)
								}
							/>
							<a href="/static/files/Asset_Upload_Template.xlsx" download>
								Download Sample CSV file
							</a>
							{AlreadyExists.length > 0 && (
								<div>
									<Heading
										fontSize="16px"
										marginTop="16px"
										style={{ fontWeight: "bold" }}
									>
										Assets with the following plate numbers already exist
									</Heading>
									{AlreadyExists.map((item) => (
										<Text marginTop="8px">{item.PlateNo}</Text>
									))}
								</div>
							)}
						</div>
					) : (
						<React.Fragment>
							<div className="add-asset__section left">
								<img src="/static/img/asset-icon.svg" alt="asset" />
								<Heading fontSize="20px" marginTop="16px">
									Add Assets
								</Heading>
								<Text marginTop="30px">
									You can add assets you want to track here. Simply click on the
									add asset button to get started. Flitrack offers real time
									tracking of all your assets listed here.
								</Text>
								{section && section === "form" ? null : (
									<ButtonComponent
										appearance="blue"
										marginTop="70px"
										className="action-button"
										onClick={() => this.showSection("form")}
									>
										Add Asset
									</ButtonComponent>
								)}
							</div>
							<div className="divider" />
							<div className="add-asset__section right">
								{section && section === "form" ? (
									<React.Fragment>
										<AssetForm close={onAssetCreationDone} />
										{/* <EmptyButtonComponent
                      className="done-button"
                      onClick={onAssetCreationDone}
                    >
                      <Icon type="check" />
                      <span>Done</span>
                    </EmptyButtonComponent> */}
									</React.Fragment>
								) : (
									<React.Fragment>
										<img src="/static/img/bulk-upload.svg" alt="bulk upload" />
										<Heading fontSize="20px" marginTop="16px">
											Bulk Upload{" "}
										</Heading>
										<Text marginTop="30px">
											Bulk uploads allow you to quickly way create lots of
											assets easily, especially useful if you already have a
											list. Download the sample CSV file, fill the asset details
											according the format, and upload.
										</Text>
										<a download style={{ marginTop: "16px" }}>
											Download Sample CSV file
										</a>
										<ButtonComponent
											appearance="blue"
											marginTop="32px"
											className="action-button"
											onClick={() => this.showSection("upload")}
										>
											Bulk Upload
										</ButtonComponent>
									</React.Fragment>
								)}
							</div>
						</React.Fragment>
					)}
				</div>
			</AddAssetSectionContainer>
		);
	}
}

const AddOrganizationName = ({ onSubmit, loading }) => {
	return (
		<div className="organization-name__section">
			<img src="/static/img/add-organization.svg" alt="add organization" />
			<Heading marginTop="54px" fontSize="25px">
				What’s your organisation’s name?
			</Heading>
			<AddOrganizationNameForm loading={loading} onSubmit={onSubmit} />
		</div>
	);
};

const AssetCreationSuccess = ({ onClick }) => {
	return (
		<div className="asset-creation__success">
			{/* <img src="/static/img/asset-icon.svg" /> */}
			<Heading fontSize="32px" marginTop="16px">
				Asset Successfully Created
			</Heading>
			<ButtonComponent appearance="blue" onClick={onClick}>
				Go to your dashboard
			</ButtonComponent>
		</div>
	);
};
