import { Skeleton } from 'antd';
import { Icon } from 'evergreen-ui';
import React, { useState } from 'react';
import styled from 'styled-components';
import { blue } from '../../design-system/colors';
import { Heading } from '../../primitives/Text';
import VehicleHistoryItem from './VehicleHistoryItem';

const TripHistoryContainer = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  box-shadow: 0px -3px 5px 0 rgba(31, 49, 74, 0.1);

  & > .header {
    background-color: #e4e4e4;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 16px;
    cursor: pointer;

    & > .heading {
      font-size: 15px;
      margin-bottom: 0;
      font-family: Circular;
    }

    & > .collapse__icon {
      transition: transform 0.3s ease-in-out;

      &.open {
        transform: rotate(180deg);
      }
    }
  }

  & > .body {
    max-height: 0px;
    height: 0px;
    background-color: #fff;
    transition: all 0.3s ease-in-out;

    &.open {
      height: calc(100vh / 1.5);
      max-height: calc(100vh / 1.5);
      overflow-y: auto;
    }

    & > .trips {
      margin: 0;
      padding: 0;

      & > .trip:nth-child(even) {
        background-color: ${blue.faint};
      }
    }
  }
`;

const VehicleHistoryList = ({ locations, selectedLocation, setSelectedLocation }) =>
  locations.map((location, index) => (
    <VehicleHistoryItem {...{ location, selectedLocation, setSelectedLocation }} key={index} />
  ));

export default ({ loading, locations, selectedLocation, setSelectedLocation }) => {
  const [tripsShown, setTripsShown] = useState(false);

  const toggleTripsView = () => setTripsShown(!tripsShown);

  return (
    <TripHistoryContainer>
      <div className='header' onClick={toggleTripsView}>
        <Heading className='heading'>Trip History</Heading>
        <Icon icon='chevron-up' className={`collapse__icon ${tripsShown && 'open'}`} />
      </div>
      {locations && locations.length > 0 && (
        <div className={`body ${tripsShown && 'open'}`}>
          <ul className='trips'>
            <Skeleton loading={loading} active>
              <VehicleHistoryList {...{ locations, selectedLocation, setSelectedLocation }} />
            </Skeleton>
          </ul>
        </div>
      )}
    </TripHistoryContainer>
  );
};
