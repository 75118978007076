import { message } from 'antd';
import useSWR from 'swr';
import { makeApiCallAdmin } from '../../api';

function useManufacturers() {
  const { data: manufacturers, error, revalidate } = useSWR(
    '/Lookup/Manufacturer',
    fetchManufacturers
  );

  return { manufacturers, loading: !manufacturers && !error, revalidate };
}

async function fetchManufacturers(url) {
  try {
    const { data = [] } = await makeApiCallAdmin(url);
    return data;
  } catch (e) {
    message.error(e.message);
  }
}

export default useManufacturers;
