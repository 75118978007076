import { message } from 'antd';
import useSWR from 'swr';
import { makeApiCallAdmin } from '../../api';

function useFolderAssets(folderId) {
  const { data: { assets, folders } = {}, error, revalidate } = useSWR(
    `/MyFolder/${folderId || ''}`,
    fetchFolderAssets
  );

  return { assets, folders, loading: !assets && !folders && !error, revalidate };
}

async function fetchFolderAssets(url) {
  try {
    const { data = [] } = await makeApiCallAdmin(url);
    return {
      assets: data.filter(({ isNodeLeaf }) => isNodeLeaf),
      folders: data.filter(({ isNodeLeaf }) => !isNodeLeaf),
    };
  } catch (e) {
    message.error(e.message);
  }
}

export default useFolderAssets;
