import { message } from 'antd';
import useSWR from 'swr';
import { makeApiCallAdmin } from '../../api';

function useVehicleModels(manufacturerId) {
  const { data: models, error, revalidate } = useSWR(
    manufacturerId ? `/Lookup/Model/${manufacturerId}` : null,
    fetchModels
  );

  return { models, loading: !models && !error, revalidate };
}

async function fetchModels(url) {
  try {
    const { data = [] } = await makeApiCallAdmin(url);
    return data;
  } catch (e) {
    message.error(e.message);
  }
}

export default useVehicleModels;
