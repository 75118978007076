import React, { useState } from "react";
import { Switch } from "antd";
import { green, gray, white } from "../design-system/colors";
import { Text } from "./Text";
import styled from "styled-components";

const PlainSwitch = ({ name, size = "small", ...rest }) => {
	return (
		<ParamSwitchContainer className="ParamSwitchContainer">
			<Switch size={size} {...rest} />
			<Text>{name}</Text>
		</ParamSwitchContainer>
	);
};

export default PlainSwitch;
export const ParamSwitchContainer = styled.div`
	display: flex;
	align-items: center;

	.ant-switch {
		border: 1px solid ${gray.light};
		background-color: ${white};
	}

	.ant-switch-checked {
		background-color: ${green.primary};
		border-color: #00b649;
	}

	p {
		margin: 0;
		padding-left: 8px;
	}
`;
