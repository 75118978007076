import { message, Skeleton } from "antd";
import Axios from "axios";
import { Avatar } from "evergreen-ui";
import * as React from "react";
import { makeApiCallAdmin } from "../api";
import AuthContainer from "../components/pages/Authentication/index";
import { ButtonComponent } from "../components/primitives/Buttons";
import { AppModal, ToggleModal } from "../components/primitives/Modal";
import { Heading, Text } from "../components/primitives/Text";
import { AppContext } from "../DataProvider";
import { loadState, saveState } from "../localStorage";
import CreateClientForm from "../Settings/Clients/CreateClientForm";
import { useClients } from "../utils/hooks";

function SetClient({ history, location: { state } }) {
	const { clients = [], error, loading, revalidate } = useClients();
	const { user } = loadState();
	const { updateState } = React.useContext(AppContext);

	const setClient = async ({ clientId, clientName, balance }) => {
		try {
			const { token } = await makeApiCallAdmin("/login/impclient", {
				data: { clientId },
				method: "post",
			});

			Axios.defaults.headers.Authorization = `Bearer ${token}`;

			const updatedUser = { ...user, clientId, clientName, token };
			updateState(updatedUser, "user");
			saveState({ user: updatedUser });
			history.push(state ? state.referrer : "/");
		} catch (error) {
			message.error(
				"Error loading client details. Please check your network connection"
			);
		}
	};

	return (
		<AuthContainer width="100%">
			<div className="mt-24 mx-auto flex justify-center items-center flex-col max-w-2xl">
				<Heading className="text-center mb-10 text-2xl">
					Select a client workspace
				</Heading>

				{loading ? (
					<>
						{Array(4)
							.fill()
							.map((elem, index) => (
								<Skeleton
									key={index}
									paragraph={false}
									avatar={{ shape: "circle" }}
									active={true}
									className="mb-4"
								/>
							))}
					</>
				) : error ? (
					<>
						<Text className="text-center text-base">{error}</Text>
						<Text align="center">
							We encountered an error while trying to fetch list of clients.
							Please refresh this page to retry or contact an agent if issue
							persists
						</Text>
					</>
				) : !loading && clients.length > 0 ? (
					<ul className="list-style-type-none p-0 w-full max-w-xl">
						{clients.map((client) => (
							<li
								className="px-6 h-16 flex items-center shadow-md cursor-pointer rounded-md mb-4 hover:bg-blue-100 border-2 border-solid border-gray-100"
								key={client.clientId}
								onClick={() => setClient(client)}
							>
								<Avatar
									name={client.clientName}
									size={40}
									className="mr-4 text-lg"
								/>
								<span className="text-lg font-semibold">
									{client.clientName}
								</span>
							</li>
						))}
					</ul>
				) : (
					<Text>
						You have not created any clients. Click the button below to create
						one
					</Text>
				)}
				<ToggleModal>
					{(showModal, onOpenModal, onCloseModal) => (
						<>
							<AppModal
								title="Create Client"
								visible={showModal}
								onCancel={onCloseModal}
							>
								<CreateClientForm
									onCancel={() => {
										onCloseModal();
										revalidate();
									}}
								/>
							</AppModal>
							<ButtonComponent
								appearance="green"
								size="large"
								style={{ width: "300px" }}
								className="mt-12"
								onClick={onOpenModal}
							>
								New Client
							</ButtonComponent>
						</>
					)}
				</ToggleModal>
			</div>
		</AuthContainer>
	);
}

export default SetClient;
