import { isEmpty, isEqual } from 'lodash';
import React from 'react';
import styled from 'styled-components';
import { silver } from '../pages/Fleet/SilverMapStlye';
import { ButtonComponent } from './Buttons';

export const StyledMap = styled.div`
  #map {
    height: 500px;
    margin: 20px 0;
  }
  .delete-buttons {
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }
`;

class GeofenceMap extends React.Component {
  state = {
    all_overlays: [],
    markers: [],
    selectedShape: null,
    map: null,
    location: {},
  };

  onScriptLoad() {
    const map = new window.google.maps.Map(document.getElementById('map'), {
      zoom: 15,
      center: this.props.coordinate[0] || new window.google.maps.LatLng(6.5244, 3.3792),
      mapTypeId: window.google.maps.MapTypeId.ROADMAP,
      disableDefaultUI: true,
      zoomControl: true,
      fullscreenControl: true,
      styles: silver,
    });
    if (this.props.coordinate.length > 0) {
      const bounds = new window.google.maps.LatLngBounds();
      //extend bounds
      this.props.coordinate.forEach((route) => {
        bounds.extend(route);
      });
      //fit map to bounds
      map.fitBounds(bounds);
    }

    this.setState({
      map,
    });
    // this.props.onMapLoad(map);
    var polyOptions = {
      strokeWeight: 0,
      fillOpacity: 0.45,
      editable: true,
    };
    const drawingManager = new window.google.maps.drawing.DrawingManager({
      drawingMode: window.google.maps.drawing.OverlayType.POLYGON,
      markerOptions: {
        draggable: true,
      },
      polylineOptions: {
        editable: true,
      },
      drawingControlOptions: {
        drawingModes: [
          window.google.maps.drawing.OverlayType.CIRCLE,
          window.google.maps.drawing.OverlayType.POLYGON,
          window.google.maps.drawing.OverlayType.RECTANGLE,
        ],
      },
      rectangleOptions: polyOptions,
      circleOptions: polyOptions,
      polygonOptions: polyOptions,
      map: map,
    });
    window.google.maps.event.addListener(drawingManager, 'overlaycomplete', (e) => {
      const { all_overlays } = this.state;
      all_overlays.push(e);
      if (e.type !== window.google.maps.drawing.OverlayType.MARKER) {
        drawingManager.setDrawingMode(null);
        var newShape = e.overlay;
        newShape.type = e.type;
        window.google.maps.event.addListener(newShape, 'click', () => {
          this.setSelection(newShape);
        });
        this.setSelection(newShape);
      }
    });
    window.google.maps.event.addListener(
      drawingManager,
      'drawingmode_changed',
      this.clearSelection
    );
    window.google.maps.event.addListener(map, 'click', this.clearSelection);
    if (!isEmpty(this.props.coordinate)) {
      drawingManager.setDrawingMode(null);
      let polygon = new window.google.maps.Polygon({
        paths: this.props.coordinate,
        editable: true,
        clickable: true,
      });
      polygon.setMap(map);
      let { all_overlays } = this.state;
      all_overlays.push({ overlay: polygon });
      polygon.type = 'polygon';
      this.setSelection(polygon);
      map.setZoom(20);
    }
  }

  componentDidMount() {
    this.onScriptLoad();
  }

  componentDidUpdate(prevProps) {
    if (!isEqual(prevProps.location, this.props.location)) {
      this.addMarker();
    }
    if (!isEqual(prevProps.success, this.props.success) && this.props.success) {
      this.deleteAllShape();
      this.deleteAllMarkers();
    }
    if (!isEqual(prevProps.coordinate, this.props.coordinate) && !isEmpty(this.props.coordinate)) {
      this.onScriptLoad();
    }
  }

  addMarker = () => {
    const { map } = this.state;
    const { location } = this.props;
    if (!isEmpty(location)) {
      let marker = new window.google.maps.Marker({
        position: location,
      });
      this.setState(({ markers }) => ({
        markers: [marker, ...markers],
      }));
      let center = new window.google.maps.LatLng(location.lat, location.lng);
      map.panTo(center);
      map.setZoom(20);
      marker.setMap(map);
    }
  };
  clearSelection = () => {
    let { selectedShape } = this.state;
    if (selectedShape) {
      selectedShape.setEditable(false);
      this.setState({
        selectedShape: null,
      });
    }
    this.props.getGeofenceBounds(null);
  };

  setSelection = (shape) => {
    this.clearSelection();
    this.setState({
      selectedShape: shape,
    });
    shape.setEditable(true);
    this.props.getGeofenceBounds(shape);
  };

  deleteSelectedShape = () => {
    const { selectedShape } = this.state;
    if (selectedShape) {
      selectedShape.setMap(null);
    }
    this.props.getGeofenceBounds(null);
  };

  deleteAllShape = () => {
    const { all_overlays } = this.state;
    for (var i = 0; i < all_overlays.length; i++) {
      all_overlays[i].overlay.setMap(null);
    }
    this.setState({
      all_overlays: [],
    });
    this.props.getGeofenceBounds(null);
  };
  deleteAllMarkers = () => {
    const { markers } = this.state;
    for (var i = 0; i < markers.length; i++) {
      markers[i].setMap(null);
    }
  };
  render() {
    let conditionToShowDelete =
      this.state.all_overlays.length > 0 || this.props.coordinate.length > 0;
    return (
      <StyledMap>
        <div id="map" />
        <div className="delete-buttons">
          {/* <ButtonComponent onClick={this.deleteSelectedShape} size="small">
            Delete Selected Shape
          </ButtonComponent> */}
          {conditionToShowDelete && (
            <ButtonComponent onClick={this.deleteAllShape} size="small">
              Delete Geofence
            </ButtonComponent>
          )}
        </div>
      </StyledMap>
    );
  }
}

GeofenceMap.defaultProps = {
  onMapLoad: (map) => console.log(map),
};

export default GeofenceMap;
