import { message, Select } from 'antd';
import { Formik } from 'formik';
import * as React from 'react';
import * as yup from 'yup';
import { makeApiCallAdmin } from '../api';
import { black, red } from '../components/design-system/colors';
import { AppInput } from '../components/primitives/Input';
import { SaveAndCancelButtons } from '../components/primitives/SaveAndCancelButtons';
import { SelectStyle } from '../components/primitives/Select';
import { Label, Text } from '../components/primitives/Text';
import { useDrivers } from '../utils/hooks';

const { Option } = Select;

function AssignDriverForm({ asset, close }) {
  const { drivers = [] } = useDrivers();

  return (
    <Formik
      initialValues={{ driverId: '' }}
      onSubmit={async (values, actions) => {
        try {
          await makeApiCallAdmin(`/Assets/${asset.assetId}/AssignDriver`, {
            method: 'put',
            data: values,
          });
          message.success('Driver assigned successfully');
          close();
        } catch (error) {
          message.error(error.message);
        } finally {
          actions.setSubmitting(false);
        }
      }}
      validationSchema={validationSchema}
      enableReinitialize
    >
      {({ values, errors, touched, handleSubmit, isSubmitting, setFieldValue }) => {
        return (
          <form onSubmit={handleSubmit}>
            <AppInput
              name="asset"
              label="Asset"
              value={`${asset.tag} - ${asset.plateNo}`}
              disabled
            />
            <SelectStyle
              marginTop="20px"
              border={black}
              css={'.ant-select-search__field { top: 0; }'}
            >
              <Label>Select Driver To Assign</Label>
              <Select
                value={values.driverId}
                placeholder="Select a driver to assign"
                onChange={(value) => setFieldValue('driverId', value)}
                showSearch
                filterOption={(inputValue, option) => {
                  const isValueInIMEI = option.props.value
                    .toLowerCase()
                    .includes(inputValue.toLowerCase());
                  const isValueInName = `${option.props.children}`
                    .toLowerCase()
                    .includes(inputValue.toLowerCase());
                  return isValueInIMEI || isValueInName;
                }}
              >
                {drivers.map(({ id, firstName, lastName }) => (
                  <Option value={id} key={id} className="capitalize">
                    {firstName} {lastName}
                  </Option>
                ))}
              </Select>
              {errors.driverId && touched.driverId && (
                <Text fontSize="12px" marginTop="4px" color={red.primary}>
                  {errors.driverId}
                </Text>
              )}
            </SelectStyle>
            <div className="mt-10">
              <SaveAndCancelButtons
                successButtonAppearance="blue"
                saveText="Assign Driver"
                cancelText="Cancel"
                onCancel={close}
                loading={isSubmitting}
              />
            </div>
          </form>
        );
      }}
    </Formik>
  );
}

var validationSchema = yup.object().shape({
  driverId: yup.string().required('Please select a driver to assign'),
});

export default AssignDriverForm;
