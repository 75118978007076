import { Button, message } from "antd";
import { Formik } from "formik";
import * as React from "react";
import { useState } from "react";
import * as yup from "yup";
import { makeApiCallAdmin } from "../../api";
import { black } from "../../components/design-system/colors";
import { phoneRegExp } from "../../components/pages/Alerts/helpers";
import { AppInput } from "../../components/primitives/Input";
import { AppModal } from "../../components/primitives/Modal";
import { SaveAndCancelButtons } from "../../components/primitives/SaveAndCancelButtons";
import { SelectComponent } from "../../components/primitives/Select";
import { useCostPlans } from "../../utils/hooks";
import NewCostPlanForm from "../CostPlans/NewCostPlanForm";

function CreateClientForm({ onCancel, isEdit = false, client = {} }) {
	const [isNewCostPlanFormVisible, toggleNewCostPlanForm] = useState(false);
	const {
		costPlans = [],
		loading,
		revalidate: refetchCostPlans,
	} = useCostPlans();

	return (
		<>
			<Formik
				initialValues={{
					clientName: client.clientName || "",
					contactFirstName: client.contactFirstName || "",
					contactLastName: client.contactLastName || "",
					contactPhone: client.phone || "",
					contactEmail: client.email || "",
					address: client.address || "",
					costPlanId: client.costPlanId || "",
				}}
				onSubmit={async (values, { props, setSubmitting, resetForm }) => {
					try {
						await makeApiCallAdmin(`/Client/${client.clientId || ""}`, {
							method: isEdit ? "put" : "post",
							data: values,
						});
						message.success(
							`Client ${isEdit ? "updated" : "created"} successfully`
						);
						resetForm();
						onCancel();
					} catch (e) {
						message.error(e.message);
					} finally {
						setSubmitting(false);
					}
				}}
				validationSchema={validationSchema}
				enableReinitialize
			>
				{({
					values,
					errors,
					touched,
					handleSubmit,
					handleChange,
					setFieldValue,
					isSubmitting,
				}) => (
					<form onSubmit={handleSubmit}>
						<AppInput
							name="clientName"
							value={values.clientName}
							type="text"
							size="large"
							label="Client Name"
							placeholder=""
							error={touched.clientName && errors.clientName}
							errorMessage={errors.clientName}
							onChange={handleChange}
						/>
						{/* The contact info will have to be updated by the client since this is used to create
						 * a user upon client creation
						 */}
						<AppInput
							name="contactFirstName"
							value={values.contactFirstName}
							size="large"
							type="text"
							placeholder=""
							label="First Name"
							error={touched.contactFirstName && errors.contactFirstName}
							errorMessage={errors.contactFirstName}
							onChange={handleChange}
							disabled={isEdit}
						/>
						<AppInput
							name="contactLastName"
							value={values.contactLastName}
							size="large"
							type="text"
							placeholder=""
							label="Last Name"
							error={touched.contactLastName && errors.contactLastName}
							errorMessage={errors.contactLastName}
							onChange={handleChange}
							disabled={isEdit}
						/>
						<AppInput
							name="contactPhone"
							value={values.contactPhone}
							type="text"
							size="large"
							placeholder="08012345678"
							label="Phone Number"
							error={touched.contactPhone && errors.contactPhone}
							errorMessage={errors.contactPhone}
							onChange={handleChange}
							disabled={isEdit}
						/>
						<AppInput
							name="contactEmail"
							value={values.contactEmail}
							type="email"
							size="large"
							placeholder="staff@company.com"
							label="Email"
							error={touched.contactEmail && errors.contactEmail}
							errorMessage={errors.contactEmail}
							onChange={handleChange}
							disabled={isEdit}
						/>
						<AppInput
							name="address"
							value={values.address}
							type="address"
							size="large"
							placeholder=""
							label="Company address"
							error={touched.address && errors.address}
							errorMessage={errors.address}
							onChange={handleChange}
						/>
						<div className="mt-5">
							<SelectComponent
								name="costPlanId"
								value={values.costPlanId}
								border={black}
								size="large"
								placeholder="Select Cost Plan"
								label="Cost Plan"
								options={costPlans.map(({ costPlanId, costPlanName }) => ({
									value: costPlanId,
									label: costPlanName,
								}))}
								onChange={(e) => setFieldValue("costPlanId", e)}
								error={errors.costPlanId && touched.costPlanId}
								errorMessage={errors.costPlanId}
								loading={loading}
							/>
							<Button
								type="link"
								className="px-0"
								onClick={() => toggleNewCostPlanForm(true)}
							>
								Add new cost plan
							</Button>
						</div>
						<SaveAndCancelButtons onCancel={onCancel} loading={isSubmitting} />
					</form>
				)}
			</Formik>
			<AppModal
				title="Create Cost Plan"
				visible={isNewCostPlanFormVisible}
				onCancel={() => toggleNewCostPlanForm(false)}
			>
				<NewCostPlanForm
					close={() => {
						toggleNewCostPlanForm(false);
						refetchCostPlans();
					}}
				/>
			</AppModal>
		</>
	);
}

var validationSchema = yup.object().shape({
	clientName: yup.string().required("Enter client name"),
	contactFirstName: yup
		.string()
		.required("Enter firstname of the contact person"),
	contactLastName: yup
		.string()
		.required("Enter lastname of the contact person"),
	contactPhone: yup
		.string()
		.matches(phoneRegExp, "Phone number is not valid")
		.required("Enter phone number of the contact person"),
	contactEmail: yup
		.string()
		.email()
		.required("Enter email address of the contact person"),
	address: yup.string().required("Please provide client address"),
	costPlanId: yup.string().required("Please select a cost plan"),
});

export default CreateClientForm;
