import styled from "styled-components";
import { customFonts } from "../components/design-system/fonts";

export const ContactBodyWrapper = styled.div`
	display: flex;
	justify-content: center;
	flex-direction: column;
	align-items: center;

	margin-top: 2rem;
`;

export var StyledContactPage = styled.div`
	width: 100%;
	min-height: 100vh;
	height: auto;
	background: #f3f5f9;
	padding: 2rem 0;
	.logo {
		width: 120px;
		img {
			width: 100%;
		}
	}
	.back-arrow {
		padding: 0 1rem;
		a {
			img {
				width: 2rem;
			}
		}
	}
	.contact-heading {
		text-align: center;
		font-family: ${customFonts.circular};

		.logo {
			// background: #fff;
			width: 100px;
			// height: 40px;
			// border-radius: 50%;
			display: flex;
			align-items: center;
			justify-content: center;
			margin: auto;
		}
		h3 {
			margin-bottom: 0;
			font-family: ${customFonts.circular};
			font-weight: bold;
			letter-spacing: -0.1px;
		}
		.address {
			display: flex;
			align-items: center;
			justify-content: center;
			img {
				width: 1rem;
				height: 1rem;
			}
			p {
				margin-bottom: 0;
			}
		}
	}
`;
export const ContactCard = styled.div`
	background: #fff;
	padding: 2rem 1rem;
	border-radius: 8px;
	width: 50%;
	margin-bottom: 2rem;
	box-shadow: 4px 4px 15px 2px rgba(221, 221, 221, 1);
	font-family: ${customFonts.circular};
	h3 {
		text-align: center;
		font-weight: bold;
	}
	@media screen and (max-width: 575px) {
		width: 90%;
	}
`;
export const ContactInfo = styled.div`
	border-radius: 20px;
	padding: 0.8rem 1rem;
	display: flex;
	align-items: center;
	justify-content: space-between;
	width: 70%;
	margin: auto;
	margin-bottom: 1rem;
	background: rgba(174, 184, 255, 0.16);

	.contact-icon-container {
		width: 10%;
		display: inline-flex;
		justify-content: flex-end;

		.contact-card-icon {
			width: 30px;
			height: 30px;
		}
	}
	.contact-info-content-container {
		width: 85%;
		p {
			margin-bottom: 0;
			font-weight: bold;
			color: #5468ff;
		}
	}
	@media screen and (max-width: 575px) {
		width: 100%;
	}
`;
export const SocialContactInfo = styled.div`
	border-radius: 20px;
	width: 70%;
	margin: auto;
	margin-bottom: 1rem;
	background: rgba(174, 184, 255, 0.16);
	a {
		padding: 0.8rem 1rem;
		display: flex;
		align-items: center;
		justify-content: space-between;
	}
	.contact-icon-container {
		width: 10%;
		display: inline-flex;
		justify-content: flex-end;

		.contact-card-icon {
			width: 30px;
			height: 30px;
		}
	}
	.contact-info-content-container {
		width: 85%;
		p {
			margin-bottom: 0;
			font-weight: bold;
			color: #5468ff;
		}
	}
	@media screen and (max-width: 575px) {
		width: 100%;
	}
`;
export const ContactInfoDark = styled.div`
	border-radius: 20px;
	padding: 0.8rem 1rem;
	display: flex;
	width: 70%;
	margin: auto;
	align-items: center;
	margin-top: 1rem;
	justify-content: space-between;
	background: rgba(52, 67, 86, 0.1);
	.contact-icon-container {
		width: 10%;
		display: inline-flex;
		justify-content: flex-end;

		.contact-card-icon {
			width: 16px;
			height: 16px;
		}
	}
	.contact-info-content-container {
		width: 85%;
		p {
			margin-bottom: 0;
			font-weight: bold;
			color: #344356;
		}
	}
	@media screen and (max-width: 575px) {
		width: 100%;
	}
`;
