import React from "react";
import { Route } from "react-router-dom";
import SetClient from "./SetClient";
import SignIn from "./SignIn";
import ForgotPassword from "./ForgotPassword";
import ResetPassword from "./ResetPassword";
import VerifyPage from "./Verify";

export const AuthenticationRoutes = [
	<Route exact key="signin" path="/signin" component={SignIn} />,
	<Route
		exact
		key="forgot-password"
		path="/forgot-password"
		component={ForgotPassword}
	/>,
	<Route exact key="verify" path="/verify" component={VerifyPage} />,
	<Route
		exact
		key="reset-password"
		path="/reset-password"
		component={ResetPassword}
	/>,
	<Route exact key="set-client" path="/set-client" component={SetClient} />,
];
