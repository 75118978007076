import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';
import { ButtonComponent } from './Buttons';

const Container = styled.div`
  display: flex;
  margin-top: ${(props) => props.marginTop || '20px'};

  button {
    &:first-of-type {
      margin-right: 16px;
    }
  }
`;

export const SaveAndCancelButtons = ({
  type,
  onCancel,
  onSave,
  saveText,
  cancelText,
  loading,
  successButtonAppearance,
  buttonSize,
  marginTop,
}) => {
  return (
    <Container marginTop={marginTop}>
      <ButtonComponent
        onClick={(e) => {
          e.preventDefault();
          onCancel();
        }}
        size={buttonSize}
        disabled={loading}
      >
        {cancelText}
      </ButtonComponent>
      {type === 'submit' ? (
        <ButtonComponent
          css="width: auto"
          appearance={successButtonAppearance}
          htmlType={type}
          loading={loading}
          size={buttonSize}
        >
          {saveText}
        </ButtonComponent>
      ) : (
        <ButtonComponent
          css="width: auto"
          appearance={successButtonAppearance}
          htmlType={type}
          onClick={onSave}
          loading={loading}
          size={buttonSize}
        >
          {saveText}
        </ButtonComponent>
      )}
    </Container>
  );
};

SaveAndCancelButtons.defaultProps = {
  type: 'submit',
  saveText: 'Save',
  cancelText: 'Cancel',
  successButtonAppearance: 'green',
  buttonSize: 'default',
  onSave: () => {},
  onCancel: () => {},
};

SaveAndCancelButtons.propTypes = {
  type: PropTypes.string,
  saveText: PropTypes.string,
  cancelText: PropTypes.string,
  successButtonAppearance: PropTypes.string,
  buttonSize: PropTypes.string,
  onSave: PropTypes.func,
  onCancel: PropTypes.func,
};
