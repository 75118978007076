import { isEmpty, isEqual } from "lodash";
import React from "react";
import Geosuggest from "react-geosuggest";
import styled from "styled-components";
import { black, gray, red } from "../design-system/colors";
import { Label, Text } from "./Text";

const AddressInputDiv = styled.div`
	margin-top: ${(props) => props.marginTop};
	label {
		padding-bottom: 4px;
	}
	.geosuggest {
		font-size: 16px;
		position: relative;
		text-align: left;
	}
	.geosuggest__input {
		width: 100%;
		outline: none;
		border: 1px solid ${(props) => props.border};
		padding: 0 11px;
		padding: 0.5em 1em;
		-webkit-transition: border 0.2s, box-shadow 0.2s;
		transition: border 0.2s, box-shadow 0.2s;
		color: black;
		${(props) => generateSize(props.size)};
	}
	.geosuggest__input:focus {
		border-color: ${(props) => props.border};
	}
	.geosuggest__suggests {
		position: absolute;
		top: 100%;
		left: 0;
		right: 0;
		max-height: 25em;
		padding: 0;
		margin-top: -1px;
		background: #fff;
		border: 1px solid ${black};
		border-top-width: 1px;
		overflow-x: hidden;
		overflow-y: auto;
		list-style: none;
		z-index: 5;
		-webkit-transition: max-height 0.2s, border 0.2s;
		transition: max-height 0.2s, border 0.2s;
	}
	.geosuggest__suggests--hidden {
		max-height: 0;
		overflow: hidden;
		border-width: 0;
	}
	.geosuggest__item {
		font-size: 18px;
		font-size: 1rem;
		padding: 0.5em 0.65em;
		color: ${black};
		cursor: pointer;
	}
	.geosuggest__item:hover,
	.geosuggest__item:focus {
		background: ${gray.ui01};
	}
	.geosuggest__item--active {
		background: #267dc0;
		color: #fff;
	}
	.geosuggest__item--active:hover,
	.geosuggest__item--active:focus {
		background: #ccc;
	}
	.geosuggest__item__matched-text {
		font-weight: bold;
	}
`;

const generateSize = (size) => {
	if (size === "large") {
		return `height: 40px;`;
	}
	if (size === "small") {
		return `height: 24px;`;
	}
	return `height: 32px;`;
};

export class AddressInput extends React.Component {
	onSuggestSelect = (location) => {
		if (!isEmpty(location)) {
			this.props.onChange(location);
		}
	};
	componentDidUpdate(prevProps) {
		if (!isEqual(prevProps.success, this.props.success) && this.props.success) {
			this._geoSuggest.clear();
		}
	}
	render() {
		const {
			placeholder,
			initialValue,
			label,
			errorMessage,
			error,
			radius,

			marginTop,
			border,
			size,
		} = this.props;

		return (
			<AddressInputDiv marginTop={marginTop} border={border} size={size}>
				{label && <Label>{label}</Label>}
				<Geosuggest
					ref={(el) => (this._geoSuggest = el)}
					placeholder={placeholder}
					initialValue={initialValue}
					onSuggestSelect={this.onSuggestSelect}
					onChange={this.props.onInputChange}
					location={
						window.google
							? new window.google.maps.LatLng(53.558572, 9.9278215)
							: ""
					}
					radius={radius}
					minLength={3}
					country="ng"
				/>
				{error && (
					<Text fontSize="12px" marginTop="4px" color={red.primary}>
						{errorMessage}
					</Text>
				)}
			</AddressInputDiv>
		);
	}
}

AddressInput.defaultProps = {
	initialValue: "",
	placeholder: "Enter address",
	marginTop: "20px",
	radius: "20",
	border: black,
};
