import { message } from "antd";
import Axios from "axios";
import { Formik } from "formik";
import * as React from "react";
import { NavLink } from "react-router-dom";
import * as yup from "yup";
import { makeApiCallAdmin } from "../api";
import { gray } from "../components/design-system/colors";
import AuthContainer from "../components/pages/Authentication/index";
import { ButtonComponent } from "../components/primitives/Buttons";
import { InputComponent } from "../components/primitives/Input";
import { Heading, Text } from "../components/primitives/Text";
import { AppContext } from "../DataProvider";
import { saveState } from "../localStorage";

function SignIn({ history, match: { url } }) {
	const { updateState } = React.useContext(AppContext);
	return (
		<AuthContainer url={url}>
			<Heading align="center" fontSize="30px">
				Sign In
			</Heading>
			<Formik
				initialValues={{ email: "", password: "" }}
				validationSchema={validationSchema}
				onSubmit={async (values, actions) => {
					try {
						let { data: user, token } = await makeApiCallAdmin("/login", {
							method: "post",
							data: values,
						});
						Axios.defaults.headers.Authorization = `Bearer ${token}`;
						updateState({ ...user, token }, "user");
						saveState({ user: { ...user, token } });
						history.push(user.userType === 1 ? "/set-client" : "/");
					} catch (error) {
						message.error(error.message);
					} finally {
						actions.setSubmitting(false);
					}
				}}
			>
				{({
					values,
					errors,
					touched,
					handleChange,
					handleSubmit,
					isSubmitting,
				}) => (
					<form onSubmit={handleSubmit}>
						<InputComponent
							name="email"
							type="text"
							placeholder="Email Address"
							size="large"
							value={values.email}
							error={errors.email && touched.email}
							errorMessage={errors.email}
							onChange={handleChange}
						/>
						<InputComponent
							name="password"
							type="password"
							placeholder="Password"
							size="large"
							value={values.password}
							error={errors.password && touched.password}
							errorMessage={errors.password}
							onChange={handleChange}
						/>
						<ButtonComponent
							size="large"
							appearance="blue"
							htmlType="submit"
							loading={isSubmitting}
							className="mt-5 w-full"
						>
							Sign In
						</ButtonComponent>
					</form>
				)}
			</Formik>
			<div className="sign-in-help__links">
				<Text align="center" marginTop="32px" color={gray.primary}>
					Don’t have an account? <NavLink to="/signup">Sign up</NavLink>
				</Text>
				<Text align="center" marginTop="15px">
					<NavLink to="/forgot-password">Forgot Password</NavLink>
				</Text>
				<Text align="center" marginTop="15px">
					<NavLink to="/contact">Contact Us</NavLink>
				</Text>
			</div>
		</AuthContainer>
	);
}

var validationSchema = yup.object().shape({
	email: yup
		.string()
		.email("This is an invalid email")
		.required("This field is required"),
	password: yup.string().required("This field is required"),
});

export default SignIn;
