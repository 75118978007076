import React from 'react';
import styled from 'styled-components';
import { DateTimePicker } from '@atlaskit/datetime-picker';
import DateRangePicker from '@wojtekmaj/react-daterange-picker';
import { DatePicker, TimePicker } from 'antd';
import { Label, Text } from './Text';
import { gray, black, red } from '../design-system/colors';

const datepickerTimes = [
  '00:00',
  '00:30',
  '01:00',
  '01:30',
  '02:00',
  '02:30',
  '03:00',
  '03:30',
  '04:00',
  '04:30',
  '05:00',
  '05:30',
  '06:00',
  '06:30',
  '07:00',
  '07:30',
  '08:00',
  '08:30',
  '09:00',
  '09:30',
  '10:00',
  '10:30',
  '11:00',
  '11:30',
  '12:00',
  '12:30',
  '13:00',
  '13:30',
  '14:00',
  '14:30',
  '15:00',
  '15:30',
  '16:00',
  '16:30',
  '17:00',
  '17:30',
  '18:00',
  '18:30',
  '19:00',
  '19:30',
  '20:00',
  '20:30',
  '21:00',
  '21:30',
  '22:00',
  '22:30',
  '23:00',
  '23:30',
];

const { RangePicker } = DatePicker;

const DatePickerContainer = styled.div`
  margin-top: ${props => props.marginTop};

  label {
    padding-bottom: 4px;
  }
  .ant-input:focus {
    border-color: ${props => props.border || gray.light};
    outline: 0;
    box-shadow: none;
    border-right-width: 1px !important;
  }
  .ant-calendar-picker {
    width: 100%;
  }
  .ant-calendar-picker-icon {
    display: none;
  }
  .ant-calendar-picker-input {
    border-radius: 0;
    border-color: ${props => props.border || gray.light};
    width: 100%;
    &::-webkit-input-placeholder {
      color: ${black};
    }
    &::-moz-placeholder {
      color: ${black};
    }
    &::-ms-input-placeholder {
      color: ${black};
    }
  }
  .ant-calendar-picker:focus
    .ant-calendar-picker-input:not(.ant-input-disabled) {
    border-color: ${props => props.border || gray.light};
    outline: 0;
    box-shadow: none;
    border-right-width: 1px !important;
  }
  .ant-calendar-picker:hover
    .ant-calendar-picker-input:not(.ant-input-disabled) {
    border-color: ${props => props.border || gray.light};
  }

  .ant-calendar-range-picker-input {
    &::-webkit-input-placeholder {
      color: ${black};
    }
    &::-moz-placeholder {
      color: ${black};
    }
    &::-ms-input-placeholder {
      color: ${black};
    }
  }
`;

export const DatePickerComponent = props => {
  return (
    <DatePickerContainer marginTop={props.marginTop} border={props.border}>
      {props.label && <Label>{props.label}</Label>}
      <DatePicker {...props} />
      {props.error && (
        <Text fontSize="12px" marginTop="4px" color={red.primary}>
          {props.errorMessage}
        </Text>
      )}
    </DatePickerContainer>
  );
};

DatePickerComponent.defaultProps = {
  errorMessage: 'This field is required',
};

export const RangePickerComponent = props => {
  return (
    <DatePickerContainer
      marginTop={props.marginTop}
      border={props.border}
      className="RangePickerComponent"
    >
      {props.label && <Label>{props.label}</Label>}
      <RangePicker {...props} />
      {props.error && (
        <Text fontSize="12px" marginTop="4px" color={red.primary}>
          {props.errorMessage}
        </Text>
      )}
    </DatePickerContainer>
  );
};

RangePickerComponent.defaultProps = {
  errorMessage: 'This field is required',
};

const TimePickerContainer = styled.div`
  margin-top: ${props => props.marginTop};

  label {
    padding-bottom: 4px;
  }
  .ant-input:focus {
    border-color: ${props => props.border || gray.light};
    outline: 0;
    box-shadow: none;
    border-right-width: 1px !important;
  }
  .ant-time-picker {
    width: 100%;
  }
  .ant-time-picker-icon {
    display: none;
  }
  .ant-time-picker-input {
    border-radius: 0;
    border-color: ${props => props.border || gray.light};
    width: 100%;
    &::-webkit-input-placeholder {
      color: ${black};
    }
    &::-moz-placeholder {
      color: ${black};
    }
    &::-ms-input-placeholder {
      color: ${black};
    }
  }
  .ant-time-picker:focus .ant-time-picker-input:not(.ant-input-disabled) {
    border-color: ${props => props.border || gray.light};
    outline: 0;
    box-shadow: none;
    border-right-width: 1px !important;
  }
  .ant-time-picker:hover .ant-time-picker-input:not(.ant-input-disabled) {
    border-color: ${props => props.border || gray.light};
  }
`;

export class TimePickerComponent extends React.Component {
  state = {
    value: this.props.value,
  };
  onChange = value => {
    this.setState(
      {
        value,
      },
      () => {
        this.props.onChange(value);
      }
    );
  };
  render() {
    const {
      marginTop,
      border,
      label,
      error,
      errorMessage,
      value,
      onChange,
      ...rest
    } = this.props;
    return (
      <TimePickerContainer marginTop={marginTop} border={border}>
        {label && <Label>{label}</Label>}
        <TimePicker
          onChange={this.onChange}
          value={this.state.value}
          {...rest}
        />
        {error && (
          <Text fontSize="12px" marginTop="4px" color={red.primary}>
            {errorMessage}
          </Text>
        )}
      </TimePickerContainer>
    );
  }
}

const StyledDateRangeInput = styled.div`
  margin-top: ${props => props.marginTop};

  label {
    padding-bottom: 4px;
  }
`;

export const DateTimePickerComponent = props => (
  <StyledDateRangeInput>
    {props.label && <Label>{props.label}</Label>}
    <DateTimePicker {...props} />
  </StyledDateRangeInput>
);

const StyledDateRangePickerComponent = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;

  & > div {
    width: 50%;
    border-radius: 0;
    &:first-child {
      margin-right: 8px;
    }
    &:last-child {
      margin-left: 8px;
    }
  }
  .DateRangePicker__input {
    border-color: ${black};
  }
  .react-daterange-picker__wrapper {
    border-color: ${black};
    padding: 5px 8px;
  }
  .react-daterange-picker__inputGroup {
    color: ${black};
  }
  .react-daterange-picker__inputGroup__input {
    outline: none;
  }
  .react-daterange-picker__calendar--open {
    right: -77px !important;
  }
`;

export class DateRangePickerComponent extends React.Component {
  state = {
    startDate: this.props.value ? this.props.value[0] : '',
    endDate: this.props.value ? this.props.value[0] : '',
  };
  setStartDate = date => {
    this.setState(
      {
        startDate: date,
      },
      () => {
        this.props.onChange(this.state);
      }
    );
  };
  setEndDate = date => {
    this.setState(
      {
        endDate: date,
      },
      () => {
        this.props.onChange(this.state);
      }
    );
  };
  render() {
    const { startDate, endDate } = this.state;
    const { border } = this.props;
    return (
      <StyledDateRangePickerComponent>
        <DateTimePickerComponent
          value={startDate}
          onChange={this.setStartDate}
          timePickerProps={{
            timeIsEditable: true,
            placeholder: '8:00am',
            times: datepickerTimes,
          }}
          datePickerProps={{
            placeholder: 'Start Time',
            dateFormat: 'DD/MM/YYYY',
          }}
          innerProps={{
            style: {
              borderRadius: '0',
              backgroundColor: 'transparent',
              borderColor: border,
              borderWidth: '1px',
            },
          }}
        />
        <DateTimePickerComponent
          value={endDate}
          onChange={this.setEndDate}
          timePickerProps={{
            timeIsEditable: true,
            placeholder: '10:00am',
            times: datepickerTimes,
          }}
          datePickerProps={{
            placeholder: 'End Time',
            dateFormat: 'DD/MM/YYYY',
          }}
          innerProps={{
            style: {
              borderRadius: '0',
              backgroundColor: 'transparent',
              borderColor: border,
              borderWidth: '1px',
            },
          }}
        />
      </StyledDateRangePickerComponent>
    );
  }
}

DateRangePickerComponent.defaultProps = {
  border: black,
};

export class DateRangePickerComponent2 extends React.Component {
  state = {
    date:
      this.props.value.length > 0 ? this.props.value : [new Date(), new Date()],
  };

  onChange = date => {
    this.setState({ date }, () => {
      this.props.onChange(date);
    });
  };

  render() {
    return (
      <StyledDateRangePickerComponent>
        <DateRangePicker
          onChange={this.onChange}
          value={this.state.date}
          clearIcon={null}
          // calendarIcon={null}
          calendarClassName="DateRangePicker__input"
        />
      </StyledDateRangePickerComponent>
    );
  }
}

DateRangePickerComponent2.defaultProps = {
  border: black,
};
