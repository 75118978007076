import { message } from 'antd';
import useSWR from 'swr';
import { makeApiCallAdmin } from '../../../api';

function useIdleAssets() {
  const { data: idleAssets, error, revalidate } = useSWR('/Dashboard/Idling', fetchIdleAssets);

  return { idleAssets, loading: !idleAssets && !error, revalidate };
}

async function fetchIdleAssets(url) {
  try {
    const { data = [] } = await makeApiCallAdmin(url);
    return data;
  } catch (e) {
    message.error(e.message);
  }
}

export default useIdleAssets;
