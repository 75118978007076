import { Skeleton } from 'antd';
import { isEmpty } from 'lodash';
import moment from 'moment';
import * as React from 'react';
import { gray } from '../components/design-system/colors';
import { AppTable } from '../components/primitives/Table';
import { Heading } from '../components/primitives/Text';
import { useFleetSummary } from '../utils/hooks';

const columns = [
  {
    title: 'Asset Name',
    dataIndex: 'assetName',
  },
  {
    title: 'Driver Name',
    dataIndex: 'driverName',
  },
  {
    title: 'Fleet',
    dataIndex: 'fleetGroup',
  },
  {
    title: 'Last event',
    dataIndex: 'lastEvent',
    render: (lastEvent) => <span>{lastEvent || '-'}</span>,
  },
  {
    title: 'Date/Time',
    dataIndex: 'time',
    render: (time) => <span>{moment.unix(time).format('DD/MM/YYYY hh:mm a')}</span>,
  },
  {
    title: 'status',
    dataIndex: 'status',
    render: (status) => (
      <span style={{ color: status ? '#00ab44' : '#ed6347' }}>
        {status ? 'Active' : 'Inactive'}
      </span>
    ),
  },
  {
    title: 'speed',
    dataIndex: 'speed',
  },
  {
    title: 'Odometer',
    dataIndex: 'odometer',
  },
];

function FleetSummaryPage() {
  const { fleetSummary = [], loading } = useFleetSummary();

  return (
    <div className="Dashboard">
      <div className="Dashboard__content">
        <Heading marginTop="32px">Fleet Summary</Heading>
        <Skeleton loading={loading}>
          {isEmpty(fleetSummary) ? (
            <p>Data unavailable</p>
          ) : (
            <AppTable
              hasBorder
              marginTop="32px"
              dataSource={fleetSummary}
              loading={loading}
              border_color={gray.light}
              rowKey="assetName"
              columns={columns}
              pagination={fleetSummary.length > 20 ? { pageSize: 20 } : false}
            />
          )}
        </Skeleton>
      </div>
    </div>
  );
}

export default FleetSummaryPage;
