import { Dropdown, Icon, Menu, message, Popconfirm, Skeleton } from "antd";
import React from "react";
import styled from "styled-components";
import { cache, mutate } from "swr";
import { makeApiCallAdmin } from "../../../api";
import { black, red } from "../../design-system/colors";
import { EmptyButtonComponent } from "../../primitives/Buttons";
import { AppModal, ToggleModal } from "../../primitives/Modal";
import { Text } from "../../primitives/Text";
import { CreateFolderForm } from "./forms";
import MoveItem from "./MoveItem";

function FolderItem2(props) {
	const {
		nodeId,
		nodeDesc,
		loading,
		onClick,
		showMenu = true,
		isNodeLeaf,
		parentNodeId,
		parentNodeDesc,
		hoverable = true,
	} = props;

	const deleteFolder = async (nodeId) => {
		try {
			await makeApiCallAdmin(`/MyFolder/${nodeId}`, { method: "delete" });
			// https://github.com/vercel/swr/issues/418#issuecomment-637043907
			cache
				.keys() // get all the cached keys
				// filter by key, here you could also use a regex
				.filter((key) => key.includes("MyFolder"))
				// trigger the revalidation
				.forEach((key) => mutate(key, undefined, true));
		} catch (error) {
			message.error(error.message);
		}
	};

	return (
		<Skeleton active loading={loading} paragraph={false}>
			<FolderItemDiv hoverable={hoverable} className="FolderItem">
				<EmptyButtonComponent
					className="FolderItem__button"
					onClick={() =>
						onClick({
							nodeId,
							nodeDesc,
							isNodeLeaf,
							parentNodeDesc,
							parentNodeId,
						})
					}
				>
					<div className="FolderItem__info">
						<div className="FolderItem__name">
							<img src="/static/img/folder.svg" alt="folder icon" />
							<Text className="Text" color={black}>
								{nodeDesc}
							</Text>
						</div>
					</div>
				</EmptyButtonComponent>
				{showMenu && (
					<Dropdown
						overlay={
							<Menu style={{ minWidth: "120px" }}>
								<Menu.Item>
									<ToggleModal>
										{(showModal, onOpenModal, onCloseModal) => (
											<>
												<AppModal
													title="Edit Folder"
													visible={showModal}
													onCancel={onCloseModal}
													zIndex={99999}
												>
													<CreateFolderForm
														{...{
															nodeId,
															nodeDesc,
															isNodeLeaf,
															parentNodeId,
															parentNodeDesc,
														}}
														close={onCloseModal}
													/>
												</AppModal>
												<a onClick={onOpenModal}>Edit</a>
											</>
										)}
									</ToggleModal>
								</Menu.Item>
								<Menu.Item>
									<ToggleModal>
										{(showModal, onOpenModal, onCloseModal) => (
											<>
												<AppModal
													title={`Move ${nodeDesc} to... `}
													visible={showModal}
													onCancel={onCloseModal}
													zIndex={99999}
												>
													<MoveItem
														node={{
															nodeId,
															nodeDesc,
															isNodeLeaf,
															parentNodeId,
															parentNodeDesc,
														}}
														close={onCloseModal}
													/>
												</AppModal>
												<a onClick={onOpenModal}>Move</a>
											</>
										)}
									</ToggleModal>
								</Menu.Item>
								<Menu.Item>
									<Popconfirm
										title="Are you sure you want to delete this folder?"
										onConfirm={() => deleteFolder(nodeId)}
										okText="Delete"
										cancelText="Cancel"
										icon={
											<Icon type="question-circle-o" style={{ color: "red" }} />
										}
									>
										<div role="button" style={{ color: red.primary }}>
											Delete
										</div>
									</Popconfirm>
								</Menu.Item>
							</Menu>
						}
						trigger={["click"]}
					>
						<EmptyButtonComponent className="FolderItem__context-actions">
							<Icon type="ellipsis" />
						</EmptyButtonComponent>
					</Dropdown>
				)}
			</FolderItemDiv>
		</Skeleton>
	);
}

var FolderItemDiv = styled.div`
	display: flex;
	align-items: center;

	.FolderItem {
		&__button {
			display: flex;
			justify-content: space-between;
			padding: 8px 0;
			width: 100%;

			.ant-skeleton-title {
				margin-top: 0;
			}
		}
		&__info {
			display: flex;
		}
		&__name {
			display: flex;
			align-items: center;

			.Text {
				margin: 0;
				margin-left: 8px;
				opacity: ${(props) => (props.isCollapsed ? 0 : 1)};
				transition: opacity 0.2s ease-in-out;
				will-change: opacity;
			}
		}
		&__icon {
			.anticon {
				font-size: 12px;
				color: ${black};
			}
		}
		&__context-actions {
			position: relative;
			top: 1px;
		}
	}
	&:hover {
		${(props) =>
			props.hoverable ? `background-color: rgba(85, 172, 238, 0.1)` : ``};
	}
`;

export default FolderItem2;
