import { Col, Icon, Row, Select, Switch } from "antd";
import { isEmpty, isEqual } from "lodash";
import moment from "moment";
import React from "react";
import PlainSelect from "../../primitives/PlainSelect";
import { mutate } from "swr";
import { black, white } from "../../design-system/colors";
import {
	ButtonComponent,
	EmptyButtonComponent,
} from "../../primitives/Buttons";
import { TimePickerComponent } from "../../primitives/DatePicker";
import { AppInput } from "../../primitives/Input";
import { SaveAndCancelButtons } from "../../primitives/SaveAndCancelButtons";
import { SelectComponent } from "../../primitives/Select";
import { SwitchGroup } from "../../primitives/SwitchGroup";
import PlainSwitch from "../../primitives/PlainSwitch";
import { Heading, Text } from "../../primitives/Text";
import { AppTextArea } from "../../primitives/TextArea";
import { SeverityLevel } from "..//Alerts/AlertPage";
import { AlertSectionContainer, AlertSectionFormContainer } from "./AssetAlert";
import { AlertFormItem } from "./components";

const { Option } = Select;

export class DriverAlertForm extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			fields: isEmpty(this.props.fields)
				? {
						Type: "all-vehicles",
						violationTypeParams: {},
				  }
				: {
						...this.props.fields,
						triggerAlert: this.getTriggerAlertValue(
							this.props.fields.violationTypeParams
						),
						contacts: [],
				  },
			error: false,
			disableAlertPeriod: this.props.fields.disableAlertPeriod || [],
		};
	}
	componentDidUpdate(prevProps) {
		if (!isEqual(prevProps.fields, this.props.fields)) {
			this.setState({
				...this.state,
				fields: isEmpty(this.props.fields)
					? {
							Type: "all-vehicles",
							violationTypeParams: {},
					  }
					: {
							...this.props.fields,
							triggerAlert: this.getTriggerAlertValue(
								this.props.fields.violationTypeParams
							),
							contacts: [],
					  },
				disableAlertPeriod: this.props.fields.disableAlertPeriod || [],
			});
		}
	}
	setSingleDisableAlertPeriod = () => {
		this.setState(({ disableAlertPeriod }) => ({
			disableAlertPeriod: [
				{
					startTime: moment("12:00:00", "HH:mm:ss"),
					endTime: moment("12:00:00", "HH:mm:ss"),
				},
				...disableAlertPeriod,
			],
		}));
	};
	addDisableAlertPeriod = (id) => {
		const { disableAlertPeriod } = this.state;
		let { startTime, endTime } = this.state.fields;
		if (id) {
			const indexOfAlertPeriod = disableAlertPeriod.findIndex(
				(value, i) => id === i
			);
			if (startTime) {
				disableAlertPeriod[indexOfAlertPeriod].startTime = startTime;
			}
			if (endTime) {
				disableAlertPeriod[indexOfAlertPeriod].endTime = endTime;
			}
			if (startTime && endTime) {
				disableAlertPeriod[indexOfAlertPeriod] = { startTime, endTime };
			}
			this.setState({
				disableAlertPeriod,
			});
		} else {
			const indexOfEmpty = disableAlertPeriod.findIndex((value) =>
				isEqual(value, {
					startTime: moment("12:00:00", "HH:mm:ss"),
					endTime: moment("12:00:00", "HH:mm:ss"),
				})
			);
			disableAlertPeriod[indexOfEmpty] = { startTime, endTime };
			this.setState({
				disableAlertPeriod,
			});
		}
	};
	removeAlertPeriod = (e, index) => {
		e.preventDefault();
		this.setState(({ disableAlertPeriod }) => ({
			disableAlertPeriod: disableAlertPeriod.filter(
				(alertPeriod, i) => i !== index
			),
		}));
	};
	onChange = (e, field) => {
		const { fields } = this.state;
		fields[field] = e.target.value;
		this.setState({
			fields,
		});
	};
	onDatePickerChange = (value, field, callback) => {
		const { fields } = this.state;
		fields[field] = value ? moment(value).format("HH:mm:ss") : "";
		this.setState(
			{
				fields,
			},
			() => {
				if (callback) {
					callback();
				} else return;
			}
		);
	};
	onSelectChange = (value, field) => {
		const { fields } = this.state;
		fields[field] = value;
		this.setState({
			fields,
		});
	};
	onSwitchGroupChange = (value) => {
		const { fields } = this.state;

		fields.violationTypeParams = {
			ViolationType: 104,
			otherViolationParameter: value.map((item) => ({
				paramName: item.paramName,
				parameterValue: item.parameterValue,
			})),
		};

		this.setState({
			fields,
		});
	};
	displayError = (field) => {
		let { fields, error } = this.state;
		return error && !Boolean(fields[field]);
	};

	isValid = () => {
		let { fields } = this.state;
		let newData = { ...fields };
		let isValid = [
			"triggerAlert",
			"severity",
			"label",
			"description",
		].every((x) => Boolean(newData[x]));
		return isValid;
	};
	clearForm = () => {
		this.setState({
			fields: {},
		});
	};
	formatContacts = (contacts) => {
		let phoneNumber = [];
		let emailAddress = [];
		if (contacts) {
			if (this.props.edit) {
				contacts.forEach((contact) => {
					emailAddress.push(contact);
				});
			} else {
				contacts.forEach((contact) => {
					let splitContact = contact.split("-");
					emailAddress.push(splitContact[0].trim());
					phoneNumber.push(splitContact[1].trim());
				});
			}

			return { emailAddress, phoneNumber };
		}
	};
	onSubmit = (e) => {
		e.preventDefault();
		const { onSubmit, onCancel } = this.props;
		const { fields, disableAlertPeriod } = this.state;
		const { edit, Type, endTime, startTime, contacts, ...rest } = fields;

		if (fields.triggerAlert) {
			let triggerAlertValue = rest.violationTypeParams.otherViolationParameter.find(
				(item) => item.paramName === 1061
			);
			let triggerAlertValueId = rest.violationTypeParams.otherViolationParameter.findIndex(
				(item) => item.paramName === 1061
			);
			if (triggerAlertValue) {
				rest.violationTypeParams.otherViolationParameter = [
					...rest.violationTypeParams.otherViolationParameter.slice(
						0,
						triggerAlertValueId
					),
					{ ...triggerAlertValue, parameterValue: fields.triggerAlert },
					...rest.violationTypeParams.otherViolationParameter.slice(
						triggerAlertValueId + 1
					),
				];
			} else {
				rest.violationTypeParams.otherViolationParameter.push({
					paramName: 1061,
					parameterValue: fields.triggerAlert,
				});
			}
		}
		const payload = {
			...rest,
			...this.formatContacts(contacts),
			disableAlertPeriod,
		};

		if (this.isValid()) {
			onSubmit(payload, () => {
				onCancel();
				mutate("alerts");
				this.clearForm();
			});
		} else {
			this.setState({
				error: true,
			});
		}
	};
	getTriggerAlertValue = (data) => {
		if (!isEmpty(data)) {
			const { otherViolationParameter } = data;
			let triggerAlertValue = otherViolationParameter.find(
				(parameter) => parameter.paramName === 1061
			);
			return triggerAlertValue ? triggerAlertValue.parameterValue : "";
		}
		return "";
	};
	renderContactList = () => {
		const { contacts } = this.props;
		const contactList = [];
		contacts.forEach((contact) => {
			contactList.push(<Option key={contact.label}>{contact.label}</Option>);
		});
		return contactList;
	};

	render() {
		const { onCancel, loading, contacts, edit } = this.props;
		const { fields } = this.state;

		return (
			<AlertSectionFormContainer>
				<div className="flex-row baseline">
					<Col span={11}>
						<AppInput
							name="label"
							type="text"
							label="Name"
							size="large"
							placeholder="Alert Name"
							borderColor={black}
							value={fields.label}
							onChange={(e) => this.onChange(e, "label")}
							error={this.displayError("label")}
							marginTop="0"
						/>
					</Col>
					<Col span={11}>
						<SelectComponent
							marginTop="32px"
							size="large"
							label="Severity Level"
							placeholder="Severity Level"
							options={SeverityLevel}
							border={black}
							value={fields.severity}
							onChange={(e) => this.onSelectChange(e, "severity")}
							error={this.displayError("severity")}
						/>
					</Col>
				</div>
				<Row gutter={8}>
					<Col>
						<AlertFormItem
							noBorder
							paddingTop="54px"
							paddingBottom="0px"
							className="alert__form-item"
						>
							<div className="switch-wrapper">
								<SwitchGroup
									onChange={this.onSwitchGroupChange}
									params={[
										{
											name: "Speed Limit",
											paramName: 1021,
											parameterValue: false,
										},
										{
											name: "Seat Belt",
											paramName: 1044,
											parameterValue: 1044,
										},
										{
											name: "Harsh Acceleration",
											paramName: 1041,
											parameterValue: false,
										},
										{
											name: "Harsh Turning",
											paramName: 1043,
											parameterValue: false,
										},
										{
											name: "Harsh Braking",
											paramName: 1042,
											parameterValue: false,
										},
									]}
									selected={
										fields.violationTypeParams
											? fields.violationTypeParams.otherViolationParameter
											: []
									}
								/>
							</div>
						</AlertFormItem>
					</Col>
				</Row>
				<AlertFormItem className="alert__form-item">
					<div className="alert__form-item__flex">
						<div>
							<Text className="Text">
								Only alert if this condition holds for more than
							</Text>
						</div>
						<div>
							<AppInput
								name="triggerAlert"
								type="text"
								placeholder="0"
								size="large"
								borderColor={black}
								value={fields.triggerAlert}
								onChange={(e) => this.onChange(e, "triggerAlert")}
								error={this.displayError("triggerAlert")}
								className="alert__form-item__input"
								marginTop="0"
							/>
						</div>
						<div>
							<Text className="Text">minutes.</Text>
						</div>
					</div>
				</AlertFormItem>
				<AlertFormItem>
					<div>
						<Text className="Text">
							Disable notifications between the following times:
						</Text>
					</div>
					{this.state.disableAlertPeriod.map((alertPeriod, i) => (
						<div className="alert__form-item__flex" key={i.toString()}>
							<div>
								<TimePickerComponent
									size="large"
									marginTop="20px"
									border={black}
									value={moment(alertPeriod.startTime, "HH:mm:ss")}
									onChange={(e) => this.onDatePickerChange(e, "startTime")}
								/>
							</div>
							<div>
								<TimePickerComponent
									size="large"
									marginTop="20px"
									border={black}
									value={moment(alertPeriod.endTime, "HH:mm:ss")}
									onChange={(e) =>
										this.onDatePickerChange(e, "endTime", () =>
											this.addDisableAlertPeriod(i)
										)
									}
								/>
							</div>
							<div>
								<EmptyButtonComponent
									onClick={(e) => this.removeAlertPeriod(e, i)}
									className="remove-alert-period"
								>
									<img src="/static/img/close-mini.svg" alt="delete" />
								</EmptyButtonComponent>
							</div>
						</div>
					))}

					<ButtonComponent
						marginTop="20px"
						onClick={this.setSingleDisableAlertPeriod}
					>
						Add Time Range
					</ButtonComponent>
				</AlertFormItem>
				<div className="contacts-section">
					<div className="flex-row flex-end">
						<Col span={11}>
							<Text fontSize="20px" className="Text">
								Contacts that will receive alerts:
							</Text>
							{edit ? (
								<PlainSelect
									mode="multiple"
									placeholder="Select contacts edit"
									size="large"
									defaultValue={fields.emailAddress}
									onChange={(e) => this.onSelectChange(e, "contacts")}
								>
									{this.renderContactList()}
								</PlainSelect>
							) : (
								<SelectComponent
									options={contacts}
									placeholderColor={black}
									placeholder="Select Contacts"
									border={black}
									mode="multiple"
									marginTop="8px"
									size="large"
									notFoundContent="No contacts"
									error={this.displayError("contacts")}
									onChange={(e) => this.onSelectChange(e, "contacts")}
									value={fields.contacts}
								/>
							)}
						</Col>
						<Col span={11}>
							<AppTextArea
								autosize
								name="description"
								size="large"
								marginTop="0px"
								border={black}
								bgColor={white}
								placeholder="description"
								value={fields.description}
								onChange={(e) => this.onChange(e, "description")}
								error={this.displayError("description")}
								errorMessage="Please enter a description"
							/>
						</Col>
					</div>
				</div>
				<SaveAndCancelButtons
					onCancel={onCancel}
					loading={loading}
					onSave={this.onSubmit}
					type="button"
					marginTop="44px"
				/>
			</AlertSectionFormContainer>
		);
	}
}

export class DriverAlert extends React.Component {
	onSubmit = (values, callback) => {
		const { edit, createAlert, updateAlert } = this.props;
		edit ? updateAlert(values, callback) : createAlert(values, callback);
	};
	render() {
		const {
			edit,
			isModal,
			contacts,
			hideSection,
			buttonLoading,
			nodesWithChildren,
			createAlert,
			updateAlert,
			deleteAlert,
			createGeofenceAlert,
			updateGeofenceAlert,
			...rest
		} = this.props;
		let newContacts = contacts.map((contact) => ({
			value: `${contact.email} - ${contact.phone}`,
			label: contact.email,
		}));
		return (
			<AlertSectionContainer>
				<div className="form-header">
					<EmptyButtonComponent
						onClick={hideSection}
						className="back-button"
						color={black}
					>
						<Icon type="arrow-left" /> Back
					</EmptyButtonComponent>
					<Heading fontSize="20px">Driver Behaviour Alert</Heading>
				</div>
				<DriverAlertForm
					fields={rest}
					edit={edit}
					isModal={isModal}
					onCancel={hideSection}
					contacts={newContacts}
					loading={buttonLoading}
					onSubmit={this.onSubmit}
					nodesWithChildren={nodesWithChildren}
				/>
			</AlertSectionContainer>
		);
	}
}
