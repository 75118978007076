import { Col, message, Row } from 'antd';
import { Formik } from 'formik';
import moment from 'moment';
import * as React from 'react';
import useSWR from 'swr';
import * as yup from 'yup';
import { makeApiCallAdmin } from '../api';
import { black } from '../components/design-system/colors';
import { AddressInput } from '../components/primitives/AddressInput';
import { DatePickerComponent } from '../components/primitives/DatePicker';
import { AppInput } from '../components/primitives/Input';
import { SaveAndCancelButtons } from '../components/primitives/SaveAndCancelButtons';
import { SelectComponent } from '../components/primitives/Select';
import { Heading } from '../components/primitives/Text';

function DriverForm({ close, driver = {}, isEdit = false }) {
  const { data: licenceTypes = [] } = useSWR('/Driver/LicType', fetchLicenceTypes);
  return (
    <Formik
      initialValues={{
        firstName: driver.firstName || '',
        lastName: driver.lastName || '',
        mobileNo: driver.mobileNo || '',
        email: driver.email || '',
        homeAddress: driver.homeAddress || '',
        tagId: driver.tagId || '',
        licNo: driver.licNo || '',
        licType: driver.licType || '',
        licExpiryDate: driver.licExpiryDate || '',
        remark: driver.remark || 'remark',
        driverStatus: driver.driverStatus || true,
      }}
      onSubmit={async (values, actions) => {
        try {
          await makeApiCallAdmin(`/Driver/${isEdit ? driver.id : ''}`, {
            method: isEdit ? 'put' : 'post',
            data: {
              ...values,
              licExpiryDate: moment(values.licExpiryDate).toISOString(),
            },
          });
          message.success(`Driver ${isEdit ? 'updated' : 'created'} successfully`);
          close();
        } catch (error) {
          message.error(error.message);
        } finally {
          actions.setSubmitting(false);
        }
      }}
      validationSchema={validationSchema}
      enableReinitialize
    >
      {({ values, touched, errors, handleChange, setFieldValue, handleSubmit, isSubmitting }) => (
        <form className="-mt-3" onSubmit={handleSubmit}>
          <Row gutter={24}>
            <Col span={12}>
              <AppInput
                name="firstName"
                type="text"
                placeholder="Louis"
                label="First Name"
                size="large"
                value={values.firstName}
                onChange={handleChange}
                error={touched.firstName && errors.firstName}
                className="mt-0"
              />
            </Col>
            <Col span={12}>
              <AppInput
                name="lastName"
                type="text"
                placeholder="Collins"
                label="Last Name"
                size="large"
                value={values.lastName}
                onChange={handleChange}
                error={touched.lastName && errors.lastName}
              />
            </Col>
          </Row>
          <AppInput
            name="email"
            type="email"
            placeholder="rudolph_lebsack@hane.name"
            label="Email Address"
            size="large"
            value={values.email}
            onChange={handleChange}
            error={touched.email && errors.email}
          />
          <Row gutter={24}>
            <Col span={12}>
              <AppInput
                name="mobileNo"
                type="text"
                placeholder="08062455714"
                label="Phone Number"
                size="large"
                value={values.mobileNo}
                onChange={handleChange}
                error={touched.mobileNo && errors.mobileNo}
              />
            </Col>
            <Col span={12}>
              <AppInput
                name="tagId"
                type="text"
                label="Tag ID"
                size="large"
                value={values.tagId}
                onChange={handleChange}
                error={touched.tagId && errors.tagId}
              />
            </Col>
          </Row>
          <AddressInput
            label="Home Address"
            placeholder="35 Abiola Drive Suite 804"
            initialValue={values.homeAddress}
            size="large"
            onChange={({ label }) => setFieldValue('homeAddress', label)}
            onInputChange={(value) => setFieldValue('homeAddress', value)}
            error={touched.homeAddress && errors.homeAddress}
            errorMessage={errors.homeAddress}
          />
          <div className="mt-6 pt-6 pb-5 border-t border-b border-solid border-gray-100">
            <Heading className="m-0 text-lg font-bold" style={{ fontFamily: 'Circular' }}>
              Licence Details
            </Heading>
            <Row gutter={24}>
              <Col span={12}>
                <AppInput
                  name="licNo"
                  type="text"
                  placeholder="340-410-0918"
                  label="Licence Number"
                  size="large"
                  value={values.licNo}
                  onChange={handleChange}
                  error={touched.licNo && errors.licNo}
                />
              </Col>
              <Col span={12}></Col>
            </Row>
            <Row gutter={24}>
              <Col span={12}>
                <SelectComponent
                  options={licenceTypes}
                  label="Licence Type"
                  placeholder="Licence Type"
                  marginTop="20px"
                  border={black}
                  size="large"
                  value={values.licType}
                  onChange={(value) => setFieldValue('licType', value)}
                  error={touched.licType && errors.licType}
                />
              </Col>
              <Col span={12}>
                <DatePickerComponent
                  size="large"
                  label="Licence Expiry Date"
                  marginTop="20px"
                  border={black}
                  defaultValue={driver.licExpiryDate ? moment(driver.licExpiryDate) : null}
                  onChange={(value) => setFieldValue('licExpiryDate', moment(value).toISOString())}
                  error={touched.licExpiryDate && errors.licExpiryDate}
                />
              </Col>
            </Row>
          </div>
          <SaveAndCancelButtons
            onCancel={close}
            loading={isSubmitting}
            onSave={handleSubmit}
            type="submit"
          />
        </form>
      )}
    </Formik>
  );
}

async function fetchLicenceTypes(url) {
  try {
    const { data = [] } = await makeApiCallAdmin(url);
    return data.map(({ id, text }) => ({ value: id, label: text }));
  } catch (error) {
    message.error(error.message);
  }
}

var validationSchema = yup.object().shape({
  firstName: yup.string().required('Enter driver first name'),
  lastName: yup.string().required('Enter driver last name'),
  mobileNo: yup.string().required('Enter driver mobile number'),
  email: yup.string().required('Enter email address'),
  homeAddress: yup.string().required('Enter driver home address'),
  licNo: yup.string().required('Enter driver licence number'),
  licType: yup.string().required('Select a licence type'),
  licExpiryDate: yup.string().required('Select licence expiration date'),
  remark: yup.string().required(),
  driverStatus: yup.boolean().required(),
});

export default DriverForm;
