import { Breadcrumb, Checkbox, Icon, message } from 'antd';
import { isEmpty } from 'lodash';
import React, { useState } from 'react';
import { withRouter } from 'react-router';
import styled from 'styled-components';
import { makeApiCallAdmin } from '../api';
import { black, gray } from '../components/design-system/colors';
import { customFonts } from '../components/design-system/fonts';
import { Fleet } from '../components/pages/Fleet';
import { AssetAssignComponent } from '../components/pages/Fleet/components';
import { VehicleItemTag } from '../components/pages/Fleet/VehicleList';
import { ButtonComponent, EmptyButtonComponent } from '../components/primitives/Buttons';
import { EmptyStateComponent } from '../components/primitives/Layout';
import { Heading, Text } from '../components/primitives/Text';
import { useFolderAssets, useRootFolder, useTrip } from '../utils/hooks';

const AssetAssign = withRouter((props) => {
  const { trip = {} } = useTrip(props.match.params.id);
  const [currentFolderId, setCurrentFolderId] = useState();
  const [selectedFolders = {}, setSelectedFolders] = useState({});
  const [selectedAssets = {}, setSelectedAssets] = useState({});
  const { folders = [], assets = [], loading } = useFolderAssets(currentFolderId);
  const [folderBreadcrumbs, setFolderBreadcrumbs] = useState([]);
  const [isSubmitting, seteSubmitting] = useState(false);
  const { rootFolder } = useRootFolder();

  const onAssign = async () => {
    try {
      seteSubmitting(true);
      let violationNodeAssets = Object.values(selectedAssets).map(
        ({ nodeId, nodeDesc, nodeDescription }) => ({
          nodeId,
          nodeDesc,
        })
      );
      let violationNodeFolders = Object.values(selectedFolders).map(({ nodeId, nodeDesc }) => ({
        nodeId,
        nodeDesc,
      }));
      await makeApiCallAdmin(`/Trips/${props.match.params.id}/Assign`, {
        method: 'put',
        data: {
          violationNodeAssets,
          violationNodeFolders,
        },
      });
      message.success('Assets assigned successfully');
      props.history.goBack();
    } catch (error) {
      message.error(error.message);
    } finally {
      seteSubmitting(false);
    }
  };

  const onFolderClick = (folder) => {
    const { nodeId, nodeDesc, parentNodeId, parentNodeDesc } = folder;
    setCurrentFolderId(nodeId);
    if (folderBreadcrumbs.length > 0) {
      if (folderBreadcrumbs[folderBreadcrumbs.length - 1].nodeDesc === nodeDesc) return;
      folderBreadcrumbs.push(folder);
    } else {
      folderBreadcrumbs.push(folder);
    }
  };

  const onFolderBreadcrumbClick = (folder, index) => {
    setFolderBreadcrumbs(folderBreadcrumbs.slice(0, index + 1));
    onFolderClick(folder);
  };

  const goBack = () => props.history.goBack();

  return (
    <Fleet>
      <Container id="container">
        <div className="Container__sidebar">
          <ButtonComponent
            fullWidth
            appearance="blue"
            onClick={onAssign}
            loading={isSubmitting}
            className="assign-button"
            disabled={isEmpty(selectedAssets) && isEmpty(selectedFolders)}
          >
            Assign
          </ButtonComponent>
          <div>
            <div className="Container__sidebar-menu">
              <div className="entity-name">
                <EmptyButtonComponent onClick={goBack} title="Go back to trips">
                  <Icon type="left" style={{ color: '#a5adba' }} /> {trip.description}
                </EmptyButtonComponent>
              </div>
            </div>
            <div className="Container__sidebar-content">
              <Breadcrumb
                separator=">"
                style={{ padding: '0.5rem 1.25rem' }}
                className="border-0 border-b border-solid border-gray-200"
              >
                {rootFolder && (
                  <Breadcrumb.Item>
                    <a
                      onClick={() => {
                        setCurrentFolderId(rootFolder.nodeId);
                        setFolderBreadcrumbs([]);
                      }}
                    >
                      {rootFolder.nodeDesc}
                    </a>
                  </Breadcrumb.Item>
                )}
                {folderBreadcrumbs.map((folder, i) => (
                  <Breadcrumb.Item key={`breadcrumb-${folder.nodeId}-${folder.nodeDesc}`}>
                    <a
                      onClick={() => onFolderBreadcrumbClick(folder, i)}
                      className={i === folderBreadcrumbs.length - 1 ? 'pointer-events-none' : ''}
                    >
                      {folder.nodeDesc}
                    </a>
                  </Breadcrumb.Item>
                ))}
              </Breadcrumb>
              <div className="Container__sidebar-content__folders px-5 py-3">
                {folders.map((folder) => {
                  const { nodeId, nodeDesc } = folder;

                  return (
                    <EmptyButtonComponent
                      key={`${nodeId} ${nodeDesc}`}
                      className="w-full py-4 flex items-center"
                      onDoubleClick={() => onFolderClick(folder)}
                    >
                      <Checkbox
                        onChange={() => {
                          if (selectedFolders[nodeId]) {
                            const newObj = { ...selectedFolders };
                            delete newObj[nodeId];
                            setSelectedFolders(newObj);
                          } else {
                            setSelectedFolders({
                              ...selectedFolders,
                              [nodeId]: { nodeId, nodeDesc },
                            });
                          }
                        }}
                        checked={selectedFolders.hasOwnProperty(nodeId)}
                      />
                      <img src="/static/img/folder.svg" alt="folder" className="ml-4 mr-2" />
                      <Text className="text-lg mb-0" color={black}>
                        {nodeDesc}
                      </Text>
                    </EmptyButtonComponent>
                  );
                })}
              </div>
              <div className="px-5 py-3">
                {assets.length > 0 ? (
                  assets.map(({ nodeId, nodeDesc }) => (
                    <EmptyButtonComponent
                      key={`${nodeId}-${nodeDesc}`}
                      onClick={() => {
                        if (selectedAssets[nodeId]) {
                          const newObj = { ...selectedAssets };
                          delete newObj[nodeId];
                          setSelectedAssets(newObj);
                        } else {
                          setSelectedAssets({ ...selectedAssets, [nodeId]: { nodeId, nodeDesc } });
                        }
                      }}
                      className="w-full flex items-center py-4 border-0 border-b border-solid border-gray-200"
                    >
                      <Checkbox checked={selectedAssets.hasOwnProperty(nodeId)} />
                      <Text className="mb-0 text-lg ml-4" color={black}>
                        {nodeDesc}
                      </Text>
                    </EmptyButtonComponent>
                  ))
                ) : (
                  <EmptyStateComponent>
                    <Heading className="EmptyStateComponent__text">No orphan assets</Heading>
                  </EmptyStateComponent>
                )}
              </div>
            </div>
          </div>
        </div>
        <div className="Container__data-section">
          <div className="data-set selected-folders__section">
            <Heading>Folders</Heading>
            <div className="selected-folders-display">
              {Object.values(selectedFolders).map((folder) => (
                <VehicleItemTag
                  name={folder.nodeDesc}
                  id={folder.nodeId}
                  key={`${folder.nodeId}-${folder.nodeDesc}`}
                  onClose={() => {
                    const newObj = { ...selectedFolders };
                    delete newObj[folder.nodeId];
                    setSelectedFolders(newObj);
                  }}
                />
              ))}
            </div>
          </div>
          <div className="data-set selected-assets__section">
            <Heading>Assets</Heading>
            <div className="selected-folders-display">
              {Object.values(selectedAssets).map((asset) => (
                <AssetAssignComponent
                  {...asset}
                  key={`${asset.nodeId}-${asset.nodeDesc}`}
                  onRemove={() => {
                    const newObj = { ...selectedAssets };
                    delete newObj[asset.nodeId];
                    setSelectedAssets(newObj);
                  }}
                />
              ))}
            </div>
          </div>
        </div>
      </Container>
    </Fleet>
  );
});

var Container = styled.div`
  position: relative;
  height: 100vh;
  display: flex;

  .filter__dropdown {
    top: 40px !important;
    .ant-menu-vertical {
      border-radius: 5px !important;
      border-right: none !important;
      box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1) !important;
    }
    &__button {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  .Container {
    &__sidebar {
      display: block;
      min-width: 400px;
      width: inherit;
      overflow: auto;
      padding-bottom: 200px;
      border-right: 1px solid ${gray.faint};

      .assign-button {
        position: fixed !important;
        min-width: 400px;
        width: inherit;
        z-index: 2;
        bottom: 0;
      }
      .ant-btn.ant-btn-loading:not(.ant-btn-circle):not(.ant-btn-circle-outline):not(.ant-btn-icon-only) {
        position: fixed !important;
      }
    }
    &__sidebar-section {
      position: relative;
      height: 100vh;
      overflow: auto;
      padding-bottom: 100px;
      min-width: 400px;
      width: inherit;
    }
    &__sidebar-menu {
      position: fixed;
      min-width: 400px;
      width: inherit;
      z-index: 1;
      overflow: hidden;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 16px;
      background-color: ${gray.ui01};

      .entity-name {
        display: flex;
        width: 280px;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
        button {
          font-size: 18px;
          padding: 0;
          font-weight: bold;
          color: ${black};
          text-transform: capitalize;
        }
      }
    }
    &__data-section {
      width: 100%;
      height: 100%;
    }
    &__sidebar-content {
      padding-top: 59px;
      &__folders {
        border-bottom: 1px solid ${gray.light};
        .FolderItem {
          padding-left: 32px;
          padding-right: 16px;
          &__button {
            padding: 12px 0;
          }
        }
      }
      &__assets {
        padding-bottom: 100px;
        .VehicleItem {
          padding: 12px 33px;
        }
      }
    }
    &__data-section {
      max-width: 900px;
      margin: 0 auto;
      padding: 32px 16px;

      .data-set > h1 {
        font-size: 18px;
        text-transform: uppercase;
        color: ${black};
        margin: 0;
        font-weight: bold;
        padding-bottom: 20px;
        font-family: ${customFonts.circular};
      }
      .selected-folders__section {
        .selected-folders-display {
          display: flex;
          align-items: center;
          flex-flow: row wrap;
          padding-bottom: 48px;
        }
      }
    }
  }
`;

export default AssetAssign;
