import { Checkbox } from 'antd';
import React from 'react';
import { NavLink } from 'react-router-dom';
import styled from 'styled-components';
import { gray } from '../../design-system/colors';
import { customFonts } from '../../design-system/fonts';
import { SaveAndCancelButtons } from '../../primitives/SaveAndCancelButtons';
import { Heading, Text } from '../../primitives/Text';

const CheckboxGroup = Checkbox.Group;

export const sharedStyle = `
h1 {
  font-family: ${customFonts.circular}!important;
  font-weight: bold;
  margin: 0;
  font-size: 20px;
  padding-bottom: 20px;
}
p {
  color: #454f5b;
  padding-bottom: 20px;
  margin: 0;
}

`;

const StyledEmailNotifications = styled.div`
  margin-bottom: 64px;
  .ant-checkbox-group-item {
    display: flex;
  }
  .email-notification {
    &__header-section {
      border-bottom: 1px solid ${gray.faint};
      padding-bottom: 40px;
      margin-bottom: 50px;
      ${sharedStyle};
    }
    &__account-email {
      ${sharedStyle};
    }
    &__transaction-email {
      ${sharedStyle};
    }
  }
  .checkbox-group__container {
    padding-top: 32px;
  }
`;

const StyledText = styled(Text)`
  display: flex;
  flex-direction: column;
  position: relative;
  top: -6px;
  margin-bottom: 20px !important;
  span:first-child {
    padding-bottom: 4px;
    color: #454f5b;
  }
  span:last-child {
    color: ${gray.primary};
  }
`;

const CheckboxLabel = ({ heading, body }) => (
  <StyledText>
    <span>{heading}</span>
    <span>{body}</span>
  </StyledText>
);

const accountEmailOptions = [
  {
    label: (
      <CheckboxLabel
        heading='Daily Fleet/Asset Summary'
        body='Receive stats about assets in your fleet, this includes incidences,
        violations and reports at the end of each day.'
      />
    ),
    value: 'Daily Fleet/Asset Summary'
  },
  {
    label: (
      <CheckboxLabel
        heading='Monthly & Annual Fleet Summary'
        body='Receive emails about assets in your fleet, this includes incidences, violations and reports at the end of the month & year.'
      />
    ),
    value: 'Monthly & Annual Fleet Summary'
  },
  {
    label: (
      <CheckboxLabel
        heading='Product Updates'
        body='Stay up to date with new products and features.'
      />
    ),
    value: 'Product Updates'
  },
  {
    label: (
      <CheckboxLabel
        heading='Programs and Events'
        body='Hear about special offers, upcoming events and receive business-specific messages from Flitrack.'
      />
    ),
    value: 'Programs and Events'
  },
  {
    label: (
      <CheckboxLabel
        heading='New to Flitrack'
        body='Learn about how to get started with Flitrack.'
      />
    ),
    value: 'New to Flitrack'
  }
];

const transactionEmailOptions = [
  {
    label: (
      <CheckboxLabel
        heading='When administrators request to change settings'
        body='Receive an email anytime an adminstrator without the permission wants to change things on the platform'
      />
    ),
    value: 'When administrators request to change settings'
  },
  {
    label: (
      <CheckboxLabel
        heading='When administrators wants to delete'
        body='Receive an email anytime an adminstrator without the permission wants to delete trips, assets, or alerts.'
      />
    ),
    value: 'When administrators wants to delete'
  }
];

export class EmailNotifications extends React.Component {
  accountEmailOnChange = checkedValues => {
    console.log('checked = ', checkedValues);
  };

  transactionEmailOnChange = checkedValues => {
    console.log('checked = ', checkedValues);
  };
  render() {
    return (
      <StyledEmailNotifications className='email-notification compact'>
        <div className='email-notification__header-section'>
          <Heading>Manage your email notifications</Heading>
          <Text>
            Learn about new products or features and receive updates on how your fleet is being
            managed.
          </Text>
        </div>
        <div className='email-notification__account-email'>
          <Heading>Account Email</Heading>
          <Text>
            Automatically sent to your account email.{' '}
            <NavLink to='/settings/personal-information'>Change account email ></NavLink>
          </Text>
          <div className='checkbox-group__container'>
            <CheckboxGroup options={accountEmailOptions} onChange={this.accountEmailOnChange} />
          </div>
        </div>
        <div className='email-notification__transaction-email'>
          <Heading>Transaction Email</Heading>
          <div className='checkbox-group__container'>
            <CheckboxGroup
              options={transactionEmailOptions}
              onChange={this.transactionEmailOnChange}
            />
          </div>
        </div>
        <div>
          <SaveAndCancelButtons />
        </div>
      </StyledEmailNotifications>
    );
  }
}
