export const red = {
  dark: '#C23636',
  primary: '#DB3236',
  light: '#E57777',
  faint: '#F7CDC5',
  ui01: '#ed6347',
};

export const green = {
  dark: '#499687',
  primary: '#00B649',
  light: '#ebeaeb',
  faint: '#f1fbf8',
};

export const white = '#ffffff';

export const black = '#000000';

export const gray = {
  light: '#e8e8e8',
  dark: '#E6EAEE',
  primary: '#9b9b9b',
  faint: 'rgba(0, 0, 0, 0.10000000149011612)',
  ui01: '#f9f9f9',
  ui02: '#4a4a4a',
};

export const blue = {
  primary: '#007ACE',
  disabled: 'rgba(0, 122, 206, .7)',
  faint: 'rgba(85,172,238,0.1)',
};

export const violationColors = {
  idling: '#910065',
  overspeed: '#006d64',
  harshAcceleration: '#a5a512',
  harshBreak: '#ff9800',
  sos: '#ff0000',
  noEntryZone: '#1f2a82',
  noExitZone: '#1630ff',
  zoneOverspeed: '#158e8e',
};
