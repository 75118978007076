import React from 'react'
import { Select as SelectComponent } from 'antd';

const Option = SelectComponent.Option;

export default ({ options, onInputChange, asset }) => (
  <SelectComponent
    placeholder="Select Asset"
    onChange={(e) => onInputChange("asset", e)}
    value={asset}
    defaultValue={asset}
    size="large"
  >
    {options.map((option, i) => (
      <Option value={option.value} key={i} >{option.label}</Option>
    ))}
  </SelectComponent>
)
