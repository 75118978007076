import React from "react";
import { Select } from "antd";
import { black } from "../design-system/colors";
import styled from "styled-components";

const PlainSelect = ({ children, ...rest }) => {
	return (
		<SelectStyle>
			<Select mode="multiple" size="large" {...rest}>
				{children}
			</Select>
		</SelectStyle>
	);
};

export default PlainSelect;
export const SelectStyle = styled.div`
	margin-top: ;
	.ant-select {
		width: 100%;
	}
	.ant-select-selection {
		border-radius: 0;
		border: 1px solid ${black};
	}
	.ant-select-selection:hover {
		border: 1px solid ${black};
	}
	.ant-select-arrow-icon {
		color: ${black};
	}
	.ant-select-focused .ant-select-selection,
	.ant-select-selection:focus,
	.ant-select-selection:active {
		border-color: ${black};
		outline: 0;
		-webkit-box-shadow: none;
		box-shadow: none;
	}
	.ant-select-selection-selected-value {
		font-size: 16px;
		color: ${black};
	}
	.ant-select-selection__placeholder {
		font-size: 16px;
		color: #bfbfbf;
	}
	.ant-select-selection--multiple .ant-select-selection__rendered {
		margin-left: 11px;
		margin-right: 11px;
	}
	.ant-select-selection--multiple
		.ant-select-selection__rendered
		.ant-select-selection__choice {
		border: 1px solid ${black};
		background: transparent;
	}
	.ant-select-search__field {
		position: relative;
		top: 4px;
	}
	label {
		padding-bottom: 4px;
		display: block;
	}
	${(props) => props.css};
`;
