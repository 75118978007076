import { message } from 'antd';
import useSWR from 'swr';
import { makeApiCallAdmin } from '../../api';

function useAssets() {
  const { data: assets, error, revalidate } = useSWR('/Assets', fetchAssets);

  return { assets, loading: !assets && !error, revalidate };
}

async function fetchAssets(url) {
  try {
    const { data = [] } = await makeApiCallAdmin(url);
    return data;
  } catch (e) {
    message.error(e.message);
  }
}

export default useAssets;
