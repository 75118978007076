import useSWR from 'swr';
import { makeApiCallAdmin } from '../../api';

function useCommands(productId, plateNo) {
  const { data: commands, error, revalidate } = useSWR(
    `/AssetCommand/Commands/${productId}/${plateNo}`,
    fetchCommands
  );

  return { commands, loading: !commands && !error, revalidate };
}

async function fetchCommands(url) {
  const { data: commands } = await makeApiCallAdmin(url);
  return commands;
}

export default useCommands;
