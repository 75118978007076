import { message } from 'antd';
import useSWR from 'swr';
import { makeApiCallAdmin } from '../../../api';

function useHeartBeat() {
  const { data: heartBeat, error, revalidate } = useSWR('/Dashboard/Heartbeat', fetchHeartBeat);

  return { heartBeat, loading: !heartBeat && !error, revalidate };
}

async function fetchHeartBeat(url) {
  try {
    const { data = {} } = await makeApiCallAdmin(url);
    return data;
  } catch (e) {
    message.error(e.message);
  }
}

export default useHeartBeat;
