import { Icon, Select, Slider, Spin } from "antd";
import { isEmpty, isEqual } from "lodash";
import moment from "moment";
import React from "react";
import styled from "styled-components";
import { green } from "../../design-system/colors";
import { customFonts } from "../../design-system/fonts";
import {
	ButtonComponent,
	EmptyButtonComponent,
} from "../../primitives/Buttons";
import FleetInsightMap from "../../primitives/FleetInsightMap";
import FleetInsightMapLive from "../../primitives/FleetInsightMapLive";
import { Div } from "../../primitives/Layout";
import {
	DateRangePicker,
	FleetInsightOptionsMenu,
	PlayIcon,
} from "./components";
import DeviceCommandsPanel from "./DeviceCommandsPanel";
import { OverviewContainer } from "./Overview";
import VehicleInfo from "./VehicleInfo";

const Option = Select.Option;

const FleetInsightContainer = styled.div`
	.SecondaryNavbar {
		position: fixed;
		top: 56px;
		display: flex;
		justify-content: space-between;
		width: 100%;
		z-index: 1;
		padding: 16px;
		background: rgb(255, 255, 255);
		border-bottom: 1px solid rgb(232, 232, 232);

		.left-section {
			display: flex;
			justify-content: space-between;

			button {
				i.anticon {
					font-size: 10px;
				}
			}

			.ant-dropdown-link {
				cursor: pointer;
			}

			.options-section {
				display: flex;
				align-items: center;
				z-index: 5;

				@media (min-width: 1000px) {
					margin-left: 100px;
				}

				button:focus {
					box-shadow: none !important;
				}

				& > div > .ant-checkbox-wrapper > span,
				.ant-dropdown-link {
					font-family: ${customFonts.circular}!important;
					color: #000;
				}
			}
		}

		.right-section {
			display: flex;
			align-items: center;
			justify-content: flex-end;
			flex-grow: 1;

			.replay-controls-section {
				display: flex;
				align-items: center;
				flex: 0 0 35%;
				max-width: 35%;

				.playback_button {
					display: flex;
				}

				.Text {
					margin: 0;
					font-family: ${customFonts.circular}!important;
				}
				.speed-slider-wrapper {
					flex: 1;

					.ant-slider-handle {
						border: solid 2px #fff;
						box-shadow: 0 4px 8px -2px rgba(9, 30, 66, 0.25),
							0 0 1px 0 rgba(9, 30, 66, 0.31);
					}
					.ant-slider-track {
						background-color: #1f8efa;
					}
					.ant-slider-rail {
						background-color: #dfe1e6;
					}
				}
			}

			.date-filter-section {
				display: flex;
				align-items: center;

				.date-range-wrapper {
					display: flex;

					& > .ant-calendar-picker {
						width: auto !important;
					}
				}

				.span-wrapper {
					span {
						font-family: ${customFonts.circular}!important;
						color: #000;
					}
					.ant-select.ant-select-enabled {
						min-width: 70px;
					}
					.ant-select-selection {
						border: none;
					}
					.ant-select-dropdown {
						min-width: 130px;
					}
					.ant-select-focused .ant-select-selection,
					.ant-select-selection:focus,
					.ant-select-selection:active {
						box-shadow: none !important;
					}
				}
			}

			.SelectComponent:first-of-type {
				width: 120px;
				margin-right: 16px;
			}

			.SelectComponent:nth-of-type(2n) {
				width: 80px;
			}

			.RangePickerComponent {
				width: auto;
				margin: 0 0 0 30px;
			}

			.pulse {
				background-color: ${green.primary};
				width: 30px;
				height: 30px;
				border-radius: 50%;
				margin-right: 30px;
				position: relative;
				animation: pulse-button 2.5s linear infinite;

				&::before,
				&::after {
					content: "";
					display: block;
					position: absolute;
					border: 1px solid ${green.primary};
					left: -15px;
					right: -15px;
					top: -15px;
					bottom: -15px;
					border-radius: 50%;
					animation: pulse-rings 2.5s linear infinite;
					opacity: 0;
					backface-visibility: hidden;
				}

				&::after {
					animation-delay: 0.5s;
				}
			}

			@keyframes pulse-button {
				0%,
				65%,
				80%,
				100% {
					transform: scale(1);
				}
				70% {
					transform: scale(0.9);
				}
				75% {
					transform: scale(1.1);
				}
			}

			@keyframes pulse-rings {
				30% {
					opacity: 0;
					transform: scale(0.5);
				}
				70% {
					opacity: 1;
					transform: scale(0.5);
				}
				100% {
					transform: scale(1);
					opacity: 0;
				}
			}

			.live-button {
				display: flex;
				align-items: center;
				margin-left: 30px;

				& > button {
					background-color: #00b649;
					color: #fff;
					border: none;

					&.live {
						background-color: #ed6347;
					}
				}
			}
		}
	}
	.overview__container {
		padding-top: 129px;
		overflow: hidden;
		min-height: 100vh !important;
		display: flex;
		justify-items: stretch;

		& > .map__container {
			overflow: auto;
			width: 100%;
		}
	}

	.date-range-wrapper {
		margin-right: 5px;

		.ant-calendar-picker-input {
			border-radius: 0;
			border-color: rgb(0, 0, 0);

			* {
				color: rgb(0, 0, 0);
			}
		}

		.ant-calendar-picker + .ant-calendar-picker {
			margin-left: 5px;
		}
	}
`;

export class FleetInsight extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			allLocations: this.props.trips || [],
			currentLocationIndex: 0,
			lastInterval: null,
			play: false,
			pause: false,
			stop: false,
			showGeofence: false,
			presetDateRangePicker: 10,
			isDateRangeInvalid: false,
			sliderValue: 50,
			delay: 5500,
			live: false,
			options: { showPolyline: true },
		};
	}

	componentDidUpdate(prevProps) {
		if (!isEqual(prevProps.trips, this.props.trips)) {
			this.setState({
				allLocations: this.props.trips,
			});
		}
	}

	onDateRangeChange = (type, date) => {
		this.setState({ presetDateRangePicker: 10 }); // reset the preset range picker
		/**
		 * auto adjust the date range to 24 hours if the selected range is above 24 hours
		 * and prevent negative date range selection (i.e. where the start date > end date)
		 */
		const { startDate, endDate } = this.props;
		if (type === "startDate") {
			if (
				date.isBefore(moment(endDate).subtract(1, "days")) ||
				date.isSameOrAfter(moment(endDate))
			) {
				this.props.updateState("endDate", moment(date).add(1, "day"));
			}
		} else if (type === "endDate") {
			if (
				date.isAfter(moment(startDate).add(1, "days")) ||
				date.isSameOrBefore(moment(startDate))
			) {
				this.props.updateState("startDate", moment(date).subtract(1, "day"));
			}
		}

		this.props.updateState(type, date);
	};

	getDateRangeFromDropdown = (num) => {
		this.setState({ presetDateRangePicker: num });
		if (num === 10) return;
		// Represent the date range as an array of momentjs date values [start, end]
		let datevalue;
		if (num === 0) {
			datevalue = [moment().subtract(12, "hours"), moment()];
		} else if (num === 1) {
			datevalue = [
				moment()
					.subtract(1, "day")
					.startOf("day"),
				moment()
					.subtract(1, "day")
					.endOf("day"),
			];
		} else if (num === 2) {
			datevalue = [moment().subtract(24, "hours"), moment()];
		} else if (num === 3) {
			datevalue = [moment().subtract(1, "hours"), moment()];
		} else {
			datevalue = [moment().startOf("day"), moment()];
		}

		this.props.updateState("startDate", datevalue[0]);
		this.props.updateState("endDate", datevalue[1]);
		// this.props.submitDateRange();
	};

	onSliderChange = (sliderValue) => {
		const inverse = 100 - sliderValue;
		const range = this.state.live ? 300 : 90;
		const min = this.state.live ? 30000 : 1000;
		const delay = range * inverse + min;
		this.setState({ delay, sliderValue });
		if (this.state.play) {
			this.setState({ pause: true, play: false }, () =>
				this.setState({ pause: false, play: true })
			);
		}
	};

	tipFormatter = () => {
		return `Delay: ${this.state.delay / 1000}s`;
	};

	run = (callback) => {
		const { allLocations, delay } = this.state;
		this.clear();
		const newInterval = setInterval(() => {
			const { currentLocationIndex: index } = this.state;
			if (index === allLocations.length - 1) return this.stop();
			this.setState({ currentLocationIndex: index + 1 }, () => {
				const location = allLocations[this.state.currentLocationIndex];
				callback(location);
			});
		}, delay || 5500);
		this.setState({ lastInterval: newInterval });
	};

	clear = () => {
		const { lastInterval } = this.state;
		if (lastInterval) {
			clearInterval(lastInterval);
		}
	};

	start = (callback) => {
		const { allLocations, currentLocationIndex: index } = this.state;
		if (index < allLocations.length) {
			this.setState(
				{
					markerAnimate: callback,
				},
				() => this.run(callback)
			);
		} else {
			this.setState(
				{
					currentLocationIndex: 0,
					markerAnimate: callback,
				},
				() => this.run(callback)
			);
		}
	};

	play = () => {
		this.setState({ play: true, pause: false, stop: false });
	};

	pause = () => {
		this.clear();
		this.setState({ pause: true, play: false, stop: false });
	};

	next = () => {
		const {
			markerAnimate,
			currentLocationIndex: index,
			allLocations,
		} = this.state;
		this.pause();
		if (index === allLocations.length - 1) return this.stop();
		this.setState({ currentLocationIndex: index + 1 }, () => {
			const location = allLocations[this.state.currentLocationIndex];
			markerAnimate(location);
		});
	};

	previous = () => {
		const {
			markerAnimate,
			currentLocationIndex: index,
			allLocations,
		} = this.state;
		this.pause();
		if (index === 0) this.stop();
		this.setState({ currentLocationIndex: index - 1 }, () => {
			const location = allLocations[this.state.currentLocationIndex];
			markerAnimate(location);
		});
	};

	stop = () => {
		const { allLocations, markerAnimate, delay } = this.state;
		this.clear();
		this.setState(
			{
				currentLocationIndex: 0,
				pause: false,
				play: false,
				stop: true,
			},
			() =>
				setTimeout(() => {
					if (markerAnimate) {
						markerAnimate(allLocations[0]);
					}
				}, delay)
		);
	};

	toggleRouteDisplay = () => {
		this.setState({
			showGeofence: !this.state.showGeofence,
		});
	};

	toggleLive = async () => {
		await this.props.getCurrentLocation();
		await this.clear();
		await this.props.clearTrips();
		this.setState({ stop: true, pause: false, play: false });
		if (this.state.live) {
			this.setState({ live: false, delay: 5500, sliderValue: 50 });
		} else {
			this.setState({ live: true, delay: 30000, sliderValue: 100 });
		}
	};

	setOption = ({ name, checked }) => {
		this.setState(({ options }) => ({
			options: {
				...options,
				[name]: checked,
			},
		}));
	};

	render() {
		const {
			to,
			from,
			goBack,
			trips,
			loading,
			currentLocation,
			selectedLocation,
			setSelectedLocation,
			setCurrentLocation,
			getCurrentLocation,
			liveLoading,
			submitDateRange,
			startDate,
			endDate,
			geofenceList,
			getAddress,
			showVehicleRoute,
			vehicleInformation,
			reset,
		} = this.props;

		const {
			presetDateRangePicker,
			live,
			delay,
			sliderValue,
			options,
			stop,
			play,
			pause,
			currentLocationIndex,
			markerAnimate,
		} = this.state;

		return (
			<FleetInsightContainer>
				<div className="SecondaryNavbar">
					<div className="left-section">
						<ButtonComponent onClick={goBack}>
							<Icon type="left" theme="outlined" /> All Vehicles
						</ButtonComponent>
						<div className="options-section">
							<FleetInsightOptionsMenu
								options={options}
								setOption={this.setOption}
							/>
						</div>
					</div>
					<div className="right-section">
						{(live || showVehicleRoute) && (
							<div className="replay-controls-section">
								{!live && !isEmpty(trips) && (
									<React.Fragment>
										{currentLocationIndex > 0 && (
											<React.Fragment>
												<EmptyButtonComponent className="playback_button">
													<img
														src="/static/img/rectangle.svg"
														alt="stop"
														onClick={this.stop}
													/>
												</EmptyButtonComponent>
												<EmptyButtonComponent className="playback_button">
													<img
														src="/static/img/backward.svg"
														alt="go to previous location"
														onClick={this.previous}
													/>
												</EmptyButtonComponent>
											</React.Fragment>
										)}

										{play ? (
											<EmptyButtonComponent
												className="playback_button"
												onClick={this.pause}
											>
												<img src="static/img/pause.svg" alt="pause" />
											</EmptyButtonComponent>
										) : (
											<EmptyButtonComponent
												className="playback_button"
												onClick={this.play}
											>
												<PlayIcon />
											</EmptyButtonComponent>
										)}
										{!(stop || currentLocationIndex >= trips.length) &&
											markerAnimate && (
												<EmptyButtonComponent>
													<img
														src="/static/img/forward.svg"
														alt="go to next location"
														onClick={this.next}
													/>
												</EmptyButtonComponent>
											)}
									</React.Fragment>
								)}

								<div style={{ padding: "1px 8px" }}>
									<img
										src="/static/img/slow.svg"
										alt="decrease playback speed"
									/>
								</div>
								<div className="speed-slider-wrapper">
									<Slider
										tipFormatter={this.tipFormatter}
										value={sliderValue}
										onChange={(value) => this.onSliderChange(value)}
									/>
								</div>
								<div style={{ padding: "1px 8px" }}>
									<img
										src="/static/img/fast.svg"
										alt="increase playback speed"
									/>
								</div>
							</div>
						)}

						{!live && (
							<div className="date-filter-section">
								<div className="span-wrapper" id="span-wrapper">
									<Select
										placeholder="Day"
										getPopupContainer={() =>
											document.getElementById("span-wrapper")
										}
										onSelect={(value) => this.getDateRangeFromDropdown(value)}
										defaultValue={10}
										value={presetDateRangePicker}
									>
										<Option value={10} disabled>
											Select Range
										</Option>
										<Option value={0}>Last 12 Hours</Option>
										<Option value={1}>Yesterday</Option>
										<Option value={2}>Last 24 Hours</Option>
										<Option value={3}>Last Hour</Option>
										<Option value={4}>Today</Option>
									</Select>
								</div>
								<div className="date-range-wrapper">
									<DateRangePicker
										onInputChange={this.onDateRangeChange}
										startDate={startDate}
										endDate={endDate}
									/>
								</div>
								<div
									className="submit-button"
									style={{ display: "flex", alignItems: "center", gap: "4px" }}
								>
									<ButtonComponent
										theme="outlined"
										onClick={() => {
											this.stop();
											submitDateRange();
										}}
									>
										Replay
									</ButtonComponent>
									<ButtonComponent
										theme="outlined"
										onClick={() => {
											this.stop();
											reset();
										}}
									>
										Clear
									</ButtonComponent>
								</div>
							</div>
						)}
						<div className="live-button">
							{live && <div className="pulse" />}
							<ButtonComponent
								className={live ? "live" : "not__live"}
								onClick={this.toggleLive}
							>
								{live ? "Stop" : "Go Live"}
							</ButtonComponent>
						</div>
					</div>
				</div>
				<OverviewContainer className="overview__container">
					<VehicleInfo
						to={to}
						from={from}
						live={live}
						locations={trips}
						loading={liveLoading}
						currentLocation={currentLocation}
						showVehicleRoute={showVehicleRoute}
						selectedLocation={selectedLocation}
						setSelectedLocation={setSelectedLocation}
						vehicleInformation={vehicleInformation}
					/>
					<div className="map__container">
						{live ? (
							<FleetInsightMapLive
								delay={delay}
								liveLoading={liveLoading}
								currentLocation={currentLocation}
								setCurrentLocation={setCurrentLocation}
								getCurrentLocation={getCurrentLocation}
								showGeofence={options.showGeofence}
								geofenceList={geofenceList}
								getAddress={getAddress}
								vehicleInformation={vehicleInformation}
							/>
						) : (loading && !live) || isEmpty(currentLocation) ? (
							<Div
								cssProp={`
                    height: 100vh;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    background: rgba(0,0,0,.2);
                    text-align: center;
                  `}
							>
								<Spin />
							</Div>
						) : (
							<FleetInsightMap
								locations={trips}
								playFunc={this.start}
								play={play}
								stop={stop}
								pause={pause}
								options={options}
								showGeofence={options.showGeofence}
								showPolyline={options.showPolyline}
								showIdling={options.showIdling}
								showHarshAcceleration={options.showHarshAcceleration}
								showHarshBraking={options.showHarshBraking}
								showOverspeeding={options.showOverspeeding}
								showZoneOverspeed={options.showZoneOverspeed}
								showNoExitZone={options.showNoExitZone}
								showNoEntryZone={options.showNoEntryZone}
								showSos={options.showSos}
								geofenceList={geofenceList}
								currentLocation={currentLocation}
								selectedLocation={selectedLocation}
								setCurrentLocation={setCurrentLocation}
								getAddress={getAddress}
								showVehicleRoute={showVehicleRoute}
								vehicleInformation={vehicleInformation}
							/>
						)}
					</div>
					<DeviceCommandsPanel asset={vehicleInformation} />
				</OverviewContainer>
			</FleetInsightContainer>
		);
	}
}
