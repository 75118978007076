import { message } from 'antd';
import useSWR from 'swr';
import { makeApiCallAdmin } from '../../../api';

function useDriverBehavior() {
  const { data: driverBehavior, error, revalidate } = useSWR(
    '/Dashboard/DriverBehviour',
    fetchDriverBehavior
  );

  return { driverBehavior, loading: !driverBehavior && !error, revalidate };
}

async function fetchDriverBehavior(url) {
  try {
    const { data = {} } = await makeApiCallAdmin(url);
    return data;
  } catch (e) {
    message.error(e.message);
  }
}

export default useDriverBehavior;
