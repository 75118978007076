import { Select } from "antd";
import React from "react";
import styled from "styled-components";
import { black, gray, red } from "../design-system/colors";
import { Label, Text } from "./Text";

const Option = Select.Option;

export const SelectStyle = styled.div`
	margin-top: ${(props) => props.marginTop};
	.ant-select {
		width: 100%;
	}
	.ant-select-selection {
		border-radius: 0;
		border: ${(props) =>
			props.border !== "none"
				? `1px solid ${props.border || gray.light}`
				: `none`};
	}
	.ant-select-selection:hover {
		border: ${(props) =>
			props.border !== "none"
				? `1px solid ${props.border || gray.light}`
				: `none`};
	}
	.ant-select-arrow-icon {
		color: ${black};
	}
	.ant-select-focused .ant-select-selection,
	.ant-select-selection:focus,
	.ant-select-selection:active {
		border-color: ${(props) => props.border || gray.light};
		outline: 0;
		-webkit-box-shadow: none;
		box-shadow: none;
	}
	.ant-select-selection-selected-value {
		font-size: 16px;
		color: ${black};
	}
	.ant-select-selection__placeholder {
		font-size: 16px;
		color: ${(props) => props.placeholderColor || `#bfbfbf`};
	}
	.ant-select-selection--multiple .ant-select-selection__rendered {
		margin-left: 11px;
		margin-right: 11px;
	}
	.ant-select-search__field {
		position: relative;
		top: 4px;
	}
	label {
		padding-bottom: 4px;
		display: block;
	}
	${(props) => props.css};
`;

export class SelectComponent extends React.Component {
	state = {
		value: this.props.value,
	};
	onChange = (data) => {
		this.setState(
			{
				value: data,
			},
			() => {
				this.props.onChange(data);
			}
		);
	};
	render() {
		const { value } = this.state;
		const {
			placeholder,
			onFocus,
			onBlur,
			mode,
			dropdownStyle,
			disabled,
			autoFocus,
			showSearch,
			showArrow,
			defaultValue,
			size,
			options,
			label,
			css,
			marginTop,
			border,
			error,
			errorMessage,
			placeholderColor,
			onSelect,
			useOnSelect,
			...rest
		} = this.props;

		return (
			<SelectStyle
				css={css}
				marginTop={marginTop}
				border={border}
				placeholderColor={placeholderColor}
				className="SelectComponent"
			>
				{label && <Label>{label}</Label>}
				<Select
					defaultValue={defaultValue}
					disabled={disabled}
					onChange={!useOnSelect ? this.onChange : () => {}}
					placeholder={placeholder}
					onFocus={onFocus}
					onBlur={onBlur}
					mode={mode}
					dropdownStyle={dropdownStyle}
					autoFocus={autoFocus}
					showArrow={showArrow}
					showSearch={showSearch}
					value={value}
					size={size}
					onSelect={onSelect}
					{...rest}
				>
					{options.map((option, i) => (
						<Option value={option.value} key={i.toString()}>
							{option.label}
						</Option>
					))}
				</Select>
				{error && (
					<Text fontSize="12px" marginTop="4px" color={red.primary}>
						{errorMessage}
					</Text>
				)}
			</SelectStyle>
		);
	}
}

SelectComponent.defaultProps = {
	dropdownStyle: {
		borderRadius: 0,
		top: "100px",
	},
	errorMessage: "This field is required",
};
