import { DatePicker, Radio } from 'antd';
import moment from 'moment';
import * as React from 'react';
import useSWR from 'swr';
import { makeApiCallAdmin } from '../api';
import { AppNavbar } from '../components/primitives/Navbar';
import addThousandSeparator from '../utils/addThousandSeparator';
import formatAsCurrency from '../utils/formatAsCurrency';
import { useProfile } from '../utils/hooks';

const { RangePicker } = DatePicker;

function BillingPage() {
  const { userType, clientName } = useProfile();
  const isClient = userType === 2;
  const [billingType, setBillingType] = React.useState(isClient ? 'client' : 'agent');
  const [txnHistoryDateRange, setTxnHistoryDateRange] = React.useState([
    moment()
      .startOf('y')
      .format('YYYY-MM-DD'),
    moment().format('YYYY-MM-DD'),
  ]);

  // Payment history for year-to-date. I decided the range myself
  const { data: paymentHistory = [] } = useSWR(
    `/Bill/${billingType}/Invoice/${txnHistoryDateRange[0]}/${txnHistoryDateRange[1]}?limit=10`,
    fetchPaymentHistory
  );

  // Billing summary for the current year
  const { data: billingSummary = [] } = useSWR(
    `/Bill/${billingType}/Summary/${new Date().getFullYear()}?limit=7`,
    fetchBillingSummary
  );

  const toggleBillingType = () => setBillingType(billingType === 'client' ? 'agent' : 'client');

  const onTxnRangeChange = (value1, value2) => setTxnHistoryDateRange(value2);

  return (
    <>
      <AppNavbar />
      <div className="flex min-h-screen pt-24 px-4">
        <div className="max-w-screen-xl w-full mx-auto">
          {!isClient && (
            <div className="mb-4">
              <Radio.Group onChange={toggleBillingType} value={billingType}>
                <Radio value="agent">Show my bills</Radio>
                <Radio value="client">Show bills for {clientName}</Radio>
              </Radio.Group>
            </div>
          )}
          <div
            className="shadow-lg border border-solid border-gray-200 bg-white rounded-md p-5 mb-6 overflow-y-auto"
            style={{ height: '400px' }}
          >
            <div className="flex justify-between mb-6">
              <h3 className="opacity-50">Transaction History</h3>
              <RangePicker
                disabledDate={(date) => date && date > moment().endOf('day')}
                onChange={onTxnRangeChange}
                allowClear={false}
                defaultValue={[moment().startOf('y'), moment()]}
                ranges={{
                  'Last Year': [
                    moment()
                      .startOf('y')
                      .subtract(1, 'y'),
                    moment()
                      .startOf('y')
                      .subtract(1, 'd'),
                  ],
                  'This Year': [moment().startOf('y'), moment()],
                }}
              />
            </div>
            {paymentHistory.map(({ date, remark, amount }) => (
              <div className="flex mb-4" key={date + remark + amount}>
                <span className="font-bold w-48">{moment(date).format('DD MMM YYYY')}</span>
                <span className="mx-4">{remark}</span>
                <span className="ml-auto">{formatAsCurrency(amount)}</span>
              </div>
            ))}
          </div>
          <div className="flex -mx-3">
            <div
              className="w-full flex flex-col border border-solid border-gray-400 bg-white rounded-md p-5 mx-3"
              style={{ height: '350px' }}
            >
              <h3 className="opacity-50 mb-6">Payment Details</h3>
              <div className="w-full h-full relative overflow-hidden">
                <img
                  src="/static/img/credit-card-placeholder.png"
                  alt="credit card placeholder"
                  className="w-full min-h-full"
                />
                <div
                  className="flex items-center justify-center absolute inset-0"
                  style={{ backgroundColor: 'rgba(0,0,0,.75)' }}
                >
                  <span className="text-white">Coming soon...</span>
                </div>
              </div>
            </div>
            <div
              className="w-full border border-solid border-gray-400 bg-white rounded-md p-5 mx-3"
              style={{ height: '350px' }}
            >
              <h3 className="opacity-50 mb-4">Current Year Billing Summary</h3>
              <div className="flex items-center mb-4 p-2 bg-gray-100 -mx-5 px-5">
                {billingType === 'client' ? (
                  <>
                    <span className="w-48">Asset</span>
                    <span className="mx-4">Product</span>
                    <span className="ml-auto">Amount</span>
                  </>
                ) : (
                  <>
                    <span className="w-48">Client</span>
                    <span className="mx-4 w-20 text-right">Amount</span>
                    <span className="ml-auto">Profit</span>
                  </>
                )}
              </div>
              {billingSummary.map((bill) =>
                billingType === 'client' ? (
                  <div key={bill.plateNo} className="flex mb-3">
                    <span className="font-bold w-48 truncate">{bill.plateNo}</span>
                    <span className="mx-4 truncate">{bill.device}</span>
                    <span className="ml-auto">{addThousandSeparator(bill.amount)}</span>
                  </div>
                ) : (
                  <div key={bill.client} className="flex mb-3">
                    <span className="font-bold w-48 truncate">{bill.client}</span>
                    <span className="mx-4 w-20 text-right">
                      {addThousandSeparator(bill.retailPrice)}
                    </span>
                    <span className="ml-auto">{addThousandSeparator(bill.profit)}</span>
                  </div>
                )
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

async function fetchPaymentHistory(url) {
  const { data } = await makeApiCallAdmin(url);
  return data;
}

async function fetchBillingSummary(url) {
  const { data } = await makeApiCallAdmin(url);
  return data;
}

export default BillingPage;
