import { message } from 'antd';
import { Formik } from 'formik';
import { isEqual } from 'lodash';
import * as React from 'react';
import styled from 'styled-components';
import * as yup from 'yup';
import { makeApiCallAdmin } from '../api';
import { sharedStyle } from '../components/pages/Settings/EmailNotifications';
import { ButtonComponent } from '../components/primitives/Buttons';
import { AppInput } from '../components/primitives/Input';
import { Heading } from '../components/primitives/Text';
import { useBusinessInfo, useProfile } from '../utils/hooks';

const StyledBusinessInfo = styled.div`
  margin-bottom: 64px;
  ${sharedStyle}
  .division-section {
    padding-top: 64px;
  }
`;

export function BusinessInfo(props) {
  const { userType } = useProfile();

  return (
    <StyledBusinessInfo className="compact">
      <div>
        <Heading>Organisation Information</Heading>
        {userType === 1 ? <UpdateBusinessInfoAgent /> : <UpdateBusinessInfoClient />}
      </div>
    </StyledBusinessInfo>
  );
}

function UpdateBusinessInfoAgent() {
  const businessInfo = useBusinessInfo();
  const { agentId, costPlanId, fullName, id } = useProfile();

  const initialValues = {
    companyName: businessInfo['agentName'],
    email: businessInfo['companyEmail'],
    companyPhone: businessInfo['companyPhone'],
    companyAddress: businessInfo['agentAddress'],
  };

  const validationSchema = yup.object().shape({
    companyPhone: yup
      .string()
      .test('valid-mobile-number', 'Please enter a valid mobile number', (value) => {
        const isPhoneNumber = /^[0-9]*$/gm.test(value); // must only contain numbers
        const isCorrectLength = value && value.length === 11;
        return isPhoneNumber && isCorrectLength;
      })
      .required('Please provide a phone number'),
    companyAddress: yup.string().required('Please enter address information'),
  });

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      enableReinitialize
      onSubmit={async (values, actions) => {
        try {
          await makeApiCallAdmin(`/Agent/${agentId}`, {
            method: 'put',
            data: {
              ...businessInfo,
              ...values,
              costPlanId,
              userId: id,
              contactFirstName: fullName ? fullName.substring(0, fullName.indexOf(' ')) : '',
              contactLastName: fullName ? fullName.substring(fullName.indexOf(' ') + 1) : '',
            },
          });
          message.success('Business information updated');
        } catch (error) {
          message.error(error.message);
        } finally {
          actions.setSubmitting(false);
        }
      }}
    >
      {({ values, errors, touched, handleChange, handleSubmit, isSubmitting }) => (
        <form onSubmit={handleSubmit}>
          <AppInput
            name="companyName"
            value={values['companyName']}
            type="text"
            size="large"
            placeholder="Company Name"
            error={touched['companyName'] && errors['companyName']}
            errorMessage={errors['companyName']}
            onChange={handleChange}
            title="Contact an admin for change of business name"
            disabled
          />
          <AppInput
            name="email"
            value={values['email']}
            type="email"
            size="large"
            placeholder="name@example.com"
            error={touched['email'] && errors['email']}
            errorMessage={errors['email']}
            onChange={handleChange}
            title="Contact an admin for change of email address"
            disabled
          />
          <AppInput
            name="companyPhone"
            value={values['companyPhone']}
            type="text"
            size="large"
            placeholder="08012345678"
            error={touched['companyPhone'] && errors['companyPhone']}
            errorMessage={errors['companyPhone']}
            onChange={handleChange}
          />
          <AppInput
            name="companyAddress"
            value={values['companyAddress']}
            size="large"
            type="text"
            placeholder="address"
            error={touched['companyAddress'] && errors['companyAddress']}
            errorMessage={errors['companyAddress']}
            onChange={handleChange}
          />
          <ButtonComponent
            appearance="blue"
            htmlType="submit"
            loading={isSubmitting}
            marginTop="20px"
            disabled={isEqual(initialValues, values)}
            title={isEqual(initialValues, values) ? 'No changes have been made' : ''}
          >
            Save Business Information
          </ButtonComponent>
        </form>
      )}
    </Formik>
  );
}

function UpdateBusinessInfoClient() {
  const businessInfo = useBusinessInfo();
  const { clientId } = useProfile();

  const initialValues = {
    clientName: businessInfo['clientName'],
    contactEmail: businessInfo['email'],
    contactPhone: businessInfo['phone'],
    address: businessInfo['address'],
  };

  const validationSchema = yup.object().shape({
    contactPhone: yup
      .string()
      .test('valid-mobile-number', 'Please enter a valid mobile number', (value) => {
        const isPhoneNumber = /^[0-9]*$/gm.test(value); // must only contain numbers
        const isCorrectLength = value && value.length === 11;
        return isPhoneNumber && isCorrectLength;
      })
      .required('Please provide a phone number'),
    address: yup.string().required('Please enter address information'),
  });

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      enableReinitialize
      onSubmit={async (values, actions) => {
        try {
          await makeApiCallAdmin(`/Client/${clientId}`, {
            method: 'put',
            data: { ...businessInfo, ...values },
          });
          message.success('Business information updated');
          businessInfo.revalidate();
        } catch (error) {
          message.error(error.message);
        } finally {
          actions.setSubmitting(false);
        }
      }}
    >
      {({ values, errors, touched, handleChange, handleSubmit, isSubmitting }) => (
        <form onSubmit={handleSubmit}>
          <AppInput
            name="clientName"
            value={values['clientName']}
            type="text"
            size="large"
            placeholder="Company Name"
            error={touched['clientName'] && errors['clientName']}
            errorMessage={errors['clientName']}
            onChange={handleChange}
            title="Contact an admin for change of business name"
            disabled
          />
          <AppInput
            name="contactEmail"
            value={values['contactEmail']}
            type="contactEmail"
            size="large"
            placeholder="name@example.com"
            error={touched['contactEmail'] && errors['contactEmail']}
            errorMessage={errors['contactEmail']}
            onChange={handleChange}
            title="Contact an admin for change of business name"
            disabled
          />
          <AppInput
            name="contactPhone"
            value={values['contactPhone']}
            type="number"
            size="large"
            placeholder="08012345678"
            error={touched['contactPhone'] && errors['contactPhone']}
            errorMessage={errors['contactPhone']}
            onChange={(e) => {
              const { value } = e.target;
              if (Number(value) && value.length <= 11) {
                handleChange(e);
              }
            }}
          />
          <AppInput
            name="address"
            value={values['address']}
            size="large"
            type="text"
            placeholder="address"
            error={touched['address'] && errors['address']}
            errorMessage={errors['address']}
            onChange={handleChange}
          />
          <ButtonComponent
            appearance="blue"
            htmlType="submit"
            loading={isSubmitting}
            marginTop="20px"
            disabled={isEqual(initialValues, values)}
            title={isEqual(initialValues, values) ? 'No changes have been made' : ''}
          >
            Save Business Information
          </ButtonComponent>
        </form>
      )}
    </Formik>
  );
}

export default BusinessInfo;
