import { Col, List, Row } from "antd";
import React, { useState } from "react";
import { withRouter } from "react-router";
import styled from "styled-components";
import { AppContext } from "../../../DataProvider";
import { useProfile, useTrips } from "../../../utils/hooks";
import { gray, white } from "../../design-system/colors";
import { customFonts } from "../../design-system/fonts";
import { ButtonComponent } from "../../primitives/Buttons";
import { Heading, Text } from "../../primitives/Text";
import { Fleet } from "../Fleet";
import { TripItem } from "./components";
import CreateTrip from "./CreateTrip";

export const TripsPage = withRouter(({ history }) => {
	// const { updateState } = React.useContext(AppContext);
	const [formData, setFormData] = useState({});
	const [showForm, setShowForm] = useState("");
	const { trips = [], revalidate, loading } = useTrips();
	// const { clientId } = useProfile();

	return (
		<Fleet>
			<TripsPageContainer>
				{trips && trips.length > 0 ? (
					<Row>
						<Col span={6}>
							<div className="trips-page__sidebar">
								<List
									itemLayout="horizontal"
									dataSource={trips}
									loading={loading}
									renderItem={(item) => (
										<TripItem
											{...item}
											key={item.TripId}
											onEdit={() => {
												setFormData(item);
												setShowForm("edit");
											}}
											goToAssignPage={() =>
												history.push(`/fleet/assign/${item.TripId}`)
											}
										/>
									)}
								/>
							</div>
						</Col>
						<Col span={18}>
							{showForm ? (
								<div className="trip-page__form-container">
									<div className="trip-page__form">
										{showForm === "new" ? (
											<CreateTrip
												cancel={() => {
													setShowForm("");
													revalidate();
												}}
											/>
										) : (
											<CreateTrip
												isEdit
												cancel={() => {
													setShowForm("");
													revalidate();
												}}
												initialValues={formData}
											/>
										)}
									</div>
								</div>
							) : (
								<EmptyTripsPage>
									<div className="EmptyTripsPage__content">
										<div className="image-container">
											<img src="/static/img/route.svg" alt="route icon" />
										</div>
										<Heading>Create Trips</Heading>
										<Text color={gray.primary}>
											Creating trips helps you keep track of your assets moving
											across scheduled lines.
										</Text>
										<ButtonComponent
											appearance="blue"
											size="large"
											className="create__button"
											onClick={() => setShowForm("new")}
										>
											Create
										</ButtonComponent>
									</div>
								</EmptyTripsPage>
							)}
						</Col>
					</Row>
				) : showForm === "new" ? (
					<div className="trip-page__form-container">
						<div className="trip-page__form">
							<CreateTrip cancel={() => setShowForm("")} />
						</div>
					</div>
				) : (
					<EmptyTripsPage>
						<div className="EmptyTripsPage__content">
							<div className="image-container">
								<img src="/static/img/route.svg" alt="route icon" />
							</div>
							<Heading>No Trips Created</Heading>
							<Text color={gray.primary}>
								Creating trips helps you keep track of your assets moving across
								scheduled lines.
							</Text>
							<ButtonComponent
								fullWidth
								appearance="blue"
								size="large"
								onClick={() => setShowForm("new")}
							>
								Create Trips
							</ButtonComponent>
						</div>
					</EmptyTripsPage>
				)}
			</TripsPageContainer>
		</Fleet>
	);
});

var TripsPageContainer = styled.div`
	position: fixed;
	height: calc(100vh - 128px);
	width: 100%;

	.ant-row {
		height: 100%;

		& > * {
			height: 100%;
		}
	}

	.trips-page__sidebar {
		border-right: 1px solid ${gray.light};
		background: ${white};
		height: 100%;
		width: 100%;
		overflow: auto;
		padding: 16px 0 48px 0;
	}
	.trip-page__form-container {
		height: 100%;
		display: flex;
		flex-direction: column;
		overflow: auto;
	}
	.trip-page__form {
		display: flex;
		flex-direction: column;
		flex-grow: 1;
		padding: 0 16px;
		margin: 30px 0;

		h1 {
			font-family: ${customFonts.circular}!important;
			margin-bottom: 16px;
			font-size: 20px;
			font-weight: bold;
		}
	}
`;

var EmptyTripsPage = styled.div`
	height: 100vh;
	width: 100%;
	padding: 160px 0;
	background-color: ${gray.ui01};
	display: flex;
	flex-direction: column;
	text-align: center;
	align-items: center;

	.EmptyTripsPage {
		&__content {
			max-width: 400px;

			.image-container {
				max-width: 80px;
				margin-left: auto;
				margin-right: auto;
				margin-bottom: 24px;
				img {
					width: 100%;
				}
			}
			.create__button {
				margin: 0 auto;
			}
		}
	}
`;
