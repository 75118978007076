var $ = window.$;

var telerikReportViewer = window.telerikReportViewer;

const generateReport = (report, parameters) => {
	return new Promise((resolve, reject) => {
		const reportViewer = $("#reportViewer").data("telerik_ReportViewer");
		if (reportViewer) {
			reportViewer.reportSource({ report, parameters });
			reportViewer.bind(telerikReportViewer.Events.PAGE_READY, (e) =>
				resolve(e)
			);
			reportViewer.bind(telerikReportViewer.Events.ERROR, (e) => reject(e));
		} else {
			$("#reportViewer").telerik_ReportViewer({
				serviceUrl: "https://flitrackapi.lubred.net/api/reports",
				reportSource: { report, parameters },
				scaleMode: "FIT_PAGE_WIDTH",
				viewMode: "PRINT_PREVIEW",
				pageReady: (e) => resolve(e),
				error: (e) => reject(e),
			});
		}
	});
};

export const generateTelerikReport = ({
	report,
	reportName,
	clientId,
	data,
}) => {
	const {
		folder,
		rootNode,
		asset,
		speed,
		startDate,
		endDate,
		timeLimit,
		timeUnit,
		driver,
	} = data;

	const NodeId = folder ? folder[folder.length - 1] : rootNode;
	const DriverID = driver && driver[driver.length - 1];
	const StartEpochTime = Math.floor(new Date(startDate) / 1000);
	const EndEpochTime = Math.floor(new Date(endDate) / 1000);

	let parameters = { clientId, StartEpochTime, EndEpochTime, NodeId };
	let driverParameters = {
		ClientId: clientId,
		DriverID,
		StartEpochTime,
		EndEpochTime,
	};

	switch (reportName) {
		case "Full Movement":
			const AssetName = asset ? asset.split(" ")[0] : asset;
			parameters = { ...parameters, AssetName, Speed: speed };
			break;
		case "Asset Group Violation":
			break;
		case "Non-Communicating Asset":
			parameters = { ...parameters, TimeUnit: timeUnit, Value: timeLimit };
			break;
		case "Driver Full Movement":
			parameters = {
				...driverParameters,
			};
			break;
		case "Driver Full Violation":
			parameters = {
				...driverParameters,
			};
			break;
		case "Fleet Summary":
			parameters = { ...parameters, GroupName: NodeId };
			break;
		default:
			break;
	}
	return Promise.resolve(generateReport(report, parameters));
};
