import { message } from 'antd';
import useSWR from 'swr';
import { makeApiCallAdmin } from '../../../api';

function useSOS() {
  const { data: sos, error, revalidate } = useSWR('/Dashboard/SOS', fetchSOS);

  return { sos, loading: !sos && !error, revalidate };
}

async function fetchSOS(url) {
  try {
    const { data = [] } = await makeApiCallAdmin(url);
    return data;
  } catch (e) {
    message.error(e.message);
  }
}

export default useSOS;
