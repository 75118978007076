import { message, Select } from 'antd';
import { Formik } from 'formik';
import * as React from 'react';
import * as yup from 'yup';
import { makeApiCallAdmin } from '../api';
import { black } from '../components/design-system/colors';
import { AppInput } from '../components/primitives/Input';
import { SaveAndCancelButtons } from '../components/primitives/SaveAndCancelButtons';
import { SelectStyle } from '../components/primitives/Select';
import { Label } from '../components/primitives/Text';
import { useProfile, useRoles } from '../utils/hooks';

const Option = Select.Option;

function UserForm(props) {
  const { roles = [] } = useRoles();
  const user = useProfile();

  return (
    <Formik
      initialValues={{
        fullName: props.fullName || '',
        phone: props.phone || '',
        email: props.email || '',
        roleid: props.roleid || '',
        userType: user.userType,
      }}
      onSubmit={async (values, { resetForm, setSubmitting }) => {
        try {
          await makeApiCallAdmin(`/Account/${props.id || ''}`, {
            method: props.isEdit ? 'put' : 'post',
            data: values,
          });
          message.success(`User account ${props.isEdit ? 'updated' : 'created'} successfully`);
          resetForm();
          props.cancel();
        } catch (e) {
          message.error(e.message);
        } finally {
          setSubmitting(false);
        }
      }}
      validationSchema={validationSchema}
      enableReinitialize
    >
      {({ values, touched, errors, handleChange, handleSubmit, isSubmitting, setFieldValue }) => (
        <form onSubmit={handleSubmit}>
          <AppInput
            name="fullName"
            value={values.fullName}
            type="text"
            size="large"
            label="Full Name"
            placeholder="Full Name"
            error={touched.fullName && errors.fullName}
            errorMessage={errors.fullName}
            onChange={handleChange}
          />
          <AppInput
            name="email"
            value={values.email}
            size="large"
            type="email"
            placeholder="Email"
            label="Email"
            error={touched.email && errors.email}
            errorMessage={errors.email}
            onChange={handleChange}
          />
          <AppInput
            name="phone"
            value={values.phone}
            type="text"
            size="large"
            placeholder="Phone"
            label="Phone"
            error={touched.phone && errors.phone}
            errorMessage={errors.phone}
            onChange={handleChange}
          />
          <SelectStyle
            marginTop="20px"
            border={black}
            css={'.ant-select-search__field { top: 0; }'}
          >
            <Label>Role</Label>
            <Select
              value={values.roleid}
              placeholder="Select a role"
              onChange={(value) => setFieldValue('roleid', value)}
              size="large"
            >
              {roles.map((role) => (
                <Option value={role.roleProfileId} key={role.roleProfileId}>
                  {role.roleName}
                </Option>
              ))}
            </Select>
          </SelectStyle>
          <SaveAndCancelButtons onCancel={props.cancel} loading={isSubmitting} />
        </form>
      )}
    </Formik>
  );
}

var validationSchema = yup.object().shape({
  fullName: yup.string().required('This field is required'),
  email: yup
    .string()
    .email('This is not a valid email')
    .required('This field is required'),
  phone: yup.string().required('This field is required'),
});

export default UserForm;
