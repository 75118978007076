import React from "react";
import { Link } from "react-router-dom";

import {
	StyledContactPage,
	ContactBodyWrapper,
	ContactCard,
	ContactInfo,
	SocialContactInfo,
	ContactInfoDark,
} from "./style";

const ContactPage = () => {
	return (
		<>
			<StyledContactPage>
				<div className="back-arrow">
					<Link to="/">
						<img src="/static/img/arrow-left.svg" className="logo" alt="" />
					</Link>
				</div>
				<div className="contact-heading">
					<div className="logo">
						<img src="/static/img/logo.svg" alt="" />
					</div>

					<h3>Lubred Technologies</h3>
					<div className="address">
						<img src="/static/img/marker.svg" alt="map marker" />
						<p>15 Military St, Lagos Island, Lagos</p>
					</div>
				</div>
				<div className="contact-body">
					<ContactBodyWrapper>
						<ContactCard>
							<h3>Phone</h3>
							<ContactInfo>
								<div className="contact-icon-container">
									<img
										src="/static/img/phone-blue.svg"
										className="contact-card-icon"
										alt="phone icon"
									/>
								</div>
								<div className="contact-info-content-container ">
									<p>+234 (0)1 4536 7354</p>
								</div>
							</ContactInfo>
							<ContactInfoDark>
								<div className="contact-icon-container">
									<img
										src="/static/img/phone-dark.svg"
										className="contact-card-icon"
										alt="phone icon"
									/>
								</div>
								<div className="contact-info-content-container ">
									<p>+234 (0)1 4536 7354</p>
								</div>
							</ContactInfoDark>
						</ContactCard>
						<ContactCard>
							<h3>Email</h3>
							<ContactInfo>
								<div className="contact-icon-container">
									<img
										src="/static/img/mail.svg"
										className="contact-card-icon"
										alt="mail icon"
									/>
								</div>
								<div className="contact-info-content-container ">
									<p>support@lubred.com</p>
								</div>
							</ContactInfo>
						</ContactCard>
						<ContactCard>
							<h3>Social Media</h3>
							<SocialContactInfo>
								<a href="https://facebook.com">
									<div className="contact-icon-container">
										<img
											src="/static/img/facebook.svg"
											className="contact-card-icon"
											alt="facebook icon"
										/>
									</div>
									<div className="contact-info-content-container ">
										<p>Like our facebook page</p>
									</div>
								</a>
							</SocialContactInfo>
							<SocialContactInfo>
								<a href="https://twitter.com">
									<div className="contact-icon-container">
										<img
											src="/static/img/twitter.svg"
											className="contact-card-icon"
											alt="twitter icon"
										/>
									</div>
									<div className="contact-info-content-container ">
										<p>Get our twitter updates</p>
									</div>
								</a>
							</SocialContactInfo>
							<SocialContactInfo>
								<a href="https://whatsapp.com">
									<div className="contact-icon-container">
										<img
											src="/static/img/whatsapp.svg"
											className="contact-card-icon"
											alt="whatsapp icon"
										/>
									</div>
									<div className="contact-info-content-container ">
										<p>Chat us on whatsApp</p>
									</div>
								</a>
							</SocialContactInfo>
						</ContactCard>
					</ContactBodyWrapper>
				</div>
			</StyledContactPage>
		</>
	);
};

export default ContactPage;
