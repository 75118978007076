import { message } from "antd";
import { Formik } from "formik";
import * as React from "react";
import { NavLink } from "react-router-dom";
import * as yup from "yup";
import { makeApiCallAdmin } from "../api";
import AuthContainer from "../components/pages/Authentication/index";
import { ButtonComponent } from "../components/primitives/Buttons";
import { InputComponent } from "../components/primitives/Input";
import { Heading, Text } from "../components/primitives/Text";

function VerifyPage({ history, match: { url } }) {
	return (
		<AuthContainer url={url}>
			<Heading align="center" fontSize="30px">
				Verification Code
			</Heading>
			<Formik
				initialValues={{ resetCode: "" }}
				validationSchema={validationSchema}
				onSubmit={async (values, actions) => {
					const email = localStorage.getItem("EZEETRACKER_RESET_EMAIL");
					console.log(values, email);
					try {
						let results = await makeApiCallAdmin("/Login/VerifyCode", {
							method: "post",
							data: { email: email, resetCode: values.resetCode },
						});

						localStorage.setItem("EZEETRACKER_CODE", results.validationCode);
						message.success(results.msg);
						history.push("/reset-password");
					} catch (error) {
						message.error(error.message);
					} finally {
						actions.setSubmitting(false);
					}
				}}
			>
				{({
					values,
					errors,
					touched,
					handleChange,
					handleSubmit,
					isSubmitting,
				}) => (
					<form onSubmit={handleSubmit} style={{ marginTop: "2rem" }}>
						<InputComponent
							name="resetCode"
							type="text"
							placeholder="Enter Verification code"
							size="large"
							value={values.resetCode}
							error={errors.resetCode && touched.resetCode}
							errorMessage={errors.resetCode}
							onChange={handleChange}
						/>

						<ButtonComponent
							size="large"
							appearance="blue"
							htmlType="submit"
							loading={isSubmitting}
							className="mt-5 w-full"
						>
							Submit
						</ButtonComponent>
					</form>
				)}
			</Formik>
			<div className="sign-in-help__links">
				<Text align="center" marginTop="15px">
					<NavLink to="/">Back to Login</NavLink>
				</Text>
			</div>
		</AuthContainer>
	);
}

var validationSchema = yup.object().shape({
	resetCode: yup.string().required("This field is required"),
});

export default VerifyPage;
