import { Icon, Menu, Spin, Tooltip } from 'antd';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { makeApiCallAdmin } from '../api';
import { white } from '../components/design-system/colors';
import { EmptyButtonComponent } from '../components/primitives/Buttons';
import { AppNavbar } from '../components/primitives/Navbar';
import { useProfile } from '../utils/hooks';
import ReportViewArea from './ReportViewArea';

const { SubMenu } = Menu;

function ReportsPage() {
  const [report, setSelectedReport] = useState({});
  const [reportGroups, setReportGroups] = useState([]);
  const [loading, setLoading] = useState(true);
  const [displayReport, setDisplayReport] = useState(false);
  const [isSidebarShown, toggleSideBar] = useState(true);

  const { clientId } = useProfile();

  useEffect(() => {
    getReportMenu();
  }, []);

  const showReport = () => setDisplayReport(true);
  const hideReport = () => setDisplayReport(false);

  const setReport = (report) => {
    hideReport();
    setSelectedReport(report);
  };

  const getReportMenu = async () => {
    const { data } = await makeApiCallAdmin('/LookUp/Report/Menu');
    setReportGroups(data);
    setLoading(false);
  };

  return (
    <>
      <AppNavbar />
      <div className="flex min-h-screen pt-16">
        <LeftSidebar isSidebarShown={isSidebarShown}>
          <h1 className="sidebar__heading">Reports</h1>
          {loading ? (
            <div className="loading__spinner">
              <Spin />
            </div>
          ) : (
            <Menu className="reportList" mode="inline">
              {reportGroups.map(({ menuGroup, menuItem }, index) => (
                <SubMenu key={`reportGroup-${index}`} title={menuGroup}>
                  {menuItem.map(({ item, reportName, filterControls }, i) => (
                    <Menu.Item
                      key={`${reportName}-${i}`}
                      onClick={() =>
                        setReport({
                          value: reportName,
                          text: item,
                          controls: filterControls,
                        })
                      }
                    >
                      {item}
                    </Menu.Item>
                  ))}
                </SubMenu>
              ))}
            </Menu>
          )}
          <Tooltip
            placement="left"
            mouseLeaveDelay={0}
            title={`${isSidebarShown ? `Hide` : `Show`} reports panel`}
          >
            <EmptyButtonComponent
              className={`toggle-collapse__button ${isSidebarShown ? 'shown' : 'not-shown'}`}
              onClick={() => toggleSideBar(!isSidebarShown)}
            >
              <Icon type={isSidebarShown ? 'left' : 'right'} style={{ fontSize: '12px' }} />
            </EmptyButtonComponent>
          </Tooltip>
        </LeftSidebar>
        <ReportViewArea
          report={report}
          clientId={clientId}
          displayReport={displayReport}
          showReport={showReport}
        />
      </div>
    </>
  );
}

var LeftSidebar = styled.div`
  position: relative;
  width: 300px;
  max-width: 300px;
  border-right: 1px solid rgb(232, 232, 232);
  transition: transform 0.3s ease-out;
  display: flex;
  flex-direction: column;
  transform: ${(props) => (props.isSidebarShown ? 'translateX(0)' : 'translateX(-100%)')};

  & > .loading__spinner {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-grow: 1;
  }

  .sidebar__heading {
    margin: 24px;
    overflow: hidden;
    font-size: 20px;
  }

  .toggle-collapse__button {
    position: absolute;
    right: 0;
    z-index: 2;
    bottom: 50%;
    padding: 8px 12px 8px 8px;
    border: 1px solid #f6f6f6;
    transition: all 0.2s linear 0.1s;
    border-top-left-radius: 100px;
    border-bottom-left-radius: 100px;
    background-color: ${white};

    &.not-shown {
      border-top-right-radius: 100px;
      border-bottom-right-radius: 100px;
      border-top-left-radius: 0px;
      border-bottom-left-radius: 0px;
      right: -34px;
    }
  }

  .ant-menu {
    overflow: hidden;
  }

  .ant-menu-submenu-title {
    font-weight: bold;
    font-size: 15px;
  }

  .report > a {
    font-size: 12px;
    color: #4a4a4a;
  }
`;

export default ReportsPage;
