import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { silver } from '../pages/Fleet/SilverMapStlye';

var google = window.google;

const StyledMap = styled.div`
  margin-top: 20px;
  flex-grow: 1;
`;

const TripsMap = ({
  PointAMarker,
  PointBMarker,
  updatePointAMarker,
  updatePointBMarker,
  updatePointA,
  updatePointB
}) => {
  const [map, setMap] = useState();
  const [geocoder, setGeocoder] = useState();
  const [circles, setCircles] = useState([]);
  const [directionsService, setDirectionsService] = useState();
  const [directionsDisplay, setDirectionsDisplay] = useState();

  useEffect(() => {
    const newMap = new window.google.maps.Map(document.getElementById('map'), {
      zoom: 15,
      center: new window.google.maps.LatLng(6.5244, 3.3792),
      mapTypeId: window.google.maps.MapTypeId.ROADMAP,
      disableDefaultUI: true,
      zoomControl: true,
      fullscreenControl: true,
      styles: silver
    });
    const newGeocoder = new google.maps.Geocoder();
    setMap(newMap);
    setGeocoder(newGeocoder);

    const newDirectionsService = new google.maps.DirectionsService();
    const newDirectionsDisplay = new google.maps.DirectionsRenderer({
      draggable: true,
      map: newMap
    });

    newDirectionsDisplay.addListener('directions_changed', () => {
      const { start, end, startAddress, endAddress } = getLocationsFromDirectionsDisplay(
        newDirectionsDisplay
      );
      updatePointAMarker({ location: start, address: startAddress });
      updatePointBMarker({ location: end, address: endAddress });
    });

    setDirectionsService(newDirectionsService);
    setDirectionsDisplay(newDirectionsDisplay);
  }, []);

  useEffect(() => {
    if (PointAMarker && PointBMarker && setDirectionsService && directionsDisplay) {
      showRoute();
      drawCircles();
    } else if (!PointAMarker && !PointBMarker) {
      clearMap();
    }
  }, [PointAMarker, PointBMarker, setDirectionsService, directionsDisplay]);

  const getLocationsFromDirectionsDisplay = directionsDisplay => {
    const routeLeg = directionsDisplay.directions.routes[0].legs[0];
    const start = { lat: routeLeg.start_location.lat(), lng: routeLeg.start_location.lng() };
    const startAddress = routeLeg.start_address;
    const endAddress = routeLeg.end_address;
    const end = { lat: routeLeg.end_location.lat(), lng: routeLeg.end_location.lng() };

    return { start, end, startAddress, endAddress };
  };

  const getLocationsFromCircles = (circle, pointName) => {
    const latLng = circle.getCenter();

    // Get the address from the center position of the circle using the latLng literal
    geocoder.geocode({ location: latLng }, (results, status) => {
      if (status === 'OK') {
        const lat = latLng.lat();
        const lng = latLng.lng();

        if (results[0]) {
          // Update the location of the specific point that the circle belongs
          if (pointName === 'PointAMarker') {
            updatePointAMarker({ location: { lat, lng }, address: results[0].formatted_address });
          } else {
            updatePointBMarker({ location: { lat, lng }, address: results[0].formatted_address });
          }
        }
      }
    });
  };

  const showRoute = () => {
    const request = {
      origin: new google.maps.LatLng(PointAMarker.Latitude, PointAMarker.Longitude),
      destination: new google.maps.LatLng(PointBMarker.Latitude, PointBMarker.Longitude),
      travelMode: 'DRIVING',
      unitSystem: google.maps.UnitSystem.METRIC
    };
    directionsService.route(request, (response, status) => {
      if (status === 'OK') {
        directionsDisplay.setMap(map);
        directionsDisplay.setDirections(response);
      }
    });
  };

  const getLocationsFromCircle = circle => {
    const nE = circle
      .getBounds()
      .getNorthEast()
      .toJSON();
    const sW = circle
      .getBounds()
      .getSouthWest()
      .toJSON();

    const northEast = { Latitude: nE.lat, Longitude: nE.lng };
    const southWest = { Latitude: sW.lat, Longitude: sW.lng };
    const northWest = { Latitude: nE.lat, Longitude: sW.lng };
    const southEast = { Latitude: sW.lat, Longitude: nE.lng };

    return [northEast, southWest, northWest, southEast];
  };

  const drawCircles = () => {
    // create new circles if none exists
    if (circles.length === 0) {
      // create 2 circles for the 2 locations
      const startCircle = new google.maps.Circle({
        map: map,
        radius: 300,
        draggable: true,
        center: new google.maps.LatLng(PointAMarker.Latitude, PointAMarker.Longitude)
      });
      const endCircle = new google.maps.Circle({
        map: map,
        radius: 300,
        draggable: true,
        center: new google.maps.LatLng(PointBMarker.Latitude, PointBMarker.Longitude)
      });

      // save the bounds of both circles, which is needed to create the trip
      updatePointA(getLocationsFromCircle(startCircle));
      updatePointB(getLocationsFromCircle(endCircle));

      // update location when a circle is dragged
      startCircle.addListener('dragend', () => {
        getLocationsFromCircles(startCircle, 'PointAMarker');
      });
      endCircle.addListener('dragend', () => {
        getLocationsFromCircles(endCircle, 'PointBMarker');
      });

      // save circles to state to avoid re-creating them on every re-render
      setCircles([startCircle, endCircle]);
    } else {
      // loop through the circles, updating their center location
      circles.forEach((circle, index) => {
        if (index === 0) {
          circle.setCenter(new google.maps.LatLng(PointAMarker.Latitude, PointAMarker.Longitude));
          circle.setMap(map);
          updatePointA(getLocationsFromCircle(circle));
        } else {
          circle.setCenter(new google.maps.LatLng(PointBMarker.Latitude, PointBMarker.Longitude));
          circle.setMap(map);
          updatePointB(getLocationsFromCircle(circle));
        }
      });
    }
  };

  const clearMap = () => {
    directionsDisplay && directionsDisplay.setMap(null);
    circles && circles.length > 0 && circles.forEach(circle => circle.setMap(null));
  };

  return <StyledMap id="map" />;
};

export default TripsMap;
