import { message } from "antd";
import moment from "moment";
import React, { Component } from "react";
import Geocode from "react-geocode";
import { makeApiCallAdmin } from "../api";
import { FleetInsight } from "../components/pages/Fleet/FleetInsight";
import { AppContext } from "../DataProvider";

Geocode.setApiKey("AIzaSyAdQ0vynulnP5YimxfGsZPtskcOnOLuyfk");

export class FleetInsightPage extends Component {
	static contextType = AppContext;
	constructor(props) {
		super(props);
		this.state = {
			to: "Address Not Available",
			from: "Address Not Available",
			loading: false,
			liveLoading: false,
			buttonText: "Play",
			locationList: [],
			startDate: moment().startOf("day"),
			endDate: moment(),
			currentLocation: {},
			selectedLocation: null,
			vehicleInformation: {},
			position: { lat: 6.5244, lng: 3.3792 },
			geofenceList: [],
			showVehicleRoute: false,
		};
	}

	goBack = () => {
		this.props.history.push("/fleet/overview");
	};

	componentDidMount() {
		this.getCurrentLocation();
		this.getGeofenceList();
	}

	submitDateRange = async () => {
		this.setState({ showVehicleRoute: true });
		await this.getTrips();
	};

	setCurrentLocation = (value) => {
		if (value) {
			this.setState({
				currentLocation: value,
			});
		}
	};

	setSelectedLocation = (value) => {
		this.setState({
			selectedLocation: value,
		});
	};

	onRouteClick = (value) => {
		const { locationList } = this.state;
		let point = locationList.find(
			(trip) => Number(trip.Latitude) === Number(value)
		);
		this.setCurrentLocation(point);
	};

	getCurrentLocation = async () => {
		if (!this.state.liveLoading) {
			const {
				match: {
					params: { id },
				},
			} = this.props;
			this.setState({ liveLoading: true });
			try {
				let { data: vehicleInformation } = await makeApiCallAdmin(
					`/Assets/${id}`
				);
				let { data: currentLocation } = await makeApiCallAdmin(
					`/Assets/${id}/Location`
				);
				this.setState({
					currentLocation,
					vehicleInformation,
					position: {
						lat: Number(currentLocation.latitude),
						lng: Number(currentLocation.longitude),
					},
				});
				return currentLocation;
			} catch (error) {
				message.error(error.message);
				return false;
			} finally {
				this.setState({ liveLoading: false });
			}
		} else {
			return false;
		}
	};

	getTripAddresses = async (trips) => {
		if (trips.length > 0) {
			const len = trips.length - 1;
			const [fromLat, fromLon] = [trips[0].latitude, trips[0].longitude];
			const [toLat, toLon] = [trips[len].latitude, trips[len].longitude];
			const from = await this.getAddress(fromLat, fromLon);
			const to = await this.getAddress(toLat, toLon);
			return { from, to };
		} else {
			return { from: "Not Available", to: "Not Available" };
		}
	};

	getAddress = async (latitude, longitude) => {
		const [lat, lng] = [parseFloat(latitude), parseFloat(longitude)];
		try {
			const response = await Geocode.fromLatLng(lat, lng);
			return response.results[0].formatted_address || "N/A";
		} catch (error) {
			message.error(error.message);
		}
	};

	clearTrips = () => {
		this.setState({ locationList: [] });
	};

	reset = () => {
		this.setState({
			locationList: [],
			showVehicleRoute: false,
			startDate: moment().startOf("day"),
			endDate: moment(),
		});
		this.getCurrentLocation();
	};

	getTrips = async (limit = 100) => {
		const {
			match: {
				params: { id },
			},
		} = this.props;
		const { startDate, endDate } = this.state;
		let startDateInSeconds = moment(startDate).format("X");
		let endDateInSeconds = moment(endDate).format("X");
		// let url = `/Assets/${id}/Route/${startDateInSeconds}/${endDateInSeconds}/`;
		try {
			this.setState({ loading: true });
			let { data } = await makeApiCallAdmin(
				`/Assets/${id}/Route/${startDateInSeconds}/${endDateInSeconds}/`
			);
			const { from, to } = await this.getTripAddresses(data);
			this.setState({
				locationList: data,
				loading: false,
				from,
				to,
			});
		} catch (error) {
			this.setState({ loading: false });
			message.error(error.message);
		}
	};

	getGeofenceList = async () => {
		try {
			let { data } = await makeApiCallAdmin("/Geofence");
			this.setState({ geofenceList: data });
		} catch (error) {
			message.error(error.message);
		}
	};

	updateState = (label, value) => {
		this.setState({
			[label]: value,
		});
	};

	render() {
		const {
			to,
			from,
			loading,
			locationList,
			startDate,
			endDate,
			currentLocation,
			selectedLocation,
			position,
			buttonText,
			liveLoading,
			geofenceList,
			showVehicleRoute,
			vehicleInformation,
		} = this.state;
		return (
			<FleetInsight
				{...this.props}
				to={to}
				from={from}
				reset={this.reset}
				clearTrips={this.clearTrips}
				trips={locationList}
				position={position}
				loading={loading}
				goBack={this.goBack}
				startDate={startDate}
				endDate={endDate}
				buttonText={buttonText}
				updateState={this.updateState}
				currentLocation={currentLocation}
				selectedLocation={selectedLocation}
				submitDateRange={this.submitDateRange}
				setCurrentLocation={this.setCurrentLocation}
				setSelectedLocation={this.setSelectedLocation}
				getCurrentLocation={this.getCurrentLocation}
				liveLoading={liveLoading}
				geofenceList={geofenceList}
				getAddress={this.getAddress}
				showVehicleRoute={showVehicleRoute}
				vehicleInformation={vehicleInformation}
			/>
		);
	}
}
