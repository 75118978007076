import React from 'react';
import styled from 'styled-components';
import ReportHeader from './ReportHeader';

const StyledReportView = styled.div`
  display: grid;
  height: 100%;
  grid-template-rows: 86px 1fr;
`;

export default ({ report, clientId, showReport, displayReport }) => {
  return (
    <StyledReportView>
      <ReportHeader showReport={showReport} report={report} clientId={clientId} />
      {displayReport && <div id="reportViewer" />}
    </StyledReportView>
  );
};
