import isEmpty from 'lodash/isEmpty';
export const genCharArray = (charA = 'a', charZ = 'z') => {
  let a = [],
    i = charA.charCodeAt(0),
    j = charZ.charCodeAt(0);
  for (; i <= j; ++i) {
    a.push(String.fromCharCode(i));
  }
  return a;
};

export const LicTypeValues = [
  { label: 'Type A', value: 'Type A' },
  { label: 'Type B', value: 'Type B' },
  { label: 'Type C', value: 'Type C' },
  { label: 'Type D', value: 'Type D' }
];
export const wordWithSpace = x =>
  x ? x.toString().replace(/\d+/g, ` ${x.toString().match(/\d+/g)[0]} `) : ``;

export const googleUrl = `https://maps.googleapis.com/maps/api/js?key=AIzaSyAdQ0vynulnP5YimxfGsZPtskcOnOLuyfk&libraries=geometry,drawing,places`;

export const formatNodesWithChildren = data => {
  let nodesWithChildren = data;
  if (nodesWithChildren.length > 0) {
    return nodesWithChildren.map(value => ({
      value: value.NodeId,
      label: value.NodeDesc,
      children: value.Nodes.map(node => ({
        value: node.NodeId,
        label: node.NodeDesc
      }))
    }));
  }
  return nodesWithChildren;
};

export const geocodeLocation = location => {
  let address = 'Address not available';
  if (window.google) {
    let geocoder = new window.google.maps.Geocoder();
    let latlng = {
      lat: parseFloat(location.Latitude),
      lng: parseFloat(location.Longitude)
    };
    geocoder.geocode({ location: latlng }, (results, status) => {
      if (!isEmpty(results)) {
        address = results[0].formatted_address;
      }
    });
  }
  return address;
};
