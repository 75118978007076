import { message } from 'antd';
import useSWR from 'swr';
import { makeApiCallAdmin } from '../../../api';

function useTopViolatingDrivers() {
  const { data: topViolatingDrivers, error, revalidate } = useSWR(
    '/Dashboard/TopViolatingDrivers',
    fetchTopViolatingDrivers
  );

  return { topViolatingDrivers, loading: !topViolatingDrivers && !error, revalidate };
}

async function fetchTopViolatingDrivers(url) {
  try {
    const { data = [] } = await makeApiCallAdmin(url);
    return data;
  } catch (e) {
    message.error(e.message);
  }
}

export default useTopViolatingDrivers;
