import { message } from 'antd';
import useSWR from 'swr';
import { makeApiCallAdmin } from '../../api';

function useDrivers() {
  const { data: drivers, error, revalidate } = useSWR('/Driver', fetchDrivers);

  return { drivers, loading: !drivers && !error, revalidate };
}

async function fetchDrivers(url) {
  try {
    const { data = [] } = await makeApiCallAdmin(url);
    return data;
  } catch (e) {
    message.error(e.message);
  }
}

export default useDrivers;
