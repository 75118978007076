import { message } from 'antd';
import useSWR from 'swr';
import { makeApiCallAdmin } from '../../api';

function useContacts() {
  const { data: contacts, error, revalidate } = useSWR('/Contacts', fetchContacts);

  return { contacts, error, loading: !contacts && !error, revalidate };
}

async function fetchContacts(url) {
  try {
    const { data = [] } = await makeApiCallAdmin(url);
    return data;
  } catch (e) {
    message.error(e.message);
  }
}

export default useContacts;
