import { Button, Divider, Icon, message, Popconfirm } from 'antd';
import debounce from 'lodash/debounce';
import * as React from 'react';
import { makeApiCallAdmin } from '../api';
import { gray, red } from '../components/design-system/colors';
import { Fleet } from '../components/pages/Fleet/index';
import { ButtonComponent, EmptyButtonComponent } from '../components/primitives/Buttons';
import { AppInput } from '../components/primitives/Input';
import { AppModal, confirm, ToggleModal } from '../components/primitives/Modal';
import { AppTable } from '../components/primitives/Table';
import { Text } from '../components/primitives/Text';
import { useDrivers } from '../utils/hooks';
import DriverForm from './DriverForm';

function Drivers() {
  const { drivers = [], loading, revalidate } = useDrivers();
  const [driversCopy, setDriversCopy] = React.useState();

  const changeDriverStatus = ({ driverStatus, id }) => {
    confirm({
      title: `Do you want to ${driverStatus ? 'disable' : 'enable'} this driver?`,
      okText: 'Yes',
      cancelText: 'No',
      onOk: async function() {
        try {
          await makeApiCallAdmin(`/Driver/${id}/Status`, {
            method: 'post',
            data: { status: !driverStatus },
          });
          message.success('Driver updated successfully');
          revalidate();
        } catch (error) {
          message.error(error.message);
        }
      },
    });
  };

  const searchDrivers = debounce((value) => {
    let search = value.toLowerCase();
    if (search !== '') {
      const filteredDrivers = drivers.filter((driver) => {
        return (
          driver.firstName.toLowerCase().includes(search) ||
          driver.lastName.toLowerCase().includes(search) ||
          driver.email.toLowerCase().includes(search)
        );
      });
      setDriversCopy(filteredDrivers);
    } else {
      setDriversCopy(drivers);
    }
  }, 500);

  const deleteDriver = async (driverId) => {
    try {
      await makeApiCallAdmin(`/Driver/${driverId}`, { method: 'delete' });
      message.success('Driver deleted successfully');
      revalidate();
    } catch (error) {
      message.error(error.message);
    }
  };

  const columns = [
    {
      title: 'Name',
      key: 'name',
      render: ({ firstName, lastName }) => `${firstName} ${lastName}`,
    },
    {
      title: 'Email',
      dataIndex: 'email',
    },
    {
      title: 'Phone',
      dataIndex: 'mobileNo',
    },
    {
      title: 'License Type',
      dataIndex: 'licType',
    },
    {
      title: 'Driver Status',
      dataIndex: 'driverStatus',
      render: (status) => (status ? 'Active' : <span className="text-red-600">Inactive</span>),
    },
    {
      key: 'edit',
      render: (text, driver) => {
        return (
          <>
            <ToggleModal>
              {(showModal, onOpenModal, onCloseModal) => {
                return (
                  <>
                    <AppModal title="Edit Driver" visible={showModal} onCancel={onCloseModal}>
                      <DriverForm
                        close={() => {
                          onCloseModal();
                          revalidate();
                        }}
                        driver={driver}
                        isEdit
                      />
                    </AppModal>
                    <EmptyButtonComponent onClick={onOpenModal}>Edit</EmptyButtonComponent>
                  </>
                );
              }}
            </ToggleModal>
            <Divider type="vertical" />
            <EmptyButtonComponent onClick={() => changeDriverStatus(driver)}>
              {driver.driverStatus ? 'Disable' : 'Enable'}
            </EmptyButtonComponent>
            <Divider type="vertical" />
            <Popconfirm
              title="Are you sure you want to delete this driver?"
              onConfirm={async () => {
                await deleteDriver(driver.id);
                revalidate();
              }}
              okText="Delete"
              cancelText="Cancel"
              icon={<Icon type="question-circle-o" style={{ color: 'red' }} />}
            >
              <Button type="link">
                <Text color={red.primary}>Delete</Text>
              </Button>
            </Popconfirm>
          </>
        );
      },
    },
  ];

  return (
    <Fleet isFixed={false}>
      <div className="max-w-screen-2xl px-8 lg:px-16 my-16">
        <div className="mt-3">
          <div className="flex flex-wrap items-center justify-between mb-5">
            <div className="lg:w-1/3 w-64">
              <form className="filter-section-form">
                <AppInput
                  placeholder="Filter Results"
                  size="large"
                  borderColor={gray.primary}
                  marginTop="0"
                  onChange={(e) => searchDrivers(e.target.value)}
                />
              </form>
            </div>
            <div className="flex">
              <ToggleModal>
                {(showModal, onOpenModal, onCloseModal) => {
                  return (
                    <>
                      <AppModal title="Add New Driver" visible={showModal} onCancel={onCloseModal}>
                        <DriverForm
                          close={() => {
                            onCloseModal();
                            revalidate();
                          }}
                        />
                      </AppModal>
                      <ButtonComponent style={{ borderColor: gray.primary }} onClick={onOpenModal}>
                        Add a Driver
                      </ButtonComponent>
                    </>
                  );
                }}
              </ToggleModal>
              {/* <ToggleModal>
                {(showModal, onOpenModal, onCloseModal) => {
                  return (
                    <React.Fragment>
                      <AppModal
                        title="Upload file"
                        visible={showModal}
                        onCancel={onCloseModal}
                      >
                        <FileUpload marginBottom="32px" />
                      </AppModal>
                      <ButtonComponent onClick={onOpenModal}>
                        Bulk Operation
                      </ButtonComponent>
                    </React.Fragment>
                  );
                }}
              </ToggleModal> */}
            </div>
          </div>
        </div>
        <div>
          <AppTable
            hasBorder
            border_color={gray.light}
            rowKey="id"
            loading={loading}
            columns={columns}
            dataSource={driversCopy || drivers}
            className="mt-12"
            pagination={drivers.length > 20 ? { pageSize: 20 } : false}
          />
        </div>
      </div>
    </Fleet>
  );
}

export default Drivers;
