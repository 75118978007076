import useSWR from 'swr';
import { makeApiCallAdmin } from '../../api';
import useProfile from './useProfile';

function useBusinessInfo() {
  const { userType, clientId, agentId } = useProfile();
  const { data = {}, ...rest } = useSWR(
    userType === 1 ? `/Agent/${agentId}` : `/Client/${clientId}`,
    async (url) => {
      const { data } = await makeApiCallAdmin(url);
      return data;
    }
  );

  return { ...data, ...rest };
}

export default useBusinessInfo;
