import { isEmpty } from 'lodash';
import React from 'react';
import styled from 'styled-components';
import { Text } from '../components/primitives/Text';
import ReportView from './ReportView';

const StyledReportViewArea = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: column;

  & > .empty__state {
    max-width: 500px;
    margin: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;

    & > .icon-container {
      width: 100px;
      margin-bottom: 8px;
      img {
        width: 100%;
      }
    }
    & > .text {
      color: #000100 !important;
      font-size: 30px;
      margin: 0;
      margin-bottom: 8px;
    }
    & > .sub-text {
      font-size: 14px;
      color: rgb(155, 155, 155);
      margin: 0;
    }
  }
`;

export default ({ report, clientId, displayReport, showReport }) => {
  return (
    <StyledReportViewArea>
      {!isEmpty(report) ? (
        <ReportView
          showReport={showReport}
          report={report}
          displayReport={displayReport}
          clientId={clientId}
        />
      ) : (
        <div className="empty__state">
          <div className="icon-container">
            <img src="/static/img/icon-dashboard.svg" alt="dashboard" />
          </div>
          <Text className="text">Reports are a great way to know how your fleet is doing.</Text>
          <Text className="sub-text">
            Click to view the individual reports in the drawer on the left. You can also generate
            new printable PDF reports from selected reports.
          </Text>
        </div>
      )}
    </StyledReportViewArea>
  );
};
