import { Button, Divider, Icon, message, Popconfirm } from 'antd';
import * as React from 'react';
import { makeApiCallAdmin } from '../api';
import { gray, red } from '../components/design-system/colors';
import { ButtonComponent, EmptyButtonComponent } from '../components/primitives/Buttons';
import { AppModal, ToggleModal } from '../components/primitives/Modal';
import { AppTable } from '../components/primitives/Table';
import { Heading, Text } from '../components/primitives/Text';
import { useProfile, useUsers } from '../utils/hooks';
import UserForm from './UserForm';

function Users({ roles }) {
  const { users = [], loading, revalidate } = useUsers();
  const loggedInUser = useProfile();

  const columns = [
    {
      title: 'Name',
      dataIndex: 'fullName',
    },
    {
      title: 'Email',
      dataIndex: 'email',
    },
    {
      title: 'Phone Number',
      dataIndex: 'phone',
    },
    {
      title: 'Role',
      dataIndex: 'roleName',
    },
    {
      title: '',
      key: 'edit',
      render: (user) =>
        `${loggedInUser.roleName}`.toLowerCase() === 'owner' && // Only an owner should be able to make changes to other user accounts
        user.id !== loggedInUser.id && ( // A user should not be able to modify their own account from here
          <span>
            <ToggleModal>
              {(showModal, onOpenModal, onCloseModal) => {
                return (
                  <React.Fragment>
                    <AppModal title="Edit User" visible={showModal} onCancel={onCloseModal}>
                      <UserForm {...user} cancel={onCloseModal} isEdit />
                    </AppModal>
                    <EmptyButtonComponent onClick={onOpenModal}>Edit</EmptyButtonComponent>
                  </React.Fragment>
                );
              }}
            </ToggleModal>
            <Divider type="vertical" />
            <Popconfirm
              title="Are you sure you want to delete this user?"
              onConfirm={async () => {
                await deleteUser(user.id);
                revalidate();
              }}
              okText="Delete"
              cancelText="Cancel"
              icon={<Icon type="question-circle-o" style={{ color: 'red' }} />}
            >
              <Button type="link">
                <Text color={red.primary}>Delete</Text>
              </Button>
            </Popconfirm>
          </span>
        ),
    },
  ];

  return (
    <div className="mb-16">
      <Heading>Users</Heading>
      <Text>Allow your employees better manage your fleet</Text>
      <ToggleModal>
        {(showModal, onOpenModal, onCloseModal) => (
          <>
            <AppModal title="Add Employee" visible={showModal} onCancel={onCloseModal}>
              <UserForm
                cancel={() => {
                  onCloseModal();
                  revalidate();
                }}
              />
            </AppModal>
            <ButtonComponent appearance="blue" onClick={onOpenModal}>
              Create {loggedInUser.userType === 1 ? 'Agent Admin' : 'User'}
            </ButtonComponent>
          </>
        )}
      </ToggleModal>
      {users.length > 0 && (
        <AppTable
          border_color={gray.light}
          rowKey="id"
          loading={loading}
          dataSource={users}
          marginTop="50px"
          columns={columns}
          pagination={users.length > 10 ? { pageSize: 20 } : false}
        />
      )}
    </div>
  );
}

async function deleteUser(id) {
  try {
    await makeApiCallAdmin(`/Account/${id}`, { method: 'delete' });
    message.success('User deleted successfully');
  } catch (error) {
    message.error(error.message);
  }
}

export default Users;
