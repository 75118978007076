import { message } from 'antd';
import useSWR from 'swr';
import { makeApiCallAdmin } from '../../api';

function useVehicleTypes() {
  const { data: vehicleTypes, error, revalidate } = useSWR('/Lookup/AssetType', fetchVehicleTypes);

  return { vehicleTypes, loading: !vehicleTypes && !error, revalidate };
}

async function fetchVehicleTypes(url) {
  try {
    const { data = [] } = await makeApiCallAdmin(url);
    return data;
  } catch (e) {
    message.error(e.message);
  }
}

export default useVehicleTypes;
