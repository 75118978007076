import { message } from 'antd';
import React, { Component } from 'react';
import { PersonalInfo } from '../components/pages/Settings/PersonalInfo';
import { loadState, saveState } from '../localStorage';

export class PersonalInfoPage extends Component {
  state = {
    user: this.props.user,
    preferences: this.props.getLocalPreferences(),
    passwordLoading: false,
    personalInfoLoading: false,
    preferencesLoading: false,
  };

  updatePreferences = (values) => {
    const { updatePreferences } = this.props;
    this.setState({
      preferencesLoading: true,
    });
    updatePreferences(values)
      .then((data) => {
        const { StatusCode, Description } = data;
        this.setState({
          preferencesLoading: false,
        });
        if (StatusCode === '00') {
          saveState({ ...loadState(), preferences: { ...this.state.preferences, ...values } });
          this.setState({
            preferences: this.props.getLocalPreferences(),
          });
          message.success(Description);
        } else {
          this.setState({
            preferences: this.props.getLocalPreferences(),
          });
          message.error(Description);
        }
      })
      .catch((error) => message.error('An error occured. Unable to update preferences'));
  };

  updatePassword = (values, callback) => {
    const { updatePassword } = this.props;
    this.setState({
      passwordLoading: true,
    });
    updatePassword(values)
      .then((data) => {
        const { StatusCode, Description } = data;
        this.setState({
          passwordLoading: false,
        });
        if (StatusCode === '00') {
          callback();
          message.success(Description);
        } else {
          message.error(Description);
        }
      })
      .catch((error) => message.error('An error occured. Unable to update password'));
  };

  render() {
    const { preferences } = this.state;
    const { passwordLoading, personalInfoLoading, preferencesLoading } = this.state;
    return (
      <PersonalInfo
        preferences={preferences}
        passwordLoading={passwordLoading}
        updatePassword={this.updatePassword}
        personalInfoLoading={personalInfoLoading}
        preferencesLoading={preferencesLoading}
        updatePreferences={this.updatePreferences}
      />
    );
  }
}
