import { Dropdown, Icon, List, Menu } from "antd";
import React from "react";
import { withRouter } from "react-router";
import styled from "styled-components";
import { black, gray } from "../../design-system/colors";
import { EmptyButtonComponent } from "../../primitives/Buttons";
import { AppModal, ToggleModal } from "../../primitives/Modal";
import { Text } from "../../primitives/Text";
import MoveItem from "./MoveItem";

var google = window.google;

export const VehicleList = withRouter(
	({ data, loading, history, onAssetChecked, selectedAssets }) => {
		return (
			<List
				loading={loading}
				itemLayout="horizontal"
				dataSource={data}
				renderItem={(item) => {
					return (
						<VehicleItem
							{...item}
							key={item.nodeId}
							onAssetClick={() =>
								history.push(`/fleet/overview/${item.assetId}`)
							}
						/>
					);
				}}
			/>
		);
	}
);

export const MarkerWhite = () => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		width="8"
		height="12"
		viewBox="0 0 8 12"
	>
		<path
			fill="#000"
			fillOpacity=".1"
			fillRule="evenodd"
			d="M4 5.531c-.631 0-1.143-.537-1.143-1.2 0-.663.512-1.2 1.143-1.2s1.143.537 1.143 1.2c0 .663-.512 1.2-1.143 1.2M4 0C1.79 0 0 1.88 0 4.2 0 6.52 4 12 4 12s4-5.48 4-7.8C8 1.88 6.21 0 4 0"
		/>
	</svg>
);
const MarkerGreen = () => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		width="8"
		height="12"
		viewBox="0 0 8 12"
	>
		<path
			fill="#00B649"
			fillRule="evenodd"
			d="M4 5.531c-.631 0-1.143-.537-1.143-1.2 0-.663.512-1.2 1.143-1.2s1.143.537 1.143 1.2c0 .663-.512 1.2-1.143 1.2M4 0C1.79 0 0 1.88 0 4.2 0 6.52 4 12 4 12s4-5.48 4-7.8C8 1.88 6.21 0 4 0"
		/>
	</svg>
);

export const Marker = ({ onTrip, ...rest }) => {
	let Image = onTrip ? MarkerGreen : MarkerWhite;
	return <Image {...rest} />;
};

export const VehicleItem = (props) => {
	const [currentLocation, setCurrentLocation] = React.useState("N/A");

	React.useEffect(() => {
		const { longitude, latitude } = props;
		if (longitude && latitude) {
			let geocoder = new google.maps.Geocoder();
			let latlng = { lat: parseFloat(latitude), lng: parseFloat(longitude) };
			geocoder.geocode({ location: latlng }, (results, status) => {
				setCurrentLocation(results[0].formatted_address);
			});
		}
	}, []);

	const {
		deviceLinked = false,
		nodeId,
		parentNodeId,
		parentNodeDesc,
		nodeDesc,
		plateNo,
		onAssetClick,
		location,
		isNodeLeaf,
	} = props;

	const isVehicleOnTrip = location && location.onTrip;
	const indicator = isVehicleOnTrip
		? { color: black }
		: { color: gray.primary };

	return (
		<div className="flex items-start justify-between py-3 px-5 border-0 border-b border-solid border-gray-200">
			<a
				className={`w-full ${!deviceLinked ? "pointer-events-none" : ""}`}
				onClick={onAssetClick}
			>
				<h1 className="mb-2 font-normal text-lg">
					{plateNo || nodeDesc || "N/A"}
				</h1>
				<p className="mb-2" style={indicator}>
					<Marker onTrip={isVehicleOnTrip} />
					<span className="inline-block ml-2">
						{!deviceLinked
							? "Asset Not Linked"
							: !isVehicleOnTrip
							? "Not on trip"
							: currentLocation}
					</span>
				</p>
				{location && location.driverName && (
					<p className="m-0">
						<Icon type="user" /> {location.driverName}
					</p>
				)}
			</a>
			<Dropdown
				overlay={
					<Menu>
						<Menu.Item>
							<ToggleModal>
								{(showModal, onOpenModal, onCloseModal) => (
									<>
										<AppModal
											title={`Move ${nodeDesc} to... `}
											visible={showModal}
											onCancel={onCloseModal}
											zIndex={99999}
										>
											<MoveItem
												node={{
													nodeId,
													nodeDesc,
													isNodeLeaf,
													parentNodeId,
													parentNodeDesc,
												}}
												close={onCloseModal}
											/>
										</AppModal>
										<a onClick={onOpenModal}>Move</a>
									</>
								)}
							</ToggleModal>
						</Menu.Item>
					</Menu>
				}
				trigger={["click"]}
			>
				<EmptyButtonComponent className="FolderItem__context-actions">
					<Icon type="ellipsis" />
				</EmptyButtonComponent>
			</Dropdown>
		</div>
	);
};

const VehicleItemTagContainer = styled.div`
	display: flex;
	align-items: center;
	border: 1px solid ${gray.faint};
	padding: 4px;
	border-radius: 3px;
	margin-right: 8px;
	margin-bottom: 4px;
	p {
		font-size: 12px;
		color: ${gray.primary};
		padding: 0 4px;
		margin: 0;
	}
	button {
		padding: 0;
		.anticon {
			font-size: 8px;
		}
	}
`;

export const VehicleItemTag = ({ name, id, onClose }) => {
	return (
		<VehicleItemTagContainer>
			<img src="/static/img/folder-small.svg" alt="folder" />
			<Text>{name}</Text>
			<EmptyButtonComponent onClick={onClose}>
				<Icon type="close" theme="outlined" />
			</EmptyButtonComponent>
		</VehicleItemTagContainer>
	);
};
