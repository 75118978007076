import { message } from 'antd';
import useSWR from 'swr';
import { makeApiCallAdmin } from '../../../api';

function useFleetSummary() {
  const { data: fleetSummary, error, revalidate } = useSWR(
    '/Dashboard/FleetSummary',
    fetchFleetSummary
  );

  return { fleetSummary, loading: !fleetSummary && !error, revalidate };
}

async function fetchFleetSummary(url) {
  try {
    const { data = [] } = await makeApiCallAdmin(url);
    return data;
  } catch (e) {
    message.error(e.message);
  }
}

export default useFleetSummary;
