import { Checkbox, Switch } from 'antd';
import { isEqual } from 'lodash';
import React from 'react';
import { NavLink } from 'react-router-dom';
import styled from 'styled-components';
import { useProfile } from '../../../utils/hooks';
import { black, blue, gray, green, white } from '../../design-system/colors';
import { customFonts } from '../../design-system/fonts';
import { Heading, Text } from '../../primitives/Text';

const StyledSettingsContainer = styled.div`
  display: flex;
  padding-top: 120px;

  .settings-container {
    &__sidebar {
      padding: 0 40px;
      width: 350px;

      &__section {
        border-bottom: 1px solid ${gray.faint};
        padding-bottom: 24px;
        padding-top: 30px;
        &:first-child {
          padding-top: 0;
        }
        &:last-child {
          border-bottom: none;
        }
        h1 {
          margin-bottom: 0;
          font-size: 20px;
          padding-left: 8px;
          font-family: ${customFonts.circular}!important;
          font-weight: bold;
        }
        ul {
          list-style: none;
          padding: 0;
        }
        li {
          margin-bottom: 8px;
        }
        a {
          display: block;
          color: ${black};
          padding: 8px;
          text-decoration: none;
          transition: color 0.3s ease-in-out, background-color 0.3s ease-in-out;

          &:hover,
          &:hover {
            background-color: ${blue.faint};
          }

          &.active {
            background-color: ${blue.faint};
          }
        }
      }
    }
    &__content {
      padding: 0 64px;
      width: 100%;

      & > .compact {
        width: 800px;
      }
    }
  }
`;
export const SettingsContainer = ({ children, width }) => {
  const { userType } = useProfile();

  return (
    <StyledSettingsContainer width={width} className="settings-container">
      <div className="settings-container__sidebar">
        <div className="settings-container__sidebar__section">
          <Heading>Account</Heading>
          <ul>
            <li>
              <NavLink to="/settings/personal-information">Personal Information</NavLink>
            </li>
            {/* <li>
              <NavLink to="/settings/email-notifications">Email Notifications</NavLink>
            </li> */}
          </ul>
        </div>
        <div className="settings-container__sidebar__section">
          <Heading>Business</Heading>
          <ul>
            <li>
              <NavLink to="/settings/business-information">Organisation’s Information</NavLink>
            </li>
            {userType === 1 && (
              <>
                <li>
                  <NavLink to="/settings/cost-plans">Cost Plans</NavLink>
                </li>
                <li>
                  <NavLink to="/settings/clients">Clients</NavLink>
                </li>
              </>
            )}
          </ul>
        </div>
        <div className="settings-container__sidebar__section">
          <Heading>User Management</Heading>
          <ul>
            <li>
              <NavLink to="/settings/users">Users</NavLink>
            </li>
          </ul>
        </div>
      </div>
      <div className="settings-container__content">{children}</div>
    </StyledSettingsContainer>
  );
};

const StyledRolePermissions = styled.div`
  margin-top: ${(props) => props.mt};
  .visible-section {
    padding: 24px 16px;
    border: 1px solid ${gray.light};
    &__heading {
      display: flex;
      align-items: center;
      padding-bottom: 12px;
      h1 {
        font-family: ${customFonts.circular}!important;
        font-weight: bold;
        margin: 0;
        padding-left: 8px;
        font-size: 16px;
      }
      .ant-switch {
        border: 1px solid ${gray.light};
        background-color: ${white};
      }

      .ant-switch-checked {
        background-color: ${green.primary};
        border-color: #00b649;
      }
    }
  }
  .collapsible-section {
    display: flex;
    border: 1px solid ${gray.light};
    border-top: none;
    h1 {
      font-family: ${customFonts.circular}!important;
      font-weight: bold;
      margin: 0;
      font-size: 16px;
    }
    &__left {
      display: flex;
      justify-content: center;
      flex-basis: 120px;
      padding: 24px 16px;
      background-color: rgba(144, 164, 183, 0.1);
    }
    &__right {
      flex: 1;
      padding: 24px 48px;
      background-color: ${white};

      &__permissions {
        padding-top: 12px;
        .ant-checkbox-wrapper {
          padding-bottom: 16px;
          color: #454f5b;
        }
      }
    }
  }
`;

class PermissionsComponent extends React.Component {
  state = {
    checked: this.props.active || false,
  };
  componentDidUpdate(prevProps) {
    if (!isEqual(this.props.active, prevProps.active)) {
      this.setState({
        checked: this.props.active,
      });
    }
  }
  onChange = (e) => {
    this.setState(
      {
        checked: e.target.checked,
      },
      () => {
        const { checked } = this.state;
        const { onCheck, label } = this.props;
        if (checked) {
          onCheck(e.target.value, false, label);
        } else {
          onCheck(e.target.value, true, label);
        }
      }
    );
  };
  render() {
    const { value, label, disabled } = this.props;
    const { checked } = this.state;
    return (
      <React.Fragment>
        <Checkbox checked={checked} value={value} onChange={this.onChange} disabled={disabled}>
          {label}
        </Checkbox>
        <br />
      </React.Fragment>
    );
  }
}

export class RolePermissionsComponent extends React.Component {
  state = {
    active: this.props.selectedPermissions.length > 0 || false,
    selectedPermissions: this.props.selectedPermissions || [],
  };
  componentDidMount() {
    if (this.props.selectedPermissions.length === 0) {
      this.setState({
        active: false,
        selectedPermissions: [],
      });
    }
  }
  onSetStatus = (value) => {
    this.setState(
      {
        active: value,
      },
      () => {
        const { permissions, onChange, heading } = this.props;
        if (heading.toLowerCase() === 'full access' && value) {
          const allValues = [];
          permissions.forEach(({ title, permissions }) => {
            let values = permissions.map((permission) => ({
              ...permission.value,
              Description: permission.label,
            }));
            allValues.push(...values);
          });
          this.setState(
            {
              ...this.state,
              selectedPermissions: allValues,
            },
            () => {
              const { selectedPermissions } = this.state;
              onChange({ Roles: selectedPermissions, Module: heading });
            }
          );
        } else {
          this.setState(
            {
              ...this.state,
              selectedPermissions: [],
            },
            () => {
              const { selectedPermissions } = this.state;
              onChange({ Roles: selectedPermissions, Module: heading });
            }
          );
        }
      }
    );
  };
  onPermissionSelect = (value, remove = false, label) => {
    if (!remove) {
      this.setState(
        ({ selectedPermissions }) => ({
          selectedPermissions: [{ ...value, Description: label }, ...selectedPermissions],
        }),
        () => {
          const { selectedPermissions } = this.state;
          this.props.onChange({
            Roles: selectedPermissions,
            Module: this.props.heading,
          });
        }
      );
    } else {
      const { selectedPermissions } = this.state;
      const permissions = selectedPermissions.filter(
        (permission) => permission.Description.toLowerCase() !== label.toLowerCase()
      );
      this.setState(
        {
          selectedPermissions: permissions,
        },
        () => {
          const { selectedPermissions } = this.state;
          this.props.onChange({
            Roles: selectedPermissions,
            Module: this.props.heading,
          });
        }
      );
    }
  };
  isActive = (label) => {
    const { selectedPermissions, active } = this.state;
    if (this.props.heading.toLowerCase() === 'full access') {
      return active;
    }
    if (selectedPermissions.length > 0) {
      let permissionDescriptions = selectedPermissions.map((permission) => permission.Description);
      return permissionDescriptions.includes(label);
    }
  };

  render() {
    const { heading, subheading, permissions, mt, disabled, allDisabled } = this.props;
    const { active } = this.state;
    return (
      <StyledRolePermissions mt={mt}>
        <div className="visible-section">
          <div className="visible-section__heading">
            <Switch
              size="small"
              checked={active}
              onChange={this.onSetStatus}
              disabled={allDisabled}
            />
            <Heading>{heading}</Heading>
          </div>
          <Text>{subheading}</Text>
        </div>
        {active && permissions.length > 0 && (
          <div className="collapsible-section">
            <div className="collapsible-section__left">
              <Heading>Permission</Heading>
            </div>
            <div className="collapsible-section__right">
              {permissions.map(({ title, permissions }) => (
                <React.Fragment>
                  <Heading fontSize="15px" fontFamily="circular">
                    {title}
                  </Heading>
                  <div className="collapsible-section__right__permissions">
                    {permissions.map(({ value, label }, i) => (
                      <PermissionsComponent
                        key={`${label}-${i}`}
                        value={value}
                        label={label}
                        active={this.isActive(label)}
                        onCheck={this.onPermissionSelect}
                        disabled={disabled}
                      />
                    ))}
                  </div>
                </React.Fragment>
              ))}
            </div>
          </div>
        )}
      </StyledRolePermissions>
    );
  }
}
RolePermissionsComponent.defaultProps = {
  onChange: (value) => console.log(value),
  selectedPermissions: [],
};
