import { Col, Icon, Radio, Select } from "antd";
import { isEmpty, isEqual } from "lodash";
import moment from "moment";
import React from "react";
import { mutate } from "swr";
import { black, red, white } from "../../design-system/colors";
import { AddressInput } from "../../primitives/AddressInput";
import PlainSelect from "../../primitives/PlainSelect";
import {
	ButtonComponent,
	EmptyButtonComponent,
} from "../../primitives/Buttons";
import { TimePickerComponent } from "../../primitives/DatePicker";
import GeofenceMap from "../../primitives/GeofenceMap";
import { AppInput } from "../../primitives/Input";
import { SaveAndCancelButtons } from "../../primitives/SaveAndCancelButtons";
import { SelectComponent } from "../../primitives/Select";
import { Heading, Text } from "../../primitives/Text";
import { AppTextArea } from "../../primitives/TextArea";
import { AlertSectionContainer, AlertSectionFormContainer } from "./AssetAlert";
import { AlertFormItem } from "./components";

const { Option } = Select;

export const RadioGroup = Radio.Group;

export const radioStyle = {
	display: "block",
	height: "30px",
	lineHeight: "30px",
	fontSize: "16px",
};

export const mapContainerStyle = {
	height: `400px`,
	marginTop: `16px`,
	marginBottm: `16px`,
};

export const convertToLatLng = (coordinate) => {
	if (coordinate) {
		return coordinate.map((coordinate) => ({
			lat: parseFloat(coordinate.latitude),
			lng: parseFloat(coordinate.longitude),
		}));
	}
	return [];
};

export class GeofenceForm extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			fields: isEmpty(this.props.fields)
				? {
						Type: "all-vehicles",
						alertType: 3,
						speedLimit: 0,
				  }
				: {
						...this.props.fields,
						contacts: [],
				  },
			error: false,
			errorMessages: {},
			folders: this.props.folders || [],
			disableAlertPeriod: this.props.fields.disableAlertPeriod || [],
		};
	}
	componentDidUpdate(prevProps) {
		if (!isEqual(prevProps.fields, this.props.fields)) {
			this.setState({
				...this.state,
				fields: isEmpty(this.props.fields)
					? {
							Type: "all-vehicles",
							alertType: 3,
							speedLimit: 0,
					  }
					: {
							...this.props.fields,
							contacts: [],
					  },
				disableAlertPeriod: this.props.fields.disableAlertPeriod || [],
			});
		}
	}
	setSingleDisableAlertPeriod = () => {
		this.setState(({ disableAlertPeriod }) => ({
			disableAlertPeriod: [
				{
					startTime: moment("12:00:00", "HH:mm:ss"),
					endTime: moment("12:00:00", "HH:mm:ss"),
				},
				...disableAlertPeriod,
			],
		}));
	};
	addDisableAlertPeriod = (id) => {
		const { disableAlertPeriod } = this.state;
		let { startTime, endTime } = this.state.fields;
		if (id) {
			const indexOfAlertPeriod = disableAlertPeriod.findIndex(
				(value, i) => id === i
			);
			if (startTime) {
				disableAlertPeriod[indexOfAlertPeriod].startTime = startTime;
			}
			if (endTime) {
				disableAlertPeriod[indexOfAlertPeriod].endTime = endTime;
			}
			if (startTime && endTime) {
				disableAlertPeriod[indexOfAlertPeriod] = { startTime, endTime };
			}
			this.setState({
				disableAlertPeriod,
			});
		} else {
			const indexOfEmpty = disableAlertPeriod.findIndex((value) =>
				isEqual(value, {
					startTime: moment("12:00:00", "HH:mm:ss"),
					endTime: moment("12:00:00", "HH:mm:ss"),
				})
			);
			disableAlertPeriod[indexOfEmpty] = { startTime, endTime };
			this.setState({
				disableAlertPeriod,
			});
		}
	};
	removeAlertPeriod = (e, index) => {
		e.preventDefault();
		this.setState(({ disableAlertPeriod }) => ({
			disableAlertPeriod: disableAlertPeriod.filter(
				(alertPeriod, i) => i !== index
			),
		}));
	};
	setSingleContact = () => {
		this.setState(({ contacts }) => ({
			contacts: [{}, ...contacts],
		}));
	};
	addSingleContact = (values) => {
		const { contacts } = this.state;
		const indexOfEmpty = contacts.findIndex((value) => isEmpty(value));
		contacts[indexOfEmpty] = values;
		this.setState({
			contacts,
		});
	};
	onChange = (e, field) => {
		const { fields } = this.state;
		fields[field] = e.target.value;
		this.setState({
			fields,
		});
	};
	onDatePickerChange = (value, field, callback) => {
		const { fields } = this.state;
		fields[field] = value ? moment(value).format("HH:mm:ss") : "";
		this.setState(
			{
				fields,
			},
			() => {
				if (callback) {
					callback();
				} else return;
			}
		);
	};
	onSelectChange = (value, field) => {
		const { fields } = this.state;
		fields[field] = value;
		this.setState({
			fields,
		});
	};
	onChangeAddress = (value, field) => {
		let { fields } = this.state;
		const { location, description } = value;
		fields[field] = description;
		this.setState(({ fields }) => ({
			fields,
			location,
		}));
	};
	displayError = (field) => {
		let { fields, error } = this.state;
		return error && !Boolean(fields[field]);
	};
	isValid = () => {
		this.setState({ errorMessages: {} });
		let { fields } = this.state;
		let newData = { ...fields };
		let errorMessages = ["triggerAlert", "description"].reduce((acc, val) => {
			if (!Boolean(newData[val])) return { ...acc, [val]: true };
			return acc;
		}, {});
		if (Object.keys(errorMessages).length > 0) {
			this.setState({ errorMessages });
			return false;
		}
		return true;
	};
	clearForm = () => {
		this.setState({
			fields: {},
		});
	};
	formatContacts = (contacts = []) => {
		let phoneNumber = [];
		let emailAddress = [];
		if (this.props.edit) {
			contacts.forEach((contact) => {
				emailAddress.push(contact);
			});
		} else {
			contacts.forEach((contact) => {
				let splitContact = contact.split("-");
				emailAddress.push(splitContact[0].trim());
				phoneNumber.push(splitContact[1].trim());
			});
		}

		return { emailAddress, phoneNumber };
	};
	onSubmit = (e) => {
		e.preventDefault();
		const { onSubmit, onCancel, afterFormSubmit } = this.props;
		const { fields, disableAlertPeriod } = this.state;
		const {
			edit,
			endTime,
			startTime,
			createGeofenceAlert,
			updateGeofenceAlert,
			contacts,
			...rest
		} = fields;
		const payload = {
			...rest,
			...this.formatContacts(contacts),
			violationType: 101,
			disableAlertPeriod,
		};
		if (this.isValid()) {
			onSubmit(payload, () => {
				afterFormSubmit();
				mutate("alerts");
				onCancel();
				this.clearForm();
			});
		} else {
			this.setState({
				error: true,
			});
		}
	};
	renderContactList = () => {
		const { contacts } = this.props;
		const contactList = [];
		contacts.forEach((contact) => {
			contactList.push(<Option key={contact.label}>{contact.label}</Option>);
		});
		return contactList;
	};
	render() {
		const {
			onCancel,
			loading,
			contacts,
			success,
			getGeofenceBounds,
			edit,
		} = this.props;
		const { fields, location, errorMessages } = this.state;

		return (
			<AlertSectionFormContainer>
				<div className="flex-row baseline">
					<Col span={11}>
						<AppInput
							name="label"
							type="text"
							label="Name"
							size="large"
							placeholder="Geofence Name"
							borderColor={black}
							value={fields.label}
							onChange={(e) => this.onChange(e, "label")}
							error={this.displayError("label")}
							marginTop="0"
							helpText="max. 25 characters"
						/>
					</Col>
					<Col span={11}>
						<AddressInput
							label="Address"
							placeholder={
								fields.address !== ""
									? fields.address
									: "Enter the address you want to geofence"
							}
							initialValue={""}
							size="large"
							onChange={(e) => this.onChangeAddress(e, "address")}
						/>
					</Col>
				</div>
				<GeofenceMap
					success={success}
					location={location}
					getGeofenceBounds={getGeofenceBounds}
					coordinate={convertToLatLng(fields.coordinate)}
				/>
				<AlertFormItem>
					<div id="geofence-location__type">
						<RadioGroup
							onChange={(e) => this.onChange(e, "alertType")}
							value={fields.alertType}
						>
							<Radio value={2}>Exclusive geofence</Radio>
							<Radio value={3}>Inclusive geofence</Radio>
							<Radio value={1}>Speed Limit Geofence</Radio>
						</RadioGroup>
					</div>
				</AlertFormItem>
				{fields.alertType === 1 && (
					<AlertFormItem className="alert__form-item">
						<div className="alert__form-item__flex">
							<div>
								<Text className="Text">This geofence has a speed limit of</Text>
							</div>
							<div>
								<AppInput
									name="speedLimit"
									type="number"
									placeholder="0"
									size="large"
									borderColor={black}
									value={fields.speedLimit}
									onChange={(e) => this.onChange(e, "speedLimit")}
									className="alert__form-item__input"
									marginTop="0"
								/>
							</div>
							<div>
								<Text className="Text">km/h</Text>
							</div>
						</div>
					</AlertFormItem>
				)}
				<AlertFormItem className="alert__form-item">
					<div className="alert__form-item__flex">
						<div>
							<Text className="Text">
								Only alert if this condition holds for more than
							</Text>
						</div>
						<div>
							<AppInput
								name="triggerAlert"
								type="number"
								placeholder="0"
								size="large"
								borderColor={black}
								value={fields.triggerAlert}
								onChange={(e) => this.onChange(e, "triggerAlert")}
								className="alert__form-item__input"
								marginTop="0"
							/>
						</div>
						<div>
							<Text className="Text">minutes.</Text>
						</div>
					</div>
					{errorMessages["triggerAlert"] && (
						<Text fontSize="12px" marginTop="4px" color={red.primary}>
							Please enter a value for the Alert Threshold
						</Text>
					)}
				</AlertFormItem>
				<AlertFormItem>
					<div>
						<Text className="Text">
							Disable notifications between the following times:
						</Text>
					</div>
					{this.state.disableAlertPeriod.map((alertPeriod, i) => (
						<div className="alert__form-item__flex" key={i.toString()}>
							<div>
								<TimePickerComponent
									size="large"
									marginTop="20px"
									border={black}
									value={moment(alertPeriod.startTime, "HH:mm:ss")}
									onChange={(e) => this.onDatePickerChange(e, "startTime")}
								/>
							</div>
							<div>
								<TimePickerComponent
									size="large"
									marginTop="20px"
									border={black}
									value={moment(alertPeriod.endTime, "HH:mm:ss")}
									onChange={(e) =>
										this.onDatePickerChange(e, "endTime", () =>
											this.addDisableAlertPeriod(i)
										)
									}
								/>
							</div>
							<div>
								<EmptyButtonComponent
									onClick={(e) => this.removeAlertPeriod(e, i)}
									className="remove-alert-period"
								>
									<img src="/static/img/close-mini.svg" alt="delete" />
								</EmptyButtonComponent>
							</div>
						</div>
					))}

					<ButtonComponent
						marginTop="20px"
						onClick={this.setSingleDisableAlertPeriod}
					>
						Add Time Range
					</ButtonComponent>
				</AlertFormItem>
				<div className="contacts-section">
					<Text fontSize="20px" className="Text">
						Contacts that will receive alerts:
					</Text>

					<div className="flex-row flex-start">
						<Col span={11}>
							{edit ? (
								<PlainSelect
									mode="multiple"
									placeholder="Select contacts"
									size="large"
									defaultValue={fields.emailAddress}
									onChange={(e) => this.onSelectChange(e, "contacts")}
								>
									{this.renderContactList()}
								</PlainSelect>
							) : (
								<SelectComponent
									options={contacts}
									placeholderColor={black}
									placeholder="Select Contacts"
									border={black}
									defaultValue={fields.emailAddress}
									mode="multiple"
									size="large"
									notFoundContent="No contacts"
									onChange={(e) => this.onSelectChange(e, "contacts")}
									error={this.displayError("contacts")}
									value={fields.contacts}
								/>
							)}
						</Col>
						<Col span={11}>
							<AppTextArea
								autosize
								name="description"
								size="large"
								marginTop="0px"
								border={black}
								bgColor={white}
								placeholder="description"
								value={fields.description}
								onChange={(e) => this.onChange(e, "description")}
								helpText="max. 25 characters"
								error={errorMessages["description"]}
								errorMessage="Please enter a description"
							/>
						</Col>
					</div>
				</div>
				<SaveAndCancelButtons
					onCancel={onCancel}
					loading={loading}
					onSave={this.onSubmit}
					type="button"
					marginTop="44px"
				/>
			</AlertSectionFormContainer>
		);
	}
}

export const convertToPayload = (value) => ({
	latitude: value.lat,
	longitude: value.lng,
});

export class Geofence extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			coordinate: [],
			geofence: {},
			success: false,
			geofenceType: {
				polygon: this.onPolygonComplete,
				circle: this.onCircleComplete,
				rectangle: this.onRectangleComplete,
			},
		};
	}
	onPolygonComplete = (poly) => {
		const polyArray = poly.getPath().getArray();
		let paths = [];
		polyArray.forEach(function(path) {
			paths.push({
				latitude: path.lat(),
				longitude: path.lng(),
			});
		});
		this.setState({
			coordinate: paths,
		});
	};
	onCircleComplete = (circle) => {
		const northEast = circle
			.getBounds()
			.getNorthEast()
			.toJSON();
		const southEast = circle
			.getBounds()
			.getSouthWest()
			.toJSON();
		this.setState({
			coordinate: [convertToPayload(northEast), convertToPayload(southEast)],
		});
	};
	onRectangleComplete = (rect) => {
		const northEast = rect
			.getBounds()
			.getNorthEast()
			.toJSON();
		const southEast = rect
			.getBounds()
			.getSouthWest()
			.toJSON();
		this.setState({
			coordinate: [convertToPayload(northEast), convertToPayload(southEast)],
		});
	};
	afterFormSubmit = () => {
		this.setState({
			success: true,
		});
	};
	getGeofenceBounds = (geofence) => {
		if (geofence) {
			let func = this.state.geofenceType[geofence.type];
			if (func) {
				func(geofence);
			}
		} else {
			this.setState({
				coordinate: [],
			});
		}
	};

	onSubmit = (values, callback) => {
		const { edit, createGeofenceAlert, updateGeofenceAlert } = this.props;
		const { coordinate } = this.state;

		edit
			? updateGeofenceAlert({ ...values, coordinate }, callback)
			: createGeofenceAlert({ ...values, coordinate }, callback);
	};
	render() {
		const {
			edit,
			isModal,
			folders,
			loading,
			contacts,
			onDelete,
			hideSection,
			orphanAssets,
			buttonLoading,
			nodesWithChildren,
			createAlert,
			updateAlert,
			deleteAlert,
			createGeofenceAlert,
			updateGeofenceAlert,
			...rest
		} = this.props;
		let { success } = this.state;
		let newContacts = contacts.map((contact) => ({
			value: `${contact.email} - ${contact.phone}`,
			label: contact.email,
		}));

		return (
			<AlertSectionContainer className="geofence">
				<div className="form-header">
					<EmptyButtonComponent
						onClick={hideSection}
						className="back-button"
						color={black}
					>
						<Icon type="arrow-left" /> Back
					</EmptyButtonComponent>
					<Heading fontSize="20px">Geofence Alert</Heading>
				</div>
				<GeofenceForm
					fields={rest}
					edit={edit}
					folders={folders}
					success={success}
					assets={orphanAssets}
					onCancel={hideSection}
					contacts={newContacts}
					loading={buttonLoading}
					onSubmit={this.onSubmit}
					nodesWithChildren={nodesWithChildren}
					afterFormSubmit={this.afterFormSubmit}
					getGeofenceBounds={this.getGeofenceBounds}
				/>
			</AlertSectionContainer>
		);
	}
}
