import { Tooltip } from 'antd';
import isEqual from 'lodash/isEqual';
import moment from 'moment';
import React from 'react';
import styled from 'styled-components';
import { blue, violationColors, white } from '../../design-system/colors';

const VehicleTripItemContainer = styled.li`
  padding: 16px;
  cursor: pointer;
  ${({ isSelected }) =>
    isSelected && `background-color: ${blue.primary} !important; color: ${white} !important;`}

  &:hover {
    background-color: ${blue.primary} !important;
    color: ${white} !important;
  }

  & > .date__time__violations {
    display: flex;
    justify-content: space-between;

    & > .date__and__time {
      & > .date {
        display: inline-block;
        font-size: 13px;
        color: #c0c3cc;
        margin-right: 5px;
      }
      & > .time {
        font-weight: bold;
      }
    }

    & > .violations {
      display: flex;
      align-items: center;

      & > .violation {
        width: 15px;
        height: 15px;
        border-radius: 50%;
        margin-left: 5px;
      }
    }
  }

  & > .trip__points {
    display: flex;
    align-items: center;

    & > .point__names {
      display: flex;

      & > .point {
        font-family: Circular;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }
    }
  }
`;

export const VehicleTripItemIcon = ({ OnTrip }) =>
  OnTrip ? (
    <svg
      width="12px"
      height="30px"
      viewBox="0 0 12 30"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="Flitrack" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="Fleet-Insight-2" transform="translate(-33.000000, -234.000000)" fill="#00B649">
          <g id="History-List-(Active)" transform="translate(33.000000, 223.000000)">
            <g id="History-Listing" transform="translate(0.000000, 1.000000)">
              <path
                d="M6.00021125,36.9996681 C4.34576951,36.9996681 3.00031688,35.6541659 3.00031688,33.9996633 C3.00031688,32.3451606 4.34576951,30.9996585 6.00021125,30.9996585 C7.654653,30.9996585 9.00010563,32.3451606 9.00010563,33.9996633 C9.00010563,35.6541659 7.654653,36.9996681 6.00021125,36.9996681 M7.50015844,28.211154 L7.50015844,14.4936322 L8.13613605,15.1296332 C8.72111545,15.7146341 9.67208197,15.7146341 10.2570614,15.1281332 L10.2570614,15.1281332 C10.8420408,14.5416322 10.8420408,13.5921307 10.2540615,13.0071298 L8.11513679,10.8741264 C6.94667793,9.70862454 5.05374458,9.70862454 3.88528572,10.8741264 L1.74636103,13.0056298 C1.15838174,13.5921307 1.15838174,14.5416322 1.74336114,15.1281332 C2.32984049,15.7146341 3.27930706,15.7146341 3.86428646,15.1296332 L4.50026407,14.4936322 L4.50026407,28.211154 C1.48837012,28.9911553 -0.617555732,32.0256601 0.163916752,35.4231655 C0.661899218,37.581669 2.41233758,39.3351718 4.56926164,39.8346726 C8.50512306,40.743674 12,37.7796693 12,33.9996633 C12,31.2066588 10.0830675,28.8801551 7.50015844,28.211154"
                id="arrow_up_circle-[#309]-copy"
              />
            </g>
          </g>
        </g>
      </g>
    </svg>
  ) : (
    <svg xmlns="http://www.w3.org/2000/svg" width="9" height="30" viewBox="0 0 9 30">
      <defs>
        <linearGradient
          id="linear-gradient"
          x1="0.5"
          x2="0.5"
          y2="1"
          gradientUnits="objectBoundingBox"
        >
          <stop offset="0" stop-color="#0067bc" />
          <stop offset="1" stop-color="#00bc54" />
        </linearGradient>
      </defs>
      <g id="svg" transform="translate(0 0.5)">
        <path
          id="Path_370"
          data-name="Path 370"
          d="M4.511,21.422"
          transform="translate(-0.011 -0.819)"
          fill="none"
          stroke="#9b9b9b"
          stroke-width="2"
        />
        <circle
          id="Ellipse_60"
          data-name="Ellipse 60"
          cx="4"
          cy="4"
          r="4"
          transform="translate(0.5)"
          fill="#0067bc"
          stroke="#0067bc"
          stroke-width="1"
        />
        <circle
          id="Ellipse_61"
          data-name="Ellipse 61"
          cx="4"
          cy="4"
          r="4"
          transform="translate(0.5 21)"
          fill="#00bc54"
          stroke="#00bc54"
          stroke-width="1"
        />
        <rect
          id="Rectangle_749"
          data-name="Rectangle 749"
          width="2"
          height="13"
          transform="translate(3.5 8)"
          fill="url(#linear-gradient)"
        />
      </g>
    </svg>
  );

export default ({ location, selectedLocation, setSelectedLocation }) => {
  const {
    time,
    latitude,
    longitude,
    idling,
    harshBreak,
    harshAcceleration,
    noEntryZone,
    noExitZone,
    overspeed,
    sos,
    zoneOverspeed,
  } = location;

  const violations = Object.entries({
    sos,
    idling,
    overspeed,
    harshBreak,
    noExitZone,
    noEntryZone,
    zoneOverspeed,
    harshAcceleration,
  }).filter(([key, value]) => value);

  const isSelected = isEqual(location, selectedLocation);

  const toggleSelectedLocation = () => {
    setSelectedLocation(isSelected ? null : location);
  };

  return (
    <VehicleTripItemContainer
      className="trip"
      onClick={toggleSelectedLocation}
      isSelected={isSelected}
    >
      <div className="date__time__violations">
        <div className="date__and__time">
          <span className="date">{moment(time * 1000).format('DD/MM')}</span>
          <span className="time">{moment(time * 1000).format('h:mm:ss a')}</span>
        </div>

        {violations.length > 0 && (
          <div className="violations">
            {violations.map(([violation]) => (
              <Tooltip title={violation} key={violation}>
                <div
                  className="violation"
                  style={{ backgroundColor: violationColors[violation] }}
                />
              </Tooltip>
            ))}
          </div>
        )}
      </div>
      <div className="trip__points">
        <div className="point__names">
          <span className="point">Lat: {Number(latitude).toFixed(4)}, </span>
          <span className="point">Lng: {Number(longitude).toFixed(4)}</span>
        </div>
      </div>
    </VehicleTripItemContainer>
  );
};
