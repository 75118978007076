import styled from 'styled-components';
import { black } from '../design-system/colors';
import { customFonts, fontFamily } from '../design-system/fonts';

export const Heading = styled.h1`
  text-align: ${props => props.align};
  font-size: ${props => props.fontSize};
  color: ${props => props.color || black};
  line-height: ${props => props.lineHeight};
  font-weight: ${props => props.fontWeight};
  margin-top: ${props => props.marginTop};
  font-family: ${props => (props.fontFamily === 'circular' ? customFonts.circular : 'inherit')};
`;

export const Text = styled.p`
  text-align: ${props => props.align};
  font-size: ${props => props.fontSize || '15px'};
  color: ${props => props.color || black};
  line-height: ${props => props.lineHeight};
  font-weight: ${props => props.fontWeight};
  margin-top: ${props => props.marginTop};
  margin-bottom: ${props => props.marginBottom};
  font-family: ${fontFamily};
`;

export const Label = styled.label`
  display: inline-block;
  font-size: 15px;
  color: ${black};
`;
