import { Menu } from 'antd';
import React from 'react';
import { NavLink } from 'react-router-dom';
import styled from 'styled-components';
import { useProfile } from '../../../utils/hooks';
import { black, gray } from '../../design-system/colors';
import { SecondaryNavbar } from '../../primitives/Navbar';

export const FleetOverviewContainer = styled.div`
  .Fleet-page-content {
    position: ${(props) => (props.isFixed ? `fixed` : `relative`)};
    width: 100%;
    padding-top: 128px;
  }
  .SecondaryNavbar {
    .ant-divider {
      margin: 0;
    }
    .ant-menu-item {
      a {
        color: ${gray.primary};

        &:focus,
        &:hover,
        &.active {
          color: ${black};
        }
      }

      &:nth-of-type(5n),
      &:nth-of-type(7n) {
        padding: 0;
      }
    }
  }
`;

export const Fleet = ({ children, isFixed = true }) => {
  const { userType } = useProfile();

  return (
    <div>
      <FleetOverviewContainer isFixed={isFixed}>
        <SecondaryNavbar>
          <Menu mode="horizontal">
            <Menu.Item key="overview">
              <NavLink to="/fleet/overview" activeClassName="active">
                Assets
              </NavLink>
            </Menu.Item>
            <Menu.Item key="trips">
              <NavLink to="/fleet/trips" activeClassName="active">
                Trips
              </NavLink>
            </Menu.Item>
            <Menu.Item key="drivers">
              <NavLink to="/fleet/drivers" activeClassName="active">
                Drivers
              </NavLink>
            </Menu.Item>
            <Menu.Item key="asset-management">
              <NavLink to="/fleet/asset-management" activeClassName="active">
                Asset Management
              </NavLink>
            </Menu.Item>
            {userType === 1 && (
              <Menu.Item key="device-management">
                <NavLink to="/fleet/device-management" activeClassName="active">
                  Device Management
                </NavLink>
              </Menu.Item>
            )}
          </Menu>
        </SecondaryNavbar>
        <div className="Fleet-page-content">{children}</div>
      </FleetOverviewContainer>
    </div>
  );
};
