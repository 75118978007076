import { message, Select } from 'antd';
import { Formik } from 'formik';
import * as React from 'react';
import * as yup from 'yup';
import { makeApiCallAdmin } from '../api';
import { black, red } from '../components/design-system/colors';
import { AppInput } from '../components/primitives/Input';
import { SaveAndCancelButtons } from '../components/primitives/SaveAndCancelButtons';
import { SelectStyle } from '../components/primitives/Select';
import { Label, Text } from '../components/primitives/Text';
import { useUnassignedDevices } from '../utils/hooks';

const { Option } = Select;

function AssignDeviceForm({ asset, close }) {
  const { devices = [] } = useUnassignedDevices();

  return (
    <Formik
      initialValues={{
        vin: asset.vin,
        plateNo: asset.plateNo,
        sim: '',
        deviceId: '',
      }}
      onSubmit={async (values, actions) => {
        try {
          await makeApiCallAdmin(`/Device/${values.deviceId}/Assign`, {
            method: 'put',
            data: values,
          });
          message.success('Asset assigned successfully');
          close();
        } catch (error) {
          message.error(error.message);
        } finally {
          actions.setSubmitting(false);
        }
      }}
      validationSchema={validationSchema}
      enableReinitialize
    >
      {({ values, errors, touched, handleSubmit, handleChange, isSubmitting, setFieldValue }) => {
        return (
          <form className="asset-form" onSubmit={handleSubmit}>
            <AppInput
              name="vin"
              label="Vehicle Identification Number"
              value={values.vin}
              disabled
            />
            <AppInput name="plateNo" label="Vehicle Plate Number" value={values.plateNo} disabled />
            <AppInput
              name="sim"
              label="SIM"
              placeholder="08012345678"
              value={values.sim}
              onChange={handleChange}
            />
            <SelectStyle
              marginTop="20px"
              border={black}
              css={'.ant-select-search__field { top: 0; }'}
            >
              <Label>Select Device To Assign</Label>
              <Select
                value={values.deviceId}
                placeholder="Select a device to assign"
                onChange={(value) => setFieldValue('deviceId', value)}
                showSearch
                filterOption={(inputValue, option) => {
                  const isValueInIMEI = option.props.value
                    .toLowerCase()
                    .includes(inputValue.toLowerCase());
                  const isValueInName = `${option.props.children}`
                    .toLowerCase()
                    .includes(inputValue.toLowerCase());
                  return isValueInIMEI || isValueInName;
                }}
              >
                {devices.map((device) => (
                  <Option value={device.id} key={device.id}>
                    {device.product} - {device.imei}
                  </Option>
                ))}
              </Select>
              {errors.imei && touched.imei && (
                <Text fontSize="12px" marginTop="4px" color={red.primary}>
                  {errors.imei}
                </Text>
              )}
            </SelectStyle>
            <div className="mt-10">
              <SaveAndCancelButtons
                successButtonAppearance="blue"
                saveText="Assign Device"
                cancelText="Cancel"
                onCancel={close}
                loading={isSubmitting}
              />
            </div>
          </form>
        );
      }}
    </Formik>
  );
}

var validationSchema = yup.object().shape({
  deviceId: yup.string().required('Please select a device to assign'),
  sim: yup.string().required('Please enter device sim number'),
});

export default AssignDeviceForm;
