import { Col, Row, Skeleton } from 'antd';
import { isEmpty } from 'lodash';
import * as React from 'react';
import { Heading, Text } from '../components/primitives/Text';
import { useHeartBeat } from '../utils/hooks';

function HeartbeatCheck() {
  const { heartBeat = {}, loading } = useHeartBeat();

  const { assetsOnline, assetsOffline, violation, onTrip, tripDone, sos } = heartBeat;

  return (
    <div className="Dashboard__content__numbers">
      <Heading>Heartbeat Check</Heading>
      <Skeleton loading={loading}>
        {isEmpty(heartBeat) ? (
          <p>Data unavailable</p>
        ) : (
          <div className="Dashboard__content__numbers__card">
            <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
              <Col xl={{ span: 4 }} lg={{ span: 4 }} md={{ span: 8 }} className="number-col">
                <div className="number">
                  <Heading color="#50b83c">{assetsOnline}</Heading>
                  <Text color="#707c90" fontSize="14px">
                    Assets Online
                  </Text>
                </div>
              </Col>
              <Col xl={{ span: 4 }} lg={{ span: 4 }} md={{ span: 8 }} className="number-col">
                <div className="number">
                  <Heading color="#ed6347">{assetsOffline}</Heading>
                  <Text color="#707c90" fontSize="14px">
                    Assets Offline
                  </Text>
                </div>
              </Col>
              <Col xl={{ span: 4 }} lg={{ span: 4 }} md={{ span: 8 }} className="number-col">
                <div className="number">
                  <Heading color="#f49342">{sos}</Heading>
                  <Text color="#707c90" fontSize="14px">
                    SOS
                  </Text>
                </div>
              </Col>
              <Col xl={{ span: 4 }} lg={{ span: 4 }} md={{ span: 8 }} className="number-col">
                <div className="number">
                  <Heading color="#ed6347">{violation}</Heading>
                  <Text color="#707c90" fontSize="14px">
                    Violations
                  </Text>
                </div>
              </Col>
              <Col xl={{ span: 4 }} lg={{ span: 4 }} md={{ span: 8 }} className="number-col">
                <div className="number">
                  <Heading color="#2e384d">{onTrip}</Heading>
                  <Text color="#707c90" fontSize="14px">
                    Drivers On Trip
                  </Text>
                </div>
              </Col>
              <Col xl={{ span: 4 }} lg={{ span: 4 }} md={{ span: 8 }} className="number-col">
                <div className="number">
                  <Heading color="#2e384d">{tripDone}</Heading>
                  <Text color="#707c90" fontSize="14px">
                    Trips Done
                  </Text>
                </div>
              </Col>
            </Row>
          </div>
        )}
      </Skeleton>
    </div>
  );
}

export default HeartbeatCheck;
