import { Breadcrumb, Dropdown, Icon, Spin, Tooltip } from "antd";
import React from "react";
import styled from "styled-components";
import { blue, gray, white } from "../components/design-system/colors";
import { Fleet } from "../components/pages/Fleet";
import { CreateMenu, MapComponent } from "../components/pages/Fleet/components";
import FolderItem2 from "../components/pages/Fleet/FolderItem2";
import { googleUrl } from "../components/pages/Fleet/helpers";
import { VehicleList } from "../components/pages/Fleet/VehicleList";
import {
	ButtonComponent,
	EmptyButtonComponent,
} from "../components/primitives/Buttons";
import { EmptyStateComponent } from "../components/primitives/Layout";
import { AppModal, ToggleModal } from "../components/primitives/Modal";
import { Heading } from "../components/primitives/Text";
import { useFolderAssets, useRootFolder } from "../utils/hooks";
import AssetForm from "./AssetForm";

function FleetOverview() {
	const [currentFolderId, setCurrentFolderId] = React.useState(null);
	const [isCollapsed, setCollapsed] = React.useState(false);
	const [folderBreadcrumbs, setFolderBreadcrumbs] = React.useState([]);
	const {
		assets = [],
		folders = [],
		revalidate: refetchAssets,
		loading,
	} = useFolderAssets(currentFolderId);
	const { rootFolder } = useRootFolder();

	const formatAssets = assets.map((item) => ({
		label: item.tag,
		value: item.tag,
	}));

	const toggleNav = () => setCollapsed(!isCollapsed);

	const onFolderClick = (folder) => {
		const { nodeId, nodeDesc, parentNodeId, parentNodeDesc } = folder;
		setCurrentFolderId(nodeId);
		if (folderBreadcrumbs.length > 0) {
			if (folderBreadcrumbs[folderBreadcrumbs.length - 1].nodeDesc === nodeDesc)
				return;
			folderBreadcrumbs.push(folder);
		} else {
			folderBreadcrumbs.push(folder);
		}
	};

	const onFolderBreadcrumbClick = (folder, index) => {
		setFolderBreadcrumbs(folderBreadcrumbs.slice(0, index + 1));
		onFolderClick(folder);
	};

	return (
		<Fleet>
			<OverviewContainer id="area" isCollapsed={isCollapsed}>
				<Dropdown
					overlay={<CreateMenu />}
					getPopupContainer={() => document.getElementById("area")}
					overlayClassName="create__dropdown"
					trigger={["click"]}
				>
					<EmptyButtonComponent className="create__button">
						<Icon type="plus" />
					</EmptyButtonComponent>
				</Dropdown>
				<div className="OverviewContainer">
					<div className="relative h-full sidebar-container">
						<Tooltip
							placement="left"
							mouseLeaveDelay={0}
							title={`${isCollapsed ? `Show` : `Hide`} side panel`}
						>
							<EmptyButtonComponent
								className="toggle-collapse__button"
								onClick={toggleNav}
							>
								<Icon
									type={isCollapsed ? "right" : "left"}
									style={{ fontSize: "12px" }}
								/>
							</EmptyButtonComponent>
						</Tooltip>
						<div className="OverviewContainer__sidebar h-full flex flex-col overflow-y-auto">
							<div className="OverviewContainer__sidebar-menu">
								<Breadcrumb separator=">">
									{rootFolder && (
										<Breadcrumb.Item>
											<a
												onClick={() => {
													setCurrentFolderId(rootFolder.nodeId);
													setFolderBreadcrumbs([]);
												}}
											>
												{rootFolder.nodeDesc}
											</a>
										</Breadcrumb.Item>
									)}
									{folderBreadcrumbs.map((folder, i) => (
										<Breadcrumb.Item key={folder.nodeId}>
											<a
												onClick={() => onFolderBreadcrumbClick(folder, i)}
												className={
													i === folderBreadcrumbs.length - 1
														? "pointer-events-none"
														: ""
												}
											>
												{folder.nodeDesc}
											</a>
										</Breadcrumb.Item>
									))}
								</Breadcrumb>
							</div>
							<div className="OverviewContainer__sidebar-content flex-grow flex-shrink-0 w-full">
								{loading ? (
									<Spin
										style={{ display: "flex" }}
										className="h-full w-full items-center justify-center"
									/>
								) : (
									<>
										{folders.length > 0 && (
											<div className="OverviewContainer__sidebar-content__folders">
												{folders.map((folder, i) => (
													<FolderItem2
														{...folder}
														loading={loading}
														onClick={onFolderClick}
														key={`${folder.nodeId}-`}
													/>
												))}
											</div>
										)}

										{assets.length > 0 ? (
											<div className="OverviewContainer__sidebar-content__assets">
												<VehicleList data={assets} />
											</div>
										) : (
											<EmptyStateComponent>
												<Heading className="EmptyStateComponent__text">
													No Assets
												</Heading>
											</EmptyStateComponent>
										)}
									</>
								)}
							</div>
						</div>
					</div>
					<div className="OverviewContainer__map-section">
						<MapComponent
							isMarkerShown
							googleMapURL={googleUrl}
							loadingElement={<div style={{ height: `100%` }} />}
							markers={assets}
						/>
					</div>
				</div>
			</OverviewContainer>
			<ToggleModal>
				{(showModal, onOpenModal, onCloseModal) => {
					return (
						<>
							<AppModal
								title="Add New Asset"
								visible={showModal}
								onCancel={onCloseModal}
							>
								<AssetForm
									close={() => {
										onCloseModal();
										refetchAssets();
									}}
								/>
							</AppModal>
							<ButtonComponent onClick={onOpenModal}>
								Create Asset
							</ButtonComponent>
						</>
					);
				}}
			</ToggleModal>
		</Fleet>
	);
}

var OverviewContainer = styled.div`
  position: relative;
  height: calc(100vh - 129px);
  .create__button {
    position: absolute;
    z-index: 10;
    display: flex;
    align-items: center;
    justify-content: center;
    top: 14px;
    background: ${blue.primary};
    color: ${white};
    border-top-right-radius: 100px;
    border-bottom-right-radius: 100px;
    padding: 8px 12px 8px 8px;
    box-shadow: -1px 3px 8px 0 rgba(146, 146, 146, 0.7);
  }
  .create__dropdown {
    top: 14px !important;
    left: 40px !important;
    .ant-menu-vertical {
      border-radius: 5px !important;
      border-right: none !important;
      box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1) !important;
    }
  }
  .toggle-collapse__button {
    position: absolute;
    right: 0;
    z-index: 10;
    bottom: 50%;
    padding: 8px 12px 8px 8px;
    border: 1px solid #f6f6f6;
    ${(props) =>
			props.isCollapsed
				? `
    border-top-right-radius: 100px;
    border-bottom-right-radius: 100px;
    transform: translateX(34px);
    `
				: `
    border-top-left-radius: 100px;
    border-bottom-left-radius: 100px;
    `}
    background-color: ${white};
  }
  .OverviewContainer {
    position: relative;
    display: flex;
    height: 100%;
    .sidebar-container {
      position: absolute;
      left: 0;
      bottom: 0;
      height: 100%;
      z-index: 5;
      background: white;
      min-width: 400px;
      transform: ${(props) =>
				props.isCollapsed ? `translateX(-100%)` : `translateX(0)`};
      transition: transform 0.3s ease-in-out;
    }
    &__map-section {
      width: 100%;
      height: 100%;
      position: relative;
      top: -110px;
    }
    &__sidebar-menu {
      padding: 16px;
      padding-left: 50px;
      background-color: ${gray.ui01};

      button {
        display: flex;
        align-items: center;
        margin-right: 16px;
        p {
          margin: 0;
        }
        img {
          padding-right: 8px;
        }
      }

      .folder-breadcrumbs {
        p {
          margin: 0;
        }
      }
    }
    &__sidebar-content {
      &__folders {
        padding-top: 8px;
        padding-bottom: 12px;
        border-bottom: 1px solid ${gray.light};
        .FolderItem {
          padding-left: 32px;
          padding-right: 16px;
          &__button {
            padding: 12px 0;
          }
        }
      }
      &__assets {
        margin-left: 16px;
      }
    }
  }
`;

export default FleetOverview;
