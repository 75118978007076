import { Breadcrumb, message, Radio } from "antd";
import * as React from "react";
import { cache, mutate } from "swr";
import { useFolderAssets, useRootFolder } from "../../../../src/utils/hooks";
import { makeApiCallAdmin } from "../../../api";
import { ButtonComponent } from "../../primitives/Buttons";

function MoveItem({ close, node }) {
	const [currentFolderId, setCurrentFolderId] = React.useState(null);
	const { folders = [] } = useFolderAssets(currentFolderId);
	const { rootFolder } = useRootFolder(currentFolderId);
	const [folderBreadcrumbs, setFolderBreadcrumbs] = React.useState([]);
	const [selectedFolder, setSelectedFolder] = React.useState({});
	const [isSubmitting, setSubmitting] = React.useState(false);

	const moveItem = async () => {
		setSubmitting(true);
		try {
			await makeApiCallAdmin(`/MyFolder/${node.nodeId}/Move`, {
				method: "put",
				data: {
					nodeDesc: node.nodeDesc,
					newParentNodeId: selectedFolder.nodeId,
					newParentNodeDesc: selectedFolder.nodeDesc,
				},
			});
			message.success(`${node.nodeDesc} moved to ${selectedFolder.nodeDesc}`);
			close();
			// Refetch folders
			// https://github.com/vercel/swr/issues/418#issuecomment-637043907
			cache
				.keys() // get all the cached keys
				// filter by key, here you could also use a regex
				.filter((key) => key.includes("MyFolder"))
				// trigger the revalidation
				.forEach((key) => mutate(key, undefined, true));
		} catch (error) {
			message.error(error.message);
		} finally {
			setSubmitting(false);
		}
	};

	const onFolderClick = (folder) => {
		const { nodeId, nodeDesc } = folder;
		setCurrentFolderId(nodeId);
		if (folderBreadcrumbs.length > 0) {
			if (folderBreadcrumbs[folderBreadcrumbs.length - 1].nodeDesc === nodeDesc)
				return;
			folderBreadcrumbs.push(folder);
		} else {
			folderBreadcrumbs.push(folder);
		}
	};

	const onFolderBreadcrumbClick = (folder, index) => {
		setFolderBreadcrumbs(folderBreadcrumbs.slice(0, index + 1));
		onFolderClick(folder);
	};

	return (
		<div>
			<Breadcrumb separator=">">
				<Breadcrumb.Item>
					<a
						onClick={() => {
							setCurrentFolderId(null);
							setFolderBreadcrumbs([]);
						}}
					>
						Root
					</a>
				</Breadcrumb.Item>
				{folderBreadcrumbs.map((folder, i) => (
					<Breadcrumb.Item key={folder.nodeId}>
						<a
							onClick={() => onFolderBreadcrumbClick(folder, i)}
							className={
								i === folderBreadcrumbs.length - 1 ? "pointer-events-none" : ""
							}
						>
							{folder.nodeDesc}
						</a>
					</Breadcrumb.Item>
				))}
			</Breadcrumb>
			{rootFolder && (
				<div
					className="flex items-center mt-4 cursor-pointer"
					onClick={() => setSelectedFolder(rootFolder)}
				>
					<Radio checked={selectedFolder.nodeId === rootFolder.nodeId} />
					<img
						src="/static/img/folder.svg"
						alt="folder icon"
						className="mr-3"
					/>
					<span className="text-black">{rootFolder.nodeDesc}</span>
				</div>
			)}
			{/* If it's a folder being moved, do not show the folder. You don't want to move a folder to itself */}
			{(node.isNodeLeaf
				? folders
				: folders.filter((folder) => folder.nodeId !== node.nodeId)
			).map((folder, i) => (
				<div
					className="flex items-center mt-4 ml-4 cursor-pointer"
					onDoubleClick={() => onFolderClick(folder)}
					onClick={() => setSelectedFolder(folder)}
					key={folder.nodeId}
				>
					<Radio checked={folder.nodeId === selectedFolder.nodeId} />
					<img
						src="/static/img/folder.svg"
						alt="folder icon"
						className="mr-3"
					/>
					<span className="text-black">{folder.nodeDesc}</span>
				</div>
			))}
			<div className="flex mt-8">
				<ButtonComponent onClick={close} disabled={isSubmitting}>
					Cancel
				</ButtonComponent>
				<ButtonComponent
					appearance="green"
					disabled={selectedFolder.nodeId === undefined}
					loading={isSubmitting}
					className="ml-4"
					onClick={moveItem}
				>
					Move
				</ButtonComponent>
			</div>
		</div>
	);
}

export default MoveItem;
