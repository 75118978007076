import { Checkbox, DatePicker, Icon, Menu } from "antd";
import { Popover, Position, TextDropdownButton } from "evergreen-ui";
import moment from "moment";
import React from "react";
import {
	GoogleMap,
	InfoWindow,
	Marker as MarkerP,
	withGoogleMap,
	withScriptjs,
} from "react-google-maps";
import { MarkerClusterer } from "react-google-maps/lib/components/addons/MarkerClusterer";
import { withRouter } from "react-router";
import styled from "styled-components";
import AssetForm from "../../../Fleet/AssetForm";
import { blue, gray, red, white } from "../../design-system/colors";
import { customFonts } from "../../design-system/fonts";
import { EmptyButtonComponent } from "../../primitives/Buttons";
import { AppModal, ToggleModal } from "../../primitives/Modal";
import { Heading, Text } from "../../primitives/Text";
import { CreateFolderForm } from "./forms";
import { silver } from "./SilverMapStlye";
import { Marker } from "./VehicleList";

var google = window.google;

const MarkerInfoWindowContentStyle = styled.div`
	.actions-section {
		display: flex;
		a {
			padding-right: 24px;
			font-size: 12px;
			color: #007ace;
			text-decoration: none;
		}
	}
`;

const MarkerInfoWindowContent = ({
	location,
	nodeDesc,
	address,
	goToAssetPage,
}) => {
	return (
		<MarkerInfoWindowContentStyle>
			<Heading fontSize="16px" fontWeight="bold">
				{nodeDesc}
			</Heading>
			{address && (
				<Text fontSize="12px" color={gray.primary}>
					{address}
				</Text>
			)}
			{location.onTrip ? (
				<Text fontSize="12px" color="#00b649">
					Ignition is On
				</Text>
			) : (
				<Text fontSize="12px" color="#d0021b">
					Stopped, Ignition is Off
				</Text>
			)}
			<div>
				<Text fontWeight="bold" fontSize="12px" marginBottom="8px">
					Time Based Position:
				</Text>
				<Text fontSize="12px" color={gray.primary}>
					{moment.unix(location.time).format("DD-MMM-YYYY HH:mm:ss a")}
				</Text>
			</div>
			<div>
				<Text fontWeight="bold" fontSize="12px" marginBottom="8px">
					Temperature:
				</Text>
				<Text fontSize="12px" color={gray.primary}>
					{location.engineTemperature
						? `${location.engineTemperature}\u2103`
						: `No Temperature Sensor Installed.`}
				</Text>
			</div>
			<div>
				<Text fontWeight="bold" fontSize="12px" marginBottom="8px">
					Driver:
				</Text>
				<Text fontSize="12px" color={gray.primary}>
					{location.driverName ? location.driverName : `No Driver associated`}
				</Text>
			</div>
			<div>
				<Text fontWeight="bold" fontSize="12px" marginBottom="8px">
					Last Load Weight:
				</Text>
				<Text fontSize="12px" color={gray.primary}>
					0kg
				</Text>
			</div>
			<div className="actions-section">
				<a onClick={goToAssetPage}>Go to asset page</a>
			</div>
		</MarkerInfoWindowContentStyle>
	);
};

const assetIconType = (asset) => {
	if (asset.deviceLinked) {
		if (asset.location.onTrip) {
			return "/static/img/location-green.svg";
		} else {
			return "/static/img/location-red.svg";
		}
	}
	return "/static/img/location-gray.svg";
};

class CustomMarker extends React.Component {
	state = {
		infoWindowOpen: false,
		address: null,
	};
	componentDidMount() {
		const {
			marker: { location },
		} = this.props;
		if (window.google) {
			this.getVehicleLocation(location.latitude, location.longitude);
		}
	}
	toggleInfoWindow = () => {
		this.setState(({ infoWindowOpen }) => ({
			infoWindowOpen: !infoWindowOpen,
		}));
	};
	getVehicleLocation = (lat, lng) => {
		let geocoder = new google.maps.Geocoder();
		let latlng = { lat: parseFloat(lat), lng: parseFloat(lng) };
		geocoder.geocode({ location: latlng }, (results, status) => {
			if (results) {
				this.setState({
					address: results.length > 0 ? results[0].formatted_address : "N/A",
				});
			}
		});
	};
	goToAssetPage = () => {
		const {
			history: { push },
			marker,
		} = this.props;
		push(`/fleet/overview/${marker.assetId}`);
	};
	render() {
		const { marker } = this.props;
		const { location, nodeDesc } = marker;

		return (
			<React.Fragment>
				{this.state.infoWindowOpen && (
					<React.Fragment>
						<InfoWindow
							position={{
								lat: parseFloat(location.latitude),
								lng: parseFloat(location.longitude),
							}}
							onCloseClick={this.toggleInfoWindow}
							options={{ maxWidth: 250 }}
						>
							<MarkerInfoWindowContent
								location={location}
								nodeDesc={nodeDesc}
								address={this.state.address}
								goToAssetPage={this.goToAssetPage}
							/>
						</InfoWindow>
					</React.Fragment>
				)}
				<MarkerP
					position={{
						lat: parseFloat(location.latitude),
						lng: parseFloat(location.longitude),
					}}
					labelAnchor={{ x: 0, y: 0 }}
					labelStyle={{
						backgroundColor: "rgba(0, 0, 0, 0.7)",
						borderRadius: "3px",
						fontSize: "12px",
						padding: "4px 8px",
						color: white,
					}}
					clickable
					icon={assetIconType(marker)}
					onClick={this.toggleInfoWindow}
				/>
			</React.Fragment>
		);
	}
}

export const MapComponent = withScriptjs(
	withGoogleMap(
		withRouter((props) => {
			return (
				<GoogleMap
					defaultZoom={6}
					// mapTypeId={'ROADMAP'}
					defaultCenter={{ lat: 9.05870215639214, lng: 7.44873046875 }}
					options={{ styles: silver }}
				>
					<MarkerClusterer>
						{props.markers.map((marker) => {
							if (marker.location && marker.location.latitude) {
								return (
									<CustomMarker
										marker={marker}
										key={marker.nodeId}
										history={props.history}
									/>
								);
							}
						})}
					</MarkerClusterer>
				</GoogleMap>
			);
		})
	)
);

MapComponent.defaultProps = {
	containerElement: <div style={{ height: `100vh`, width: `100%` }} />,
	mapElement: <div style={{ height: `100vh`, width: `100%` }} />,
	isMarkerShown: true,
};

export const CreateMenu = ({ createFolder, buttonLoading }) => {
	return (
		<Menu>
			<Menu.Item key="1">
				<ToggleModal>
					{(showModal, onOpenModal, onCloseModal) => (
						<React.Fragment>
							<AppModal
								title="Create Asset"
								visible={showModal}
								onCancel={onCloseModal}
							>
								<AssetForm close={onCloseModal} />
							</AppModal>
							<a onClick={onOpenModal}>
								<Icon type="car" /> Create New Asset
							</a>
						</React.Fragment>
					)}
				</ToggleModal>
			</Menu.Item>
			<Menu.Item key="2">
				<ToggleModal>
					{(showModal, onOpenModal, onCloseModal) => (
						<React.Fragment>
							<AppModal
								title="Create Folder"
								visible={showModal}
								onCancel={onCloseModal}
							>
								<CreateFolderForm close={onCloseModal} folderList={[]} />
							</AppModal>
							<a
								onClick={() => {
									onOpenModal();
								}}
							>
								<Icon type="folder" /> Create New Folder
							</a>
						</React.Fragment>
					)}
				</ToggleModal>
			</Menu.Item>
		</Menu>
	);
};

export const AssignFilterMenu = ({ filterFunc }) => {
	return (
		<Menu>
			<Menu.Item key="1">
				<a onClick={() => filterFunc("alphabetically")}>
					<Icon type="car" /> Alphabetically
				</a>
			</Menu.Item>
			<Menu.Item key="2">
				<a onClick={() => filterFunc("recently_created")}>
					<Icon type="folder" /> Recently Created
				</a>
			</Menu.Item>
			<Menu.Item key="3">
				<a onClick={() => filterFunc("asset_only")}>
					<Icon type="folder" /> Asset Only
				</a>
			</Menu.Item>
		</Menu>
	);
};

const AssetAssignComponentContainer = styled.div`
	margin-bottom: 16px;
	padding-bottom: 8px;
	border-bottom: 1px solid ${gray.faint};

	h1 {
		margin: 0;
		font-size: 18px;
	}
	.bottom {
		display: flex;
		align-items: center;
		justify-content: space-between;

		.asset-location {
			margin: 0;
			color: ${gray.primary};
			span {
				padding-left: 8px;
			}
		}

		button {
			color: #e84a50;
			padding: 8px 24px;
			border-radius: 4px;
			font-weight: bold;
			text-transform: uppercase;
			background-color: rgba(232, 74, 80, 0.15);
		}
	}
`;

export const AssetAssignComponent = ({
	nodeDesc,
	plateNo,
	location,
	onRemove,
}) => (
	<AssetAssignComponentContainer>
		<Heading>{plateNo || nodeDesc || "N/A"}</Heading>
		<div className="bottom">
			<Text className="asset-location">
				<Marker onTrip={false} />
				<span>{location || "Not available"}</span>
			</Text>
			<EmptyButtonComponent onClick={onRemove}>remove</EmptyButtonComponent>
		</div>
	</AssetAssignComponentContainer>
);

export const PlayIcon = () => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		viewBox="0 0 512 512"
		fill={blue.primary}
		width="18"
		height="18"
	>
		<path d="M96 52v408l320-204L96 52z" />
	</svg>
);

export const PauseIcon = () => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		viewBox="0 0 512 512"
		fill={blue.primary}
		width="24"
		height="24"
	>
		<path d="M96 448h106.7V64H96v384zM309.3 64v384H416V64H309.3z" />
	</svg>
);

export const StopIcon = () => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		viewBox="0 0 512 512"
		fill={red.primary}
		width="24"
		height="24"
	>
		<path d="M405.333 64H106.667C83.198 64 64 83.198 64 106.667v298.666C64 428.802 83.198 448 106.667 448h298.666C428.802 448 448 428.802 448 405.333V106.667C448 83.198 428.802 64 405.333 64z" />
	</svg>
);

export const FleetInsightSpanMenu = (
	<Menu>
		<Menu.Item key="0">
			<Checkbox onChange={(e) => console.log(e.target.value)}>
				Last 12 Hours
			</Checkbox>
		</Menu.Item>
		<Menu.Item key="1">
			<Checkbox onChange={(e) => console.log(e.target.value)}>
				Yesterday
			</Checkbox>
		</Menu.Item>
		<Menu.Item key="2">
			<Checkbox onChange={(e) => console.log(e.target.value)}>
				Last 24 Hours
			</Checkbox>
		</Menu.Item>
		<Menu.Item key="3">
			<Checkbox onChange={(e) => console.log(e.target.value)}>
				Last Hour
			</Checkbox>
		</Menu.Item>
		<Menu.Item key="4">
			<Checkbox onChange={(e) => console.log(e.target.value)}>Today</Checkbox>
		</Menu.Item>
	</Menu>
);

const FleetInsightOptionsMenuContainer = styled.div`
	padding: 18px 12px;
	border-radius: 3px;
	box-shadow: 0 2px 4px 0 rgba(144, 164, 183, 0.22);
	border: solid 0.2px var(--black-10);
	background-color: #ffffff;

	.ant-menu.ant-menu-vertical {
		border-right: none;

		.ant-menu-item-selected,
		.ant-menu-item {
			background-color: transparent;
			margin: 0;
		}
	}

	& > .options-list {
		display: flex;

		& > .right-menu-options {
			border-left: 1px solid #e8e8e8;
			padding-left: 10px;

			.default_color {
				&:hover {
					.ant-checkbox-inner {
						border-color: #9e9e9e;
					}
				}

				.ant-checkbox-input:focus + .ant-checkbox-inner {
					border-color: #9e9e9e;
				}

				.ant-checkbox.ant-checkbox-checked {
					& > .ant-checkbox-inner {
						background-color: #9e9e9e;
						border-color: #9e9e9e;
					}
				}
			}
		}

		span {
			font-family: ${customFonts.akkurat}!important;
			color: #4a4a4a;
		}

		.idling {
			span {
				color: #910065;
			}

			&:hover {
				.ant-checkbox-inner {
					border-color: #910065;
				}
			}
			& > .ant-checkbox.ant-checkbox-checked {
				& > .ant-checkbox-inner {
					background-color: #910065;
					border-color: #910065;
				}
			}
		}

		.harsh_acceleration {
			span {
				color: #a5a512;
			}

			&:hover {
				.ant-checkbox-inner {
					border-color: #a5a512;
				}
			}
			& > .ant-checkbox.ant-checkbox-checked {
				& > .ant-checkbox-inner {
					background-color: #a5a512;
					border-color: #a5a512;
				}
			}
		}

		.harsh_braking {
			span {
				color: #ff9800;
			}

			&:hover {
				.ant-checkbox-inner {
					border-color: #ff9800;
				}
			}
			& > .ant-checkbox.ant-checkbox-checked {
				& > .ant-checkbox-inner {
					background-color: #ff9800;
					border-color: #ff9800;
				}
			}
		}

		.overspeeding {
			span {
				color: #006d64;
			}

			&:hover {
				.ant-checkbox-inner {
					border-color: #006d64;
				}
			}
			& > .ant-checkbox.ant-checkbox-checked {
				& > .ant-checkbox-inner {
					background-color: #006d64;
					border-color: #006d64;
				}
			}
		}

		.sos {
			span {
				color: #ff0000;
			}

			&:hover {
				.ant-checkbox-inner {
					border-color: #ff0000;
				}
			}
			& > .ant-checkbox.ant-checkbox-checked {
				& > .ant-checkbox-inner {
					background-color: #ff0000;
					border-color: #ff0000;
				}
			}
		}

		.no_entry {
			span {
				color: #1f2a82;
			}

			&:hover {
				.ant-checkbox-inner {
					border-color: #1f2a82;
				}
			}
			& > .ant-checkbox.ant-checkbox-checked {
				& > .ant-checkbox-inner {
					background-color: #1f2a82;
					border-color: #1f2a82;
				}
			}
		}

		.no_exit {
			span {
				color: #1630ff;
			}

			&:hover {
				.ant-checkbox-inner {
					border-color: #1630ff;
				}
			}
			& > .ant-checkbox.ant-checkbox-checked {
				& > .ant-checkbox-inner {
					background-color: #1630ff;
					border-color: #1630ff;
				}
			}
		}

		.zone_overspeed {
			span {
				color: #158e8e;
			}

			&:hover {
				.ant-checkbox-inner {
					border-color: #158e8e;
				}
			}
			& > .ant-checkbox.ant-checkbox-checked {
				& > .ant-checkbox-inner {
					background-color: #158e8e;
					border-color: #158e8e;
				}
			}
		}
	}
`;

export class FleetInsightOptionsMenu extends React.Component {
	constructor(props) {
		super(props);

		this.state = { showOptions: false };

		this.open = this.open.bind(this);
		this.close = this.close.bind(this);
	}

	close() {
		this.setState({ showOptions: false });
	}

	open() {
		this.setState({ showOptions: true });
	}

	render() {
		const { options, setOption } = this.props;
		const {
			showIdling = false,
			showOverspeeding = false,
			showHarshBraking = false,
			showHarshAcceleration = false,
			showSos = false,
			showNoEntryZone,
			showNoExitZone = false,
			showZoneOverspeed = false,
			showPolyline = false,
			showGeofence = false,
		} = options;

		const handleChange = ({ target: { name, checked } }) => {
			setOption({ name, checked });
		};

		return (
			<Popover
				content={() => (
					<FleetInsightOptionsMenuContainer>
						<div className="options-list">
							<div>
								<Menu>
									<Menu.Item key="0">
										<Checkbox
											name="showIdling"
											onChange={handleChange}
											checked={showIdling}
											className="idling"
										>
											Idling
										</Checkbox>
									</Menu.Item>
									<Menu.Item key="1">
										<Checkbox
											name="showHarshAcceleration"
											onChange={handleChange}
											checked={showHarshAcceleration}
											className="harsh_acceleration"
										>
											Harsh Acceleration
										</Checkbox>
									</Menu.Item>
									<Menu.Item key="2">
										<Checkbox
											name="showHarshBraking"
											onChange={handleChange}
											checked={showHarshBraking}
											className="harsh_braking"
										>
											Harsh Brake
										</Checkbox>
									</Menu.Item>
									<Menu.Item key="3">
										<Checkbox
											name="showOverspeeding"
											onChange={handleChange}
											checked={showOverspeeding}
											className="overspeeding"
										>
											Overspeed
										</Checkbox>
									</Menu.Item>
									<Menu.Item key="4">
										<Checkbox
											name="showSos"
											onChange={handleChange}
											checked={showSos}
											className="sos"
										>
											S.O.S
										</Checkbox>
									</Menu.Item>
								</Menu>
							</div>
							<div className="right-menu-options">
								<Menu>
									<Menu.Item key="0">
										<Checkbox
											name="showNoEntryZone"
											onChange={handleChange}
											checked={showNoEntryZone}
											className="no_entry"
										>
											No Entry Zone
										</Checkbox>
									</Menu.Item>
									<Menu.Item key="1">
										<Checkbox
											name="showNoExitZone"
											onChange={handleChange}
											checked={showNoExitZone}
											className="no_exit"
										>
											No Exit Zone
										</Checkbox>
									</Menu.Item>
									<Menu.Item key="2">
										<Checkbox
											name="showZoneOverspeed"
											onChange={handleChange}
											checked={showZoneOverspeed}
											className="zone_overspeed"
										>
											Zone Overspeed
										</Checkbox>
									</Menu.Item>
									<Menu.Item key="3">
										<Checkbox
											name="showGeofence"
											onChange={handleChange}
											checked={showGeofence}
											className="default_color"
										>
											Show Geofence
										</Checkbox>
									</Menu.Item>
									<Menu.Item key="4">
										<Checkbox
											name="showPolyline"
											onChange={handleChange}
											checked={showPolyline}
											className="default_color"
										>
											Show Polyline
										</Checkbox>
									</Menu.Item>
								</Menu>
							</div>
						</div>
					</FleetInsightOptionsMenuContainer>
				)}
				position={Position.BOTTOM_LEFT}
				shouldCloseOnExternalClick={false}
				isShown={this.state.showOptions}
				onOpen={this.open}
				onClose={this.close}
			>
				<TextDropdownButton
					className="dropdown-button"
					icon="chevron-down"
					fontSize="inherit"
					color="black"
					fontFamily="Circular !important"
					borderRadius={0}
					marginLeft={5}
					paddingX={10}
				>
					Options
				</TextDropdownButton>
			</Popover>
		);
	}
}

const DateRangePickerWrapper = styled.div`
	position: relative;

	&::after {
		position: absolute;
		content: attr(data-invalid-text);
		bottom: -18px;
		left: 0;
	}
`;

export const DateRangePicker = ({ onInputChange, startDate, endDate }) => {
	const onStartChange = (value) => onInputChange("startDate", value);

	const onEndChange = (value) => onInputChange("endDate", value);

	return (
		<DateRangePickerWrapper data-invalid-text="Maximum range allowed is 24 hours">
			<DatePicker
				format="DD/MM/YYYY HH:mm:ss"
				showTime
				value={startDate}
				placeholder="Start"
				onChange={onStartChange}
				size="large"
				allowClear={false}
			/>
			<DatePicker
				format="DD/MM/YYYY HH:mm:ss"
				showTime
				value={endDate}
				placeholder="End"
				onChange={onEndChange}
				size="large"
				allowClear={false}
			/>
		</DateRangePickerWrapper>
	);
};
