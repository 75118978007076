import { message } from 'antd';
import { useRef } from 'react';
import useSWR from 'swr';
import { makeApiCallAdmin } from '../../api';

function useUnresolvedIncidentsCount() {
  const dateRef = useRef(Math.round(new Date().getTime() / 1000));
  const { data: count, error, revalidate } = useSWR(
    `/Incidents/Date/${dateRef.current}/new`,
    fetchIncidents,
    { initialData: localStorage.getItem('incidentsCount') }
  );

  return { count, loading: count === undefined && !error, revalidate };
}

async function fetchIncidents(url) {
  try {
    const { data } = await makeApiCallAdmin(url);
    localStorage.setItem('incidentsCount', data);
    localStorage.setItem('referenceCount', data);
    return data;
  } catch (e) {
    message.error(e.message);
  }
}

export default useUnresolvedIncidentsCount;
