import { Avatar, Skeleton } from 'antd';
import moment from 'moment';
import React from 'react';
import styled from 'styled-components';
import { Heading } from '../../primitives/Text';
import TripHistory from './TripHistory';
import { VehicleTripItemIcon } from './VehicleHistoryItem';

const VehicleInfoContainer = styled.div`
  width: 300px;
  height: 100%;
  background-color: #fff;
  box-shadow: 3px 0px 5px 0 rgba(31, 49, 74, 0.1);
  position: relative;
  z-index: 5;

  .ant-skeleton {
    padding: 20px 8px;

    .ant-skeleton-paragraph {
      padding: 0;
    }
  }

  & > .summary {
    padding: 26px 16px 16px;

    & > .asset__name {
      font-size: 25px;
      margin-bottom: 12px;
    }

    & > .driver__info {
      display: flex;
      align-items: center;
      margin-bottom: 20px;

      & > .avatar {
        margin-right: 10px;
      }

      & > .info__text {
        display: flex;
        align-items: center;
        flex-direction: column;

        & > .driver__name {
          font-size: 12px;
          font-family: Circular;
          color: #000;
        }

        & > .violation {
          font-size: 12px;
          font-family: Circular;
          color: #ed6347;
        }
      }
    }

    & > .trip__points {
      display: flex;
      align-items: center;
      padding-left: 5px;
      margin-bottom: 20px;

      & > svg {
        transform: rotate(180deg);
      }

      & > .point__names {
        display: flex;
        flex-direction: column;
        margin-left: 14px;

        & > .point {
          font-size: 13px;
          font-family: Circular;
          color: #c0c3cc;
        }
      }
    }

    & > .ignition__status__wrapper {
      display: flex;
      align-items: center;
      padding-left: 2px;

      & > .ignition__icon {
        margin-right: 8px;
        width: 16px;
        height: 16px;
      }

      & > .ignition__status {
        font-size: 12px;

        &.on {
          color: #3dd598;
        }

        &.off {
          color: #ed6347;
        }
      }
    }
  }

  & > .time__and__speed {
    background-color: #f9f9f9;
    padding: 16px 12px;
    display: flex;
    align-items: flex-start;

    & > img {
      margin-right: 20px;
    }

    & > .details {
      margin-top: -10px;

      & > * {
        margin: 0;
      }
    }
  }

  & > .speed__info {
    border-bottom: solid 1px #e3e3e3;

    & > .details {
      & > .current__speed {
        font-family: Circular;
        font-size: 15px;

        & > .speed {
          color: #ed6347;
          font-family: 'Circular-Std-Medium';
          font-size: 33px;
        }
      }

      & > .speed__limit {
        font-family: Akkurat;
        font-size: 13px;
        color: #9b9b9b;

        & > .speed {
          color: #ed6347;
          font-family: 'Circular-Std-Medium';
          font-size: 15px;

          &.dim {
            color: #ffa97f;
          }
        }
      }
    }
  }

  & > .time__info {
    background-color: #f9f9f9;
    padding: 16px 12px;
    display: flex;
    align-items: flex-start;

    & > img {
      margin-right: 20px;
    }

    & > .details {
      font-family: Circular;

      & > .current__time {
        font-size: 15px;
        color: #000;

        & > .time {
          font-family: 'Circular-Std-Medium';
          font-size: 27px;
        }
      }

      & > .date {
        font-family: 'Circular-Std-Medium';
        font-size: 10px;
        color: #000;
        margin-bottom: 8px;
      }

      & > .report__type {
        font-size: 12px;
        color: #36a3f9;
      }
    }
  }

  & > .other__details {
    display: flex;
    padding: 16px 12px;
    border-bottom: solid 1px #e1e2e8;

    & > * {
      min-width: 50%;
      font-size: 13px;

      & > .property {
        font-family: Akkurat;
        color: #4a4a4a;
        margin-bottom: 0;
      }

      & > .value {
        font-family: Circular-Std-Bold;
        color: #000000;
        margin-bottom: 0;
      }
    }
  }
`;

function VehicleInfo({
  to,
  from,
  live,
  loading,
  locations,
  showVehicleRoute,
  vehicleInformation: { nodeDesc = '' },
  selectedLocation,
  setSelectedLocation,
  currentLocation: {
    longitude = '0',
    latitude = '0',
    fuelTankLevel = 0,
    odometer = 0,
    speed = 0,
    engineTemperature,
    acc = false,
    onTrip = false,
    driverName,
    time,
    violationDescription = '',
  },
}) {
  const TrimmedLat = Number.parseFloat(latitude).toPrecision(8);
  const TrimmedLon = Number.parseFloat(longitude).toPrecision(8);

  return (
    <VehicleInfoContainer>
      <Skeleton title={false} size="large" loading={loading && !live} active>
        <div className="summary">
          <Heading className="asset__name">{nodeDesc || 'N/A'}</Heading>
          <div className="driver__info">
            <Avatar className="avatar" alt={`${driverName}`} icon="user" size="small" />
            <div className="info__text">
              <span className="driver__name">{driverName}</span>
              {violationDescription && <span className="violation">{violationDescription}</span>}
            </div>
          </div>

          {onTrip && (
            <div className="trip__points">
              <VehicleTripItemIcon OnTrip={true} />
              <div className="point__names">
                <span className="point">{from}</span>
                <span className="point">{to}</span>
              </div>
            </div>
          )}
          <div className="ignition__status__wrapper">
            <img
              className="ignition__icon"
              src={`/static/img/ignition-${acc ? 'on' : 'off'}.svg`}
              alt="vehicle ignition status indicator"
            />
            <span className={`ignition__status ${acc ? 'on' : 'off'}`}>
              Ignition {acc ? 'on' : 'off'}
            </span>
          </div>
        </div>
      </Skeleton>

      <Skeleton title={false} size="large" loading={loading && !live} active>
        <div className="time__and__speed speed__info">
          <img src="/static/img/car.svg" alt="vehicle icon" />
          <div className="details">
            <p className="current__speed">
              <span className="speed">{speed}</span> km/h
            </p>
            <p className="speed__limit">
              Global Limit: <span className="speed">80km/h</span>
            </p>
            <p className="speed__limit">
              Parameter Limit: <span className="dim speed">75km/h</span>
            </p>
          </div>
        </div>
        <div className="time__and__speed time__info">
          <img src="/static/img/clock.svg" alt="clock icon" />
          <div className="details">
            <p className="current__time">
              <span className="time">{moment(time * 1000).format('h:mm')}</span>
              {moment(time * 1000).format(' a')}
            </p>
            <p className="date">{moment(time * 1000).format('Do dddd, MMMM YYYY')}</p>
          </div>
        </div>
      </Skeleton>

      <Skeleton title={false} size="large" loading={loading && !live} active>
        <div className="other__details">
          <div className="fuel">
            <p className="property">Total Fuel</p>
            <p className="value">{fuelTankLevel} liters</p>
          </div>
          <div className="temperature">
            <p className="property">Temperature</p>
            <p className="value">{engineTemperature}℃</p>
          </div>
        </div>
        <div className="other__details">
          <div className="doors">
            <p className="property">Doors</p>
            <p className="value">Closed</p>
          </div>
          <div className="mileage">
            <p className="property">Mileage</p>
            <p className="value">{odometer.toLocaleString()}</p>
          </div>
        </div>
        <div className="other__details">
          <div className="location">
            <p className="property">Location</p>
            <p className="value">{`${TrimmedLat}, ${TrimmedLon}`}</p>
          </div>
        </div>
      </Skeleton>

      {showVehicleRoute && (
        <TripHistory {...{ loading, locations, selectedLocation, setSelectedLocation }} />
      )}
    </VehicleInfoContainer>
  );
}

export default VehicleInfo;
