import React from "react";
import { NavLink } from "react-router-dom";
import styled from "styled-components";
import { black, blue, gray, white } from "../../design-system/colors";
import { customFonts } from "../../design-system/fonts";
import { EmptyButtonComponent } from "../../primitives/Buttons";
import { AppInput } from "../../primitives/Input";
import { EmptyStateComponent } from "../../primitives/Layout";
import { AppModal } from "../../primitives/Modal";
import { AppNavbar, SecondaryNavbar } from "../../primitives/Navbar";
import { AppTable } from "../../primitives/Table";
import { Text } from "../../primitives/Text";
import { AlertsNavigation, CreateAlertContainer } from "./AlertPage";
import ResolveIncidentForm from "./ResolveIncidentForm";

const incidentsTableColumns = ({
	setSelectedIncident,
	assetFilters,
	violationFilters,
}) => [
	{
		title: "VIOLATION",
		dataIndex: "description",
		filters: violationFilters,
		onFilter: (value, record) => record.description === value,
		render: (violation) => (
			<span className="incident-item__title">
				<svg
					xmlns="http://www.w3.org/2000/svg"
					width="18"
					height="17"
					viewBox="0 0 18 17"
				>
					<path
						fill="#ED6347"
						fillRule="evenodd"
						d="M4.2 10.15h12.96V.43H4.2v9.72zM.96 16.63h1.62V.43H.96v16.2z"
					/>
				</svg>
				<span>{violation}</span>
			</span>
		),
	},
	{
		title: "ASSET NAME",
		dataIndex: "assetName",
		filters: assetFilters,
		onFilter: (value, record) => record.assetName === value,
	},
	{ title: "Incident Date", dataIndex: "incidentDate", key: "incidentDate" },
	{
		key: "ResolveButton",
		render: (text, record) =>
			!record.resolved && (
				<EmptyButtonComponent
					onClick={() => {
						setSelectedIncident(record);
					}}
					css={`
          border-radius: 3px;
          border: 1px solid ${blue.primary};
          background-color: ${white};
          color: ${blue.primary}
          padding: 8px 24px;
          float: right;
        `}
				>
					Resolve
				</EmptyButtonComponent>
			),
	},
];

const IncidentItemContainer = styled.div`
	.incident {
		&__notification-contacts {
			font-size: 15px;
			color: ${gray.ui02};

			a {
				color: ${black};
				text-decoration: underline;
			}
		}
		&__vehicle {
			font-size: 20px;
			&__type {
				text-transform: uppercase;
			}
		}
		&__description {
			color: ${gray.ui02};
		}
	}
	.incident-wrapper {
		display: flex;
		justify-content: space-between;
		a.map-link {
			color: ${black};
			text-decoration: underline;

			font-weight: 600;
		}
	}
`;

const severityType = {
	1: "CRITICAL",
	2: "MAJOR",
	3: "MINOR",
	4: "TRIVIAL",
};

const alerts = {
	10: "OVER_SPEED",
	11: "ZONE_OVER_SPEED",
	20: "IDLING",
	30: "MOVEMENT",
	40: "HARSH_ACCELERATION",
	41: "HARSH_DECELERATION",
	50: "HARSH_TURNING",
	60: "SEAT_BELT",
	70: "OUT_SIDE_PERIMETER",
	71: "IN_SIDE_PERIMETER",
};

class IncidentItem extends React.Component {
	formatContacts = (contacts) => {
		return contacts.map((contact, i) => (
			<a key={i.toString()} className="incident-contact">
				{contact}{" "}
			</a>
		));
	};
	render() {
		const {
			description: Description,
			alertType: AlertType,
			assetName: AssetName,
			severity: Severity,
			coordinate: Coords,
		} = this.props.incident;

		return (
			<IncidentItemContainer className="incident">
				{/* <Text className="incident__notification-contacts">
          Notification sent to {this.formatContacts(Contacts)}on {TimeSent}
        </Text> */}
				<div className="incident-wrapper">
					<div>
						<Text className="incident__vehicle">
							<span className="incident__vehicle__type">
								{severityType[Severity]}
							</span>
							: Vehicle {AssetName} reported a {alerts[AlertType]} violation.
						</Text>
						<Text className="incident__description">{Description}</Text>
					</div>
					<div>
						<a
							className="map-link"
							href={`https://www.google.com/maps?q=${Coords.latitude},${Coords.longitude}`}
							target="_blank"
						>
							View on map
						</a>
					</div>
				</div>
			</IncidentItemContainer>
		);
	}
}

const IncidentsContainer = styled(CreateAlertContainer)`
	position: relative !important;

	.sidebar {
		position: fixed;
		left: 0;
		top: 128px;
		padding: 40px;
		width: 300px;

		ul {
			list-style: none;
			padding: 0;
		}
		li {
			margin-bottom: 8px;
		}
		a {
			display: block;
			color: ${black};
			padding: 8px;
			text-decoration: none;
			transition: color 0.3s ease-in-out, background-color 0.3s ease-in-out;

			&:hover,
			&:hover {
				background-color: ${blue.faint};
			}

			&.active {
				background-color: ${blue.faint};
			}
		}
	}

	.incidents {
		&__table-container {
			padding: 128px 40px 0 0;
			margin-left: 360px;
			max-width: 1500px;
			position: relative;

			.header {
				position: sticky;
				top: 129px;
				padding-top: 39px;
				padding-bottom: 50px;
				z-index: 10;
				background-color: white;

				form {
					max-width: 570px;
				}
			}
		}
	}

	.incident-item__title {
		display: flex;
		align-items: center;
		span {
			font-family: ${customFonts.circular}!important;
			font-weight: bold;
			padding-left: 16px;
		}
	}
	.incident-item__resolvedAt {
		color: ${gray.primary};
	}

	.ant-table-expanded-row,
	.ant-table-expanded-row:hover {
		background: #f6f9fc;
	}
`;

export const IncidentsPage = ({
	history,
	data = [],
	loading,
	onSearch,
	hideModal,
	isModalShown,
	searchIncidents,
	logout,
	selectedIncident,
	setSelectedIncident,
}) => {
	const gotoLogin = () => history.push("/signin");

	const assetFilters = Object.values(
		data.reduce(
			(acc, { assetName }) => ({
				...acc,
				...(assetName && {
					[assetName]: { text: assetName, value: assetName },
				}),
			}),
			{}
		)
	);

	const violationFilters = Object.values(
		data.reduce(
			(acc, { description }) => ({
				...acc,
				...(description && {
					[description]: { text: description, value: description },
				}),
			}),
			{}
		)
	);

	return (
		<React.Fragment>
			<AppNavbar logout={() => logout(gotoLogin)} />
			<IncidentsContainer className="incidents">
				<SecondaryNavbar>
					<AlertsNavigation
						showSearch={false}
						onFilter={onSearch}
						searchIncidents={searchIncidents}
					/>
				</SecondaryNavbar>
				<div className="sidebar">
					<ul>
						<li>
							<NavLink to="/alert/incidents" exact>
								Unresolved Incidents
							</NavLink>
						</li>
						<li>
							<NavLink to="/alert/incidents/resolved" exact>
								Resolved Incidents
							</NavLink>
						</li>
					</ul>
				</div>
				<div className="incidents__table-container">
					<div className="header">
						<form className="filter-section-form">
							<AppInput
								placeholder="Search by Violation or Asset Name"
								size="large"
								borderColor={gray.primary}
								marginTop="0"
								onChange={searchIncidents}
							/>
						</form>
					</div>
					{loading || data.length > 0 ? (
						<React.Fragment>
							<AppTable
								hasBorder
								loading={loading}
								border_color={gray.faint}
								expandedRowRender={(record) => (
									<IncidentItem incident={record} />
								)}
								columns={incidentsTableColumns({
									setSelectedIncident,
									assetFilters,
									violationFilters,
								})}
								dataSource={data}
								pagination={
									data.length > 20
										? { pageSize: 20, showSizeChanger: true }
										: false
								}
							/>
							<AppModal
								title="Resolve Incident"
								visible={isModalShown}
								onCancel={hideModal}
							>
								<ResolveIncidentForm
									closeDialog={hideModal}
									selectedIncident={selectedIncident}
								/>
							</AppModal>
						</React.Fragment>
					) : (
						<EmptyStateComponent className="EmptyStateComponent" margin="200px">
							<div className="EmptyStateComponent__icon-container">
								<img src="/static/img/403.svg" alt="forbidden icon" />
							</div>
							<Text className="EmptyStateComponent__text">
								You currently have no incidences
							</Text>
							<Text color={gray.primary}>
								There are currently no incidence at the moment.
							</Text>
						</EmptyStateComponent>
					)}
				</div>
			</IncidentsContainer>
		</React.Fragment>
	);
};
