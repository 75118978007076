import { message } from 'antd';
import useSWR from 'swr';
import { makeApiCallAdmin } from '../../../api';

function useOverSpeedingAssets() {
  const { data: overSpeedingAssets, error, revalidate } = useSWR(
    '/Dashboard/OverSpeed',
    fetchOverSpeedingAssets
  );

  return { overSpeedingAssets, loading: !overSpeedingAssets && !error, revalidate };
}

async function fetchOverSpeedingAssets(url) {
  try {
    const { data = [] } = await makeApiCallAdmin(url);
    return data;
  } catch (e) {
    message.error(e.message);
  }
}

export default useOverSpeedingAssets;
