import { Dropdown, Icon, Menu, message, Switch, Tooltip } from "antd";
import { Formik } from "formik";
import React, { useState } from "react";
import styled from "styled-components";
import { makeApiCall, makeApiCallAdmin } from "../../../api";
import { useProfile, useTrips } from "../../../utils/hooks";
import { black, gray, green, red, white } from "../../design-system/colors";
import { EmptyButtonComponent } from "../../primitives/Buttons";
import { AppInput } from "../../primitives/Input";
import { AppModal, confirm, ToggleModal } from "../../primitives/Modal";
import { SaveAndCancelButtons } from "../../primitives/SaveAndCancelButtons";
import { Heading, Text } from "../../primitives/Text";

export function TripItem(props) {
	const { clientId, id } = useProfile();
	const [status, setStatus] = useState(props.Status);
	const { revalidate } = useTrips();

	const {
		AssetDesc,
		Driver,
		Description,
		PointAAddress,
		PointBAddress,
		PointAMarker,
		PointBMarker,
		PointA,
		PointB,
		SearchAddrA,
		SearchAddrB,
		Trips,
		NumberOfAssetAssigned,
		goToAssignPage,
	} = props;

	const onSetStatus = async (newStatus) => {
		setStatus(newStatus);
		try {
			const { Status, Description } = await makeApiCall(
				"/Trip/Status",
				"post",
				{},
				{
					Id: props.TripId,
					Status: newStatus,
					ClientId: clientId,
					LoginUserId: id,
				}
			);
			if (!Status) throw new Error(Description);
			revalidate();
		} catch (error) {
			setStatus(!newStatus);
			message.error(error.message);
		}
	};

	const showDeleteConfirm = (TripId) => {
		confirm({
			title: "Do you want to delete this trip?",
			okText: "Yes",
			cancelText: "No",
			onOk: async () => {
				try {
					await makeApiCallAdmin(`/Trips/${TripId}`, { method: "delete" });
					message.success("Trip deleted");
					revalidate();
				} catch (error) {
					message.error(error.message);
				}
			},
		});
	};

	const menu = () => (
		<Menu>
			<Menu.Item>
				<EmptyButtonComponent onClick={props.onEdit}>
					Edit Trip
				</EmptyButtonComponent>
			</Menu.Item>
			<Menu.Item>
				<ToggleModal>
					{(showModal, onOpenModal, onCloseModal) => (
						<React.Fragment>
							<AppModal
								title={`Duplicate Trip - ${Description}`}
								visible={showModal}
								onCancel={onCloseModal}
							>
								<Formik
									initialValues={{
										PointAMarker,
										PointBMarker,
										PointAAddress,
										PointBAddress,
										PointA,
										PointB,
										SearchAddrA,
										SearchAddrB,
									}}
									onSubmit={async (values, actions) => {
										const payload = {
											...values,
											Status: true,
										};
										try {
											let { StatusCode, Description } = await makeApiCall(
												"Trip/Create",
												"post",
												{},
												{ ...payload, ClientId: clientId, LoginUserId: id }
											);
											if (StatusCode === "00") {
												message.success(Description);
												onCloseModal();
												revalidate();
											} else {
												throw new Error(Description);
											}
										} catch (error) {
											message.error(error.message);
										} finally {
											actions.setSubmitting(false);
										}
									}}
								>
									{({
										values,
										errors,
										touched,
										handleChange,
										handleSubmit,
										isSubmitting,
									}) => (
										<form onSubmit={handleSubmit}>
											<AppInput
												name="Description"
												type="text"
												value={values.Description}
												placeholder="Enter Trip Name"
												size="large"
												onChange={handleChange}
												error={touched.Description && errors.Description}
												errorMessage={errors.Description}
											/>
											<SaveAndCancelButtons
												onCancel={onCloseModal}
												loading={isSubmitting}
												marginTop="44px"
											/>
										</form>
									)}
								</Formik>
							</AppModal>
							<EmptyButtonComponent onClick={onOpenModal}>
								Duplicate Trip
							</EmptyButtonComponent>
						</React.Fragment>
					)}
				</ToggleModal>
			</Menu.Item>
			<Menu.Item>
				<EmptyButtonComponent
					color={red.primary}
					onClick={() => showDeleteConfirm(props.TripId)}
				>
					Delete Trip
				</EmptyButtonComponent>
			</Menu.Item>
		</Menu>
	);

	return (
		<TripItemContainer className="alert-item">
			<div className="trip-item__left">
				<Switch size="small" checked={status} onChange={onSetStatus} />
			</div>
			<div className="trip-item__right">
				<Heading>{Description}</Heading>
				<div className="trip-item__right__info">
					<div className="trip-item__right__info-container">
						<div className="trip-item__right__info-location">
							<div>
								<img src="/static/img/route-small.svg" alt="route icon" />
							</div>
							<div>
								<Text className="address-text">{PointAAddress}</Text>
								<Text className="address-text">{PointBAddress}</Text>
							</div>
						</div>
						<div className="trip-item__right__info-asset">
							{AssetDesc && <div>{AssetDesc}</div>}
							{Driver && (
								<div>
									<Text>
										{" "}
										<Icon type="user" style={{ color: gray.primary }} />{" "}
										{Driver}
									</Text>
								</div>
							)}
						</div>

						<div className="trip-item__bottom">
							<div className="trip-item__bottom-left">
								<Icon
									type="car"
									style={{ color: "rgba(181, 181, 181, 0.5)" }}
								/>
								<div className="trip-count">{NumberOfAssetAssigned}</div>
								<Tooltip placement="right" title="Assign">
									<EmptyButtonComponent
										className="assign-button"
										onClick={goToAssignPage}
									>
										<img src="static/img/circle-icon.svg" alt="circle icon" />
									</EmptyButtonComponent>
								</Tooltip>
							</div>
							<div className="trip-item__bottom-right">
								<Text>{Trips || "-"}</Text>
								<Text>Trips in Total</Text>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div className="trip-item__dropdown">
				<Dropdown overlay={menu()} trigger={["click"]}>
					<EmptyButtonComponent>
						<Icon
							type="ellipsis"
							style={{ fontSize: 24, color: "rgba(0,0,0,.5)" }}
						/>
					</EmptyButtonComponent>
				</Dropdown>
			</div>
		</TripItemContainer>
	);
}

var TripItemContainer = styled.div`
	display: flex;
	border-bottom: 1px solid ${gray.light};
	padding: 20px 4px 20px 20px;
	.trip-item {
		&__bottom {
			display: flex;
			justify-content: space-between;

			&-left {
				display: flex;
				align-items: center;
				.trip-count {
					width: 32px;
					height: 32px;
					margin-left: 4px;
					border-radius: 50%;
					display: flex;
					align-items: center;
					justify-content: center;
					background-color: ${gray.light};
				}
				.assign-button {
					padding: 0;
					position: relative;
					left: -4px;
				}
			}
			&-right {
				p {
					margin: 0;

					&:first-child {
						font-size: 25px;
					}

					&:last-child {
						font-size: 12px;
						color: ${gray.primary};
					}
				}
			}
		}
		&__left {
			padding-right: 24px;

			.ant-switch {
				border: 1px solid ${gray.light};
				background-color: ${white};
			}

			.ant-switch-checked {
				background-color: ${green.primary};
				border-color: #00b649;
			}
		}
		&__right {
			flex: 1;
			h1 {
				font-size: 18px;
				color: ${black};
				margin: 0;
				text-transform: capitalize;
			}
			p {
				font-size: 14px;
				margin: 0;
			}

			&__info {
				display: flex;
				justify-content: space-between;
				align-items: center;
				&-speed {
					display: flex;
					flex-direction: column;
					align-items: center;
					margin-left: 16px;

					h1 {
						font-size: 25px;
					}
				}
				&-location {
					display: flex;
					align-items: center;
					padding-top: 16px;

					div:first-child {
						margin-right: 8px;
					}

					p {
						width: 200px;
						text-overflow: ellipsis;
						white-space: nowrap;
						overflow: hidden;
					}

					p:first-child {
						padding-bottom: 8px;
					}
					.address-text {
						svg {
							margin-right: 8px;
						}
					}
				}
				&-asset {
					display: flex;
					align-items: center;
					padding-top: 16px;
					div:first-child {
						border: 1px solid ${gray.light};
						color: ${gray.primary};
						padding: 8px;
						margin-right: 8px;
						border-radius: 4px;
					}
					div:last-child {
						p {
							color: ${gray.ui02};
							font-size: 12px;
						}
					}
				}
			}
		}
	}
`;
