import { Checkbox, message } from "antd";
import { Formik } from "formik";
import React from "react";
import * as yup from "yup";
import { makeApiCallAdmin } from "../../../../api";
import { useSubscriptions } from "../../../../utils/hooks";
import { AppInput } from "../../../primitives/Input";
import { SaveAndCancelButtons } from "../../../primitives/SaveAndCancelButtons";
import { Label } from "../../../primitives/Text";

const validationSchema = yup.object().shape({
	odometer: yup.number().required("Odometer is required"),
	subscription: yup
		.array()
		.of(yup.string())
		.required("Select at least one subscription"),
});

function LinkAssetForm({ device, close }) {
	const { subscriptions = [] } = useSubscriptions();

	return (
		<Formik
			initialValues={{
				odometer: "",
				vin: device.vin,
				plateNo: device.plateNo,
				imei: device.imei,
				// Pre-select all compulsory subscriptions
				subscription: subscriptions.reduce(
					(acc, { isBasic, subId }) => (isBasic ? [...acc, subId] : acc),
					[]
				),
			}}
			onSubmit={async (values, { setSubmitting, resetForm }) => {
				try {
					await makeApiCallAdmin(`/Device/${device.deviceId}/Link`, {
						method: "put",
						data: {
							...values,
							subscription: values.subscription.map((subId) => ({ subId })),
						},
					});
					message.success("Device linked successfully");
					close();
				} catch (e) {
					message.error(e.message);
				} finally {
					setSubmitting(false);
				}
			}}
			validationSchema={validationSchema}
			enableReinitialize
		>
			{({
				values,
				touched,
				errors,
				handleChange,
				handleSubmit,
				setFieldValue,
				isSubmitting,
			}) => {
				return (
					<form className="asset-form" onSubmit={handleSubmit}>
						<AppInput
							name="imei"
							label="Device IMEI"
							value={values.imei}
							error={errors.imei && touched.imei}
							errorMessage={errors.imei}
							onChange={handleChange}
							disabled
						/>
						<AppInput
							name="vin"
							label="Vehicle Identification Number"
							value={values.vin}
							error={errors.vin && touched.vin}
							errorMessage={errors.vin}
							onChange={handleChange}
							disabled
						/>
						<AppInput
							name="plateNo"
							label="License Plate Number"
							value={values.plateNo}
							error={errors.plateNo && touched.plateNo}
							errorMessage={errors.plateNo}
							onChange={handleChange}
							disabled
						/>
						<AppInput
							name="odometer"
							label="Vehicle Odometer (km)"
							placeholder="Enter the current vehicle odometer"
							value={values.odometer}
							error={errors.odometer && touched.odometer}
							errorMessage={errors.odometer}
							onChange={handleChange}
							type="number"
						/>
						<Label className="mt-5 mb-2">Select Subscriptions</Label>
						<Checkbox.Group
							options={subscriptions.map(({ subId, subName, isBasic }) => ({
								label: subName,
								value: subId,
								// Prevent removing compulsory subscriptions
								disabled: isBasic,
							}))}
							value={values.subscription}
							onChange={(value) => setFieldValue("subscription", value)}
						/>
						<div style={{ marginTop: "40px" }}>
							<SaveAndCancelButtons
								successButtonAppearance="blue"
								saveText="Submit"
								cancelText="Cancel"
								onCancel={close}
								loading={isSubmitting}
							/>
						</div>
					</form>
				);
			}}
		</Formik>
	);
}

export default LinkAssetForm;
