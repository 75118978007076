import { Menu } from 'antd';
import * as React from 'react';
import { NavLink, Redirect, Route, Switch } from 'react-router-dom';
import { AppNavbar, SecondaryNavbar } from '../components/primitives/Navbar';
import DashboardAnalytics from './DashboardAnalytics';
import FleetSummary from './FleetSummary';
import { DashboardContainer } from './style';

function DashboardIndex({ match: { path } }) {
  return (
    <DashboardContainer>
      <AppNavbar />
      <SecondaryNavbar>
        <Menu mode="horizontal">
          <Menu.Item key="analytics">
            <NavLink to="/dashboard/analytics" activeClassName="active">
              Analytics
            </NavLink>
          </Menu.Item>
          <Menu.Item key="summary">
            <NavLink to="/dashboard/summary" activeClassName="active">
              Fleet Summary
            </NavLink>
          </Menu.Item>
        </Menu>
      </SecondaryNavbar>
      <Switch>
        <Redirect key="redirect" exact from="/dashboard" to="/dashboard/analytics" />
        <Route path={`${path}/analytics`} render={(props) => <DashboardAnalytics {...props} />} />
        <Route path={`${path}/summary`} render={(props) => <FleetSummary {...props} />} />
      </Switch>
    </DashboardContainer>
  );
}

export default DashboardIndex;
