import { message } from 'antd';
import useSWR from 'swr';
import { makeApiCallAdmin } from '../../../api';

function useHarshDriving() {
  const { data: harshDriving, error, revalidate } = useSWR(
    '/Dashboard/HarshDriving',
    fetchHarshDriving
  );

  return { harshDriving, loading: !harshDriving && !error, revalidate };
}

async function fetchHarshDriving(url) {
  try {
    const { data = {} } = await makeApiCallAdmin(url);
    return data;
  } catch (e) {
    message.error(e.message);
  }
}

export default useHarshDriving;
