import { Checkbox, Icon, message, Skeleton } from 'antd';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import styled from 'styled-components';
import { makeApiCall } from '../../../api';
import { black, blue, gray } from '../../design-system/colors';
import { EmptyButtonComponent } from '../../primitives/Buttons';
import { Text } from '../../primitives/Text';

const FolderItemDiv = styled.div`
  display: flex;
  align-items: center;
  padding: ${props => (props.isCollapsed ? '0' : '0 8px')};
  &:hover {
    background-color: rgba(85, 172, 238, 0.1);
    .FolderItem__icon {
      .anticon {
        color: ${blue.primary};
      }
    }
  }
  &:focus {
    background-color: rgba(85, 172, 238, 0.1);
    .FolderItem__icon {
      .anticon {
        color: ${blue.primary};
      }
    }
  }
  ${props =>
    props.isOpen
      ? `
    background-color: rgba(85, 172, 238, 0.1);
    .FolderItem__icon {
      .anticon {
        color: ${blue.primary}!important;
      }
    }`
      : ``}
  .FolderItem {
    &__button {
      display: flex;
      justify-content: space-between;
      padding: 8px 0;
      width: 100%;

      .ant-skeleton-title {
        margin-top: 0;
      }
    }
    &__check {
      margin-right: 15px;
    }
    &__info {
      display: flex;
    }
    &__name {
      display: flex;
      align-items: center;

      .Text {
        margin: 0;
        margin-left: 8px;
        opacity: ${props => (props.isCollapsed ? 0 : 1)};
        transition: opacity 0.2s ease-in-out;
        will-change: opacity;
      }
    }
    &__icon {
      .anticon {
        color: ${gray.light};
      }
    }
  }
`;

const FolderItemContainer = styled.div`
  cursor: pointer;
  .FolderItem__children {
    padding-left: 16px;
  }
`;

export class FolderItem extends Component {
  state = {
    isOpen: false,
    checked: false,
    folders: [],
    assets: []
  };

  onChecked = e => {
    this.setState(
      ({ checked }) => ({
        checked: !checked
      }),
      () => {
        const { setAssetsOnParent } = this.props;
        const { checked } = this.state;
        if (checked) {
          this.getNodeAssets(e.target.value.id, e.target.value.name);
        } else {
          this.setState(
            {
              assets: []
            },
            () => setAssetsOnParent(this.state.assets, e.target.value.id)
          );
        }
      }
    );
  };

  openFolder = () => {
    const { folders } = this.state;
    this.setState(({ isOpen }) => ({
      isOpen: !isOpen
    }));
    if (folders.length === 0) {
      this.getNodeFolders();
    }
  };

  getNodeFolders = () => {
    const { NodeId, clientId } = this.props;
    const url = `Node/Children/Folder/List/${NodeId}/${clientId}`;
    makeApiCall(url)
      .then(data => {
        const { Nodes } = data;
        if (Nodes.length > 0) {
          this.setState({
            folders: Nodes
          });
        } else {
          this.setState({
            isOpen: false
          });
        }
      })
      .catch(error => message.error('An error occured'));
  };

  getNodeAssets = (id, name) => {
    const { setAssetsOnParent, populateSelectedFolder, clientId } = this.props;
    const url = `Node/Children/Assets/List/${id}/${clientId}`;
    makeApiCall(url)
      .then(data => {
        let { Nodes } = data;
        this.setState(
          {
            assets: Nodes.map(data => ({
              ...data,
              ParentNodeId: id,
              SelectedFolderName: name
            }))
          },
          () => {
            setAssetsOnParent(this.state.assets);
            populateSelectedFolder(id, name);
          }
        );
      })
      .catch(error => console.log(error));
  };

  renderChildren = () => {
    const { folders } = this.state;
    const { setAssetsOnParent, populateSelectedFolder, clientId } = this.props;
    if (folders.length > 0) {
      return (
        <React.Fragment>
          {folders.map((folder, i, array) => (
            <FolderItem
              key={`${i.toString()}-${folder.NodeId}`}
              clientId={clientId}
              setAssetsOnParent={setAssetsOnParent}
              populateSelectedFolder={populateSelectedFolder}
              {...folder}
            />
          ))}
        </React.Fragment>
      );
    }
  };

  render() {
    const {
      NodeId,
      NodeDesc,
      loading,
      isParent,
      isCollapsed,
      showArrow = true,
      showCheckbox = true,
      onClick
    } = this.props;
    const { isOpen, checked } = this.state;
    const iconArrow = isOpen ? 'down' : 'right';
    return (
      <FolderItemContainer onClick={onClick}>
        <FolderItemDiv
          className="FolderItem"
          isOpen={isOpen}
          isParent={isParent}
          isCollapsed={isCollapsed}
        >
          {showCheckbox && (
            <div className="FolderItem__check">
              <Checkbox
                value={{ id: NodeId, name: NodeDesc }}
                onChange={this.onChecked}
                checked={checked}
              />
            </div>
          )}
          <EmptyButtonComponent className="FolderItem__button" onClick={this.openFolder}>
            <Skeleton active loading={loading} paragraph={false}>
              <div className="FolderItem__info">
                <div className="FolderItem__name">
                  <img src="/static/img/folder.svg" style={isParent ? {} : { width: '14px' }} />
                  {!isCollapsed && (
                    <Text className="Text" color={black}>
                      {NodeDesc}
                    </Text>
                  )}
                </div>
              </div>
              {showArrow && !isCollapsed && (
                <div className="FolderItem__icon">
                  <Icon type={iconArrow} theme="outlined" />
                </div>
              )}
            </Skeleton>
          </EmptyButtonComponent>
        </FolderItemDiv>
        {isOpen && <div className="FolderItem__children">{this.renderChildren()}</div>}
      </FolderItemContainer>
    );
  }
}

FolderItem.propTypes = {
  NodeId: PropTypes.string,
  NodeDesc: PropTypes.string,
  IsNodeLeaf: PropTypes.bool,
  isParent: PropTypes.bool,
  loading: PropTypes.bool,
  setAssetsOnParent: PropTypes.func,
  populateSelectedFolder: PropTypes.func
};
