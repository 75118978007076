import { Skeleton } from 'antd';
import { isEmpty } from 'lodash';
import moment from 'moment';
import * as React from 'react';
import { Heading, Text } from '../components/primitives/Text';
import { useHarshDriving } from '../utils/hooks';
import { DashboardParameterItem } from './style';

function HarshDriving() {
  const { harshDriving = {}, loading } = useHarshDriving();

  return (
    <div className="parameter__list">
      <Heading>Harsh Driving </Heading>
      <Skeleton active loading={loading}>
        {isEmpty(harshDriving) ? (
          <p>Data unavailable</p>
        ) : (
          harshDriving.map((item) => (
            <DashboardParameterItem className="parameter__item" key={item.plateNo}>
              <div className="section">
                <Text className="first">{item.plateNo}</Text>
                <Text className="last">{item.driverName}</Text>
              </div>
              <div className="section">
                <Text className="first">Timestamp</Text>
                <Text className="last">{moment.unix(item.time).format('DD.MM.YYYY, hh:mm a')}</Text>
              </div>
            </DashboardParameterItem>
          ))
        )}
      </Skeleton>
    </div>
  );
}

export default HarshDriving;
