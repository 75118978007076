import { message } from 'antd';
import useSWR from 'swr';
import { makeApiCallAdmin } from '../../api';

function useUsers() {
  const { data: users, error, revalidate } = useSWR('/Account', fetchUsers);

  return { users, loading: !users && !error, revalidate };
}

async function fetchUsers(url) {
  try {
    const { data = [] } = await makeApiCallAdmin(url);

    // Make the Owner account appear at the top
    data.unshift(
      data.splice(
        data.findIndex(({ roleName }) => roleName === 'Owner'),
        1
      )[0]
    );

    return data;
  } catch (e) {
    message.error(e.message);
    throw new Error(e.message);
  }
}

export default useUsers;
