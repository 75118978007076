import { message } from 'antd';
import useSWR from 'swr';
import { makeApiCallAdmin } from '../../api';

function useCostPlans() {
  const { data: costPlans, error, revalidate } = useSWR('/CostPlanAgent', fetchCostPlans);

  return { costPlans, loading: !costPlans && !error, revalidate };
}

async function fetchCostPlans() {
  try {
    const { data = [] } = await makeApiCallAdmin(`/CostPlanAgent`);
    return data;
  } catch (e) {
    message.error(e.message);
  }
}

export default useCostPlans;
