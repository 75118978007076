import styled from 'styled-components';
import { customFonts } from '../components/design-system/fonts';
import { FleetOverviewContainer } from '../components/pages/Fleet';

export const DashboardContainer = styled(FleetOverviewContainer)`
  .Dashboard__content {
    margin: 0 auto;
    max-width: 1400px;
    padding-top: 128px;
    padding-left: 32px;
    padding-right: 32px;

    h1 {
      font-family: ${customFonts.circular}!important;
      font-size: 18px;
    }

    &__numbers {
      margin-top: 32px;
      padding: 50px;
      background-color: rgba(246, 246, 246, 0.5);

      &__card {
        margin-top: 32px;
        border-radius: 1px;
        background-color: rgba(246, 246, 246, 0.5);
        padding: 40px 80px;

        .number-col {
          border-right: 1px solid rgba(0, 0, 0, 0.1);

          @media (max-width: 767px) {
            border-right: none;
          }

          &:last-child {
            border-right: none;
          }
        }

        .number {
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          h1 {
            font-size: 40px;
            margin: 0;
          }

          p {
            margin: 0;
          }
        }
      }
    }

    &__charts {
      margin-bottom: 50px;
      padding: 50px;
      padding-top: 0;
      background-color: rgba(246, 246, 246, 0.5);

      .doughnut-chart {
        padding-top: 48px;
      }
      .bar-chart {
        padding-top: 32px;
      }
    }
  }
`;

export const DashboardParameterItem = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 12px;
  border-bottom: solid 1px rgba(144, 164, 183, 0.22);

  .section {
    &.flexed {
      display: flex;
    }
    p {
      margin: 0;
      font-size: 14px;
    }
    p.first {
      color: #000;
      padding-bottom: 4px;
    }
    p.last {
      color: #707d8d;
      padding-bottom: 8px;
    }
  }
`;

export const ViolationsContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(400px, 1fr));
  column-gap: 20px;
  row-gap: 30px;
  margin-bottom: 50px;

  .parameter__list {
    padding: 40px;
    box-shadow: 0 0 2px 0 rgba(67, 90, 111, 0.47);

    h1 {
      padding-bottom: 24px;
    }
    .parameter__item {
      &:last-of-type {
        border-bottom: none;
      }
    }
  }
`;
