import { Icon, message, Collapse, Row, Col } from "antd";
import React, { useState, useRef, useCallback, useMemo } from "react";
import styled from "styled-components";
import { makeApiCallAdmin } from "../../../api";
import { useCommands } from "../../../utils/hooks";
import { customFonts } from "../../design-system/fonts";
import { green } from "../../design-system/colors";
import { formatDate } from "../../../utils/helpers";
import {
	ButtonComponent,
	EmptyButtonComponent,
} from "../../primitives/Buttons";
import { Heading } from "../../primitives/Text";

const { Panel } = Collapse;

function DeviceCommandsPanel({ asset }) {
	const [isCollapsed, setCollapsed] = React.useState(false);
	const [activeKey, setActiveKey] = useState("1");
	// const [deviceResponse, setdeviceResponse] = useState(null);

	const [isSendingCommand, setIsSendingCommand] = React.useState(false);
	const { commands = [], revalidate: refetchCommands } = useCommands(
		asset.productId,
		asset.plateNo
	);

	const deviceResponseRef = useRef(null);
	const responseIntervalRef = useRef(null);

	const deviceResponse = useMemo(() => {
		return deviceResponseRef.current;
	}, [deviceResponseRef.current]);

	const toggleCollapse = () => setCollapsed(!isCollapsed);

	const sendCommand = async (command) => {
		try {
			const result = await makeApiCallAdmin("/AssetCommand", {
				method: "post",
				data: {
					plateNo: asset.plateNo,
					productId: asset.productId,
					imei: asset.imei,
					commandParamId: command.commandParamId,
				},
			});

			if (result) {
				getDeviceUpdate(result.data.id, command);
			}
			await refetchCommands();
			message.success(`Command (${command.commandName}) sent successfully`);
		} catch (error) {
			message.error(error.message);
		}
	};

	const cancelCommand = async (command) => {
		try {
			await makeApiCallAdmin(`/AssetCommand/${command.commandInboxId}/Cancel`, {
				method: "put",
			});
			await refetchCommands();
			message.success(`Command (${command.commandName}) cancelled`);
		} catch (error) {
			message.error(error.message);
		}
	};

	const sendDeviceCommand = async (command) => {
		setIsSendingCommand(true);
		if (command) {
			await sendCommand(command);
			setIsSendingCommand(false);
		}
	};

	const cancelDeviceCommand = async (command) => {
		setIsSendingCommand(true);
		await cancelCommand(command);
		setIsSendingCommand(false);
	};

	const changePanel = (key) => {
		setActiveKey(key);
	};

	const getDeviceUpdate = useCallback((inboxId, command) => {
		let deviceStatus;
		responseIntervalRef.current = responseIntervalRef.current + 1;
		if (responseIntervalRef.current >= 20) return;
		makeApiCallAdmin(`/AssetCommand/Inbox/${inboxId && inboxId}`, {
			method: "get",
		})
			.then((results) => {
				if (results) {
					deviceStatus = results.data;
					deviceResponseRef.current = deviceStatus;
					console.log(results, "==");

					let statusCheck = deviceStatus.status.toLowerCase() !== "sent";

					if (statusCheck) {
						console.log(
							responseIntervalRef.current,
							"status counter if",
							deviceStatus.status.toLowerCase()
						);

						getDeviceUpdate(inboxId, command);
					} else {
						console.log(responseIntervalRef.current, "status counter ");
						// clearInterval(responseIntervalRef.current);
					}

					return null;
				}
			})
			.catch((err) => console.log(err))
			.finally(() => {
				return null;
			});
	}, []);

	const isAllCommandMsgReady = commands.every(
		(command) => !command.isCommandMsgPending
	);

	return (
		<DeviceCommandsPanelContainer
			isCollapsed={isCollapsed}
			className="px-3 pt-5 pb-16 border-0 border-l border-solid border-black"
		>
			<Heading className="font-bold m-0 text-base mb-4">
				Device Commands
			</Heading>
			<div className="overflow-y-auto h-full">
				<Collapse
					defaultActiveKey={[activeKey]}
					onChange={changePanel}
					accordion
				>
					{commands.map((command) => {
						if (command.isCommandMsgPending) {
							return (
								<Panel
									header={command.commandName}
									key={command.commandParamId}
								>
									<h3 className="command-heading">Message Sent</h3>
									<p>{command.description}</p>

									<div className=" status-container">
										<h3 className="command-heading">Device Response</h3>
										{getDeviceUpdate(command.commandInboxId, command)}

										{deviceResponse === null ? null : (
											<div>
												<Row>
													<Col md={10}>
														<p className="status-heading">Status:</p>
													</Col>
													<Col md={12}>
														<p
															className={
																"status-body " +
																(deviceResponse.status.toLowerCase() ===
																"pending"
																	? "warning-text"
																	: deviceResponse.status.toLowerCase() ===
																	  "sent"
																	? "success-text"
																	: "success-text")
															}
														>
															{deviceResponse.status}
														</p>
													</Col>
													<Col md={10}>
														<p className="status-heading">Remark:</p>{" "}
													</Col>
													<Col md={12}>
														<p>
															{deviceResponse.deviceResponse === ""
																? "No Remark"
																: deviceResponse.deviceResponse}
														</p>
													</Col>
													<Col md={10}>
														<p className="status-heading">Queue Date:</p>{" "}
													</Col>
													<Col md={12}>
														<p>{formatDate(deviceResponse.queueDate)}</p>
													</Col>
													<Col md={10}>
														<p className="status-heading">Response Time:</p>
													</Col>
													<Col md={12}>
														<p>{formatDate(deviceResponse.responseDate)}</p>
													</Col>
													<Col md={10}>
														<p className="status-heading">Sent Time:</p>
													</Col>
													<Col md={12}>
														<p>{formatDate(deviceResponse.sentDate)}</p>
													</Col>
												</Row>
											</div>
										)}
									</div>
									<div className="flex">
										{command.isCommandMsgPending ? (
											<ButtonComponent
												appearance="gray"
												className="w-full"
												loading={isSendingCommand}
												onClick={(command) => cancelDeviceCommand(command)}
												style={{ height: "32px" }}
												title="Command in queue"
											>
												Cancel Command
											</ButtonComponent>
										) : (
											<ButtonComponent
												appearance="green"
												className="w-full "
												loading={isSendingCommand}
												onClick={() => sendDeviceCommand(command)}
												style={{ height: "32px" }}
												disabled={!isAllCommandMsgReady}
											>
												Send Command
											</ButtonComponent>
										)}
									</div>
								</Panel>
							);
						}
						return (
							<Panel header={command.commandName} key={command.commandParamId}>
								<h3 className="command-heading">Message Sent</h3>
								<p>{command.description}</p>
								<div className="flex">
									{command.isCommandMsgPending ? (
										<ButtonComponent
											appearance="gray"
											className="w-full"
											loading={isSendingCommand}
											onClick={(command) => cancelDeviceCommand(command)}
											style={{ height: "32px" }}
											title="Command in queue"
										>
											Cancel Command
										</ButtonComponent>
									) : (
										<ButtonComponent
											appearance="green"
											className="w-full "
											loading={isSendingCommand}
											onClick={() => sendDeviceCommand(command)}
											style={{ height: "32px" }}
											disabled={!isAllCommandMsgReady}
										>
											Send Command
										</ButtonComponent>
									)}
								</div>
							</Panel>
						);
					})}
				</Collapse>
			</div>
			<EmptyButtonComponent
				className="toggle-button"
				onClick={toggleCollapse}
				title={isCollapsed ? "Show device commands" : "close"}
			>
				{isCollapsed ? <Icon type="left" /> : <Icon type="right" />}
			</EmptyButtonComponent>
		</DeviceCommandsPanelContainer>
	);
}

var DeviceCommandsPanelContainer = styled.div`
	position: absolute;
	top: 130px;
	right: 0;
	bottom: 0;
	width: 300px;
	transform: ${(props) =>
		props.isCollapsed ? `translateX(300px)` : `translateX(0)`};
	min-height: calc(100vh - 130px);
	background-color: #fdfdfd;
	box-shadow: 0 3px 5px 0 rgba(31, 49, 74, 0.1);
	transition: transform 0.3s ease-in-out;
	will-change: width;
	padding-bottom: 100px;
	font-family: ${customFonts.circular}!important;
	.status-container{
		margin-top: 1.5rem;
		.status-heading {
			font-weight: 600;
			margin-bottom0;
		}
		.status-body {
			margin-bottom: 1rem;
			font-weight:600;
		}
		
		.status-body.warning-text{
			color: #ed6347;

		}
		.status-body.success-text{
			color: #00b649;
			
		}
	}
	.command-heading{
		font-weight:600;
		font-size:14px;
		background: ${green.primary};
		color: #fff;
		padding: 10px;
    border-radius: 3px;
	}

	.toggle-button {
		position: absolute;
		top: 50px;
		left: -39px;
		background: #fdfdfd;
		padding: 12px;
		border: 1px solid black;
		border-right: none;
	}

	.flex {
		display: flex !important;
	}
	.ant-collapse-header {
		font-weight: 600;
	}
`;

export default DeviceCommandsPanel;
