import { Divider, message, Spin } from 'antd';
import { FieldArray, Formik, getIn } from 'formik';
import * as React from 'react';
import useSWR from 'swr';
import * as yup from 'yup';
import { makeApiCallAdmin } from '../../api';
import { AppInput } from '../../components/primitives/Input';
import { SaveAndCancelButtons } from '../../components/primitives/SaveAndCancelButtons';
import { Text } from '../../components/primitives/Text';
import { arrayToObject } from '../../utils/helpers';
import useProfile from '../../utils/hooks/useProfile';

function NewCostPlanForm({ close, isEdit = false, costPlan }) {
  const user = useProfile();
  const { data: allSubscriptions, errorOne } = useSWR(
    `/CostPlanAdmin/${user.costPlanId}/Subscription`,
    fetchSubscriptions
  );

  const { data: constPlanSubscriptions, errorTwo } = useSWR(
    // Don't fetch if this is a new cost plan
    isEdit
      ? allSubscriptions
        ? /**
           * We need to wait for allSubscriptions to finish loading before loading the ones for the
           * cost plan being edited (allSubscriptions is needed to get default amount for each sub)
           */
          [`/CostPlanAgent/${costPlan.costPlanId}/Subscription`, allSubscriptions]
        : null
      : null,
    fetchCostPlanSubscriptions
  );

  const subscriptions = !isEdit ? allSubscriptions : constPlanSubscriptions;

  return (
    <Spin spinning={!subscriptions && !errorOne && !errorTwo}>
      <Formik
        initialValues={{
          costPlanName: isEdit ? costPlan.costPlanName : '',
          costPlanDesc: isEdit ? costPlan.costPlanDesc : '',
          subscriptionTypes: subscriptions || [],
        }}
        validationSchema={validationSchema}
        enableReinitialize
        onSubmit={async (values, actions) => {
          try {
            await makeApiCallAdmin(`/CostPlanAgent/${isEdit ? costPlan.costPlanId : ''}`, {
              method: isEdit ? 'put' : 'post',
              data: extractPayload(values),
            });
            message.success(`Cost plan ${isEdit ? 'updated' : 'created'} successfully`);
            close();
          } catch (e) {
            message.error(e.message);
          } finally {
            actions.setSubmitting(false);
          }
        }}
      >
        {({ values, touched, errors, handleChange, handleSubmit, isSubmitting }) => (
          <form onSubmit={handleSubmit} className="">
            <label htmlFor="costPlanName" className="font-semibold">
              Cost Plan Name
            </label>
            <AppInput
              name="costPlanName"
              value={values.costPlanName}
              type="text"
              error={touched.costPlanName && errors.costPlanName}
              errorMessage={errors.costPlanName}
              onChange={handleChange}
              marginTop="0.5rem"
              className="max-w-sm mb-6 mt-2"
            />
            <label htmlFor="costPlanDesc" className="font-semibold">
              Cost Plan Description
            </label>
            <AppInput
              name="costPlanDesc"
              value={values.costPlanDesc}
              type="text"
              error={touched.costPlanDesc && errors.costPlanDesc}
              errorMessage={errors.costPlanDesc}
              onChange={handleChange}
              marginTop="0.5rem"
              className="max-w-sm mt-2"
            />
            <Divider orientation="left" style={{ margin: '40px 0' }}>
              <Text fontSize="18px" marginBottom={0}>
                Subscriptions
              </Text>
            </Divider>
            <FieldArray>
              {() => {
                return (
                  <ul className="mb-8 pl-0">
                    <div className="flex mb-4">
                      <div className="max-w-sm flex-grow"></div>
                      <p className="font-semibold mx-16 mb-0">Cost Price</p>
                      <p className="font-semibold ml-auto mb-0 w-24">Retail Price</p>
                    </div>
                    {values.subscriptionTypes.map((subscription, index) => {
                      const isInvalid = getIn(errors, `subscriptionTypes.${index}.costPlanAmount`);

                      return (
                        <li key={subscription.subName + index} className="flex items-center mb-4">
                          <p className="max-w-sm flex-grow font-semibold capitalize mb-0">
                            {subscription.subName}
                          </p>
                          <p className="mx-16 mb-0">{subscription.defaultAmount}</p>
                          <AppInput
                            name={`subscriptionTypes.${index}.costPlanAmount`}
                            value={subscription.costPlanAmount}
                            type="number"
                            error={isInvalid}
                            onChange={handleChange}
                            marginTop="0"
                            className="w-24 ml-auto"
                          />
                        </li>
                      );
                    })}
                  </ul>
                );
              }}
            </FieldArray>
            <SaveAndCancelButtons onCancel={close} loading={isSubmitting} />
          </form>
        )}
      </Formik>
    </Spin>
  );
}

function extractPayload({ costPlanName, costPlanDesc, subscriptionTypes }) {
  return {
    costPlanName,
    costPlanDesc,
    subscriptionTypes: subscriptionTypes.map(({ subId, costPlanAmount, billingFrequency }) => ({
      subId,
      costPlanAmount,
      billingFrequency,
    })),
  };
}

var fetchSubscriptions = async (url) => {
  try {
    const { data } = await makeApiCallAdmin(url);
    // duplicate defaultAmount for comparison when setting a new value in the form
    const subscriptions = data.map((sub) => ({
      ...sub,
      costPlanAmount: sub.defaultAmount,
    }));
    return subscriptions;
  } catch (e) {
    message.error(e.message);
  }
};

var fetchCostPlanSubscriptions = async (url, allSubscriptions) => {
  try {
    const { data } = await makeApiCallAdmin(url);
    /**
     * Fetching the subscriptions for a specific cost plan doesn't return the default amount
     * So I use the default amount from the cost plan as defined by an admin for this agent
     * If it's not there for any reason, we use the currenly set costPlanAmount
     */
    const allSubscriptionsObject = arrayToObject(allSubscriptions, 'subId');
    const combinedSubscriptionsObject = arrayToObject([...allSubscriptions, ...data], 'subId');
    return Object.values(combinedSubscriptionsObject).map((sub) => ({
      ...sub,
      defaultAmount: allSubscriptionsObject[sub.subId]
        ? allSubscriptionsObject[sub.subId].defaultAmount
        : sub.costPlanAmount,
    }));
  } catch (e) {
    message.error(e.message);
  }
};

const validationSchema = yup.object().shape({
  costPlanName: yup.string().required('Please enter a name for this cost plan'),
  costPlanDesc: yup.string().required('Please enter cost plan description'),
  subscriptionTypes: yup.array().of(
    yup.object().shape({
      subId: yup.string().required(),
      costPlanAmount: yup.number().required(),
      billingFrequency: yup.number().required(),
    })
  ),
});

export default NewCostPlanForm;
