import { DatePicker } from 'antd';
import React from 'react';

export default class DateRange extends React.Component {
  state = { endOpen: false };

  disabledStartDate = (startDate) => {
    const endDate = this.props.endDate;
    if (!startDate || !endDate) {
      return false;
    }
    return startDate.valueOf() > endDate.valueOf();
  };

  disabledEndDate = (endDate) => {
    const startDate = this.props.startDate;
    if (!endDate || !startDate) {
      return false;
    }
    return endDate.valueOf() <= startDate.valueOf();
  };

  onStartChange = (value) => {
    this.props.onInputChange('startDate', value);
  };

  onEndChange = (value) => {
    this.props.onInputChange('endDate', value);
  };

  handleStartOpenChange = (open) => {
    if (!open) {
      this.setState({ endOpen: true });
    }
  };

  handleEndOpenChange = (open) => {
    this.setState({ endOpen: open });
  };

  render() {
    const { endOpen } = this.state;
    const { startDate, endDate, submit } = this.props;
    return (
      <React.Fragment>
        <DatePicker
          disabledDate={this.disabledStartDate}
          format="DD/MM/YYYY HH:mm:ss"
          showTime
          value={startDate}
          placeholder="Start"
          onChange={this.onStartChange}
          onOpenChange={this.handleStartOpenChange}
          size="large"
          allowClear={false}
        />
        <DatePicker
          disabledDate={this.disabledEndDate}
          format="DD/MM/YYYY HH:mm:ss"
          showTime
          value={endDate}
          placeholder="End"
          onChange={this.onEndChange}
          open={endOpen}
          onOpenChange={this.handleEndOpenChange}
          size="large"
          allowClear={false}
          {...(submit && { onOk: submit })}
        />
      </React.Fragment>
    );
  }
}
