import React from 'react';
import { Select as SelectComponent } from 'antd';

const Option = SelectComponent.Option;

export default ({ onInputChange, timeUnit }) => (
  <SelectComponent
    placeholder="Select Time Unit"
    onChange={e => onInputChange('timeUnit', e)}
    value={timeUnit}
    size="large"
  >
    <Option value={0}>Minutes</Option>
    <Option value={1}>Hours</Option>
    <Option value={2}>Days</Option>
  </SelectComponent>
);
