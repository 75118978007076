import { message } from 'antd';
import useSWR from 'swr';
import { makeApiCallAdmin } from '../../api';

function useRoles() {
  const { data: roles, error, revalidate } = useSWR('/roleAgent', fetchRoles);

  return { roles, loading: !roles && !error, revalidate };
}

async function fetchRoles() {
  try {
    const { data = [] } = await makeApiCallAdmin('/roleAgent');
    return data;
  } catch (e) {
    message.error(e.message);
    throw new Error(e.message);
  }
}

export default useRoles;
