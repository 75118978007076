import { message } from "antd";
import { Formik } from "formik";
import React from "react";
import styled from "styled-components";
import * as yup from "yup";
import { makeApiCall } from "../../../api";
import { useProfile } from "../../../utils/hooks";
import { ButtonComponent } from "../../primitives/Buttons";
import { AppInput as Description } from "../../primitives/Input";
import { Heading } from "../../primitives/Text";
import TripsMap from "../../primitives/TripsMap";
import LocationInput from "./LocationInput";

const TripFormValidation = yup.object().shape({
	Description: yup.string().required("Trip description is required"),
	PointAMarker: yup.object().required("Select start location to continue"),
	PointAAddress: yup
		.string()
		.required("Enter start location description to continue"),
	PointBMarker: yup.object().required("Select end location to continue"),
	PointBAddress: yup
		.string()
		.required("Enter end location description to continue"),
});

const ButtonsContainer = styled.div`
	display: flex;
	margin-top: 20px;

	button {
		&:first-of-type {
			margin-right: 16px;
		}
	}
`;

const TripFormContainer = styled.form`
	max-width: 700px;
`;

function CreateTrip({ cancel, createTrip, isEdit = false, initialValues }) {
	const { clientId, id } = useProfile();

	return (
		<Formik
			initialValues={
				isEdit
					? initialValues
					: {
							Description: "",
							PointAMarker: "",
							PointBMarker: "",
							PointAAddress: "",
							PointBAddress: "",
							PointA: [],
							PointB: [],
							SearchAddrA: "",
							SearchAddrB: "",
					  }
			}
			onSubmit={async (values, actions) => {
				const payload = {
					...values,
					Status: true,
				};
				try {
					let { StatusCode, Description } = await makeApiCall(
						`Trip/${isEdit ? "Update" : "Create"}`,
						"post",
						{},
						{ ...payload, ClientId: clientId, LoginUserId: id }
					);
					if (StatusCode === "00") {
						message.success(Description);
						cancel();
					} else {
						throw new Error(Description);
					}
				} catch (error) {
					message.error(error.message);
				} finally {
					actions.setSubmitting(false);
				}
			}}
			validationSchema={TripFormValidation}
			render={({
				values,
				errors,
				touched,
				handleChange,
				submitForm,
				resetForm,
				setFieldValue,
				isSubmitting,
			}) => {
				const updatePointAMarker = ({ location: { lat, lng }, address }) => {
					setFieldValue("PointAMarker", { Latitude: lat, Longitude: lng });
					address && setFieldValue("SearchAddrA", address);
				};

				const updatePointBMarker = ({ location: { lat, lng }, address }) => {
					setFieldValue("PointBMarker", { Latitude: lat, Longitude: lng });
					address && setFieldValue("SearchAddrB", address);
				};

				const updatePointA = (latLngBounds) => {
					setFieldValue("PointA", latLngBounds);
				};

				const updatePointB = (latLngBounds) => {
					setFieldValue("PointB", latLngBounds);
				};

				return (
					<React.Fragment>
						<TripFormContainer>
							<Heading>Create Trip</Heading>
							<Description
								name="Description"
								type="text"
								value={values.Description}
								placeholder="Enter Trip Name"
								size="large"
								onChange={handleChange}
								error={touched.Description && errors.Description}
								errorMessage={errors.Description}
							/>
							<LocationInput
								geoSuggestPlaceholder="Point A - Type an address to search"
								geoSuggestInputValue={values.SearchAddrA}
								customNamePlaceholder="Enter custom name for Point A"
								onLocationChange={(res) =>
									res && updatePointAMarker({ location: res.location })
								}
								addressInputName="PointAAddress"
								addressInvalid={errors.PointAAddress && touched.PointAAddress}
								locationInvalid={errors.PointAMarker && touched.PointAMarker}
								onAddressChange={handleChange}
								address={values.PointAAddress}
								point="start"
							/>
							<LocationInput
								geoSuggestPlaceholder="Point B - Type an address to search"
								geoSuggestInputValue={values.SearchAddrB}
								customNamePlaceholder="Enter custom name for Point B"
								onLocationChange={(res) =>
									res && updatePointBMarker({ location: res.location })
								}
								addressInputName="PointBAddress"
								addressInvalid={errors.PointBAddress && touched.PointBAddress}
								locationInvalid={errors.PointBMarker && touched.PointBMarker}
								onAddressChange={handleChange}
								address={values.PointBAddress}
								point="end"
							/>
						</TripFormContainer>
						<TripsMap
							PointAMarker={values.PointAMarker}
							PointBMarker={values.PointBMarker}
							updatePointAMarker={updatePointAMarker}
							updatePointBMarker={updatePointBMarker}
							updatePointA={updatePointA}
							updatePointB={updatePointB}
						/>
						<ButtonsContainer>
							<ButtonComponent
								css="width: auto"
								appearance="green"
								htmlType="button"
								onClick={submitForm}
								loading={isSubmitting}
							>
								Save
							</ButtonComponent>
							<ButtonComponent
								onClick={() => {
									resetForm();
									cancel();
								}}
							>
								Cancel
							</ButtonComponent>
						</ButtonsContainer>
					</React.Fragment>
				);
			}}
		/>
	);
}

export default CreateTrip;
