import { Button } from 'antd';
import React from 'react';
import styled from 'styled-components';
import { black, blue, gray, green, white } from '../design-system/colors';
import { customFonts } from '../design-system/fonts';

const generateSize = (size) => {
  if (size === 'large') {
    return `height: 60px; padding: 0 30px; font-size: 16px;`;
  }
  if (size === 'small') {
    return `height: 24px; padding: 0 12px; font-size: 15px;`;
  }
  return `height: 40px; padding: 0 20px; font-size: 15px;`;
};

const generatebgColor = (appearance, disabled) => {
  if (appearance === 'green') {
    return `background-color: ${green.primary}; border: 1px solid ${green.primary}; color: ${white} `;
  }
  if (appearance === 'blue') {
    return `background-color: ${blue.primary}; border: 1px solid ${blue.primary}; color: ${white} `;
  }
  if (appearance === 'blue' && disabled) {
    return `background-color: ${blue.disabled}!important; border: 1px solid ${blue.disabled}!important; color: ${white}important; `;
  }
  if (appearance === 'gray') {
    return `background-color: ${gray.ui02}; border: 1px solid ${gray.ui02}; color: ${white}`;
  }
  if (appearance === 'light') {
    return `background-color: ${gray.light}; border: 1px solid ${gray.light}; color: #3b3737`;
  }
  return `background-color: none; border: 1px solid ${black}; color: ${black}`;
};

export const PrimaryButton = ({ children, ...rest }) => (
  <Button type="primary" {...rest}>
    {children}
  </Button>
);

// Using this to pass the className property from styled-components
const SomeButton = ({ className, children, onClick, ...rest }) => {
  return (
    <Button className={className} onClick={onClick} {...rest}>
      {children}
    </Button>
  );
};

export const ButtonComponent = styled(SomeButton)`
  ${(props) => generateSize(props.size)};
  ${(props) => generatebgColor(props.appearance, props.disabled)};
  margin-top: ${(props) => props.marginTop};
  ${(props) => (props.fullWidth ? `width: 100%` : ``)};
  cursor: pointer;
  border-radius: 0;
  box-shadow: none;
  outline: none;
  display: block;
  font-family: ${customFonts.circular}!important;
  ${(props) => props.css};

  &:hover,
  &:focus {
    ${(props) => generatebgColor(props.appearance)};
  }

  &:disabled {
    cursor: not-allowed;
    /* background-color: ${blue.disabled}!important;
    border: 1px solid ${blue.disabled}!important;
    color: ${white}!important; */
  }
`;

export const EmptyButtonComponent = styled.button`
  margin-top: ${(props) => props.marginTop};
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
  border-radius: 0;
  box-shadow: none;
  outline: none;
  border: none;
  background-color: transparent;
  font-family: '${customFonts.circular}', system-ui, sans-serif !important;
  color: ${(props) => props.color};
  ${(props) => props.css};
`;
