import React from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import { AppNavbar } from "../components/primitives/Navbar";
import { AppContext } from "../DataProvider";
import Drivers from "../Drivers/index";
import { Trips } from "../Trips";
import AssetAssignPage from "./AssetAssign";
import AssetAssignAlerts from "./AssetAssignAlerts";
import Assets from "./Assets";
import Devices from "./Devices";
import { FleetInsightPage } from "./FleetInsight";
import FleetOverview from "./FleetOverview";
import { FleetProvider } from "./FleetProvider";

export class FleetIndex extends React.Component {
	static contextType = AppContext;

	render() {
		const {
			match: { path },
		} = this.props;
		const { user, clientId, updateState, getAllOrphanAssets } = this.context;
		return (
			<FleetProvider getAllOrphanAssets={getAllOrphanAssets}>
				{({
					nodesWithChildren,

					buttonLoading: loadingState,
				}) => (
					<React.Fragment>
						<AppNavbar />
						<Switch>
							<Redirect
								key="redirect"
								exact
								from="/fleet"
								to="/fleet/overview"
							/>
							<Route
								exact
								path={`${path}/overview`}
								render={(props) => <FleetOverview />}
							/>
							<Route
								exact
								path={`${path}/overview/:id`}
								render={(props) => (
									<FleetInsightPage {...props} clientId={clientId} />
								)}
							/>
							<Route
								exact
								path={`${path}/drivers`}
								render={(props) => <Drivers />}
							/>
							<Route
								exact
								path={`${path}/trips`}
								render={(props) => (
									<Trips
										{...props}
										clientId={clientId}
										updateState={updateState}
										nodesWithChildren={nodesWithChildren}
									/>
								)}
							/>
							<Route
								exact
								path={`${path}/assign/:id`}
								render={() => <AssetAssignPage />}
							/>
							<Route
								exact
								path={`${path}/assign/:id/:alertType`}
								render={() => <AssetAssignAlerts />}
							/>
							<Route
								exact
								path={`${path}/asset-management`}
								render={(props) => <Assets {...props} />}
							/>
							{user.userType === 1 && (
								<Route
									exact
									path={`${path}/device-management`}
									render={(props) => <Devices />}
								/>
							)}
						</Switch>
					</React.Fragment>
				)}
			</FleetProvider>
		);
	}
}
