import { Button, Divider, Icon, message, Popconfirm } from "antd";
import moment from "moment";
import * as React from "react";
import { makeApiCallAdmin } from "../../api";
import { gray, red } from "../../components/design-system/colors";
import { ButtonComponent } from "../../components/primitives/Buttons";
import { AppModal, ToggleModal } from "../../components/primitives/Modal";
import { AppTable } from "../../components/primitives/Table";
import { Heading, Text } from "../../components/primitives/Text";
import { useClients } from "../../utils/hooks";
import CreateClientForm from "./CreateClientForm";

function Clients() {
	const { clients = [], revalidate } = useClients();

	const deleteClient = async (clientId) => {
		try {
			await makeApiCallAdmin(`/Client/${clientId}`, { method: "delete" });
			message.success("Client deleted successfully");
			revalidate();
		} catch (error) {
			message.error(error.message);
		}
	};

	const columns = [
		{
			title: "Client Name",
			dataIndex: "clientName",
			key: "clientName",
		},
		{
			title: "Contact Person",
			key: "ContactPerson",
			render: ({ contactFirstName, contactLastName }) =>
				`${contactFirstName} ${contactLastName}`,
		},
		{
			title: "Email Address",
			dataIndex: "email",
			key: "email",
		},
		{
			title: "Phone Number",
			dataIndex: "phone",
			key: "phone",
		},
		{
			title: "Date Created",
			dataIndex: "entryDate",
			key: "entryDate",
			render: (date) => moment(date).format("DD MMM YYYY"),
		},
		{
			dataIndex: "clientId",
			render: (clientId, client) => (
				<>
					<ToggleModal>
						{(showModal, onOpenModal, onCloseModal) => (
							<>
								<AppModal
									title="Update Client Info"
									visible={showModal}
									onCancel={onCloseModal}
								>
									<CreateClientForm
										client={client}
										onCancel={() => {
											onCloseModal();
											revalidate();
										}}
										isEdit
									/>
								</AppModal>
								<Button onClick={onOpenModal} type="link">
									Edit
								</Button>
							</>
						)}
					</ToggleModal>
					<Divider type="vertical" />
					<Popconfirm
						title="Are you sure you want to delete this client?"
						onConfirm={() => deleteClient(clientId)}
						okText="Delete"
						cancelText="Cancel"
						icon={<Icon type="question-circle-o" style={{ color: "red" }} />}
					>
						<Button type="link">
							<Text color={red.primary}>Delete</Text>
						</Button>
					</Popconfirm>
				</>
			),
		},
	];

	return (
		<div className="mb-16 max-w-screen-xl">
			<div className="flex">
				<Heading>Clients</Heading>
				<ToggleModal>
					{(showModal, onOpenModal, onCloseModal) => (
						<>
							<AppModal
								title="Create Client"
								visible={showModal}
								onCancel={onCloseModal}
							>
								<CreateClientForm
									onCancel={() => {
										onCloseModal();
										revalidate();
									}}
								/>
							</AppModal>
							<ButtonComponent
								appearance="blue"
								className="ml-5"
								onClick={onOpenModal}
							>
								Create Client
							</ButtonComponent>
						</>
					)}
				</ToggleModal>
			</div>
			<AppTable
				border_color={gray.light}
				rowKey="clientId"
				dataSource={clients}
				marginTop="50px"
				columns={columns}
				pagination={clients.length > 10 ? { pageSize: 10 } : false}
			/>
		</div>
	);
}

export default Clients;
