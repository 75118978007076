import { Table } from 'antd';
import React from 'react';
import styled from 'styled-components';
import { black, gray, white } from '../design-system/colors';

const TableContainer = styled.div`
  margin-top: ${props => props.marginTop};
  .ant-table-thead > tr > th {
    text-transform: uppercase;
    color: ${gray.primary};
    font-size: 12px;
  }
  .ant-table-thead > tr > th {
    background: ${white};
    border-color: ${gray.primary};
  }
  .ant-table-tbody > tr > td {
    border-bottom: ${props => (props.hasBorder ? `1px solid` : `none`)};
    border-color: ${props => props.border_color || `${gray.primary}`};
    color: ${black};
  }
  .ant-table-tbody > tr:hover > td {
    background: none !important;
  }
`;

export const AppTable = props => (
  <TableContainer
    marginTop={props.marginTop}
    hasBorder={props.hasBorder}
    border_color={props.border_color}
  >
    <Table {...props} />
  </TableContainer>
);
