const { default: useSWR } = require('swr');
const { makeApiCallAdmin } = require('../../api');

function useAccountDetails() {
  const { data: user = {}, ...rest } = useSWR('/Account/MyDetails', async (url) => {
    const { data } = await makeApiCallAdmin(url);
    return data;
  });

  return { ...user, ...rest };
}

export default useAccountDetails;
