/**
 * Add thousand separators to a number
 * Example: addThousandSeparator(1200) // 1,200.00
 * @param {number} number - number to be formatted
 * @param {number} decimals - number of fractional digits to return
 * @returns {string}
 */
function addThousandSeparator(number, decimals = 0) {
  const formattedNumber = Number(Number(number).toFixed(2)).toLocaleString('en', {
    minimumFractionDigits: decimals,
    maximumFractionDigits: decimals,
  });
  return formattedNumber;
}

export default addThousandSeparator;
