import { message } from "antd";
import { Formik } from "formik";
import React from "react";
import styled from "styled-components";
import * as yup from "yup";
import { makeApiCallAdmin } from "../../../api";
import { AppContext } from "../../../DataProvider";
import { saveState } from "../../../localStorage";
import { useAccountDetails } from "../../../utils/hooks";
import { red } from "../../design-system/colors";
import { customFonts } from "../../design-system/fonts";
import { ButtonComponent } from "../../primitives/Buttons";
import { AppInput } from "../../primitives/Input";
import { AppModal, ToggleModal } from "../../primitives/Modal";
import { Heading, Text } from "../../primitives/Text";
import { PreferenceForm, UpdatePasswordForm } from "./forms";

export function PersonalInfo(props) {
	const { preferences, updatePreferences, preferencesLoading } = props;

	return (
		<StyledPersonalInfo className="personal-info compact">
			<div className="personal-info__section">
				<Heading>Basic Information</Heading>
				<PersonalInfoForm />
			</div>
			<div className="personal-info__section">
				<Heading>Password</Heading>
				<Text>
					A strong password contains a mix of numbers, letters, and symbols. It
					is hard to guess, does not resemble a real word, and is only used for
					this account.
				</Text>
				<ToggleModal>
					{(showModal, onOpenModal, onCloseModal) => (
						<React.Fragment>
							<AppModal
								title="Update Password"
								visible={showModal}
								onCancel={onCloseModal}
							>
								<UpdatePasswordForm onCancel={onCloseModal} />
							</AppModal>
							<ButtonComponent appearance="blue" onClick={onOpenModal}>
								Update Password
							</ButtonComponent>
						</React.Fragment>
					)}
				</ToggleModal>
			</div>
			<div className="personal-info__section">
				<Heading>Preferences</Heading>
				<PreferenceForm
					{...preferences}
					onSubmit={updatePreferences}
					loading={preferencesLoading}
				/>
			</div>
		</StyledPersonalInfo>
	);
}

function PersonalInfoForm() {
	const { email = "", fullName = "", phone = "" } = useAccountDetails();
	const { updateState, user } = React.useContext(AppContext);

	return (
		<Formik
			initialValues={{ fullName, phone }}
			validationSchema={personalInfoValidationSchema}
			onSubmit={async (values, actions) => {
				try {
					await makeApiCallAdmin("/Account/MyDetails", {
						method: "put",
						data: values,
					});
					message.success("Profile updated successfully");
					updateState("user", { ...user, ...values });
					saveState({ user: { ...user, ...values } });
				} catch (error) {
					message.error(error.message);
				} finally {
					actions.setSubmitting(false);
				}
			}}
			enableReinitialize
		>
			{({
				values,
				errors,
				touched,
				handleChange,
				handleSubmit,
				isSubmitting,
			}) => (
				<form onSubmit={handleSubmit}>
					<AppInput
						name="fullName"
						value={values.fullName}
						type="text"
						size="large"
						placeholder="Full Name"
						error={touched.fullName && errors.fullName}
						errorMessage={errors.fullName}
						onChange={handleChange}
					/>
					<AppInput name="email" value={email} size="large" disabled />
					<AppInput
						name="phone"
						value={values.phone}
						type="text"
						size="large"
						placeholder="Phone"
						error={touched.phone && errors.phone}
						errorMessage={errors.phone}
						onChange={handleChange}
					/>
					<ButtonComponent
						appearance="blue"
						htmlType="submit"
						loading={isSubmitting}
						marginTop="20px"
					>
						Save Personal Information
					</ButtonComponent>
				</form>
			)}
		</Formik>
	);
}

var StyledPersonalInfo = styled.div`
	.personal-info {
		&__section {
			padding-bottom: 50px;
			h1 {
				font-family: ${customFonts.circular}!important;
				font-weight: bold;
				font-size: 20px;
				margin: 0;
				padding-bottom: 20px;
			}
			p {
				color: #454f5b;
				padding-bottom: 20px;
				margin: 0;
			}
			.deactivate-account__button {
				border-color: ${red.primary};
				color: ${red.primary};

				&:hover,
				&:focus {
					border-color: ${red.primary};
					color: ${red.primary};
				}
			}
		}
	}
`;

var personalInfoValidationSchema = yup.object().shape({
	phone: yup
		.string()
		.trim()
		.required("Phone number is required")
		.length(11, "Phone number must be 11 digits"),
	fullName: yup.string().required("Full name is required"),
});
