import axios from 'axios';

export const BASEURL = 'https://flitrackapiold.lubred.net/api';
export const ADMIN_BASEURL = 'https://flitrackapi.lubred.net/api';

export const makeApiCall = async (url, method = 'get', params, data) => {
  const config = {
    baseURL: BASEURL,
    url,
    method,
    params,
    data,
  };
  try {
    const result = await axios(config);
    const { data } = await result;
    return data;
  } catch (error) {
    throw error;
  }
};

export const makeApiCallAdmin = async (url, axiosConfig = {}) => {
  const config = {
    url,
    baseURL: ADMIN_BASEURL,
    ...axiosConfig,
  };
  try {
    const { data } = await axios(config);
    return data;
  } catch (error) {
    if (error.response && error.response.data && error.response.data.msg) {
      throw new Error(error.response.data.msg);
    }
    throw error;
  }
};
