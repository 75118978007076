import { message } from 'antd';
import { Formik } from 'formik';
import * as React from 'react';
import * as yup from 'yup';
import { makeApiCallAdmin } from '../api';
import { AppInput } from '../components/primitives/Input';
import { SaveAndCancelButtons } from '../components/primitives/SaveAndCancelButtons';

function CreateContactForm({ isEdit = false, contact = {}, close }) {
  return (
    <Formik
      initialValues={{
        firstName: contact.firstName || '',
        lastName: contact.lastName || '',
        email: contact.email || '',
        phone: contact.phone || '',
      }}
      onSubmit={async (values, actions) => {
        try {
          await makeApiCallAdmin(`/Contacts/${isEdit ? contact.contactId : ''}`, {
            method: isEdit ? 'put' : 'post',
            data: values,
          });
          message.success(`Contact ${isEdit ? 'updated' : 'created'} successfully`);
          close();
        } catch (error) {
          message.error(error.message);
        } finally {
          actions.setSubmitting(false);
        }
      }}
      validationSchema={validationSchema}
      enableReinitialize
    >
      {({ values, touched, errors, handleChange, handleSubmit, isSubmitting }) => (
        <form onSubmit={handleSubmit}>
          <AppInput
            name="firstName"
            label="First Name"
            placeholder="John"
            size="large"
            value={values.firstName}
            error={errors.firstName && touched.firstName}
            onChange={handleChange}
            errorMessage={errors.firstName}
          />
          <AppInput
            name="lastName"
            label="Last Name"
            placeholder="Ebube"
            size="large"
            value={values.lastName}
            error={errors.lastName && touched.lastName}
            onChange={handleChange}
            errorMessage={errors.lastName}
          />
          <AppInput
            name="email"
            label="Email"
            placeholder="email@example.com"
            size="large"
            value={values.email}
            error={errors.email && touched.email}
            onChange={handleChange}
            errorMessage={errors.email}
          />
          <AppInput
            name="phone"
            type="text"
            placeholder="E.g: 08062455714"
            label="Phone Number"
            size="large"
            value={values.phone}
            error={errors.phone && touched.phone}
            onChange={handleChange}
            errorMessage={errors.phone}
          />
          <SaveAndCancelButtons type="submit" onCancel={close} loading={isSubmitting} />
        </form>
      )}
    </Formik>
  );
}

var validationSchema = yup.object().shape({
  firstName: yup.string().required(),
  lastName: yup.string().required(),
  email: yup.string().required(),
  phone: yup.string().required(),
});

export default CreateContactForm;
