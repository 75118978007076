import React from 'react';
import styled from 'styled-components';
import { Modal } from 'antd';
import { black } from '../design-system/colors';

const StyledModal = styled(Modal)`
  .ant-modal-content {
    border-radius: 0;
  }
  .ant-modal-header {
    border-radius: 0;
    background: transparent;
    color: ${black};
    border-bottom: none;
    padding: 32px 54px 0;
  }
  .ant-modal-title {
    font-family: 'Circular' !important;
    font-weight: 700;
    font-size: 18px;
  }
`;

export const confirm = Modal.confirm;

export const AppModal = props => {
  return (
    <StyledModal
      {...props}
      closable={props.closable || false}
      style={{ top: '24px' }}
    >
      {props.children}
    </StyledModal>
  );
};

AppModal.defaultProps = {
  width: '630px',
  footer: null,
  bodyStyle: {
    paddingLeft: 54,
    paddingRight: 54,
  },
};

export class ToggleModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showModal: this.props.showModal || false,
    };
  }

  componentDidUpdate(prevProps) {
    if (prevProps.showModal !== this.props.showModal) {
      this.setState({
        showModal: this.props.showModal,
      });
    }
  }

  onCloseModal = () => {
    this.setState({ showModal: false });
  };

  onOpenModal = () => {
    this.setState({ showModal: true });
  };

  render() {
    return (
      <React.Fragment>
        {this.props.children(
          this.state.showModal,
          this.onOpenModal,
          this.onCloseModal
        )}
      </React.Fragment>
    );
  }
}
