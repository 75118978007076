import React from "react";
import { silver } from "../pages/Fleet/SilverMapStlye";

var $ = window.$;
var google = window.google;

class FleetInsightMapLive extends React.Component {
	state = {
		map: null,
		marker: null,
		lastLocation: {},
		intervalId: null,
		geofenseMarkers: [],
	};

	componentDidMount() {
		const {
			currentLocation: {},
			vehicleInformation: { bodyColour, vehecleType },
		} = this.props;
		const geofenseMarkers = this.createGeofenseMarkers(this.props.geofenceList);

		this.setState({
			carType: vehecleType ? vehecleType.toLowerCase() : "car",
			carColor: bodyColour ? bodyColour.toLowerCase() : "black",
			geofenseMarkers,
		});
		this.createMap().then(async (map) => {
			const infoWindow = new google.maps.InfoWindow({ maxWidth: 400 });
			const marker = await this.createMarker(map);
			marker.addListener("click", () => this.openInfoWindow(marker));
			this.setState({ map, marker, infoWindow }, () => {
				if (this.props.showGeofence) {
					this.showGeofenseMarkers();
				}
			});
			this.props.getCurrentLocation().then(() => this.start());
		});
	}

	componentWillUnmount = () => {
		this.clear();
	};

	componentDidUpdate({ delay, showGeofence, currentLocation }) {
		if (delay !== this.props.delay) {
			this.clear();
			this.start();
		}
		if (showGeofence !== this.props.showGeofence) {
			if (this.props.showGeofence) {
				this.showGeofenseMarkers();
			} else {
				this.hideGeofenseMarkers();
			}
		}
	}

	createMap = async () => {
		const map = await new window.google.maps.Map(
			document.getElementById("map"),
			{
				zoom: 19,
				center: this.getLocationLatLng(this.props.currentLocation),
				mapTypeId: window.google.maps.MapTypeId.ROADMAP,
				disableDefaultUI: true,
				zoomControl: true,
				styles: silver,
			}
		);
		return map;
	};

	createMarker = async (map) => {
		const { carType, carColor } = this.state;
		console.log(carType, "===");

		const marker = await new window.google.maps.Marker({
			map: map,
			position: this.getLocationLatLng(this.props.currentLocation),
			icon: {
				url: `/static/img/cars/${carType}/${carColor}.png`,
				anchor: new google.maps.Point(39, 60),
				size: new google.maps.Size(150, 150),
			},
		});
		return marker;
	};

	createGeofenseMarkers = (geofenceList) => {
		return geofenceList
			.filter(({ coordinate }) => !!coordinate)
			.map(({ coordinate }) => {
				const geofencePaths = coordinate.map((location) =>
					this.getLocationLatLng(location)
				);
				return new window.google.maps.Polygon({
					paths: geofencePaths,
					strokeColor: "#FF0000",
					strokeOpacity: 1,
					strokeWeight: 1,
					fillColor: "#FF0000",
					fillOpacity: 0.35,
				});
			});
	};

	showGeofenseMarkers = () => {
		const { geofenseMarkers, map } = this.state;
		geofenseMarkers.forEach((marker) => marker.setMap(map));
	};

	hideGeofenseMarkers = () => {
		const { geofenseMarkers } = this.state;
		geofenseMarkers.forEach((marker) => marker.setMap(null));
	};

	getLocationLatLng = (location) => {
		return new google.maps.LatLng(
			Number(location.latitude),
			Number(location.longitude)
		);
	};

	start = () => {
		const intervalId = setInterval(() => {
			this.play();
		}, this.props.delay);
		this.setState({ intervalId });
	};

	play = async () => {
		const { carType, carColor, map } = this.state;
		const lastLocation = this.props.currentLocation;
		const currentLocation = await this.props.getCurrentLocation();
		if (!currentLocation || !lastLocation) return;
		let { marker } = this.state;
		let from = this.getLocationLatLng(lastLocation);
		let to = this.getLocationLatLng(currentLocation);
		try {
			marker.animateTo(to);
			const mapsHeading = google.maps.geometry.spherical.computeHeading(
				from,
				to
			);
			const heading = mapsHeading === 0 ? lastLocation.Heading : mapsHeading;
			$(`img[src="/static/img/cars/${carType}/${carColor}.png"]`).css({
				transform: "rotate(" + heading + "deg)",
				"transform-origin": "39px 60px",
			});
		} catch (error) {
			marker.setPosition(to);
		} finally {
			const circle = new google.maps.Circle({ center: to, radius: 100 });
			map.fitBounds(circle.getBounds());
		}
	};

	openInfoWindow = async (marker) => {
		const { infoWindow, map } = this.state;
		await this.updateInfoWindowAddress();
		infoWindow.open(map, marker);
		setTimeout(this.closeInfoWindow, 5000);
	};

	updateInfoWindowAddress = async () => {
		const { infoWindow } = this.state;
		const { latitude, longitude } = this.props.currentLocation;
		const address = await this.props.getAddress(latitude, longitude);
		infoWindow.setContent(
			`<p style="margin: 0;font-weight: bold;">${address}</p>`
		);
	};

	closeInfoWindow = () => {
		const { infoWindow } = this.state;
		infoWindow.close();
	};

	clear = () => clearInterval(this.state.intervalId);

	render() {
		return (
			<div
				id="map"
				style={{
					height: "100%",
				}}
			/>
		);
	}
}

export default FleetInsightMapLive;
