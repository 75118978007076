import { Switch } from "antd";
import isEmpty from "lodash/isEmpty";
import React, { Component } from "react";
import styled from "styled-components";
import { gray, green, white } from "../design-system/colors";
import { Text } from "./Text";

const SwitchGroupContainer = styled.div`
	display: flex;
	flex-wrap: wrap;
	.ParamSwitchContainer {
		margin-right: 16px;
		margin-bottom: 16px;
	}
`;

const ParamSwitchContainer = styled.div`
	display: flex;
	align-items: center;

	.ant-switch {
		border: 1px solid ${gray.light};
		background-color: ${white};
	}

	.ant-switch-checked {
		background-color: ${green.primary};
		border-color: #00b649;
	}

	p {
		margin: 0;
		padding-left: 8px;
	}
`;

class ParamSwitch extends Component {
	state = { checked: this.props.checked || false };
	onChange = (checked) => {
		this.setState(
			{
				checked,
			},
			() => this.props.onChange(checked)
		);
	};
	render() {
		return (
			<ParamSwitchContainer className="ParamSwitchContainer">
				<Switch
					size="small"
					checked={this.state.checked}
					onChange={this.onChange}
				/>
				<Text>{this.props.name}</Text>
			</ParamSwitchContainer>
		);
	}
}

export class SwitchGroup extends Component {
	state = {
		selectedParams: this.props.selected || this.props.params,
	};

	onChange = (checked, paramName) => {
		const { params } = this.props;
		const { selectedParams } = this.state;

		if (checked) {
			for (let item of selectedParams) {
				if (item.paramName === paramName) {
					item.parameterValue = true;

					this.props.onChange(selectedParams);
				}
			}

			// this.setState(
			// 	({ selectedParams }) => ({
			// 		selectedParams: [
			// 			...selectedParams,
			// 			params.find((param) => param.paramName === paramName),
			// 		],
			// 	}),
			// 	() => {
			// 		const { selectedParams } = this.state;
			// 		this.props.onChange(selectedParams);
			// 	}
			// );
		} else {
			for (let item of selectedParams) {
				if (item.paramName === paramName) {
					item.parameterValue = false;

					// this.setState({ selectedParams: this.props.params });
					this.props.onChange(selectedParams);
				}
			}

			// for (let item of this.state.selectedParams) {
			// 	if (item.paramName === paramName) {
			// 		item.isSelected = false;
			// 	}
			// }
			// this.setState(
			// 	({ selectedParams }) => ({
			// 		selectedParams: selectedParams.filter(
			// 			(param) => param.paramName !== paramName
			// 		),
			// 	}),
			// 	() => {
			// 		const { selectedParams } = this.state;
			// 		this.props.onChange(selectedParams);
			// 	}
			// );
		}
	};

	render() {
		const { selected, params } = this.props;
		// const formatSelectedParams = !isEmpty(selected)
		// 	? selected.map((item) => item)
		// 	: [];

		const boolChecked = (paramName, parameterValue) => {
			if (selected !== undefined && selected.length > 0) {
				let isChecked = selected.find((item) => item.paramName === paramName);

				if (isChecked) {
					if (isChecked.parameterValue === "true") {
						return true;
					} else {
						return false;
					}
				}
			} else {
				return parameterValue;
			}
		};

		return (
			<SwitchGroupContainer>
				{params.map(({ name, paramName, parameterValue }, i) => {
					// let isChecked = !isEmpty(selected)
					// 	? selected.find((item) => item.paramName === paramName)
					// 	: parameterValue;
					// boolChecked(paramName, parameterValue);
					return (
						<ParamSwitch
							onChange={(checked) => this.onChange(checked, paramName)}
							key={i.toString()}
							// checked={formatSelectedParams.includes(paramName)}
							checked={boolChecked(paramName, parameterValue)}
							name={name}
						/>
					);
				})}
			</SwitchGroupContainer>
		);
	}
}
