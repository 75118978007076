import { message } from "antd";
import { Formik } from "formik";
import PropTypes from "prop-types";
import React from "react";
import * as yup from "yup";
import { makeApiCallAdmin } from "../../../api";
import { AppContext } from "../../../DataProvider";
import { AppInput } from "../../primitives/Input";
import { SaveAndCancelButtons } from "../../primitives/SaveAndCancelButtons";

const validationSchema = yup.object().shape({
	remark: yup.string().required("Enter reason for resolving alert"),
});

function ResolveIncidentForm({ closeDialog, selectedIncident }) {
	const context = React.useContext(AppContext);

	return (
		<Formik
			initialValues={{ remark: "" }}
			validationSchema={validationSchema}
			onSubmit={async (values, actions) => {
				try {
					await makeApiCallAdmin(
						`/Incidents/${selectedIncident.incidentId}/Resolved`,
						{
							method: "put",
							data: values,
						}
					);
					message.success("Incident resolved");
					context.getIncidents();
					actions.resetForm();
					closeDialog();
				} catch (error) {
					message.error(error.message);
				} finally {
					actions.setSubmitting(false);
				}
			}}
			render={({
				values,
				errors,
				touched,
				handleChange,
				handleSubmit,
				isSubmitting,
			}) => (
				<React.Fragment>
					<AppInput
						name="remark"
						type="text"
						placeholder="This alert was reported in error"
						label="Remark"
						size="large"
						value={values.remark}
						onChange={handleChange}
						error={errors.remark && touched.remark && errors.remark}
					/>

					<SaveAndCancelButtons
						onCancel={closeDialog}
						loading={isSubmitting}
						onSave={handleSubmit}
						type="button"
					/>
				</React.Fragment>
			)}
		/>
	);
}

ResolveIncidentForm.propTypes = {
	closeDialog: PropTypes.func.isRequired,
	selectedIncident: PropTypes.object.isRequired,
};

export default ResolveIncidentForm;
