import { Divider, message, Typography } from "antd";
import * as React from "react";
import { makeApiCallAdmin } from "../api";
import { gray, red } from "../components/design-system/colors";
import { AlertsNavigation } from "../components/pages/Alerts/AlertPage";
import {
	ButtonComponent,
	EmptyButtonComponent,
} from "../components/primitives/Buttons";
import { AppModal, confirm, ToggleModal } from "../components/primitives/Modal";
import { AppNavbar, SecondaryNavbar } from "../components/primitives/Navbar";
import { EmptyStateComponent } from "../components/primitives/Layout";
import { AppTable } from "../components/primitives/Table";
import { useContacts } from "../utils/hooks";
import CreateContactForm from "./CreateContactForm";
const { Text } = Typography;

function Contacts({ history }) {
	const { contacts = [], loading, revalidate } = useContacts();

	const changeContactStatus = ({ status, contactId }) => {
		confirm({
			title: `Do you want to ${status ? "disable" : "enable"} this contact?`,
			okText: "Yes",
			cancelText: "No",
			onOk: async function() {
				try {
					await makeApiCallAdmin(`/Contacts/${contactId}/Status`, {
						method: "put",
						data: { status: !status },
					});
					message.success("Contact updated successfully");
					revalidate();
				} catch (error) {
					message.error(error.message);
				}
			},
		});
	};

	const deleteContact = ({ contactId }) => {
		confirm({
			title: "Do you want to delete this contact?",
			okText: "Yes",
			cancelText: "No",
			onOk: async function() {
				try {
					await makeApiCallAdmin(`/Contacts/${contactId}`, {
						method: "delete",
					});
					message.success("Contact deleted successfully");
					revalidate();
				} catch (error) {
					message.error(error.message);
				}
			},
		});
	};

	const columns = [
		{
			title: "First Name",
			dataIndex: "firstName",
		},
		{
			title: "Last Name",
			dataIndex: "lastName",
		},
		{
			title: "Email Address",
			dataIndex: "email",
		},
		{
			title: "Phone Number",
			dataIndex: "phone",
		},
		{
			title: "Status",
			dataIndex: "status",
			render: (status) => (status ? "Active" : "Inactive"),
		},
		{
			title: "",
			key: "edit",
			render: (text, contact) => (
				<>
					<ToggleModal>
						{(showModal, onOpenModal, onCloseModal) => (
							<>
								<AppModal
									title="Edit Contact"
									visible={showModal}
									onCancel={onCloseModal}
								>
									<CreateContactForm
										close={() => {
											onCloseModal();
											revalidate();
										}}
										contact={contact}
										isEdit
									/>
								</AppModal>
								<EmptyButtonComponent onClick={onOpenModal}>
									Edit
								</EmptyButtonComponent>
							</>
						)}
					</ToggleModal>
					<Divider type="vertical" />
					<EmptyButtonComponent onClick={() => changeContactStatus(contact)}>
						{contact.status ? "Disable" : "Enable"}
					</EmptyButtonComponent>
					<Divider type="vertical" />
					<EmptyButtonComponent
						color={red.primary}
						onClick={() => deleteContact(contact)}
					>
						Delete
					</EmptyButtonComponent>
				</>
			),
		},
	];

	return (
		<>
			<AppNavbar logout={() => history.push("/signin")} />
			<div className="relative w-full mb-18">
				<SecondaryNavbar>
					<AlertsNavigation />
				</SecondaryNavbar>

				<div className="max-w-screen-xl px-4 pt-32 mx-auto">
					<div className="pt-10">
						<h1 className="mt-8 text-xl">Contacts</h1>
						<ToggleModal>
							{(showModal, onOpenModal, onCloseModal) => (
								<>
									<AppModal
										title="Add Contact"
										visible={showModal}
										onCancel={onCloseModal}
									>
										<CreateContactForm
											close={() => {
												onCloseModal();
												revalidate();
											}}
										/>
									</AppModal>
									<ButtonComponent onClick={onOpenModal}>
										Create New Contact
									</ButtonComponent>
								</>
							)}
						</ToggleModal>
						{contacts.length > 0 ? (
							<AppTable
								hasBorder
								loading={loading}
								border_color={gray.light}
								rowKey="contactId"
								marginTop="40px"
								dataSource={contacts}
								pagination={contacts.length > 20 ? { pageSize: 20 } : false}
								columns={columns}
							/>
						) : (
							<EmptyStateComponent
								className="EmptyStateComponent"
								margin="200px"
							>
								<div className="EmptyStateComponent__icon-container">
									<img src="/static/img/403.svg" alt="forbidden icon" />
								</div>
								<Text className="EmptyStateComponent__text">
									You currently have no Contacts
								</Text>
								<Text color={gray.primary}>
									There are currently no contacts at the moment.
								</Text>
							</EmptyStateComponent>
						)}
					</div>
				</div>
			</div>
		</>
	);
}

export default Contacts;
