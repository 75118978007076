/**
 * Creates an object from an array of objects, using a specified property as the key
 * This is to avoid loops when trying to locate a specific item in an array
 * @param {Array<Object>} array - The array you want to convert to an object
 * @param {String} [key=id] - The key you want to use as the unique identifier
 * @returns {} - A new object
 */
export const arrayToObject = (array, key = "id") => {
	return array.reduce((acc, curr) => {
		return {
			...acc,
			[curr[key]]: curr,
		};
	}, {});
};

export const formatDate = (value) => {
	console.log(value, "init date val");
	if (value === null) {
		return "-- --";
	} else {
		let date = new Date(Date.parse(value));
		let dateVal = date.toString().split("GMT")[0];
		console.log(dateVal);

		return dateVal;
	}
};
