import { message } from 'antd';
import useSWR from 'swr';
import { makeApiCallAdmin } from '../../api';

function useTrip(tripId) {
  const { data: trip, error, revalidate } = useSWR(`/Trips/${tripId}`, fetchTrip);

  return { trip, loading: !trip && !error, revalidate };
}

async function fetchTrip(url) {
  try {
    const { data } = await makeApiCallAdmin(url);
    return data && data[0];
  } catch (e) {
    message.error(e.message);
  }
}

export default useTrip;
