import { message } from "antd";
import Axios from "axios";
import { isEmpty } from "lodash";
import * as React from "react";
import { withRouter } from "react-router";
import { cache, mutate } from "swr";
import { makeApiCall, makeApiCallAdmin } from "./api";
import { alertTypes } from "./components/pages/Alerts/components";
import { geocodeLocation } from "./components/pages/Fleet/helpers";
import { clearState, loadState, saveState } from "./localStorage";

export const AppContext = React.createContext({});

class AppProviderComponent extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			isValidatingToken: true,
			context: {
				dashboardAnalytics: { initialLoad: true },
				assets: [],
				folderAssets: [],
				assetsCopy: [],
				incidents: [],
				incidentsCopy: [],
				orphanAssets: [],
				trips: [],
				alerts: [],
				user: {},
				company: {},
				preferences: {},
				email: "",
				clientId: "",
				organizationName: "",
				assignType: "",
				assignData: {},
				loading: false,
				buttonLoading: false,
				showSetupModal: false,
				updateState: this.updateState,
				getUser: this.getUser,
				getLocalPreferences: this.getLocalPreferences,
				logout: this.logout,
				getIncidents: this.getIncidents,
				getAllOrphanAssets: this.getAllOrphanAssets,
				createAssetBulkUpload: this.createAssetBulkUpload,
				getNodeAssets: this.getNodeAssets,
				getAllTrips: this.getAllTrips,
				getGeofenceAlertList: this.getGeofenceAlertList,
				getAlertList: this.getAlertList,
				getPreferences: this.getPreferences,
				getClientInfo: this.getClientInfo,
				updatePassword: this.updatePassword,
				updatePreferences: this.updatePreferences,
				updateClientInfo: this.updateClientInfo,
				setClient: this.setClient,
			},
		};
	}
	componentDidMount() {
		const user = this.getUser();
		if (user && user.token) {
			Axios.defaults.headers.Authorization = `Bearer ${user.token}`;
		}
		// Log user out for every 401 (Unauthorized) response
		Axios.interceptors.response.use(
			(response) => response,
			(error) => {
				if (error.response && error.response.status === 401) this.logout();
				return Promise.reject(error);
			}
		);
		this.setState({ isValidatingToken: false });
	}

	getPreferences = () => {
		let data = loadState();
		if (Boolean(data)) {
			const { clientId, id } = data.user;
			this.setState(({ context }) => ({
				context: {
					...context,
					loading: true,
				},
			}));
			makeApiCall(`Account/LocalPreferece/${clientId}/${id}`)
				.then((data) => {
					const { AccountEntry, Status } = data;
					this.setState(({ context }) => ({
						context: {
							...context,
							loading: false,
						},
					}));
					if (Status.StatusCode === "00") {
						this.setState(({ context }) => ({
							context: {
								...context,
								preferences: AccountEntry,
							},
						}));
						saveState({ ...loadState(), preferences: AccountEntry });
					} else {
						message.error(Status.Description);
					}
				})
				.catch((error) => console.log(error));
		}
	};

	updatePreferences = (values) => {
		const { clientId, id, email, ...rest } = loadState().user;
		this.setState(({ context }) => ({
			context: {
				...context,
				buttonLoading: true,
			},
		}));
		return makeApiCall(
			`Account/LocalPreferece/Update`,
			"POST",
			{},
			{
				...rest,
				...values,
				AuthUsersId: id,
				LoginId: id,
				ClientId: clientId,
				LoginUserId: email,
			}
		).then((data) => {
			this.getPreferences();
			return data;
		});
	};

	getClientInfo = () => {
		let data = loadState();
		if (Boolean(data)) {
			const { clientId } = data.user;
			this.setState(({ context }) => ({
				context: {
					...context,
					loading: true,
				},
			}));
			makeApiCall(`Account/ClientInfo/List/${clientId}`)
				.then((data) => {
					const { ClientEntry, Status } = data;
					this.setState(({ context }) => ({
						context: {
							...context,
							loading: false,
						},
					}));
					if (Status.StatusCode === "00") {
						this.setState(({ context }) => ({
							context: {
								...context,
								company: ClientEntry,
							},
						}));
						saveState({ ...loadState(), company: ClientEntry });
					} else {
						message.error(Status.Description);
					}
				})
				.catch((error) => console.log(error));
		}
	};

	setClient = async ({ clientId, clientName, balance }) => {
		try {
			const { token } = await makeApiCallAdmin("/login/impclient", {
				method: "post",
				data: { clientId },
			});
			Axios.defaults.headers.Authorization = `Bearer ${token}`;
			const { user } = loadState();
			const updatedUser = {
				...user,
				clientId,
				clientName,
				balance,
				token,
			};
			this.updateState(updatedUser, "user");
			saveState({ user: updatedUser });
			window.location.href = "/";
			// refetch all data
			cache
				.keys() // get all the cached keys
				// trigger the revalidation
				.forEach((key) => mutate(key, undefined, true));
		} catch (error) {
			message.error("Error setting client data");
		}
	};

	updateClientInfo = (values) => {
		const { clientId, email } = loadState().user;
		return makeApiCall(
			`Account/ClientInfo/Update`,
			"POST",
			{},
			{
				...values,
				ClientId: clientId,
				Status: true,
				ClientType: 2,
				LoginUserId: email,
			}
		).then((data) => {
			this.getClientInfo();
			return data;
		});
	};

	updatePassword = (values) => {
		const {
			clientId,
			id,
			permission,
			clientName,
			roleName,
			roleId,
			email,
			...rest
		} = loadState().user;
		return makeApiCall(
			`Account/Password/Update`,
			"POST",
			{},
			{
				...rest,
				...values,
				AuthUsersId: id,
				LoginId: id,
				Password: values.Password,
				ClientId: clientId,
				LoginUserId: email,
			}
		);
	};

	getUser = () => {
		let data = loadState();
		let { user } = this.state.context;
		if (!isEmpty(user)) {
			return user;
		} else if (Boolean(data)) {
			this.setState(({ context }) => ({
				context: {
					...context,
					user: data.user,
				},
			}));
			return data.user;
		}
		return undefined;
	};

	getLocalPreferences = () => {
		let data = loadState();
		let { preferences } = this.state.context;
		if (!isEmpty(preferences)) {
			return preferences;
		} else if (Boolean(data)) {
			this.setState(({ context }) => ({
				context: {
					...context,
					preferences: data.preferences,
				},
			}));
			return data.preferences;
		}
		return undefined;
	};

	clearAllState = () => {
		this.setState(({ context }) => ({
			context: {
				...context,
				contacts: [],
				incidents: [],
				incidentsCopy: [],
				orphanAssets: [],
				trips: [],
				alerts: [],
				user: {},
				email: "",
				loading: false,
				buttonLoading: false,
			},
		}));
	};

	logout = (callback) => {
		clearState();
		this.clearAllState();
	};

	getIncidents = async (date, params, resolved = false) => {
		// const { clientId } = loadState().user;
		const altDate = Math.round(new Date().getTime() / 1000);

		this.setState(({ context }) => ({
			context: {
				...context,
				loading: true,
			},
		}));

		try {
			let data = resolved
				? await makeApiCallAdmin(
						`Incidents/Date/${date ? date : altDate}/Resolved`
				  )
				: await makeApiCallAdmin(`Incidents/Date/${date ? date : altDate}`);

			if (data) {
				this.setState(({ context }) => ({
					context: {
						...context,
						loading: false,
						incidents: data.data,
						incidentsCopy: data.data,
					},
				}));
			}
		} catch (error) {
			this.setState(({ context }) => ({
				context: {
					...context,
					loading: false,
				},
			}));
			message.error("An error occured");
		}
	};

	getAllOrphanAssets = (refresh = false) => {
		let data = loadState();
		if (Boolean(data)) {
			if (this.state.context.orphanAssets.length === 0 || refresh) {
				const { clientId } = loadState().user;
				const url = `Node/Assets/Ophan/List/${clientId}`;
				this.setState(({ context }) => ({
					context: {
						...context,
						loading: true,
					},
				}));
				return makeApiCall(url)
					.then((data) => {
						const { Nodes } = data;
						this.setState(({ context }) => ({
							context: {
								...context,
								orphanAssets: Nodes,
								loading: false,
							},
						}));
						return Nodes;
					})
					.catch((error) => {
						this.setState(({ context }) => ({
							context: {
								...context,
								loading: false,
								assets: [],
							},
						}));
					});
			}
		}
	};

	createAssetBulkUpload = (payload, callback) => {
		const formData = new FormData();
		formData.append("file", payload);
		let data = loadState();
		if (Boolean(data)) {
			const { clientId, Id } = data.user;
			this.setState(({ context }) => ({
				context: {
					...context,
					buttonLoading: true,
				},
			}));
			return makeApiCall(
				`Assets/Create/BulkUpload/${clientId}/${Id}`,
				"POST",
				{},
				formData
			)
				.then((data) => {
					this.setState(({ context }) => ({
						context: {
							...context,
							buttonLoading: false,
						},
					}));
					const { StatusCode, Description } = data;
					if (StatusCode === "00") {
						message.success(Description);
						callback();
						this.getAllAssets();
						this.getAllOrphanAssets();
					} else {
						message.error(Description);
					}
					return data;
				})
				.catch((err) => {
					this.setState(({ context }) => ({
						context: {
							...context,
							buttonLoading: false,
						},
					}));
					message.error(`An error occured. Unable to create assets`);
					return err;
				});
		}
	};

	getNodeAssets = (id) => {
		let data = loadState();
		if (Boolean(data)) {
			const { clientId } = data.user;
			const url = `Node/Children/Assets/List/${id}/${clientId}`;
			return makeApiCall(url)
				.then((data) => {
					let { Nodes, Status } = data;
					if (Status.StatusCode === "00") {
						this.setState(({ context }) => ({
							context: {
								...context,
								folderAssets: Nodes,
							},
						}));
					} else {
						message.error(Status.Description);
					}
					return Nodes;
				})
				.catch((error) =>
					message.error("An error occured. Unable to get assets")
				);
		}
	};

	getAllTrips = async (refresh = false) => {
		let data = loadState();
		if (Boolean(data)) {
			const { clientId } = data.user;
			const url = `Trip/List/${clientId}`;
			const { trips } = this.state.context;
			if (trips.length === 0 || refresh) {
				this.setState(({ context }) => ({
					context: {
						...context,
						loading: true,
					},
				}));
				try {
					let data = await makeApiCall(url);
					const { Trips } = data;
					const updatedTrips = Trips.map((Trip) => ({
						...Trip,
						StartLocation: geocodeLocation({
							Latitude: Trip.PointA[0].Latitude,
							Longitude: Trip.PointA[0].Longitude,
						}),
						EndLocation: geocodeLocation({
							Latitude: Trip.PointB[0].Latitude,
							Longitude: Trip.PointB[0].Longitude,
						}),
					}));
					this.setState(({ context }) => ({
						context: {
							...context,
							trips: updatedTrips,
							loading: false,
						},
					}));
				} catch (error) {
					this.setState(({ context }) => ({
						context: {
							...context,
							loading: false,
						},
					}));
					message.error("An error occured. Unable to get trips");
				}
			}
		}
	};

	getGeofenceAlertList = (refresh = false, id) => {
		let data = loadState();
		if (Boolean(data)) {
			const { clientId } = data.user;
			const { alerts } = this.state.context;
			const url = `Alert/Geofence/List/${clientId}`;
			if (alerts.length === 0 || refresh) {
				this.setState(({ context }) => ({
					context: {
						...context,
						loading: true,
					},
				}));
				makeApiCall(url)
					.then((data) => {
						const { Geofence } = data;
						this.setState(({ context }) => {
							let alerts = [];
							const filterOtherViolationAlerts = context.alerts.filter(
								(alert) =>
									alert.ViolationType !== 101 && alert.ViolationType !== 0
							);
							alerts = [...Geofence, ...filterOtherViolationAlerts];
							return {
								context: {
									...context,
									alerts: alerts.map((alert) => {
										let type = "geofence";
										if (alert.ViolationType !== 0 && !alert.ViolationType) {
											type =
												alertTypes[alert.ViolationTypeParams.ViolationType];
										}
										return { ...alert, Type: type };
									}),
									loading: false,
								},
							};
						});
					})
					.catch((error) => {
						this.setState(({ context }) => ({
							context: {
								...context,
								loading: false,
							},
						}));
					});
			}
		}
	};

	getAlertList = (refresh = false, id) => {
		let data = loadState();
		if (Boolean(data)) {
			const { clientId } = loadState().user;
			const { alerts } = this.state.context;

			const url = `Alert/OtherViolation/List/${clientId}`;
			if (alerts.length === 0 || refresh) {
				this.setState(({ context }) => ({
					context: {
						...context,
						loading: true,
					},
				}));
				makeApiCall(url)
					.then((data) => {
						const { OtherViolationEntry } = data;
						this.setState(({ context }) => {
							let alerts = [];
							const filterGeofenceAlerts = context.alerts.filter(
								(alert) =>
									alert.ViolationType === 101 || alert.ViolationType === 0
							);
							alerts = [...OtherViolationEntry, ...filterGeofenceAlerts];
							return {
								context: {
									...context,
									alerts: alerts.map((alert) => {
										let type = "geofence";
										if (alert.ViolationType !== 0 && !alert.ViolationType) {
											type =
												alertTypes[alert.ViolationTypeParams.ViolationType];
										}
										return { ...alert, Type: type };
									}),
									loading: false,
								},
							};
						});
					})
					.catch((error) => {
						this.setState(({ context }) => ({
							context: {
								...context,
								loading: false,
							},
						}));
					});
			}
		}
	};

	updateState = (value, stateItem) => {
		this.setState(({ context }) => ({
			context: {
				...context,
				[stateItem]: value,
			},
		}));
	};

	render() {
		return (
			<AppContext.Provider value={this.state.context}>
				{!this.state.isValidatingToken && this.props.children}
			</AppContext.Provider>
		);
	}
}

export const AppProvider = withRouter(AppProviderComponent);
