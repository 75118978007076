import { Dropdown, Icon, Menu, message, Switch, Tooltip } from "antd";
import { Formik } from "formik";
import React, { useState } from "react";
import { GoogleMap, withGoogleMap } from "react-google-maps";
import { DrawingManager } from "react-google-maps/lib/components/drawing/DrawingManager";
import styled from "styled-components";
import { mutate } from "swr";
import { makeApiCallAdmin } from "../../../api";
import { useProfile } from "../../../utils/hooks";
import {
	black,
	blue,
	gray,
	green,
	red,
	white,
} from "../../design-system/colors";
import { EmptyButtonComponent } from "../../primitives/Buttons";
import { AppInput } from "../../primitives/Input";
import { AppModal, confirm, ToggleModal } from "../../primitives/Modal";
import { SaveAndCancelButtons } from "../../primitives/SaveAndCancelButtons";
import { Heading, Text } from "../../primitives/Text";

var google = window.google;

export const alertTypes = {
	0: "geofence",
	101: "geofence",
	102: "speed",
	103: "asset",
	104: "driver",
	105: "device",
};

const AlertItemContainer = styled.div`
	border-bottom: 1px solid ${gray.light};
	padding: 20px 0;
	.alert-item {
		display: flex;
		align-items: center;
		&__left {
			padding-right: 24px;

			.ant-switch {
				border: 1px solid ${gray.light};
				background-color: ${white};
			}

			.ant-switch-checked {
				background-color: ${green.primary};
				border-color: #00b649;
			}
		}
		&__right {
			flex: 1;
			h1 {
				font-size: 18px;
				color: ${black};
				margin: 0;
			}
			p {
				color: ${gray.primary};
				font-size: 14px;
				margin: 0;
			}
		}
		&__dropdown {
			padding-right: 8px;
		}
		&__bottom {
			display: flex;
			justify-content: space-between;
			align-items: center;
			padding: 24px 8px 0;

			&-left {
				display: flex;
				align-items: center;
				.trip-count {
					width: 32px;
					height: 32px;
					margin-left: 4px;
					border-radius: 50%;
					display: flex;
					align-items: center;
					justify-content: center;
					background-color: ${gray.light};
				}
				.assign-button {
					padding: 0;
					position: relative;
					left: -4px;
				}
			}
			&-right {
				p {
					text-transform: capitalize;
					margin: 0;
					color: ${blue.primary};
				}
			}
		}
	}
`;

export function AlertItem(props) {
	const [status, setStatus] = React.useState(props.status);
	const { clientId, id } = useProfile();

	const onSetStatus = async (newStatus) => {
		const { geofenceId, otherVolationId, violationType } = props;
		setStatus(newStatus);
		try {
			await makeApiCallAdmin(
				[0, 101].includes(violationType)
					? `/Geofence/${geofenceId}`
					: `Violation/${otherVolationId}`,
				{
					method: "put",
					data: {
						...props,
						status: false,
					},
				}
			);
			mutate("alerts");
		} catch (error) {
			setStatus(!newStatus);
			message.error(error.message);
		}
	};

	const showDeleteConfirm = () => {
		const { geofenceId, otherVolationId, violationType } = props;
		confirm({
			title: "Do you want to delete this alert?",
			okText: "Yes",
			cancelText: "No",
			onOk: async () => {
				try {
					await makeApiCallAdmin(
						[0, 101].includes(violationType)
							? `/Geofence/${geofenceId}`
							: `/Violation/${otherVolationId}`,
						{ method: "delete" }
					);
					message.success("Alert deleted");
					props.refetchAlerts();
					mutate("alerts");
				} catch (error) {
					message.error(error.message);
				}
			},
		});
	};

	const onAlertEdit = (e) => {
		e.preventDefault();

		const { onEdit, type, ...rest } = props;
		onEdit(type, rest);
	};

	const menu = () => (
		<Menu>
			<Menu.Item>
				<EmptyButtonComponent onClick={onAlertEdit}>
					Edit Alert
				</EmptyButtonComponent>
			</Menu.Item>
			<Menu.Item>
				<ToggleModal>
					{(showModal, onOpenModal, onCloseModal) => (
						<React.Fragment>
							<AppModal
								title={`Duplicate Alert - ${props.alert.label}`}
								visible={showModal}
								onCancel={onCloseModal}
							>
								<Formik
									initialValues={{
										...props.alert,
										clientId,
										loginUserId: id,
										disableAlertPeriod: props.disableAlertPeriod || [],
										violationNodeAssets: props.violationNodeAssets || [],
										violationNodeFolders: props.violationNodeFolders || [],
										label: "",
										description: "",
									}}
									onSubmit={async (values, actions) => {
										try {
											await makeApiCallAdmin(
												[0, 101].includes(props.violationType)
													? "/Geofence"
													: "/Violation",
												{ method: "post", data: values }
											);
											message.success("Alert created");
											onCloseModal();
											mutate("alerts");
										} catch (error) {
											message.error(error.message);
										} finally {
											actions.setSubmitting(false);
										}
									}}
								>
									{({
										values,
										errors,
										touched,
										handleChange,
										handleSubmit,
										isSubmitting,
									}) => (
										<form onSubmit={handleSubmit}>
											<AppInput
												name="label"
												type="text"
												value={values.label}
												placeholder="Enter Alert Name"
												size="large"
												onChange={handleChange}
												error={touched.label && errors.label}
												errorMessage={errors.label}
											/>
											<AppInput
												name="description"
												type="text"
												value={values.description}
												placeholder="Alert Description"
												size="large"
												onChange={handleChange}
												error={touched.description && errors.description}
												errorMessage={errors.description}
											/>
											<SaveAndCancelButtons
												onCancel={onCloseModal}
												loading={isSubmitting}
												marginTop="44px"
											/>
										</form>
									)}
								</Formik>
							</AppModal>
							<EmptyButtonComponent onClick={onOpenModal}>
								Duplicate Alert
							</EmptyButtonComponent>
						</React.Fragment>
					)}
				</ToggleModal>
			</Menu.Item>
			<Menu.Item>
				<EmptyButtonComponent color={red.primary} onClick={showDeleteConfirm}>
					Delete Alert
				</EmptyButtonComponent>
			</Menu.Item>
		</Menu>
	);

	let typeDisplayLabels = {
		geofence: "geofence",
		speed: "speed alert",
		asset: "asset",
		driver: "driver behaviour",
		device: "device",
	};

	let type = typeDisplayLabels[props.type];

	return (
		<AlertItemContainer>
			<div className="alert-item">
				<div className="alert-item__left">
					<Switch size="small" checked={status} onChange={onSetStatus} />
				</div>
				<div className="alert-item__right">
					<Heading>{props.label}</Heading>
					<Text>{props.description}</Text>
				</div>
				<div className="alert-item__dropdown">
					<Dropdown overlay={menu()} trigger={["click"]}>
						<EmptyButtonComponent>
							<Icon
								type="ellipsis"
								style={{ fontSize: 24, color: "rgba(0,0,0,.5)" }}
							/>
						</EmptyButtonComponent>
					</Dropdown>
				</div>
			</div>
			<div className="alert-item__bottom">
				<div className="alert-item__bottom-left">
					<Icon type="car" style={{ color: "rgba(181, 181, 181, 0.5)" }} />
					<div className="trip-count">{props.numberOfAssetAssigned}</div>
					<Tooltip placement="right" title="Assign">
						<EmptyButtonComponent
							className="assign-button"
							onClick={props.goToAssignPage}
						>
							<img src="static/img/circle-icon.svg" alt="circle icon" />
						</EmptyButtonComponent>
					</Tooltip>
				</div>
				<div className="alert-item__bottom-right">
					<Text>
						{type}
						{props.alertType &&
							` (${
								{ 1: "Speed Limit", 2: "Exclusive", 3: "Inclusive" }[
									props.alertType
								]
							})`}
					</Text>
				</div>
			</div>
		</AlertItemContainer>
	);
}

export const AlertFormItem = styled.div`
	border-bottom: ${(props) =>
		props.noBorder ? `none` : `1px solid ${gray.light}`};
	padding-top: ${(props) => props.paddingTop || "32px"};
	padding-bottom: ${(props) => props.paddingBottom || "32px"};

	.Text {
		margin: 0;
		color: ${black};
	}

	.alert__form-item {
		&__flex {
			display: flex;
			align-items: center;
			flex-flow: row wrap;

			& > div {
				margin-right: 16px;

				p {
					margin: 0;
				}
			}
		}
		&__input {
			max-width: 60px;
			input {
				text-align: center;
			}
		}
	}

	.ant-radio-wrapper {
		span {
			&:last-of-type {
				color: ${black};
			}
		}
	}

	#engine__ide {
		margin-top: 16px;
	}

	.remove-alert-period {
		position: relative;
		top: 8px;
	}
`;

export const MapWithADrawingManager = withGoogleMap((props) => {
	return (
		<GoogleMap defaultZoom={10} defaultCenter={{ lat: 6.5244, lng: 3.3792 }}>
			<DrawingManager
				defaultDrawingMode={google.maps.drawing.OverlayType.CIRCLE}
				onPolygonComplete={props.onPolygonComplete}
				onCircleComplete={props.onCircleComplete}
				onRectangleComplete={props.onRectangleComplete}
				onOverlayComplete={props.onOverlayComplete}
				defaultOptions={{
					drawingControl: true,
					drawingControlOptions: {
						position: google.maps.ControlPosition.TOP_CENTER,
						drawingModes: [
							google.maps.drawing.OverlayType.CIRCLE,
							google.maps.drawing.OverlayType.POLYGON,
							google.maps.drawing.OverlayType.RECTANGLE,
						],
					},
					circleOptions: {
						fillColor: "transparent",
						strokeWeight: 5,
						strokeColor: blue.primary,
						clickable: true,
						editable: true,
						draggable: true,
						zIndex: 1,
					},
					polygonOptions: {
						fillColor: "transparent",
						strokeWeight: 5,
						strokeColor: blue.primary,
						clickable: true,
						editable: true,
						draggable: true,
						zIndex: 1,
					},
					rectangleOptions: {
						fillColor: "transparent",
						strokeWeight: 5,
						strokeColor: blue.primary,
						clickable: true,
						editable: true,
						draggable: true,
						zIndex: 1,
					},
				}}
			/>
		</GoogleMap>
	);
});

const StyledContainer = styled.div`
	display: flex;
	align-items: center;
	p {
		margin: 0;
		padding-left: 8px;
	}
`;

export const SelectPlaceholder = ({ placeholder }) => {
	return (
		<StyledContainer>
			<img src="/static/img/folder.svg" />
			<Text>{placeholder}</Text>
		</StyledContainer>
	);
};

const AlertButtonStyle = (isActive) => `
  position: relative;
  width: 229px;
  height: 119px;
  background-color: ${gray.light};
  color: ${black};
  transition: background 0.3s ease-in-out;
  ${
		isActive
			? `
    span:first-child {
      display: block;
    }
    span:last-child {
      display: none;
    }
  `
			: `
    span {
      display: block;
      transition: all 0.3s ease-in-out;
    }
    span:first-child {
      display: block;
    }
    span:last-child {
      display: none;
    }
  `
	}
  
  ${
		isActive
			? ``
			: `
    &:hover {
      background: rgba(0,0,0,.4);
      color: ${white};
      span:first-child {
        display: none;
      }
      span:last-child {
        display: block;
      }
    }
`
	}
`;

export const AlertButton = ({ children, onClick, isActive = false }) => {
	return (
		<EmptyButtonComponent
			onClick={isActive ? onClick : () => {}}
			css={AlertButtonStyle(isActive)}
		>
			<span>{children}</span>
			<span>Coming soon</span>
		</EmptyButtonComponent>
	);
};

const FilterTag = styled(EmptyButtonComponent)`
	display: inline-flex;
	align-items: center;
	background-color: rgba(0, 122, 206, 0.05);
	border-radius: 100px;
	padding: 2px 12px;
	font-size: 14px;
	color: ${blue.primary};
	${(props) =>
		props.isActive
			? `
    border: 1px solid ${blue.primary};
    background-color: transparent;
  `
			: ``};
`;

const AlertFilterContainer = styled.div`
	display: block;
	width: 100%;
	padding: 0;
	.visible-section {
		padding: 8px 24px;
		display: flex;
		justify-content: space-between;
		align-items: center;

		p {
			margin: 0;
		}
	}
	.collapsible-section__container {
		background-color: #f6f6f6;
		padding: 12px 24px;
		overflow: auto;
		white-space: nowrap;
	}
	.collapsible-section {
		display: flex;
		align-items: center;

		.filter-tag {
			margin-right: 8px;
			margin-bottom: 4px;
		}
	}
`;

export const AlertFilter = ({ name, filters, onFilter }) => {
	const [activeFilter, setActiveFilter] = useState("");

	const handleFilter = (value) => {
		const newActiveFilter = filters.find((filter) => value === filter.value)
			.name;
		setActiveFilter(newActiveFilter);
		onFilter(value);
	};

	return (
		<AlertFilterContainer className="AlertFilterContainer">
			<div className="visible-section">
				<Text>{name}</Text>
			</div>
			<div className="collapsible-section__container">
				<div className="collapsible-section">
					{filters.map(({ name, value }, i) => (
						<FilterTag
							key={i.toString()}
							isActive={name.toLowerCase() === activeFilter.toLowerCase()}
							className="filter-tag"
							onClick={() => handleFilter(value)}
						>
							{name}
						</FilterTag>
					))}
				</div>
			</div>
		</AlertFilterContainer>
	);
};
