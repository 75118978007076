import React from "react";
import styled from "styled-components";

const LogoContainer = styled.div`
	// height: 31px;
	width: 150px;
	// background: rgba(255, 255, 255, 0.2);
	margin-right: 16px;
	float: left;
	position: relative;
	// top: 5px;
	display: flex;
	align-items: center;
	span {
	}
	img {
		width: 30px;
	}
`;

export const Logo = () => (
	<LogoContainer>
		{/* <img src="/static/img/Logo@2x.png" alt="logo" /> */}
		<img src="/static/img/eazee-logo.svg" alt="logo" />
		<LogoTextContainer>EazeeTracker</LogoTextContainer>
	</LogoContainer>
);

const LogoTextContainer = styled.p`
	font-size: 20px;
	color: #007ace;
	font-weight: bold;
	letter-spacing: -1px;
	padding: 0;
	margin: 0;

	font-family: "GraphikBold" !important;
`;

export const LogoText = () => <LogoTextContainer>Flitrack</LogoTextContainer>;
