import { message } from "antd";
import React, { useEffect, useState } from "react";
import useSWR from "swr";
import { makeApiCall, makeApiCallAdmin } from "../api";
import { AlertPage } from "../components/pages/Alerts/AlertPage";
import NewAlertPage from "../components/pages/Alerts/NewAlertPage";
import { formatNodesWithChildren } from "../components/pages/Fleet/helpers";
import { AppContext } from "../DataProvider";
import { loadState } from "../localStorage";
import { useContacts } from "../utils/hooks";

function Alerts(props) {
	const { contacts = [] } = useContacts();
	const context = React.useContext(AppContext);
	const [isSubmitting, setSubmitting] = React.useState(false);
	const [filter, setFilter] = React.useState("");
	// const { data: alerts = [] } = useSWR("alerts", fetchAlerts);
	const [updatedAlerts, setUpdatedAlerts] = useState([]);
	const [filteredAlerts, setFilteredAlerts] = useState(updatedAlerts);

	useEffect(() => {
		refetchAlerts();
	}, []);
	useEffect(() => {
		if (filter !== "") fetchFilteredAlerts();
	}, [filter]);
	useEffect(() => {
		fetchFilteredAlerts();
	}, [updatedAlerts]);

	const createGeofenceAlert = async (values, callback) => {
		const { clientId, id } = loadState().user;
		const { type, address, ...rest } = values;
		setSubmitting(true);
		try {
			await makeApiCallAdmin("/Geofence", {
				method: "post",
				data: {
					...rest,
					severity: 1,
					violationNodeFolders: [],
					violationNodeAssets: [],
					clientId,
					loginUserId: id,
					status: true,
				},
			});
			message.success("Alert created");
			callback();
			context.getGeofenceAlertList(true);
		} catch (error) {
			message.error(error.message);
		} finally {
			setSubmitting(false);
		}
	};

	const createAlert = async (values, callback) => {
		const { clientId, id } = loadState().user;
		setSubmitting(true);

		try {
			await makeApiCallAdmin("/Violation", {
				method: "post",
				data: {
					...values,
					clientId,
					loginUserId: id,
					status: true,
				},
			});
			message.success("Alert created");
			callback();
			context.getAlertList(true);
			refetchAlerts();
		} catch (error) {
			message.error(error.message);
		} finally {
			setSubmitting(false);
		}
	};

	const updateGeofenceAlert = async (values, callback) => {
		const { clientId, email, id } = loadState().user;
		setSubmitting(true);

		try {
			await makeApiCallAdmin(`/Geofence/${values.geofenceId}`, {
				method: "put",
				data: {
					...values,
					clientId,
					loginUserId: id,
					status: true,
				},
			});
			message.success("Alert updated");
			callback();
			context.getGeofenceAlertList(true, values.geofenceId);
			refetchAlerts();
		} catch (error) {
			message.error(error.message);
		} finally {
			setSubmitting(false);
		}
	};

	const updateAlert = async (values, callback) => {
		const { clientId, email, id } = loadState().user;
		setSubmitting(true);
		console.log(values, "tracking assets");
		try {
			await makeApiCallAdmin(`/Violation/${values.otherVolationId}`, {
				method: "put",
				data: {
					...values,
					clientId,
					loginUserId: id,
					status: true,
				},
			});

			message.success("Alert updated");
			callback();
			context.getAlertList(true);
			refetchAlerts();
		} catch (error) {
			message.error(error.message);
		} finally {
			setSubmitting(false);
		}
	};

	// const duplicateGeofenceAlert = (values, callback) => {
	// 	const { clientId, id } = loadState().user;
	// 	const url = `Alert/Geofence/Create`;
	// 	let { OtherViolationId, ...rest } = values;
	// 	setSubmitting(true);
	// 	makeApiCall(
	// 		url,
	// 		"POST",
	// 		{},
	// 		{ ...rest, ClientId: clientId, LoginUserId: id }
	// 	)
	// 		.then((data) => {
	// 			setSubmitting(false);
	// 			if (data.StatusCode === "00") {
	// 				message.success(data.Description);
	// 				callback();
	// 				context.getGeofenceAlertList(true);
	// 			} else {
	// 				message.error(data.Description);
	// 			}
	// 		})
	// 		.catch((error) => {
	// 			setSubmitting(false);
	// 			message.error("An error occured. Unable to duplicate geofence alert");
	// 		});
	// };

	// const duplicateAlert = (values, callback) => {
	// 	const { clientId, id } = loadState().user;
	// 	const url = `Alert/OtherViolation/Create`;
	// 	let { GeofenceId, ...rest } = values;
	// 	setSubmitting(true);
	// 	makeApiCall(
	// 		url,
	// 		"POST",
	// 		{},
	// 		{ ...rest, ClientId: clientId, LoginUserId: id }
	// 	)
	// 		.then((data) => {
	// 			setSubmitting(false);
	// 			if (data.StatusCode === "00") {
	// 				message.success(data.Description);
	// 				callback();
	// 				context.getGeofenceAlertList(true);
	// 			} else {
	// 				message.error(data.Description);
	// 			}
	// 		})
	// 		.catch((error) => {
	// 			setSubmitting(false);
	// 			message.error("An error occured. Unable to duplicate geofence alert");
	// 		});
	// };

	const deleteGeofenceAlert = async (id) => {
		setSubmitting(true);
		try {
			await makeApiCallAdmin(`/Geofence/${id}`, { method: "delete" });
			message.success("Alert deleted successfully");
			context.getGeofenceAlertList(true, id);
		} catch (error) {
			message.error(error.message);
		} finally {
			setSubmitting(false);
		}
	};

	const deleteAlert = async (id) => {
		setSubmitting(true);

		try {
			const results = await makeApiCallAdmin(`/Violation/${id}`, {
				method: "delete",
			});
			if (results) {
				console.log(results);
				message.success("Alert deleted successfully");
				context.getAlertList(true, id);
				console.log("del successful");
				refetchAlerts();
			}
		} catch (error) {
			message.error(error.message);
		} finally {
			setSubmitting(false);
		}
	};

	const refetchAlerts = async () => {
		const { data: geofenceAlerts } = await makeApiCallAdmin("/Geofence");
		const { data: otherAlerts } = await makeApiCallAdmin("/Violation");
		const editedAlerts = [...geofenceAlerts, ...otherAlerts];
		const alertsList = editedAlerts.map((alert) => ({
			...alert,
			type:
				alertTypes[
					alert.geofenceId
						? alert.violationType
						: alert.violationTypeParams.violationType
				],
		}));
		// editedAlerts.map((singleA) => {
		// 	console.log({ ...singleA }, singleA.violationTypeParams);
		// });

		setUpdatedAlerts(alertsList);
	};

	const fetchFilteredAlerts = () => {
		if (filter) {
			if (filter === "all") {
				setFilteredAlerts(updatedAlerts);
			} else {
				setFilteredAlerts(
					updatedAlerts.filter(
						(x) => x.type.toLowerCase() === filter.toLowerCase()
					)
				);
			}
		} else {
			setFilteredAlerts(updatedAlerts);
		}
	};

	return (
		// <AlertPage
		// 	{...props}
		// 	nodesWithChildren={formatNodesWithChildren(props.nodesWithChildren)}
		// 	alerts={filter ? filteredAlerts : updatedAlerts}
		// 	contacts={contacts}
		// 	logout={context.logout}
		// 	updateState={props.updateState}
		// 	buttonLoading={isSubmitting}
		// 	createAlert={createAlert}
		// 	updateAlert={updateAlert}
		// 	deleteAlert={deleteAlert}
		// 	getFilteredAlerts={filterAlerts}
		// 	deleteGeofence={deleteGeofenceAlert}
		// 	createGeofenceAlert={createGeofenceAlert}
		// 	updateGeofenceAlert={updateGeofenceAlert}
		// />
		<NewAlertPage
			{...props}
			nodesWithChildren={formatNodesWithChildren(props.nodesWithChildren)}
			alerts={filteredAlerts}
			contacts={contacts}
			filteredAlerts={filteredAlerts}
			refetchList={refetchAlerts}
			logout={context.logout}
			updateState={props.updateState}
			buttonLoading={isSubmitting}
			createAlert={createAlert}
			updateAlert={updateAlert}
			deleteAlert={deleteAlert}
			setFilter={setFilter}
			deleteGeofence={deleteGeofenceAlert}
			createGeofenceAlert={createGeofenceAlert}
			updateGeofenceAlert={updateGeofenceAlert}
		/>
	);
}

const alertTypes = {
	0: "geofence",
	101: "geofence",
	102: "speed",
	103: "asset",
	104: "driver",
	105: "device",
};

async function fetchAlerts() {
	const { data: geofenceAlerts } = await makeApiCallAdmin("/Geofence");
	const { data: otherAlerts } = await makeApiCallAdmin("/Violation");
	const alerts = [...geofenceAlerts, ...otherAlerts];
	return alerts.map((alert) => ({
		...alert,
		type:
			alertTypes[
				alert.geofenceId
					? alert.violationType
					: alert.violationTypeParams.violationType
			],
	}));
}

export default Alerts;
