import { message } from "antd";
import { Formik } from "formik";
import * as React from "react";
import { NavLink } from "react-router-dom";
import * as yup from "yup";
import { makeApiCallAdmin } from "../api";
import AuthContainer from "../components/pages/Authentication/index";
import { ButtonComponent } from "../components/primitives/Buttons";
import { InputComponent } from "../components/primitives/Input";
import { Heading, Text } from "../components/primitives/Text";

function SignIn({ history, match: { url } }) {
	return (
		<AuthContainer url={url}>
			<Heading align="center" fontSize="30px">
				Forgot Password
			</Heading>
			<Formik
				initialValues={{ email: "" }}
				validationSchema={validationSchema}
				onSubmit={async (values, actions) => {
					console.log(values);
					try {
						let results = await makeApiCallAdmin("/Login/ForgotPassword", {
							method: "post",
							data: values,
						});

						localStorage.setItem("EZEETRACKER_RESET_EMAIL", values.email);
						message.success(results.msg);
						history.push("/verify");
					} catch (error) {
						message.error(error.message);
					} finally {
						actions.setSubmitting(false);
					}
				}}
			>
				{({
					values,
					errors,
					touched,
					handleChange,
					handleSubmit,
					isSubmitting,
				}) => (
					<form onSubmit={handleSubmit} style={{ marginTop: "2rem" }}>
						<InputComponent
							name="email"
							type="text"
							placeholder="Email Address"
							size="large"
							value={values.email}
							error={errors.email && touched.email}
							errorMessage={errors.email}
							onChange={handleChange}
						/>

						<ButtonComponent
							size="large"
							appearance="blue"
							htmlType="submit"
							loading={isSubmitting}
							className="mt-5 w-full"
						>
							Submit
						</ButtonComponent>
					</form>
				)}
			</Formik>
			<div className="sign-in-help__links">
				<Text align="center" marginTop="15px">
					<NavLink to="/">Back to Login</NavLink>
				</Text>
			</div>
		</AuthContainer>
	);
}

var validationSchema = yup.object().shape({
	email: yup
		.string()
		.email("This is an invalid email")
		.required("This field is required"),
});

export default SignIn;
