import PropTypes from 'prop-types';
import React from 'react';
import { NavLink } from 'react-router-dom';
import styled from 'styled-components';
import { black, gray } from '../../design-system/colors';
import { Logo } from '../../primitives/Logo';

const AuthHeader = styled.header`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 32px 56px;
  flex-flow: row wrap;
  /* position: fixed;
  width: 100%;
  background-color: white;
  z-index: 1030; */

  .authenticated-container {
    color: #000000;

    a {
      text-decoration: underline;
    }

    .authenticated {
      a {
        color: ${black};
        text-decoration: none;
      }
    }
  }
`;

const AuthContent = styled.div`
  max-width: ${props => props.width || '638px'};
  padding: 0 56px;
  margin: 0 auto;
  margin-top: ${props => props.marginTop};
  margin-bottom: 64px;

  .terms-of-service {
    a {
      color: ${gray.primary};
      text-decoration: underline;
    }
  }

  .sign-in-help__links {
    a {
      text-decoration: underline;
    }
    p,
    a {
      color: ${black};
    }
  }

  .steps-section {
    margin-bottom: 64px;
  }

  .recaptcha-container {
    display: flex;
    flex: 1;
    justify-content: center;
    margin-top: 30px;
  }
`;

const AuthContainer = ({ auth, children, marginTop, url, width }) => {
  return (
    <React.Fragment>
      <AuthHeader>
        <div className="logo-container">
          <Logo />
        </div>
        <div className="authenticated-container">
          {url &&
          url !== '/signin' &&
          url !== '/verify-email' &&
          url !== '/forgot-password' &&
          url !== '/reset-password' ? (
            auth ? (
              <span className="authenticated">
                <NavLink to="/">Sign out</NavLink>
              </span>
            ) : (
              <span className="not-authenticated">
                <span>Already have an account?</span> <NavLink to="/signin">Sign in</NavLink>
              </span>
            )
          ) : null}
        </div>
      </AuthHeader>
      <AuthContent marginTop={marginTop} width={width}>
        {children}
      </AuthContent>
    </React.Fragment>
  );
};

AuthContainer.defaultProps = {
  auth: '',
  url: '',
  marginTop: '100px',
  children: <div />
};

AuthContainer.propTypes = {
  auth: PropTypes.string,
  url: PropTypes.string,
  marginTop: PropTypes.string,
  children: PropTypes.node
};

export default AuthContainer;
