import { message, Select } from "antd";
import { Formik, withFormik } from "formik";
import React from "react";
import styled from "styled-components";
import { cache, mutate } from "swr";
import * as yup from "yup";
import { makeApiCallAdmin } from "../../../api";
import FolderSelector from "../../../Reports/FolderSelector";
import { useProfile } from "../../../utils/hooks";
import { ButtonComponent } from "../../primitives/Buttons";
import { AppInput, InputComponent } from "../../primitives/Input";
import { SaveAndCancelButtons } from "../../primitives/SaveAndCancelButtons";

const folderFormValidation = yup.object().shape({
	nodeDesc: yup.string().required("This field is required"),
});

export function CreateFolderForm({ isEdit, folder = {}, close }) {
	const [folders, setFolders] = React.useState([]);
	// const [selectedOptions, setSelectedOptions] = React.useState([]);
	const [selectedNodes, setSelectedNodes] = React.useState([]);
	const { clientId } = useProfile();

	async function fetchFolders(folderId = "") {
		try {
			const { data = [] } = await makeApiCallAdmin(`/MyFolder/${folderId}`);
			return data
				.filter(({ isNodeLeaf }) => !isNodeLeaf)
				.map(async ({ nodeDesc: label, nodeId: value }) => ({
					value,
					label,
					children: await fetchFolders(value),
				}));
		} catch (e) {
			message.error(e.message);
		}
	}

	React.useEffect(() => {
		async function fetchRootFolders() {
			try {
				const folders = await fetchFolders();
				setFolders(folders);
			} catch (e) {
				message.error(e.message);
			}
		}
		fetchRootFolders();
	}, []);

	return (
		<Formik
			onSubmit={async (values, actions) => {
				try {
					await makeApiCallAdmin("/MyFolder", { method: "post", data: values });
					// Refetch folders > https://github.com/vercel/swr/issues/418#issuecomment-637043907
					cache
						.keys() // get all the cached keys
						// filter by key, here you could also use a regex
						.filter((key) => key.includes("MyFolder"))
						// trigger the revalidation
						.forEach((key) => mutate(key, undefined, true));

					message.success("Folder Created Successfully");
					actions.resetForm();
					close();
				} catch (error) {
					message.error(error.message);
				} finally {
					actions.setSubmitting(false);
				}
			}}
			validationSchema={folderFormValidation}
			initialValues={{
				nodeDesc: isEdit ? folder.nodeDesc : "",
				parentNodeId: isEdit ? folder.parentNodeId : "",
				parentNodeDesc: isEdit ? folder.parentNodeDesc : "",
			}}
		>
			{({
				values,
				errors,
				touched,
				handleChange,
				handleBlur,
				handleSubmit,
				isSubmitting,
				setFieldValue,
			}) => (
				<StyledCreateFolderForm onSubmit={handleSubmit}>
					<FolderSelector
						folder={selectedNodes}
						onInputChange={(name, options) => {
							setSelectedNodes(options);
							setFieldValue("parentNodeId", options[options.length - 1]);
						}}
						clientId={clientId}
						style={{ maxWidth: "initial" }}
					/>
					<AppInput
						value={values.nodeDesc}
						name="nodeDesc"
						type="text"
						onChange={handleChange}
						placeholder="Enter Folder Name"
						size="large"
						error={errors.nodeDesc}
						errorMessage={errors.nodeDesc}
					/>
					<SaveAndCancelButtons
						onCancel={close}
						loading={isSubmitting}
						type="submit"
					/>
				</StyledCreateFolderForm>
			)}
		</Formik>
	);
}

var StyledCreateFolderForm = styled.form`
	.ant-input.ant-cascader-input {
		border-color: #000000;
		border-radius: 0;
		color: #000000;

		&:focus {
			outline: none;
			box-shadow: none;
			border-color: #000000;
		}
	}
`;

const AddOrganizationNameFormComponent = ({
	values,
	errors,
	touched,
	loading,
	handleSubmit,
	handleChange,
}) => (
	<form onSubmit={handleSubmit}>
		<InputComponent
			name="ClientName"
			marginTop="50px"
			size="large"
			placeholder="Enter your Organisation Name"
			error={touched.ClientName && errors.ClientName}
			errorMessage={errors.ClientName}
			value={values.ClientName}
			onChange={handleChange}
		/>
		<ButtonComponent
			fullWidth
			size="large"
			appearance="blue"
			htmlType="submit"
			marginTop="20px"
			loading={loading}
		>
			Next
		</ButtonComponent>
	</form>
);

const AddOrganizationNameFormValidation = yup.object().shape({
	ClientName: yup.string().required("This field is required"),
});

export const AddOrganizationNameForm = withFormik({
	mapPropsToValues: (props) => ({
		ClientName: props.ClientName || "",
	}),
	handleSubmit: (values, { props, setSubmitting, resetForm }) => {
		props.onSubmit(values);
	},
	validationSchema: AddOrganizationNameFormValidation,
})(AddOrganizationNameFormComponent);
