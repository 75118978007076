import { Button, Checkbox, message, Modal, Radio } from 'antd';
import debounce from 'lodash/debounce';
import * as React from 'react';
import { makeApiCallAdmin } from '../api';
import { gray } from '../components/design-system/colors';
import { Fleet } from '../components/pages/Fleet';
import LinkAssetForm from '../components/pages/Fleet/DeviceManagement/LinkAssetForm';
import { AppInput } from '../components/primitives/Input';
import { AppModal, ToggleModal } from '../components/primitives/Modal';
import { AppTable } from '../components/primitives/Table';
import { Heading } from '../components/primitives/Text';
import { useDevices } from '../utils/hooks';
const { confirm } = Modal;

function Devices() {
  const { devices = [], loading, revalidate } = useDevices();
  const [searchKeyword, setSearchKeyword] = React.useState('');
  const [searchResult, setSearchResult] = React.useState([]);
  const [devicesToShow, setDevicesToShow] = React.useState('all');

  const clientFilters = Object.values(
    devices.reduce(
      (acc, { clientName }) => ({
        ...acc,
        ...(clientName && { [clientName]: { text: clientName, value: clientName } }),
      }),
      {}
    )
  );

  const deviceFilters = Object.values(
    devices.reduce(
      (acc, { productName }) => ({
        ...acc,
        ...(productName && { [productName]: { text: productName, value: productName } }),
      }),
      {}
    )
  );

  const search = debounce((value = '') => {
    const keyword = value.toLowerCase();
    setSearchKeyword(keyword);

    setSearchResult(
      devices.filter(
        ({ productName, clientName, plateNo, imei }) =>
          productName.toLowerCase().includes(keyword) ||
          plateNo.toLowerCase().includes(keyword) ||
          imei.toLowerCase().includes(keyword) ||
          clientName.toLowerCase().includes(keyword)
      )
    );
  }, 500);

  const unlinkDevice = ({ imei, deviceId }) => {
    confirm({
      title: 'Are you sure you want to unlink this device?',
      onOk: async () => {
        try {
          await makeApiCallAdmin(`/Device/${deviceId}/Unlink`, {
            method: 'put',
            data: { imei },
          });
          message.success('Device unlinked successfully');
          revalidate();
        } catch (e) {
          message.error(e.message);
        }
      },
      onCancel() {},
      okText: 'Unlink',
    });
  };

  const columns = [
    {
      title: 'DEVICE NAME',
      dataIndex: 'productName',
      filters: deviceFilters,
      onFilter: (value, record) => {
        return record.productName === value;
      },
    },
    {
      title: 'DEVICE IMEI',
      dataIndex: 'imei',
    },
    {
      title: 'SERIAL NUMBER',
      dataIndex: 'serialNo',
    },
    {
      title: 'CLIENT',
      dataIndex: 'clientName',
      filters: clientFilters,
      onFilter: (value, record) => {
        return record.clientName === value;
      },
    },
    {
      title: 'ASSET',
      dataIndex: 'plateNo',
      render: (PlateNo) => PlateNo || '—',
    },
    {
      title: 'Assigned',
      key: 'assignStatus',
      dataIndex: 'assignStatus',
      render: (assignedStatus) => (assignedStatus ? <Checkbox checked={assignedStatus} /> : null),
    },
    {
      title: 'Linked',
      key: 'isDeviceLinked',
      dataIndex: 'isDeviceLinked',
      render: (isDeviceLinked) => (isDeviceLinked ? <Checkbox checked={isDeviceLinked} /> : null),
    },
    {
      title: 'Active',
      key: 'isDeviceActive',
      dataIndex: 'isDeviceActive',
      render: (isDeviceActive) => (isDeviceActive ? <Checkbox checked={isDeviceActive} /> : null),
    },
    {
      key: 'Manage',
      dataIndex: 'isDeviceLinked',
      render: (status, device) => (
        <ToggleModal>
          {(showModal, onOpenModal, onCloseModal) => {
            // only devices assigned to an asset can be linked/unlinked
            if (device.assignStatus === 0) return null;
            if (!status) {
              return (
                <React.Fragment>
                  <AppModal
                    title={`Link Device - ${device.productName}`}
                    visible={showModal}
                    onCancel={onCloseModal}
                  >
                    <LinkAssetForm
                      device={device}
                      close={() => {
                        onCloseModal();
                        revalidate();
                      }}
                    />
                  </AppModal>
                  <Button type="primary" onClick={onOpenModal}>
                    Link
                  </Button>
                </React.Fragment>
              );
            }
            return (
              <Button type="danger" ghost onClick={() => unlinkDevice(device)}>
                Unlink
              </Button>
            );
          }}
        </ToggleModal>
      ),
    },
  ];

  const filteredDevices = (searchKeyword ? searchResult : devices).filter(({ isDeviceLinked }) =>
    devicesToShow === 'all' ? true : devicesToShow === 'linked' ? isDeviceLinked : !isDeviceLinked
  );

  return (
    <Fleet isFixed={false}>
      <div className="px-8 xl:px-16 mx-auto mt-12">
        <div>
          <Heading fontSize="16px">Search Devices</Heading>
          <div className="flex justify-between">
            <div className="w-1/2 max-w-lg">
              <AppInput
                placeholder="Search by Device Name, Client, Asset or IMEI"
                size="large"
                borderColor={gray.primary}
                marginTop="0"
                onChange={(e) => search(e.target.value)}
              />
            </div>
            <Radio.Group
              value={devicesToShow}
              onChange={({ target: { value } }) => setDevicesToShow(value)}
              size="large"
            >
              <Radio.Button value="all">All</Radio.Button>
              <Radio.Button value="linked">Linked</Radio.Button>
              <Radio.Button value="unlinked">Unlinked</Radio.Button>
            </Radio.Group>
          </div>
        </div>
        <div className="pb-8">
          <AppTable
            hasBorder
            border_color={gray.light}
            rowKey="deviceId"
            loading={loading}
            dataSource={filteredDevices}
            columns={columns}
            marginTop="50px"
            pagination={devices.length > 10 ? { pageSize: 10 } : false}
          />
        </div>
      </div>
    </Fleet>
  );
}

export default Devices;
