import React from 'react';
import { Redirect, Route } from 'react-router';
import { useProfile } from './utils/hooks';

export function ProtectedRoute(props) {
  const user = useProfile();

  const referrer = props.location.pathname;

  if (!user || (!user.agentId && !user.clientId)) {
    return <Redirect to={{ pathname: '/signin', state: { referrer } }} />;
  } else if (user && user.userType === 1 && !user.clientId) {
    return <Redirect to={{ pathname: '/set-client', state: { referrer } }} />;
  }

  return <Route {...props} />;
}
