import React from 'react';
import styled, { css } from 'styled-components';
import { gray } from '../design-system/colors';

export const CustomLink = styled.a`
  ${css`
    ${props => props.css};
  `};
  border-bottom: ${props => (props.border ? '1px solid #000000' : '')};
  color: ${props => props.color};
  cursor: pointer;
  display: inline-block;
`;

const DivStyle = ({ cssProp }) => css`
  ${cssProp};
`;

export const Div = styled.div`
  ${DivStyle};
`;

export const EmptyStateComponent = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  margin: ${props => props.margin || '64px'} 0;
  text-align: center;
  .EmptyStateComponent {
    &__icon-container {
      width: 80px;
      margin-bottom: 8px;
      img {
        width: 100%;
      }
    }
    &__text {
      font-size: 20px;
      margin: 0;
      margin-bottom: 8px;
    }
    &__sub-text {
      font-size: 14px;
      color: ${gray.ui02};
      margin: 0;
    }
  }
  ${props => props.css};
`;

const calcCellWidth = numberOfCells => {
  if (numberOfCells === 1) {
    return `
    .cell {
      width: 100%;
    }`;
  }
  return `
  .cell {
    width: calc(50% - 2rem);
  }`;
};

export const Grid = ({ gridCss, children, numberOfCells, className }) => (
  <Div
    cssProp={`
      .cell {
        margin: 1rem;
      }
      ${gridCss};
      @media screen and (min-width: 600px) {
        display: flex;
        flex-wrap: wrap;
        ${calcCellWidth(numberOfCells)};
      }
      @media screen and (min-width: 1000px) {
        .cell {
          width: calc((100% / ${numberOfCells}) - 2rem);
        }
      }
    `}
    className={className}
  >
    {children}
  </Div>
);
