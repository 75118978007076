import { InputNumber } from "antd";
import React from "react";
import styled from "styled-components";
import { makeApiCallAdmin } from "../api";
import { ButtonComponent } from "../components/primitives/Buttons";
import AssetSelector from "./AssetSelector";
import DateRange from "./DateRangeSelector";
import DriverSelector from "./DriverSelector";
import FolderSelector from "./FolderSelector";
import { generateTelerikReport } from "./generateReports";
import TimeUnitSelector from "./TimeUnitSelector";

const StyledReportHeader = styled.div`
	display: flex;
	justify-content: flex-start;
	height: 100%;
	padding: 30px 30px 16px;
	border-bottom: 1px solid rgba(0, 0, 0, 0.1);

	.ant-select-selection,
	.ant-calendar-picker,
	.ant-calendar-picker-input,
	.ant-input-number,
	.ant-input {
		border-radius: 0;
		border-color: black;
	}

	.ant-input-number {
		width: 120px;
	}

	& > .ant-select-lg {
		min-width: 160px;
		max-width: 200px;
	}

	& > * {
		margin-left: 10px !important;
		margin-right: 10px !important;
	}

	& > .submit_button {
		margin-left: auto !important;
		margin-right: 0 !important;
	}

	.ant-input:focus,
	.ant-select-selection:focus {
		box-shadow: none;
	}
`;

class ReportViewHeader extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			speed: 0,
			assetList: [],
			folder: [],
			driver: [],
			generatingReport: false,
		};

		this.getAssets = this.getAssets.bind(this);
		this.onInputChange = this.onInputChange.bind(this);
		this.getOptionsFromAssets = this.getOptionsFromAssets.bind(this);
	}

	onInputChange = (property, value) => {
		this.setState({ [property]: value });
		if (property === "folder") {
			this.setState({ asset: "" });
			this.getAssets(value);
		}
		if (property === "driver") {
			this.setState({ driver: [value] });
		}
	};

	async getAssets(NodeId) {
		const { data } = await makeApiCallAdmin(`/MyFolder/${NodeId}`);
		const assetList = this.getOptionsFromAssets(data);
		return this.setState({
			assetList,
			...(!this.state.folder && { rootNode: data[0].parentNodeId }),
		});
	}

	getOptionsFromAssets(assets) {
		return assets.map(({ nodeDesc, nodeId }) => {
			return {
				value: `${nodeDesc} ${nodeId}`,
				label: nodeDesc,
			};
		});
	}

	verifyControl = (controlName) => {
		const {
			report: { controls },
		} = this.props;
		return controls.some(({ controlLabel }) => controlLabel === controlName);
	};

	generateReport = async () => {
		await this.props.showReport();
		await this.setState({ generatingReport: true });
		const {
			report: { value: report, text },
			clientId,
		} = this.props;
		const {
			folder,
			rootNode,
			asset,
			speed,
			driver,
			startDate,
			endDate,
			timeLimit,
			timeUnit,
		} = this.state;
		const data = {
			folder,
			rootNode,
			asset,
			driver,
			speed,
			startDate,
			endDate,
			timeLimit,
			timeUnit,
		};
		generateTelerikReport({ data, report, reportName: text, clientId })
			.then(() => {
				console.log("Generated");
			})
			.catch((e) => {
				console.log(e);
			})
			.finally(() => this.setState({ generatingReport: false }));
	};

	render() {
		const {
			folder,
			asset,
			startDate,
			speed,
			endDate,
			driver,
			assetList,
			timeLimit,
			timeUnit,
			generatingReport,
		} = this.state;
		const { clientId } = this.props;
		return (
			<StyledReportHeader>
				{this.verifyControl("Fleet") && (
					<FolderSelector
						folder={folder}
						onInputChange={this.onInputChange}
						clientId={clientId}
					/>
				)}
				{this.verifyControl("Drivers") && (
					<DriverSelector
						drver={driver}
						onInputChange={this.onInputChange}
						clientId={clientId}
					/>
				)}
				{this.verifyControl("Assets") && (
					<AssetSelector
						asset={asset}
						onInputChange={this.onInputChange}
						options={assetList}
					/>
				)}
				{this.verifyControl("Speed") && (
					<InputNumber
						value={speed}
						onChange={(value) => this.onInputChange("speed", value)}
						min={0}
						size="large"
						placeholder="Speed"
					/>
				)}
				{this.verifyControl("Time Limit") && (
					<React.Fragment>
						<InputNumber
							value={timeLimit}
							onChange={(value) => this.onInputChange("timeLimit", value)}
							min={0}
							size="large"
							placeholder="Time Limit"
						/>
						<TimeUnitSelector
							onInputChange={this.onInputChange}
							timeUnit={timeUnit}
						/>
					</React.Fragment>
				)}
				{this.verifyControl("Begin Time") && this.verifyControl("End Time") && (
					<DateRange
						onInputChange={this.onInputChange}
						startDate={startDate}
						endDate={endDate}
					/>
				)}
				<ButtonComponent
					appearance="blue"
					onClick={this.generateReport}
					loading={generatingReport}
					className="submit_button"
				>
					Generate Report
				</ButtonComponent>
			</StyledReportHeader>
		);
	}
}

export default ReportViewHeader;
