import { Button, Checkbox, Dropdown, Menu, message } from "antd";
import debounce from "lodash/debounce";
import * as React from "react";
import styled from "styled-components";
import { makeApiCallAdmin } from "../api";
import { gray } from "../components/design-system/colors";
import { customFonts } from "../components/design-system/fonts";
import { Fleet } from "../components/pages/Fleet";
import { ButtonComponent } from "../components/primitives/Buttons";
import { AppInput } from "../components/primitives/Input";
import { AppModal, confirm, ToggleModal } from "../components/primitives/Modal";
import { AppTable } from "../components/primitives/Table";
import { Heading } from "../components/primitives/Text";
import { useAssets, useProfile } from "../utils/hooks";
import AssetForm from "./AssetForm";
import AssignDeviceForm from "./AssignDeviceForm";
import AssignDriverForm from "./AssignDriverForm";

function Assets() {
	const { assets = [], loading, revalidate } = useAssets();
	const { userType } = useProfile();
	const [assetsCopy, setAssetsCopy] = React.useState();
	// const [deletingAsset, setDeletingAsset] = React.useState(false);

	const searchAssets = debounce((value) => {
		let search = value.toLowerCase();
		if (search !== "") {
			const filteredAssets = assets.filter(
				(asset) =>
					asset.tag.toLowerCase().includes(search) ||
					asset.plateNo.toLowerCase().includes(search)
			);
			setAssetsCopy(filteredAssets);
		} else {
			setAssetsCopy(assets);
		}
	}, 500);

	const unlinkDevice = async (deviceId, imei) => {
		confirm({
			title: "Do you want to unlink this asset?",
			okText: "Yes",
			cancelText: "No",
			onOk: async () => {
				try {
					await makeApiCallAdmin(`/Device/${deviceId}/Unlink`, {
						method: "put",
						data: { imei },
					});
					message.success("Asset unlinked successfully");
					revalidate();
				} catch (e) {
					message.error(e.message);
				}
			},
		});
	};

	const unassignDevice = async (deviceId) => {
		confirm({
			title: "Do you want to unassign this asset?",
			okText: "Yes",
			cancelText: "No",
			onOk: async () => {
				try {
					await makeApiCallAdmin(`/Device/${deviceId}/Unassign`, {
						method: "put",
					});
					message.success("Asset unassigned successfully");
					revalidate();
				} catch (e) {
					message.error(e.message);
				}
			},
		});
	};

	const unassignDriver = async (asset) => {
		confirm({
			title: `Are you sure you want to unassign ${asset.driver} from this asset?`,
			okText: "Yes",
			cancelText: "No",
			onOk: async () => {
				try {
					await makeApiCallAdmin(`/Assets/${asset.assetId}/UnassignDriver`, {
						method: "put",
					});
					message.success("Driver unassigned successfully");
					revalidate();
				} catch (e) {
					message.error(e.message);
				}
			},
		});
	};

	const deleteAsset = async (assetId) => {
		confirm({
			title: "Do you want to delete this asset?",
			okText: "Yes",
			cancelText: "No",
			onOk: async () => {
				try {
					await makeApiCallAdmin(`/Assets/${assetId}`, { method: "delete" });
					message.success("Asset deleted successfully");
					revalidate();
				} catch (e) {
					message.error(e.message);
				}
			},
		});
	};

	const columns = [
		{
			title: "Name",
			dataIndex: "tag",
			key: "Name",
			render: (text, record) => <span>{record.tag || `-`}</span>,
		},
		{
			title: "vin",
			dataIndex: "vin",
			key: "vin",
		},
		{
			title: "Plate Number",
			key: "plateNo",
			dataIndex: "plateNo",
		},
		{
			title: "Color",
			key: "Color",
			dataIndex: "bodyColour",
			render: (text, record) => (
				<div
					style={{
						backgroundColor: record.bodyColour,
						width: "20px",
						height: "20px",
						borderRadius: "4px",
					}}
				/>
			),
		},
		{
			title: "Make",
			key: "Make",
			dataIndex: "manufacturer",
		},
		{
			title: "Model",
			key: "Model",
			dataIndex: "model",
		},
		{
			title: "Type",
			key: "Type",
			dataIndex: "vehecleType",
		},
		{
			title: "Driver",
			dataIndex: "driver",
		},
		{
			title: "Assigned",
			key: "assignStatus",
			dataIndex: "assignStatus",
			render: (assignedStatus) =>
				assignedStatus ? <Checkbox checked={assignedStatus} /> : null,
		},
		{
			title: "Linked",
			key: "DeviceLinked",
			dataIndex: "deviceLinked",
			render: (isDeviceLinked) =>
				isDeviceLinked ? <Checkbox checked={isDeviceLinked} /> : null,
		},
		{
			title: "Device IMEI",
			key: "imei",
			dataIndex: "imei",
		},
		{
			key: "asset",
			render: (asset) => {
				const cannotDelete =
					asset.deviceLinked || asset.assignStatus || userType !== 1;

				const menu = (
					<Menu style={{ minWidth: "5rem" }}>
						<Menu.Item key="0">
							<ToggleModal>
								{(showModal, onOpenModal, onCloseModal) => {
									return (
										<React.Fragment>
											<AppModal
												title="Edit Asset"
												visible={showModal}
												onCancel={onCloseModal}
											>
												<AssetForm
													asset={asset}
													close={() => {
														onCloseModal();
														revalidate();
													}}
													isEdit
												/>
											</AppModal>
											<div onClick={onOpenModal}>Edit</div>
										</React.Fragment>
									);
								}}
							</ToggleModal>
						</Menu.Item>
						<Menu.Item key="2" disabled={userType !== 1}>
							{asset.deviceLinked ? (
								<div onClick={() => unlinkDevice(asset.deviceId, asset.imei)}>
									Unlink Device
								</div>
							) : asset.assignStatus === 1 ? (
								<div onClick={() => unassignDevice(asset.deviceId)}>
									Unassign Device
								</div>
							) : (
								<ToggleModal>
									{(showModal, onOpenModal, onCloseModal) => {
										return (
											<React.Fragment>
												<AppModal
													title="Assign Device"
													visible={showModal}
													onCancel={onCloseModal}
												>
													<AssignDeviceForm
														asset={asset}
														close={() => {
															onCloseModal();
															revalidate();
														}}
													/>
												</AppModal>
												<div onClick={onOpenModal}>Assign Device</div>
											</React.Fragment>
										);
									}}
								</ToggleModal>
							)}
						</Menu.Item>
						<Menu.Item disabled={!asset.deviceLinked}>
							{`${asset.driver}`.trim() ? (
								<div role="button" onClick={() => unassignDriver(asset)}>
									Unassign Driver
								</div>
							) : (
								<ToggleModal>
									{(showModal, onOpenModal, onCloseModal) => {
										return (
											<React.Fragment>
												<AppModal
													title="Assign Driver"
													visible={showModal}
													onCancel={onCloseModal}
												>
													<AssignDriverForm
														asset={asset}
														close={() => {
															onCloseModal();
															revalidate();
														}}
													/>
												</AppModal>
												<div onClick={onOpenModal}>Assign Driver</div>
											</React.Fragment>
										);
									}}
								</ToggleModal>
							)}
						</Menu.Item>
						<Menu.Divider />
						<Menu.Item
							onClick={() => deleteAsset(asset.assetId)}
							disabled={cannotDelete}
							title={cannotDelete ? "Cannot delete linked/assigned assets" : ""}
							key="3"
						>
							Delete
						</Menu.Item>
					</Menu>
				);

				return (
					<Dropdown overlay={menu} trigger={["click"]}>
						<Button shape="circle" icon="more" type="link" />
					</Dropdown>
				);
			},
		},
	];

	return (
		<Fleet isFixed={false}>
			<StlyedAssetManagement>
				<div className="header-section__container">
					<div className="header-section__container__secondary">
						<Heading>Asset Details</Heading>
						{/* <a href="/static/files/Asset_Upload_Template.xlsx" download>
              Download Sample CSV file
            </a> */}
					</div>
					<div className="header-section">
						<div className="filter-section">
							<form className="filter-section-form">
								<AppInput
									placeholder="Filter Results"
									size="large"
									borderColor={gray.primary}
									marginTop="0"
									onChange={(e) => searchAssets(e.target.value)}
								/>
							</form>
						</div>
						<div className="actions-section">
							<ToggleModal>
								{(showModal, onOpenModal, onCloseModal) => {
									return (
										<React.Fragment>
											<AppModal
												title="Add New Asset"
												visible={showModal}
												onCancel={onCloseModal}
											>
												<AssetForm
													close={() => {
														onCloseModal();
														revalidate();
													}}
												/>
											</AppModal>
											<ButtonComponent onClick={onOpenModal}>
												Create Asset
											</ButtonComponent>
										</React.Fragment>
									);
								}}
							</ToggleModal>
							{/* <ToggleModal>
              {(showModal, onOpenModal, onCloseModal) => {
                return (
                  <React.Fragment>
                    <AppModal title="Upload file" visible={showModal} onCancel={onCloseModal}>
                      <FileUpload
                        marginBottom="32px"
                        customRequest={(data) => this.assetBulkUpload(data, onCloseModal)}
                      />
                      {AlreadyExists.length > 0 && (
                        <div>
                          <Heading
                            fontSize="16px"
                            marginTop="16px"
                            style={{ fontWeight: 'bold' }}
                          >
                            Assets with the following plate numbers already exist
                          </Heading>
                          {AlreadyExists.map((item) => (
                            <Text marginTop="8px">{item.PlateNo}</Text>
                          ))}
                        </div>
                      )}
                    </AppModal>
                    <ButtonComponent onClick={onOpenModal}>Bulk Operation</ButtonComponent>
                  </React.Fragment>
                );
              }}
            </ToggleModal> */}
						</div>
					</div>
				</div>
				<div className="assets-table">
					<AppTable
						hasBorder
						border_color={gray.light}
						rowKey="assetId"
						loading={loading}
						columns={columns}
						dataSource={assetsCopy || assets}
						marginTop="50px"
						pagination={assets.length > 20 ? { pageSize: 20 } : false}
					/>
				</div>
			</StlyedAssetManagement>
		</Fleet>
	);
}

var StlyedAssetManagement = styled.div`
	padding: 0 32px;
	margin-top: 48px;
	margin-left: auto;
	margin-right: auto;

	@media screen and (min-width: 1200px) {
		padding: 0 64px;
	}

	.header-section__container {
		&__secondary {
			display: flex;
			align-items: center;
			justify-content: space-between;
			margin-bottom: 20px;
			& > h1 {
				font-family: ${customFonts.circular}!important;
				font-size: 16px;
				margin: 0;
				font-weight: 700;
			}
			& > a {
				font-size: 12px;
				font-family: ${customFonts.circular}!important;
			}
		}
		.header-section {
			display: flex;
			align-items: center;
			justify-content: space-between;
			flex-flow: row wrap;

			.filter-section {
				width: 50%;
				max-width: 500px;

				&-form {
					@media (max-width: 601px) {
						margin-bottom: 8px;
					}
				}
			}

			.actions-section {
				display: flex;
				button {
					border-color: ${gray.primary};
					&:first-of-type {
						/* border-right: none; */
					}
				}
			}
		}
	}
	.assets-table {
		padding-bottom: 32px;
	}
`;

export default Assets;
