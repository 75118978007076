import * as React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { SettingsContainer } from '../components/pages/Settings/components';
import { AppNavbar } from '../components/primitives/Navbar';
import { AppContext } from '../DataProvider';
import { useProfile } from '../utils/hooks';
import BusinessInfoPage from './BusinessInfo';
import ClientsPage from './Clients/index';
import CostPlans from './CostPlans';
import { PersonalInfoPage } from './PersonalInfo';
import Users from './Users';

export function SettingsIndex({ match: { path } }) {
  const context = React.useContext(AppContext);
  const user = useProfile();

  const { updatePassword, updatePreferences, getLocalPreferences, updateState } = context;

  return (
    <>
      <AppNavbar />
      <SettingsContainer>
        <Switch>
          <Redirect key="redirect" exact from="/settings" to="/settings/personal-information" />
          <Route
            exact
            path={`${path}/personal-information`}
            render={(props) => (
              <PersonalInfoPage
                {...props}
                user={user}
                getLocalPreferences={getLocalPreferences}
                updatePassword={updatePassword}
                updatePreferences={updatePreferences}
              />
            )}
          />
          <Route
            exact
            path={`${path}/business-information`}
            render={(props) => <BusinessInfoPage />}
          />
          {user.userType === 1 && (
            <Route path={`${path}/cost-plans`} render={(props) => <CostPlans {...props} />} />
          )}
          {user.userType === 1 && (
            <Route
              exact
              path={`${path}/clients`}
              render={(props) => <ClientsPage {...props} user={user} updateState={updateState} />}
            />
          )}
          <Route exact path={`${path}/users`} render={(props) => <Users {...props} />} />
        </Switch>
      </SettingsContainer>
    </>
  );
}
