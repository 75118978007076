import { Dropdown, Icon, Menu, Switch, message } from "antd";
import React, { useContext, useEffect, useRef } from "react";
import { NavLink, Redirect } from "react-router-dom";
import styled from "styled-components";
import Axios from "axios";
import useSWR from "swr";
import { version } from "../../../package.json";
import { makeApiCallAdmin } from "../../api";
import { loadState, saveState } from "../../localStorage";
import { AppContext } from "../../DataProvider";
import {
	useBalance,
	useClients,
	useProfile,
	useUnresolvedIncidentsCount,
} from "../../utils/hooks";
import { black, blue, gray, green, red, white } from "../design-system/colors";
import { customFonts } from "../design-system/fonts";
import { Logo } from "./Logo";
import { Text } from "./Text";

export function AppNavbar({ history }) {
	const { setClient, logout } = useContext(AppContext);
	const { clients = [] } = useClients();
	const { balance } = useBalance();
	const { user } = loadState();
	const { updateState } = useContext(AppContext);
	const { userType, fullName, clientId, clientName, email } = useProfile();
	const { data: buildNumber } = useSWR("buildNo", async () => {
		const { data } = await makeApiCallAdmin("/SystemInfo");
		return data.appBuildVersion;
	});

	const notificationAudio = useRef(
		new Audio("/static/files/violation-notification.mp3")
	);

	const referenceCountRef = useRef(localStorage.getItem("referenceCount"));
	const { count, loading } = useUnresolvedIncidentsCount();

	useEffect(() => {
		if (count && !loading) {
			if (count > referenceCountRef.current) {
				notificationAudio.current.play();
			} else if (count < referenceCountRef.current) {
				referenceCountRef.current = count;
			}
		}
	}, [count]);

	const handleUpdateClient = async (data) => {
		const { clientId, clientName } = data;

		try {
			const { token } = await makeApiCallAdmin("/login/impclient", {
				data: { clientId: clientId },
				method: "post",
			});

			if (token) {
				Axios.defaults.headers.Authorization = `Bearer ${token}`;

				const updatedUser = { ...user, clientId, clientName, token };
				updateState(updatedUser, "user");
				saveState({ user: updatedUser });
				window.location.href = "/";
			}
		} catch (error) {
			console.log(error);
			message.error(
				"Error setting client details. Please check your network connection"
			);
		}
	};

	const initials = fullName[0] + fullName[fullName.indexOf(" ") + 1];

	return (
		<AppNavbarContainer>
			<div className="AppNavbarContainer-left-section">
				<Menu mode="horizontal">
					<Logo />
					<Menu.Item key="dashboard">
						<NavLink to="/dashboard/" activeClassName="active">
							Dashboard
						</NavLink>
					</Menu.Item>
					<Menu.Item key="fleet">
						<NavLink to="/fleet/" activeClassName="active">
							Fleet
						</NavLink>
					</Menu.Item>
					<Menu.Item key="alert">
						<NavLink to="/alert" activeClassName="active">
							Alert
						</NavLink>
					</Menu.Item>
					<Menu.Item key="reports">
						<NavLink to="/reports" activeClassName="active">
							Reports
						</NavLink>
					</Menu.Item>
					<Menu.Item key="settings">
						<NavLink to="/settings/" activeClassName="active">
							Settings
						</NavLink>
					</Menu.Item>
					<Menu.Item key="billing">
						<NavLink to="/billing/" activeClassName="active">
							Billing
						</NavLink>
					</Menu.Item>
				</Menu>
			</div>
			<div className="flex items-center">
				<NavLink to="/alert/incidents" className="flex">
					<Icon
						type="bell"
						style={{ color: "rgba(0, 0, 0, 0.65)" }}
						className={`text-xl mr-2 font-medium notification-icon ${
							count > 0 ? "has-notification" : ""
						}`}
						data-count={count > 99 ? "99+" : count}
						title="Incidents"
					/>
				</NavLink>
				{userType === 1 && (
					<Dropdown
						overlay={
							<StyledMenu>
								{clients.map((client) => {
									return (
										<Menu.Item
											key={client.clientId}
											className={`${
												client.clientId === clientId ? "active" : ""
											} h-10`}
											onClick={() => {
												// setClient(client);
												handleUpdateClient(client);
											}}
										>
											<div className="content">
												<span className="client-name">{client.clientName}</span>
												<span
													className={`balance ${
														Number(client.balance) < 0 ? "negative" : "positive"
													}`}
												>
													₦{Number(client.balance).toLocaleString()}
												</span>
											</div>
										</Menu.Item>
									);
								})}
							</StyledMenu>
						}
						trigger={["click"]}
					>
						<a
							className="flex items-center justify-center text-black h-8 px-4 mr-2"
							href="#"
							style={{
								backgroundColor: blue.faint,
								borderRadius: "20px",
							}}
						>
							{clientName}
						</a>
					</Dropdown>
				)}
				<Dropdown
					overlay={
						<StyledMenu selectable={false}>
							<Menu.Item key="userDetails" className="pointer-events-none">
								<div className="flex flex-col justify-center items-center py-1">
									<div
										className="flex items-center justify-center text-white rounded-full h-16 w-16 my-2"
										style={{ backgroundColor: blue.primary }}
									>
										{initials}
									</div>
									<span className="text-base text-black">{fullName}</span>
									<span className="text-xs">{email}</span>
								</div>
							</Menu.Item>
							<Menu.Divider style={{ margin: 0 }} />
							<Menu.Item key="balance" className="pointer-events-none">
								<div className="flex justify-between items-center py-1">
									<span className="">Balance: </span>
									<span
										className={`balance ${
											Number(balance) < 0 ? "negative" : "positive"
										}`}
									>
										₦{Number(balance).toLocaleString()}
									</span>
								</div>
							</Menu.Item>
							<Menu.Divider style={{ margin: 0 }} />
							<Menu.Item key="buildNumber" className="pointer-events-none">
								<div className="py-1">
									<span>Build Version:</span>
									<div className="flex justify-between text-xs">
										<span>Server:</span>
										<span>{buildNumber}</span>
									</div>
									<div className="flex justify-between text-xs">
										<span>Client:</span>
										<span>{version}</span>
									</div>
								</div>
							</Menu.Item>
							<Menu.Divider style={{ margin: 0 }} />
							<Menu.Item key="terms-and-conditions">
								<div className="py-1">
									<a
										href="https://legal.lubred.net/flitrack/terms-and-conditions/terms-and-conditions.html"
										target="_blank"
										rel="noopener noreferer"
										className="py-1"
									>
										Terms & Conditions
									</a>
								</div>
							</Menu.Item>
							<Menu.Divider style={{ margin: 0 }} />
							<Menu.Item key="logout">
								<div
									onClick={logout}
									className="py-1"
									style={{ color: red.primary }}
								>
									Logout
								</div>
							</Menu.Item>
						</StyledMenu>
					}
					trigger={["click"]}
				>
					<a
						className="flex items-center justify-center text-white rounded-full h-8 w-8"
						style={{ backgroundColor: blue.primary }}
						href="#"
					>
						{initials}
					</a>
				</Dropdown>
			</div>
		</AppNavbarContainer>
	);
}

export const SecondaryNavbar = ({ children }) => {
	return (
		<SecondaryNavbarContainer className="SecondaryNavbar">
			{children}
		</SecondaryNavbarContainer>
	);
};

export class CollapsibleNav extends React.Component {
	render() {
		const { groupName, children, isCollapsed, onToggleNav } = this.props;
		return (
			<CollapsibleNavContainer
				className="CollapsibleNav"
				isCollapsed={isCollapsed}
				onClick={isCollapsed ? onToggleNav : () => {}}
			>
				<div className="CollapsibleNav__header">
					<Text
						className="CollapsibleNav__root-group"
						fontWeight="700"
						color={black}
					>
						{groupName}
					</Text>
					<div className="CollapsibleNav__pin">
						<Text fontSize="12px" color={blue.primary}>
							Pin Open
						</Text>
						<Switch
							size="small"
							checked={!isCollapsed}
							onChange={onToggleNav}
						/>
					</div>
				</div>
				<div className="CollapsibleNav__content">{children}</div>
			</CollapsibleNavContainer>
		);
	}
}

var SecondaryNavbarContainer = styled.div`
	position: fixed;
	width: 100%;
	background: #fff;
	z-index: 1;
	border-bottom: 1px solid ${gray.light};
	padding-top: 56px;
	.ant-menu {
		font-size: 15px;
	}
	.ant-menu-horizontal {
		padding: 0 32px;
		line-height: 72px;
		border-bottom: none;
		.ant-menu-item {
			border-bottom: none;
			&:hover {
				border-bottom: none;
			}
		}
	}
`;

var CollapsibleNavContainer = styled.div`
	position: relative;
	padding: ${(props) => (props.isCollapsed ? "10px" : "20px")};
	border-right: 1px solid ${gray.light};
	background: ${white};
	height: 100vh;
	overflow: auto;
	width: 100%;
	transition: width 0.1s ease-in-out;
	will-change: width;
	overflow: auto;
	cursor: ${(props) => (props.isCollapsed ? "pointer" : "unset")};
	.CollapsibleNav {
		&__header {
			display: flex;
			align-items: center;
			justify-content: space-between;
			flex-flow: row wrap;
			margin-bottom: 8px;
			p {
				display: ${(props) => (props.isCollapsed ? "none" : "block")};
				margin: 0;
			}
		}
		&__pin {
			display: flex;
			align-items: center;
			p {
				margin-right: 8px;
			}
			.ant-switch-checked {
				background-color: ${green.primary};
			}
		}
		&__button {
			position: absolute;
			top: 40%;
			right: 0;
			color: ${gray.primary};

			&:hover {
				color: ${blue.primary};
			}
		}
		&__root-group {
			font-family: ${customFonts.circular}!important;
		}
	}
`;

var StyledMenu = styled(Menu)`
	border-radius: 5px;
	box-shadow: 0 0 2px 0 rgba(67, 90, 111, 0.47) !important;
	min-width: 250px;
	max-height: 75vh;
	overflow-y: auto;

	& > .ant-menu-item,
	& > .ant-dropdown-menu-item {
		&.active {
			background-color: ${blue.faint};
		}

		& > .content {
			display: grid;
			grid-template-columns: auto auto;
			column-gap: 20px;
			justify-content: space-between;
			height: 100%;
			align-items: center;

			& > .balance {
				font-weight: bold;

				&.positive {
					color: ${green.primary};
				}

				&.negative {
					color: ${red.primary};
				}
			}
		}
	}
`;

var AppNavbarContainer = styled.div`
	display: flex;
	justify-content: space-between;
	border-bottom: 1px solid ${gray.light};
	height: 56px;
	padding: 0 16px;
	background: white;
	position: fixed;
	z-index: 2;
	width: 100%;

	.ant-menu {
		font-size: 15px;
	}

	.ant-menu-horizontal {
		border-bottom: none;

		.ant-menu-item {
			height: 56px;
			font-size: 18px;
			margin: 0 20px;
			margin-top: -1px;
			padding: 0;

			&:hover {
				border-color: ${blue.primary};
				border-width: 4px;
			}

			a {
				color: ${black};

				&:focus {
					color: ${blue.primary};
				}

				&.active {
					color: ${blue.primary};

					&:before {
						content: "";
						display: block;
						border-bottom: 4px solid ${blue.primary};
					}
				}
			}

			&-selected {
				border-color: ${blue.primary};
				border-width: 4px;

				a {
					color: ${blue.primary};
				}
			}
		}
	}

	.notification-icon.has-notification {
		position: relative;

		&::after {
			content: attr(data-count);
			position: absolute;
			right: -0.25rem;
			top: -0.25rem;
			border-radius: 0.5rem;
			background-color: red;
			color: white;
			font-size: 0.5rem;
			height: 12px;
			min-width: 12px;
			display: flex;
			align-items: center;
			justify-content: center;
			padding: 0 3px;
		}
	}
`;
