import { Cascader } from 'antd';
import React from 'react';
import styled from 'styled-components';
import { makeApiCallAdmin } from '../api';

const StyledDiv = styled.div`
  position: relative;
  min-width: 160px;
  max-width: 200px;

  .tooltip {
    position: absolute;
    bottom: -38px;
    left: 21px;
    opacity: 1;
    transition: opacity 0.3s linear;
  }

  .tooltip.fade {
    opacity: 0;
  }

  .tooltip.hide {
    display: none;
  }

  .tooltip.show {
    display: inline;
  }

  .tooltip::before {
    position: absolute;
    content: 'i';
    width: 15px;
    height: 15px;
    background: transparent;
    border: 1px solid rgba(0, 0, 0, 0.65);
    border-radius: 50%;
    bottom: 3px;
    left: -18px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 0.6rem;
  }

  span + div > div > div {
    padding-top: 40px;
    background: transparent;
    box-shadow: none;
  }

  .ant-cascader-picker {
    width: 100%;
  }

  .ant-cascader-menu {
    background-color: #f0f0f0;
    line-height: 1.36;
    font-size: 14px;
    color: #000000;
    box-shadow: 0 2px 7px 0 rgba(0, 0, 0, 0.25);
    padding-top: 5px;
    padding-bottom: 5px;
    width: 240px;
    border-radius: 6px;
  }

  .ant-cascader-menu + .ant-cascader-menu {
    margin-top: 43px;
  }

  .ant-cascader-menu + .ant-cascader-menu + .ant-cascader-menu {
    margin-top: calc(43px * 2);
  }

  .ant-cascader-menu + .ant-cascader-menu + .ant-cascader-menu + .ant-cascader-menu {
    margin-top: calc(43px * 3);
  }

  .ant-cascader-menu-item {
    height: 19px;
    padding-top: 2px;
    padding-bottom: 2px;
    padding-left: 38px;
    box-sizing: content-box;
    position: relative;
  }

  .ant-cascader-menu-item::before {
    content: url('/static/img/folder-fill.svg');
    position: absolute;
    left: 19px;
  }

  .anticon.anticon-right > svg {
    color: transparent;
    border: 5px solid transparent;
    border-left-color: black;
  }

  .ant-cascader-menu-item + .ant-cascader-menu-item {
    margin-top: 9px;
  }

  .ant-cascader-menu-item-active:not(.ant-cascader-menu-item-disabled),
  .ant-cascader-menu-item-active:not(.ant-cascader-menu-item-disabled):hover,
  .ant-cascader-menu-item:hover {
    background-color: #4799fe !important;
  }

  input.ant-input.ant-cascader-input.ant-input-lg::placeholder {
    color: black;
  }
`;

class FolderSelector extends React.Component {
  constructor(props) {
    super(props);
    this.state = { options: [], toolTipVisible: 'hide' };

    this.getRootNodes = this.getRootNodes.bind(this);
    this.getChildrenNodes = this.getChildrenNodes.bind(this);
    this.loadData = this.loadData.bind(this);
    this.getOptionsFromNodes = this.getOptionsFromNodes.bind(this);
    this.toggleToolTip = this.toggleToolTip.bind(this);
    this.showToolTip = this.showToolTip.bind(this);
    this.hideToolTip = this.hideToolTip.bind(this);
    this.displayRender = this.displayRender.bind(this);
  }

  componentWillMount() {
    this.getRootNodes();
  }

  getOptionsFromNodes(nodes) {
    return nodes.map(({ nodeId, nodeDesc, isNodeLeaf }) => {
      return {
        value: nodeId,
        label: nodeDesc,
        isLeaf: isNodeLeaf,
      };
    });
  }

  async getRootNodes() {
    const { data } = await makeApiCallAdmin(`/MyFolder/Root`);
    const options = this.getOptionsFromNodes([{ nodeDesc: data.nodeDesc, nodeId: data.nodeId }]);
    return this.setState({ options });
  }

  async getChildrenNodes(parentNodeId) {
    const { data } = await makeApiCallAdmin(`/MyFolder/${parentNodeId}`);
    return data;
  }

  async loadData(selected) {
    const targetOption = selected[selected.length - 1];
    targetOption.loading = true; // show loading spinner
    const nodes = await this.getChildrenNodes(targetOption.value);
    const { data: assets } = await makeApiCallAdmin(`/MyFolder/${targetOption.value}`);
    targetOption.loading = false; // hide loading spinner
    /* append the subfolders count to the label and avoid repeating it in the
    case of multiple clicks in quick succession */
    targetOption.label = /(\([0-9]+\))/.test(targetOption.label)
      ? targetOption.label
      : `${targetOption.label} (${assets.length})`;
    if (nodes.length === 0) {
      // this makes the collapse arrow not show when no subfolders
      targetOption.isLeaf = true;
      return;
    }
    targetOption.children = this.getOptionsFromNodes(nodes);
    this.setState({ options: [...this.state.options] });
  }

  toggleToolTip = (dropdownVisible) => {
    if (dropdownVisible === true || dropdownVisible === undefined) {
      this.showToolTip();
    } else {
      this.hideToolTip();
    }
  };

  showToolTip() {
    this.setState({ toolTipVisible: 'show' });
  }

  hideToolTip() {
    this.setState({ toolTipVisible: 'fade' });
    setTimeout(() => this.setState({ toolTipVisible: 'hide' }), 300);
  }

  displayRender = (labels) => {
    // remove the subfolders count from the rendered label
    return labels.length > 0 && labels[labels.length - 1].split(' (')[0];
  };

  render() {
    const { folder, onInputChange, ...rest } = this.props;
    const { options, toolTipVisible } = this.state;
    return (
      <StyledDiv id="folder" {...rest}>
        <span className={`${toolTipVisible} tooltip`}>Double-click to select</span>
        <Cascader
          options={options}
          onChange={(e) => onInputChange('folder', e)}
          expandTrigger="hover"
          displayRender={this.displayRender}
          placeholder="Select Node"
          value={folder}
          changeOnSelect
          getPopupContainer={() => document.getElementById('folder')}
          size="large"
          loadData={(e) => this.loadData(e)}
          onPopupVisibleChange={this.toggleToolTip}
          allowClear={false}
        />
      </StyledDiv>
    );
  }
}

export default FolderSelector;
