import { message } from 'antd';
import * as React from 'react';
import useSWR from 'swr';
import { makeApiCallAdmin } from '../../api';
import { AppContext } from '../../DataProvider';

function useProfile() {
  const { user } = React.useContext(AppContext);

  return user;
}

export function useBalance() {
  const {
    user: { userType, agentId, clientId },
  } = React.useContext(AppContext);
  const { balance = 0, revalidate } = useSWR(
    `/${userType === 1 ? 'Agent' : 'Client'}/${userType === 1 ? agentId : clientId}`,
    getBalance
  );

  return { balance, revalidate };
}

async function getBalance(url) {
  try {
    const { data = 0 } = await makeApiCallAdmin(url);
    return data;
  } catch (e) {
    message.error(e.message);
  }
}

export default useProfile;
