import { message } from 'antd';
import useSWR from 'swr';
import { useProfile } from '.';
import { makeApiCallAdmin } from '../../api';

function useClients() {
  const { userType } = useProfile();
  const { data: clients, error, revalidate } = useSWR(
    userType === 1 ? '/Client' : null,
    fetchClients
  );

  return { clients, error, loading: !clients && !error, revalidate };
}

async function fetchClients() {
  try {
    const { data = [] } = await makeApiCallAdmin(`/Client`);
    return data;
  } catch (e) {
    message.error(e.message);
  }
}

export default useClients;
