import { Col, message, Row, Select, Button, Typography } from "antd";
import { Field, Formik } from "formik";
import * as React from "react";
import { TwitterPicker } from "react-color";
import * as yup from "yup";
import { makeApiCallAdmin } from "../api";
import { black } from "../components/design-system/colors";
import { ButtonComponent } from "../components/primitives/Buttons";
import styled from "styled-components";
import { AppInput } from "../components/primitives/Input";
import { SaveAndCancelButtons } from "../components/primitives/SaveAndCancelButtons";
import { SelectStyle } from "../components/primitives/Select";
import { Label } from "../components/primitives/Text";
import {
	useManufacturers,
	useUnassignedDevices,
	useVehicleModels,
	useVehicleTypes,
} from "../utils/hooks";

const Option = Select.Option;
const { Text, Link } = Typography;

function AssetForm({ isEdit = false, close, asset = {} }) {
	const [displayColorPicker, setDisplayColorPicker] = React.useState(false);
	const [colorList, setColorList] = React.useState(null);
	const { devices = [] } = useUnassignedDevices();
	const { manufacturers = [] } = useManufacturers();
	const { vehicleTypes = [] } = useVehicleTypes();
	const [selectedVehicleId, setSelectedVehicleId] = React.useState(null);
	const { models = [], loading: loadingModels } = useVehicleModels(
		selectedVehicleId
	);

	React.useEffect(() => {
		if (isEdit && manufacturers.length > 0) {
			const currentManufacturer = manufacturers.find(
				({ text }) => text === asset.manufacturer
			);
			if (currentManufacturer) setSelectedVehicleId(currentManufacturer.id);
		}
	}, [manufacturers]);

	React.useEffect(() => {
		getColorOptions();
	}, []);
	const getColorOptions = async () => {
		try {
			const results = await makeApiCallAdmin("LookUp/AssetBodyColor", "get");
			if (results) {
				setColorList(results.data);
			}
		} catch (error) {}
	};

	return (
		<Formik
			initialValues={{
				tag: asset.tag || "",
				vin: asset.vin || "",
				plateNo: asset.plateNo || "",
				model: asset.model || "",
				bodyColour: asset.bodyColour || "",
				vehecleType: asset.vehecleType || "",
				manufacturer: asset.manufacturer || "",
				// odometer: 0,
			}}
			validationSchema={validationSchema}
			onSubmit={async (values, actions) => {
				try {
					await makeApiCallAdmin(`/Assets/${isEdit ? asset.assetId : ""}`, {
						method: isEdit ? "put" : "post",
						data: values,
					});
					message.success(
						`Asset ${isEdit ? "updated" : "created"} successfully`
					);
					close();
				} catch (error) {
					message.error(error.message);
				} finally {
					actions.setSubmitting(false);
				}
			}}
			enableReinitialize
		>
			{({
				values,
				touched,
				errors,
				handleChange,
				submitForm,
				handleSubmit,
				setFieldValue,
				isSubmitting,
			}) => {
				return (
					<form className="asset-form" onSubmit={handleSubmit}>
						<AppInput
							name="tag"
							label="Asset Name"
							placeholder="Give your asset a name"
							marginTop="0"
							value={values.tag}
							error={errors.tag && touched.tag}
							errorMessage={errors.tag}
							onChange={handleChange}
						/>
						<AppInput
							name="vin"
							label="Vehicle Identification Number"
							placeholder="Enter your VIN"
							value={values.vin}
							error={errors.vin && touched.vin}
							errorMessage={errors.vin}
							onChange={handleChange}
						/>
						<AppInput
							name="plateNo"
							label="Vehicle Plate Number"
							placeholder="Enter your Vehicle plate number"
							value={values.plateNo}
							error={errors.plateNo && touched.plateNo}
							errorMessage={errors.plateNo}
							onChange={handleChange}
							style={{ textTransform: "uppercase" }}
						/>
						<Row gutter={16}>
							<Col span={12}>
								<SelectStyle
									marginTop="20px"
									border={black}
									css={".ant-select-search__field { top: 0; }"}
								>
									<Label>Select Make</Label>
									<Select
										value={values.manufacturer}
										placeholder="Select a device to assign"
										onChange={(value) => {
											setSelectedVehicleId(
												manufacturers.find((m) => m.text === value).id
											);
											setFieldValue("manufacturer", value);
										}}
									>
										{manufacturers.map((manufacturer) => (
											<Option value={manufacturer.text} key={manufacturer.text}>
												{manufacturer.text}
											</Option>
										))}
									</Select>
								</SelectStyle>
							</Col>
							<Col span={12}>
								<SelectStyle
									marginTop="20px"
									border={black}
									css={".ant-select-search__field { top: 0; }"}
								>
									<Label>Vehicle model</Label>
									<Select
										value={values.model}
										placeholder="Select model"
										onChange={(value) => setFieldValue("model", value)}
									>
										{models.map((model) => (
											<Option value={model.text} key={model.text}>
												{model.text}
											</Option>
										))}
									</Select>
								</SelectStyle>
							</Col>
						</Row>
						<Row gutter={16}>
							<Col span={12}>
								<SelectStyle
									marginTop="20px"
									border={black}
									css={".ant-select-search__field { top: 0; }"}
								>
									<Label>Vehicle Class</Label>
									<Select
										value={values.vehecleType}
										placeholder="Select Class"
										onChange={(value) => setFieldValue("vehecleType", value)}
									>
										{vehicleTypes.map((vehicleType) => (
											<Option value={vehicleType.text} key={vehicleType.text}>
												{vehicleType.text}
											</Option>
										))}
									</Select>
								</SelectStyle>
								<Text type="danger" className="mt-2 text-xs ">
									{errors.vehecleType}
								</Text>
							</Col>
							<Col span={12}>
								<SelectStyle
									marginTop="20px"
									border={black}
									css={".ant-select-search__field { top: 0; }"}
								>
									<Label>Color</Label>
									<Select
										value={values.bodyColour}
										placeholder="Select color"
										onChange={(value) => setFieldValue("bodyColour", value)}
									>
										{colorList &&
											colorList.map((color) => (
												<Option value={color.id.toLowerCase()} key={color.text}>
													{color.text}
												</Option>
											))}
									</Select>
								</SelectStyle>
								<Text type="danger" className="mt-2 text-xs">
									{errors.bodyColour}
								</Text>
							</Col>
						</Row>
						<div className="mt-10">
							<SaveAndCancelButtons
								type="submit"
								successButtonAppearance="blue"
								saveText={isEdit ? `Save` : `Create Asset`}
								cancelText="Cancel"
								onCancel={close}
								loading={isSubmitting}
							/>
						</div>
					</form>
				);
			}}
		</Formik>
	);
}

var validationSchema = yup.object().shape({
	tag: yup.string().required("This field is required"),
	vin: yup.string().required("This field is required"),
	plateNo: yup.string().required("This field is required"),
	model: yup.string().required("This field is required"),
	bodyColour: yup.string().required("This field is required"),
	vehecleType: yup.string().required("This field is required"),
	manufacturer: yup.string().required("This field is required"),
});

export default AssetForm;
