import React, { useState } from "react";
import { Col, Divider, Icon, Input, List, Menu, Row } from "antd";
import MenuItem from "antd/lib/menu/MenuItem";
import { isEmpty, isEqual } from "lodash";
import { NavLink } from "react-router-dom";
import styled from "styled-components";
import { black, gray, white } from "../../design-system/colors";
import { customFonts } from "../../design-system/fonts";
import { DatePickerComponent } from "../../primitives/DatePicker";
import { Grid } from "../../primitives/Layout";
import { AppNavbar, SecondaryNavbar } from "../../primitives/Navbar";
import { Heading, Text } from "../../primitives/Text";
import { AssetAlert } from "./AssetAlert";
import { AlertButton, AlertFilter, AlertItem } from "./components";
import { DeviceAlert } from "./DeviceAlert";
import { DriverAlert } from "./DriverAlert";
import { Geofence } from "./Geofence";
import { SpeedAlert } from "./SpeedAlert";

const NewAlertPage = (props) => {
	const [showForm, setShowForm] = useState(false);
	const [title, setTitle] = useState("");
	const [section, setSection] = useState("");
	const [sectionProps, setSectionProps] = useState({});
	const [sections, setSections] = useState({
		asset: AssetAlert,
		speed: SpeedAlert,
		device: DeviceAlert,
		driver: DriverAlert,
		geofence: Geofence,
	});

	const { loading, alerts } = props;

	const AlertsNavigation = ({
		showSearch = false,
		onFilter,
		searchIncidents,
	}) => (
		<Menu mode="horizontal">
			<Menu.Item key="overview">
				<NavLink to="/alert/incidents/" activeClassName="active">
					Incidents
				</NavLink>
			</Menu.Item>
			<Menu.Item key="assets">
				<NavLink to="/alert/alerts" activeClassName="active">
					Create Alert
				</NavLink>
			</Menu.Item>
			<Menu.Item key="drivers">
				<NavLink to="/alert/contacts" activeClassName="active">
					Contacts
				</NavLink>
			</Menu.Item>
			{showSearch && (
				<Menu.Item key="divider">
					<Divider type="vertical" />
				</Menu.Item>
			)}
			{showSearch && (
				<Menu.Item key="search">
					<div className="search-input">
						<Input
							size="large"
							placeholder="Search incidents"
							prefix={
								<Icon type="search" style={{ color: "rgba(0,0,0,.25)" }} />
							}
							onChange={searchIncidents}
						/>
					</div>
				</Menu.Item>
			)}
			{showSearch && (
				<MenuItem style={{ float: "right", paddingRight: "0px" }}>
					<DatePickerComponent
						format="YYYY-MM-DD HH:mm"
						showTime={{ format: "HH:mm" }}
						onOk={(value) => {
							let date = value.format("X");
							onFilter(date);
						}}
						border={black}
						placeholder="Filter incidents by date"
					/>
				</MenuItem>
			)}
		</Menu>
	);
	const setSectionToDisplay = (section, props, showForm = true) => {
		setSection(section);
		setShowForm(true);
		setSectionProps(props);
		setTitle(
			isEmpty(props) ? `Create ${section} alert` : `Edit ${section} alert`
		);
		// this.setState({
		// 	section,
		// 	showForm,
		// 	sectionProps: props,
		// 	title: isEmpty(props)
		// 		? `Create ${section} alert`
		// 		: `Edit ${section} alert`,
		// });
	};
	const showSection = (onCancel = hideSection) => {
		const {
			contacts,
			buttonLoading,
			createAlert,
			updateAlert,
			deleteAlert,
			nodesWithChildren,
			createGeofenceAlert,
			updateGeofenceAlert,
		} = props;
		if (section) {
			let Component = sections[section];
			if (isEmpty(sectionProps)) {
				return (
					<Component
						edit={false}
						contacts={contacts}
						hideSection={onCancel}
						createAlert={createAlert}
						deleteAlert={deleteAlert}
						updateAlert={updateAlert}
						buttonLoading={buttonLoading}
						nodesWithChildren={nodesWithChildren}
						createGeofenceAlert={createGeofenceAlert}
						updateGeofenceAlert={updateGeofenceAlert}
					/>
				);
			} else {
				return (
					<Component
						edit={true}
						{...sectionProps}
						contacts={contacts}
						hideSection={onCancel}
						createAlert={createAlert}
						deleteAlert={deleteAlert}
						updateAlert={updateAlert}
						buttonLoading={buttonLoading}
						nodesWithChildren={nodesWithChildren}
						createGeofenceAlert={createGeofenceAlert}
						updateGeofenceAlert={updateGeofenceAlert}
					/>
				);
			}
		}
	};
	const hideSection = () => {
		setTitle("");
		setSection("");
		setShowForm(false);
		setSectionProps({});
	};
	const onFilter = (filter) => {
		props.setFilter(filter);
	};

	return (
		<>
			<AppNavbar />
			<CreateAlertContainer className="create-alert">
				<SecondaryNavbar>
					<AlertsNavigation />
				</SecondaryNavbar>
				<div className="create-alert__container">
					<Row>
						<Col span={6}>
							<AlertFilter
								name="Alert filters"
								filters={[
									{ name: "All", value: "all" },
									{ name: "Geofence", value: "geofence" },
									{ name: "Speed Alert", value: "speed" },
									{ name: "Driver Behaviour", value: "driver" },
									{ name: "Asset Alert", value: "asset" },
									{ name: "Device", value: "Device" },
								]}
								onFilter={onFilter}
							/>

							<div className="create-alert__sidebar">
								<List
									itemLayout="horizontal"
									dataSource={alerts}
									loading={loading}
									renderItem={(item) => {
										return (
											<AlertItem
												{...item}
												alert={item}
												key={item.label}
												onEdit={setSectionToDisplay}
												refetchAlerts={props.refetchList}
												goToAssignPage={() => {
													if (item.type.toLowerCase() === "geofence") {
														props.history.push(
															`/fleet/assign/${
																[0, 101].includes(item.violationType)
																	? `${item.geofenceId}/geofence`
																	: `${item.otherVolationId}/other-violations`
															}`
														);
													} else {
														props.history.push(
															`/fleet/assign/${
																[0, 101].includes(
																	item.violationTypeParams.violationType
																)
																	? `${item.geofenceId}/geofence`
																	: `${item.otherVolationId}/other-violations`
															}`
														);
													}
												}}
											/>
										);
									}}
								/>
							</div>
						</Col>
						<Col span={18}>
							<>
								{showForm ? (
									<div className="create-alert__form-section">
										{showSection()}
									</div>
								) : (
									<div className="create-alert__content">
										<div className="create-alert__cta-section">
											<Heading className="create-alert__cta-button">
												<svg
													xmlns="http://www.w3.org/2000/svg"
													width="20"
													height="20"
													viewBox="0 0 20 20"
												>
													<path
														fill="#000"
														fillRule="evenodd"
														d="M20 9v2h-9v9H9v-9H0V9h9V0h2v9z"
													/>
												</svg>
												<span>Create an Alert</span>
											</Heading>
											<Text>Choose an alert type to configure</Text>
										</div>
										<div className="create-alert__buttons-section">
											<Grid numberOfCells={3}>
												<div className="cell">
													<AlertButton
														isActive
														className="create-alert__button"
														onClick={() => setSectionToDisplay("geofence")}
													>
														Geofence
													</AlertButton>
												</div>
												<div className="cell">
													<AlertButton
														isActive
														className="create-alert__button"
														onClick={() => setSectionToDisplay("speed")}
													>
														Speed Alert
													</AlertButton>
												</div>
												<div className="cell">
													<AlertButton
														isActive
														className="create-alert__button"
														onClick={() => setSectionToDisplay("asset")}
													>
														Asset Alert
													</AlertButton>
												</div>
											</Grid>
											<Grid numberOfCells={3}>
												<div className="cell">
													<AlertButton
														isActive
														className="create-alert__button"
														onClick={() => setSectionToDisplay("device")}
													>
														Device Alert
													</AlertButton>
												</div>
												<div className="cell">
													<AlertButton
														isActive
														className="create-alert__button"
														onClick={() => setSectionToDisplay("driver")}
													>
														Driver Behaviour
													</AlertButton>
												</div>
											</Grid>
										</div>
									</div>
								)}
							</>
						</Col>
					</Row>
				</div>
			</CreateAlertContainer>
		</>
	);
};
export var CreateAlertContainer = styled.div`
	position: fixed;
	width: 100%;

	.SecondaryNavbar {
		.ant-menu-item {
			a {
				color: ${gray.primary};

				&:focus,
				&:hover,
				&.active {
					color: ${black};
				}
			}
		}
		.ant-divider {
			margin: 0;
		}
		.search-input .ant-input {
			border: none;
			outline: none;
			box-shadow: none;

			&:hover,
			&:focus {
				border: none;
				outline: none;
				box-shadow: none;
			}

			&::-webkit-input-placeholder {
				color: ${gray.primary};
				font-size: 14px;
				font-weight: thin;
			}

			&::-moz-placeholder {
				color: ${gray.primary};
				font-size: 14px;
				font-weight: thin;
			}

			&:-moz-placeholder {
				color: ${gray.primary};
				font-size: 14px;
				font-weight: thin;
			}

			&:-ms-input-placeholder {
				color: ${gray.primary};
				font-size: 14px;
				font-weight: thin;
			}
		}
	}
	.create-alert {
		&__container {
			padding-top: 128px;

			.AlertFilterContainer {
				border-right: 1px solid ${gray.light};

				.filter-tag {
					font-size: 13px;
				}
			}
		}
		&__content {
			display: flex;
			flex-direction: column;
			align-items: center;
			text-align: center;
			padding-top: 64px;
			height: 100vh;
			overflow: auto;
			padding-bottom: 140px;
		}
		&__cta-section {
			padding-bottom: 40px;

			p {
				color: ${gray.primary};
			}
		}
		&__cta-button {
			width: 100%;
			display: flex;
			align-items: center;
			justify-content: center;
			color: ${black};
			font-size: 20px;
			padding-bottom: 16px;
			font-family: ${customFonts.circular}!important;

			span {
				padding-left: 8px;
				font-family: ${customFonts.circular}!important;
			}
		}
		&__sidebar {
			position: relative;
			border-right: 1px solid ${gray.light};
			background: ${white};
			height: 100vh;
			width: 100%;
			overflow: auto;
			padding: 16px 0 48px 32px;
			padding-bottom: 200px;
		}
		&__form-section {
			padding: 32px 48px 200px 48px;
			height: 100vh;
			overflow: auto;
		}
	}
`;

export default NewAlertPage;
