import { message } from 'antd';
import useSWR from 'swr';
import { makeApiCallAdmin } from '../../api';

function useDevices() {
  const { data: devices, error, revalidate } = useSWR('/Device', fetchDevices);

  return { devices, loading: !devices && !error, revalidate };
}

async function fetchDevices(url) {
  try {
    const { data = [] } = await makeApiCallAdmin(url);
    return data;
  } catch (e) {
    message.error(e.message);
  }
}

export default useDevices;
