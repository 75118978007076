import { message } from 'antd';
import { Field, withFormik } from 'formik';
import React from 'react';
import * as yup from 'yup';
import { makeApiCallAdmin } from '../../../api';
import { black } from '../../design-system/colors';
import { ButtonComponent } from '../../primitives/Buttons';
import { AppInput } from '../../primitives/Input';
import { SaveAndCancelButtons } from '../../primitives/SaveAndCancelButtons';
import { SelectComponent } from '../../primitives/Select';

const PreferenceFormValidation = yup.object().shape({
  Language: yup.string().required('This field is required'),
  TimeZone: yup.string().required('This field is required'),
});

const PreferenceFormComponent = ({
  values,
  errors,
  touched,
  handleSubmit,
  handleChange,
  loading,
}) => (
  <form onSubmit={handleSubmit}>
    <Field
      name="Language"
      value={values.Language}
      render={({ field, form }) => (
        <SelectComponent
          {...field}
          marginTop="0"
          border={black}
          size="large"
          placeholder="Select Language"
          options={[
            { label: 'English', value: 'English' },
            { label: 'French', value: 'French' },
            { label: 'German', value: 'German' },
          ]}
          onChange={(e) => {
            form.setFieldValue('Language', e);
          }}
        />
      )}
    />
    <Field
      name="TimeZone"
      value={values.TimeZone}
      render={({ field, form }) => (
        <SelectComponent
          {...field}
          marginTop="20px"
          border={black}
          size="large"
          placeholder="Select Time Zone"
          options={[
            { label: 'UTC', value: 'UTC' },
            { label: 'UTC+13:00', value: 'UTC+13:00' },
            { label: 'UTC+14:00', value: 'UTC+14:00' },
          ]}
          onChange={(e) => {
            form.setFieldValue('TimeZone', e);
          }}
          error={errors.TimeZone && touched.TimeZone}
          errorMessage={errors.TimeZone}
        />
      )}
    />
    <ButtonComponent appearance="blue" htmlType="submit" loading={loading} marginTop="20px">
      Save Preferences
    </ButtonComponent>
  </form>
);

export const PreferenceForm = withFormik({
  mapPropsToValues: (props) => ({
    Language: props.Language || 'English',
    TimeZone: props.TimeZone || 'UTC',
  }),
  enableReinitialize: true,
  handleSubmit: (values, { props, setSubmitting, resetForm }) => {
    props.onSubmit(values);
  },
  validationSchema: PreferenceFormValidation,
})(PreferenceFormComponent);

const UpdatePasswordFormComponent = ({
  values,
  errors,
  touched,
  handleSubmit,
  handleChange,
  isSubmitting,
  onCancel,
}) => (
  <form onSubmit={handleSubmit}>
    <AppInput
      name="oldPassword"
      value={values.oldPassword}
      size="large"
      type="password"
      placeholder="Old Password"
      error={touched.oldPassword && errors.oldPassword}
      errorMessage={errors.oldPassword}
      onChange={handleChange}
    />
    <AppInput
      name="newPassword"
      value={values.newPassword}
      size="large"
      type="password"
      placeholder="New Password"
      error={touched.newPassword && errors.newPassword}
      errorMessage={errors.newPassword}
      onChange={handleChange}
    />
    <SaveAndCancelButtons onCancel={onCancel} loading={isSubmitting} />
  </form>
);

export const UpdatePasswordForm = withFormik({
  mapPropsToValues: (props) => ({
    oldPassword: '',
    newPassword: '',
  }),
  enableReinitialize: true,
  handleSubmit: async (values, { props, setSubmitting, resetForm }) => {
    try {
      await makeApiCallAdmin('/Account/MyDetails/PasswordChange', { method: 'put', data: values });
      message.success('Password changed successfully');
      props.onCancel();
    } catch (error) {
      message.error(error.message);
    } finally {
      setSubmitting(false);
    }
  },
})(UpdatePasswordFormComponent);

const DivisionFormComponent = ({
  values,
  errors,
  touched,
  handleSubmit,
  handleChange,
  loading,
  onCancel,
}) => (
  <form onSubmit={handleSubmit}>
    <AppInput
      name="DivisionName"
      value={values.DivisionName}
      size="large"
      type="text"
      placeholder="Division Name"
      error={touched.DivisionName && errors.DivisionName}
      errorMessage={errors.DivisionName}
      onChange={handleChange}
    />
    <SaveAndCancelButtons onCancel={onCancel} loading={loading} />
  </form>
);

export const DivisionForm = withFormik({
  mapPropsToValues: (props) => ({
    DivisionName: props.DivisionName || '',
  }),
  enableReinitialize: true,
  handleSubmit: (values, { props, setSubmitting, resetForm }) => {
    props.onSubmit(values);
  },
})(DivisionFormComponent);
