import { message } from 'antd';
import useSWR from 'swr';
import { makeApiCall } from '../../api';
import useProfile from './useProfile';

function useTrips() {
  const user = useProfile();
  const { data: trips, error, revalidate } = useSWR(`/Trip/List/${user.clientId}`, fetchTrips);

  return { trips, loading: !trips && !error, revalidate };
}

async function fetchTrips(url) {
  try {
    const { Trips } = await makeApiCall(url);
    return Trips;
  } catch (e) {
    message.error(e.message);
  }
}

export default useTrips;
