import { Select } from "antd";
import React from "react";
import styled from "styled-components";
import { makeApiCallAdmin } from "../api";

const { Option } = Select;

const StyledDiv = styled.div`
	position: relative;
	min-width: 160px;
	max-width: 200px;

	.tooltip {
		position: absolute;
		bottom: -38px;
		left: 21px;
		opacity: 1;
		transition: opacity 0.3s linear;
	}

	.tooltip.fade {
		opacity: 0;
	}

	.tooltip.hide {
		display: none;
	}

	.tooltip.show {
		display: inline;
	}

	.tooltip::before {
		position: absolute;
		content: "i";
		width: 15px;
		height: 15px;
		background: transparent;
		border: 1px solid rgba(0, 0, 0, 0.65);
		border-radius: 50%;
		bottom: 3px;
		left: -18px;
		display: flex;
		justify-content: center;
		align-items: center;
		font-size: 0.6rem;
	}

	span + div > div > div {
		padding-top: 40px;
		background: transparent;
		box-shadow: none;
	}
	.ant-select.driver-selector {
		min-width: 195px;
		.ant-select-selection {
			height: 40px;
		}
		.ant-select-selection__rendered {
			padding-top: 0;
			.ant-select-selection__placeholder {
				line-height: 30px;
			}
			.ant-select-selection-selected-value {
				line-height: 38px;
			}
		}
	}

	.anticon.anticon-right > svg {
		color: transparent;
		border: 5px solid transparent;
		border-left-color: black;
	}

	input.ant-input.ant-cascader-input.ant-input-lg::placeholder {
		color: black;
	}
`;

class DriverSelector extends React.Component {
	constructor(props) {
		super(props);
		this.state = { options: [], toolTipVisible: "hide" };

		this.getRootNodes = this.getRootNodes.bind(this);
		this.getChildrenNodes = this.getChildrenNodes.bind(this);
		this.loadData = this.loadData.bind(this);
		this.getOptionsFromNodes = this.getOptionsFromNodes.bind(this);
		this.toggleToolTip = this.toggleToolTip.bind(this);
		this.showToolTip = this.showToolTip.bind(this);
		this.hideToolTip = this.hideToolTip.bind(this);
		this.displayRender = this.displayRender.bind(this);
	}

	componentWillMount() {
		this.getRootNodes();
	}

	getOptionsFromNodes(nodes) {
		return nodes.map(({ nodeId, nodeDesc, isNodeLeaf }) => {
			return {
				value: nodeId,
				label: nodeDesc,
				isLeaf: isNodeLeaf,
			};
		});
	}

	async getRootNodes() {
		const { data } = await makeApiCallAdmin(`/Driver/Lookup`);

		return this.setState({ options: data });
	}

	async getChildrenNodes(parentNodeId) {
		const { data } = await makeApiCallAdmin(`/MyFolder/${parentNodeId}`);
		return data;
	}

	async loadData(selected) {
		console.log("finding folder");
		const targetOption = selected[selected.length - 1];
		targetOption.loading = true; // show loading spinner
		const nodes = await this.getChildrenNodes(targetOption.value);
		const { data: assets } = await makeApiCallAdmin(
			`/MyFolder/${targetOption.value}`
		);
		targetOption.loading = false; // hide loading spinner
		/* append the subfolders count to the label and avoid repeating it in the
    case of multiple clicks in quick succession */
		targetOption.label = /(\([0-9]+\))/.test(targetOption.label)
			? targetOption.label
			: `${targetOption.label} (${assets.length})`;
		if (nodes.length === 0) {
			// this makes the collapse arrow not show when no subfolders
			targetOption.isLeaf = true;
			return;
		}
		targetOption.children = this.getOptionsFromNodes(nodes);
		this.setState({ options: [...this.state.options] });
	}

	toggleToolTip = (dropdownVisible) => {
		if (dropdownVisible === true || dropdownVisible === undefined) {
			this.showToolTip();
		} else {
			this.hideToolTip();
		}
	};

	showToolTip() {
		this.setState({ toolTipVisible: "show" });
	}

	hideToolTip() {
		this.setState({ toolTipVisible: "fade" });
		setTimeout(() => this.setState({ toolTipVisible: "hide" }), 300);
	}

	displayRender = (labels) => {
		// remove the subfolders count from the rendered label
		console.log(labels, "renderer");
		// return labels.length > 0 && labels[labels.length - 1].split(" (")[0];
	};

	render() {
		const { folder, onInputChange, ...rest } = this.props;
		const { options, toolTipVisible } = this.state;
		return (
			<StyledDiv id="folder" {...rest}>
				<span className={`${toolTipVisible} tooltip`}>
					Double-click to select
				</span>
				<Select
					className="driver-selector"
					placeholder="Select a driver"
					onChange={(value) => onInputChange("driver", value)}
				>
					{options.map((item) => (
						<Option value={item.driverId} key={item.driverId}>
							{item.driverName}
						</Option>
					))}
				</Select>
			</StyledDiv>
		);
	}
}

export default DriverSelector;
