import { message } from "antd";
import Axios from "axios";
import { Formik } from "formik";
import * as React from "react";
import { NavLink } from "react-router-dom";
import * as yup from "yup";
import { makeApiCallAdmin } from "../api";
import AuthContainer from "../components/pages/Authentication/index";
import { ButtonComponent } from "../components/primitives/Buttons";
import { InputComponent } from "../components/primitives/Input";
import { Heading, Text } from "../components/primitives/Text";

function SignIn({ history, match: { url } }) {
	return (
		<AuthContainer url={url}>
			<Heading align="center" fontSize="30px">
				Reset Password
			</Heading>
			<Formik
				initialValues={{ email: "" }}
				validationSchema={validationSchema}
				onSubmit={async (values, actions) => {
					if (values.password !== values.confirmPassword) {
						message.error("passwords do not match");
					}
					try {
						const verificationCode = localStorage.getItem("EZEETRACKER_CODE");
						const results = await makeApiCallAdmin("/Login/ResetPassword", {
							method: "post",
							data: {
								validationCode: verificationCode,
								password: values.password,
							},
						});

						message.success("Password reset successful");
						history.push("/");
					} catch (error) {
						message.error(error.message);
					} finally {
						actions.setSubmitting(false);
					}
				}}
			>
				{({
					values,
					errors,
					touched,
					handleChange,
					handleSubmit,
					isSubmitting,
				}) => (
					<form onSubmit={handleSubmit} style={{ marginTop: "2rem" }}>
						<InputComponent
							name="password"
							type="text"
							placeholder="Password"
							size="large"
							value={values.password}
							error={errors.password && touched.password}
							errorMessage={errors.password}
							onChange={handleChange}
						/>
						<InputComponent
							name="confirmPassword"
							type="text"
							placeholder="Confirm Password"
							size="large"
							value={values.confirmPassword}
							error={errors.confirmPassword && touched.confirmPassword}
							errorMessage={errors.confirmPassword}
							onChange={handleChange}
						/>

						<ButtonComponent
							size="large"
							appearance="blue"
							htmlType="submit"
							loading={isSubmitting}
							className="mt-5 w-full"
						>
							Save Password
						</ButtonComponent>
					</form>
				)}
			</Formik>
			<div className="sign-in-help__links">
				<Text align="center" marginTop="15px">
					<NavLink to="/">Back to Login</NavLink>
				</Text>
			</div>
		</AuthContainer>
	);
}

var validationSchema = yup.object().shape({
	password: yup.string().required("This field is required"),
	confirmPassword: yup.string().required("This field is required"),
});

export default SignIn;
