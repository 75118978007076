import React, { useEffect, useState } from 'react';
import Geosuggest from 'react-geosuggest';
import styled from 'styled-components';
import { black, gray, green, red } from '../../design-system/colors';

const AddressInputDiv = styled.div`
  margin-top: 20px;
  width: 100%;
  display: flex;
  justify-content: space-between;

  & > * {
    width: 48%;
  }

  .geosuggest__input-wrapper {
    position: relative;

    &::before {
      content: '';
      position: absolute;
      width: 8px;
      height: 8px;
      z-index: 1;
      top: 40%;
      left: 12px;
      border-radius: 50%;
      background-color: ${({ point }) => (point === 'start' ? green.primary : red.primary)};
    }

    ${({ point }) =>
      point === 'start' &&
      `
      &::after {
        content: '';
        position: absolute;
        height: 14px;
        width: 1px;
        border: 1px dashed #000000;
        top: 43px;
        left: 15px;
      }
    `}
  }

  .address__input {
    width: 100%;
    outline: none;
    border: 1px solid ${black};
    padding: 0.5em 1em;
    transition: border 0.2s, box-shadow 0.2s;
    color: black;
    height: 40px;

    &::placeholder {
      color: rgb(191, 191, 191);
    }

    &.geosuggest {
      padding-left: 25px;
      ${({ locationInvalid }) => locationInvalid && `border: 1px solid ${red.primary};`}
    }

    &.custom {
      ${({ addressInvalid }) => addressInvalid && `border: 1px solid ${red.primary};`}
    }
  }

  .geosuggest {
    position: relative;
  }

  .geosuggest__suggests {
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    max-height: 25em;
    padding: 0;
    margin-top: -1px;
    background: #fff;
    border: 1px solid ${black};
    border-top-width: 1px;
    overflow-x: hidden;
    overflow-y: auto;
    list-style: none;
    z-index: 5;
    -webkit-transition: max-height 0.2s, border 0.2s;
    transition: max-height 0.2s, border 0.2s;
  }

  .geosuggest__suggests--hidden {
    max-height: 0;
    overflow: hidden;
    border-width: 0;
  }

  .geosuggest__item {
    font-size: 18px;
    font-size: 1rem;
    padding: 0.5em 0.65em;
    color: ${black};
    cursor: pointer;
  }

  .geosuggest__item:hover,
  .geosuggest__item:focus {
    background: ${gray.ui01};
  }

  .geosuggest__item--active {
    background: #267dc0;
    color: #fff;
  }

  .geosuggest__item--active:hover,
  .geosuggest__item--active:focus {
    background: #ccc;
  }

  .geosuggest__item__matched-text {
    font-weight: bold;
  }
`;

export default ({
  geoSuggestPlaceholder,
  customNamePlaceholder,
  point,
  address,
  onAddressChange,
  onLocationChange,
  addressInputName,
  addressInvalid,
  locationInvalid,
  geoSuggestInputValue
}) => {
  const [geoSuggest, setGeoSuggest] = useState();

  useEffect(() => {
    geoSuggest && geoSuggest.update(geoSuggestInputValue);
  }, [geoSuggestInputValue, geoSuggest]);

  return (
    <AddressInputDiv {...{ point, locationInvalid, addressInvalid }}>
      <Geosuggest
        ref={el => setGeoSuggest(el)}
        placeholder={geoSuggestPlaceholder}
        location={new window.google.maps.LatLng(53.558572, 9.9278215)}
        radius={1}
        minLength={3}
        country='ng'
        inputClassName='geosuggest address__input'
        className={`${point} geosuggest__wrapper2`}
        onSuggestSelect={onLocationChange}
      />
      <div className=''>
        <input
          name={addressInputName}
          onChange={onAddressChange}
          value={address}
          className='custom address__input'
          placeholder={customNamePlaceholder}
        />
      </div>
    </AddressInputDiv>
  );
};
