export const BASE_PATH = '/static';
export const customFonts = {
  akkurat: 'Akkurat',
  circular: 'Circular'
};

export const fontWeight = { bold: 700, regular: 400, thin: 300 };

export const fontFamily = `'${customFonts.akkurat}', system-ui, sans-serif !important`;

export const formInput = `
color: #000000;
`;

export const font_face = `
@font-face {
  font-family: 'Akkurat';
  src: url('${BASE_PATH}/fonts/Akkurat.eot');
  src: url('${BASE_PATH}/fonts/Akkurat.eot?#iefix') format('embedded-opentype'),
      url('${BASE_PATH}/fonts/Akkurat.woff2') format('woff2'),
      url('${BASE_PATH}/fonts/Akkurat.woff') format('woff'),
      url('${BASE_PATH}/fonts/Akkurat.svg#Akkurat') format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Circular';
  src: url('${BASE_PATH}/fonts/CircularStd-Book.eot');
  src: url('${BASE_PATH}/fonts/CircularStd-Book.eot?#iefix') format('embedded-opentype'),
      url('${BASE_PATH}/fonts/CircularStd-Book.woff2') format('woff2'),
      url('${BASE_PATH}/fonts/CircularStd-Book.woff') format('woff'),
      url('${BASE_PATH}/fonts/CircularStd-Book.ttf') format('truetype'),
      url('${BASE_PATH}/fonts/CircularStd-Book.svg#CircularStd-Book') format('svg');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Circular';
  src: url('${BASE_PATH}/fonts/CircularStd-MediumItalic.eot');
  src: url('${BASE_PATH}/fonts/CircularStd-MediumItalic.eot?#iefix') format('embedded-opentype'),
      url('${BASE_PATH}/fonts/CircularStd-MediumItalic.woff2') format('woff2'),
      url('${BASE_PATH}/fonts/CircularStd-MediumItalic.woff') format('woff'),
      url('${BASE_PATH}/fonts/CircularStd-MediumItalic.ttf') format('truetype'),
      url('${BASE_PATH}/fonts/CircularStd-MediumItalic.svg#CircularStd-MediumItalic') format('svg');
  font-weight: 300;
  font-style: italic;
}

@font-face {
  font-family: 'Circular';
  src: url('${BASE_PATH}/fonts/CircularStd-BookItalic.eot');
  src: url('${BASE_PATH}/fonts/CircularStd-BookItalic.eot?#iefix') format('embedded-opentype'),
      url('${BASE_PATH}/fonts/CircularStd-BookItalic.woff2') format('woff2'),
      url('${BASE_PATH}/fonts/CircularStd-BookItalic.woff') format('woff'),
      url('${BASE_PATH}/fonts/CircularStd-BookItalic.ttf') format('truetype'),
      url('${BASE_PATH}/fonts/CircularStd-BookItalic.svg#CircularStd-BookItalic') format('svg');
  font-weight: 500;
  font-style: italic;
}

@font-face {
  font-family: 'Circular';
  src: url('${BASE_PATH}/fonts/CircularStd-BlackItalic.eot');
  src: url('${BASE_PATH}/fonts/CircularStd-BlackItalic.eot?#iefix') format('embedded-opentype'),
      url('${BASE_PATH}/fonts/CircularStd-BlackItalic.woff2') format('woff2'),
      url('${BASE_PATH}/fonts/CircularStd-BlackItalic.woff') format('woff'),
      url('${BASE_PATH}/fonts/CircularStd-BlackItalic.ttf') format('truetype'),
      url('${BASE_PATH}/fonts/CircularStd-BlackItalic.svg#CircularStd-BlackItalic') format('svg');
  font-weight: 900;
  font-style: italic;
}

@font-face {
  font-family: 'Circular';
  src: url('${BASE_PATH}/fonts/CircularStd-Bold.eot');
  src: url('${BASE_PATH}/fonts/CircularStd-Bold.eot?#iefix') format('embedded-opentype'),
      url('${BASE_PATH}/fonts/CircularStd-Bold.woff2') format('woff2'),
      url('${BASE_PATH}/fonts/CircularStd-Bold.woff') format('woff'),
      url('${BASE_PATH}/fonts/CircularStd-Bold.ttf') format('truetype'),
      url('${BASE_PATH}/fonts/CircularStd-Bold.svg#CircularStd-Bold') format('svg');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'Circular';
  src: url('${BASE_PATH}/fonts/CircularStd-Black.eot');
  src: url('${BASE_PATH}/fonts/CircularStd-Black.eot?#iefix') format('embedded-opentype'),
      url('${BASE_PATH}/fonts/CircularStd-Black.woff2') format('woff2'),
      url('${BASE_PATH}/fonts/CircularStd-Black.woff') format('woff'),
      url('${BASE_PATH}/fonts/CircularStd-Black.ttf') format('truetype'),
      url('${BASE_PATH}/fonts/CircularStd-Black.svg#CircularStd-Black') format('svg');
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: 'Circular-Std-Medium';
  src: url('${BASE_PATH}/fonts/CircularStd-Medium.eot');
  src: url('${BASE_PATH}/fonts/CircularStd-Medium.eot?#iefix') format('embedded-opentype'),
      url('${BASE_PATH}/fonts/CircularStd-Medium.woff2') format('woff2'),
      url('${BASE_PATH}/fonts/CircularStd-Medium.woff') format('woff'),
      url('${BASE_PATH}/fonts/CircularStd-Medium.ttf') format('truetype'),
      url('${BASE_PATH}/fonts/CircularStd-Medium.svg#CircularStd-Medium') format('svg');
  font-weight: 300;
  font-style: normal;
}
`;
