import { message } from 'antd';
import useSWR from 'swr';
import { makeApiCallAdmin } from '../../api';

function useRootFolder() {
  const { data: rootFolder, error, revalidate } = useSWR('/MyFolder/Root', fetchRootFolder);

  return { rootFolder, loading: !rootFolder && !error, revalidate };
}

async function fetchRootFolder(url) {
  try {
    const { data } = await makeApiCallAdmin(url);
    return data;
  } catch (e) {
    message.error(e.message);
  }
}

export default useRootFolder;
