import React, { Component } from 'react';
import { IncidentsPage } from '../components/pages/Alerts/Incidents';
import { AppContext } from '../DataProvider';

export default class Incidents extends Component {
  static contextType = AppContext;
  state = {
    loading: false,
    selectedIncident: {},
    isModalShown: false,
  };
  componentDidMount() {
    const { getIncidents } = this.context;
    let date = Math.round(new Date().getTime() / 1000);
    this.setState({
      loading: true,
    });
    getIncidents(date).then((data) => this.setState({ loading: false }));
  }

  setSelectedIncident = (incident) => {
    this.setState({
      selectedIncident: incident,
      isModalShown: true,
    });
  };

  hideModal = () => this.setState({ isModalShown: false });

  filterIncidents = (date, params) => {
    const { getIncidents } = this.context;
    getIncidents(date, params);
  };

  render() {
    const { incidents, drivers, orphanAssets, logout } = this.context;
    return (
      <IncidentsPage
        {...this.props}
        logout={logout}
        data={incidents}
        drivers={drivers}
        hideModal={this.hideModal}
        isModalShown={this.state.isModalShown}
        orphanAssets={orphanAssets}
        loading={this.state.loading}
        selectedIncident={this.state.selectedIncident}
        setSelectedIncident={this.setSelectedIncident}
      />
    );
  }
}
