import { message } from 'antd';
import useSWR from 'swr';
import { makeApiCallAdmin } from '../../api';
import useProfile from './useProfile';

function useSubscriptions() {
  const user = useProfile();
  const { data: subscriptions, error, revalidate } = useSWR(
    `/CostPlanAdmin/${user.costPlanId}/Subscription`,
    fetchSubscriptions
  );

  return { subscriptions, loading: !subscriptions && !error, revalidate };
}

async function fetchSubscriptions(url) {
  try {
    const { data = [] } = await makeApiCallAdmin(url);
    return data;
  } catch (e) {
    message.error(e.message);
  }
}

export default useSubscriptions;
