import { message } from 'antd';
import useSWR from 'swr';
import { makeApiCallAdmin } from '../../../api';

function useSeatbeltViolations() {
  const { data: seatbeltViolations, error, revalidate } = useSWR(
    '/Dashboard/Seatbelt',
    fetchSeatbeltViolations
  );

  return { seatbeltViolations, loading: !seatbeltViolations && !error, revalidate };
}

async function fetchSeatbeltViolations(url) {
  try {
    const { data = [] } = await makeApiCallAdmin(url);
    return data;
  } catch (e) {
    message.error(e.message);
  }
}

export default useSeatbeltViolations;
