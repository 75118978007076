import { Col, Dropdown, Row } from "antd";
import { isEmpty, uniqBy } from "lodash";
import React from "react";
import styled from "styled-components";
import { Fleet } from ".";
import { gray } from "../../design-system/colors";
import { customFonts } from "../../design-system/fonts";
import { ButtonComponent } from "../../primitives/Buttons";
import { CustomLink, EmptyStateComponent } from "../../primitives/Layout";
import { CollapsibleNav } from "../../primitives/Navbar";
import { Heading, Text } from "../../primitives/Text";
import { AddAssetModal } from "./AddAssetModal";
import { CreateMenu, MapComponent } from "./components";
import { FolderItem } from "./FolderItem";
import { genCharArray, googleUrl } from "./helpers";
import { VehicleItemTag, VehicleList } from "./VehicleList";

export const LeftColumn = styled.div`
	position: relative;
	height: 100vh;
	overflow: auto;
	border-right: 1px solid ${gray.light};

	.ant-list {
		flex: 1;
	}
	.VehicleItem {
		padding: 20px 20px 20px 40px;
		&:last-of-type {
			/* margin-bottom: 200px; */
			border-bottom: 0;
		}
	}
`;

export const OverviewContainer = styled.div`
	position: relative;
	height: 100vh;

	.initials-filter {
		position: absolute;
		align-self: center;
		margin-left: 8px;
		top: 100px;
		z-index: 5;
		a {
			display: block;
			font-size: 12px;
		}
	}
	.selected-folders-display {
		display: flex;
		align-items: center;
		flex-flow: row wrap;
		margin: 20px 16px;
	}

	.ant-row {
		height: 100%;

		.ant-col-7 {
			height: 100vh;
			overflow: auto;
			padding-bottom: 100px;
		}

		.ant-col-8,
		.ant-col-16 {
			height: 100%;
		}
	}
	.create-folder__button {
		position: absolute;
		width: 100%;
		top: 622px;
		right: 0;

		@media (max-width: 1200px) {
			top: 658px;
		}
	}
	.EmptyStateComponent {
		h1 {
			font-family: ${customFonts.circular}!important;
			font-size: 20px;
			font-weight: bold;
		}
		p {
			color: ${gray.primary};
		}
		&__icon-container {
			width: 30px;
		}
	}
`;

export class Overview extends React.Component {
	state = {
		isCollapsed: false,
		folders: [],
		success: false,
		dropdownState: false,
	};
	onToggleNav = () => {
		this.setState(({ isCollapsed }) => ({ isCollapsed: !isCollapsed }));
	};
	setAssets = (newAssets, assetId) => {
		const { assets } = this.props;
		if (newAssets.length === 0 && assetId) {
			const filteredAssets = assets.filter(
				(asset) => asset.ParentNodeId !== assetId
			);
			this.props.getAssets(filteredAssets, "assets");
			this.props.getAssets(filteredAssets, "assetsCopy");
		} else {
			const activeAssets = uniqBy(
				[...newAssets, ...assets],
				(value) => value.NodeDesc
			);
			this.props.getAssets(activeAssets, "assets");
			this.props.getAssets(activeAssets, "assetsCopy");
		}
	};
	getFolderList = () => {
		const { nodesWithChildren } = this.props;
		if (nodesWithChildren.length > 0) {
			return nodesWithChildren.map((value) => ({
				value: value.NodeId,
				label: value.NodeDesc,
				children: value.Nodes.map((node) => ({
					value: node.NodeId,
					label: node.NodeDesc,
				})),
			}));
		}
		return nodesWithChildren;
	};
	populateSelectedFolder = (id, name) => {
		this.setState(({ folders }) => ({
			folders: [{ id, name }, ...folders],
		}));
	};
	removeFolder = (assets, id) => {
		const { folders } = this.state;
		const filterSelectedFolders = folders.filter((folder) => folder.id !== id);
		this.setState({ folders: filterSelectedFolders });
		this.setAssets(assets, id);
	};
	createFolder = (values, props, callback) => {
		let payload = {};
		if (!isEmpty(values)) {
			payload = values.ParentNodeId ? { ...values, isNodeLeaf: true } : values;
			this.props.createFolder(payload, props, callback);
		}
	};
	createAsset = (values, callback) => {
		this.props.createAsset(values, callback);
	};
	onAssetCreationDone = () => {
		this.setState({
			success: true,
		});
	};
	changeDropdownState = () => {
		this.setState(({ dropdownState }) => ({
			dropdownState: !dropdownState,
		}));
	};
	render() {
		const {
			data = [],
			loading,
			buttonLoading,
			buttonLoadingAsset,
			onAssetClick,
			assets,
			filterAssets,
			searchAssets,
			user,
			model,
			manufacturers,
			assetTypes,
			getModelLoading,
			getModel,
			orphanAssets = [],
			clientId,
			updateState,
			organizationName,
			showAddAssetModal,
		} = this.props;
		let { isCollapsed, folders, success, dropdownState } = this.state;
		let assetsGroup = [];
		folders.forEach((folder) => {
			let array = assets.filter(
				(asset) => asset.SelectedFolderName === folder.name
			);
			let result = { name: folder.name, data: array };
			assetsGroup.push(result);
		});
		let formatOrphanAssets = orphanAssets.map((item) => ({
			label: item.Tag,
			value: item.Tag,
		}));

		return (
			<Fleet onSearch={searchAssets}>
				<OverviewContainer className="OverviewContainer">
					<Row>
						<Col span={isCollapsed ? 1 : 5}>
							<CollapsibleNav
								isCollapsed={isCollapsed}
								onToggleNav={this.onToggleNav}
								groupName={organizationName}
							>
								{isCollapsed ? (
									<FolderItem
										isParent
										showCheckbox={false}
										isCollapsed={isCollapsed}
										onClick={this.onToggleNav}
										clientId={clientId}
										{...data[0]}
									/>
								) : data.length > 0 ? (
									data.map((value, i) => (
										<FolderItem
											isParent
											key={i.toString()}
											isCollapsed={isCollapsed}
											loading={loading}
											setAssetsOnParent={this.removeFolder}
											clientId={clientId}
											populateSelectedFolder={this.populateSelectedFolder}
											{...value}
										/>
									))
								) : (
									<EmptyStateComponent
										margin="100px"
										className="EmptyStateComponent"
									>
										<div className="EmptyStateComponent__icon-container">
											<img src="/static/img/403.svg" alt="forbidden" />
										</div>
										<Heading>No Folders</Heading>
										<Text>Create a folder using the button below</Text>
									</EmptyStateComponent>
								)}
								<Dropdown
									overlay={React.cloneElement(<CreateMenu />, {
										createFolder: this.createFolder,
										createAsset: this.createAsset,
										getFolderList: this.getFolderList,
										getModel: getModel,
										manufacturers: manufacturers,
										assetTypes: assetTypes,
										model: model,
										getModelLoading: getModelLoading,
										buttonLoading: buttonLoading,
										buttonLoadingAsset: buttonLoadingAsset,
										changeDropdownState: this.changeDropdownState,
									})}
									visible={dropdownState}
									trigger={["click"]}
								>
									<ButtonComponent
										appearance="blue"
										fullWidth
										className="create-folder__button"
										onClick={this.changeDropdownState}
									>
										{isCollapsed ? "+" : "Create"}
									</ButtonComponent>
								</Dropdown>
							</CollapsibleNav>
						</Col>
						{assets.length > 0 && (
							<Col span={7}>
								<div className="selected-folders-display">
									{folders.map(({ id, name }, i) => (
										<VehicleItemTag
											name={name}
											id={id}
											key={i.toString()}
											onClose={() => this.removeFolder([], id)}
										/>
									))}
								</div>
								<LeftColumn className="LeftColumn">
									<div className="initials-filter">
										{genCharArray().map((letter, i) => (
											<CustomLink
												key={i.toString()}
												color={gray.primary}
												onClick={() => filterAssets(letter)}
											>
												{letter.toUpperCase()}
											</CustomLink>
										))}
										<CustomLink
											color={gray.primary}
											onClick={() => filterAssets("")}
										>
											#
										</CustomLink>
									</div>
									{assetsGroup.map(({ name, data }, i) => (
										<React.Fragment key={i.toString()}>
											<div style={{ color: gray.ui01 }} className="py-1 px-10">
												{name}
											</div>
											<VehicleList data={data} onAssetClick={onAssetClick} />
										</React.Fragment>
									))}
								</LeftColumn>
							</Col>
						)}
						{assets.length > 0 ? (
							<Col span={isCollapsed ? 16 : 12}>
								<MapComponent
									isMarkerShown
									googleMapURL={googleUrl}
									loadingElement={<div style={{ height: `100%` }} />}
									markers={assets}
								/>
							</Col>
						) : (
							<Col span={isCollapsed ? 23 : 19}>
								<MapComponent
									isMarkerShown
									googleMapURL={googleUrl}
									loadingElement={<div style={{ height: `100%` }} />}
									markers={assets}
								/>
							</Col>
						)}
					</Row>
				</OverviewContainer>
				{showAddAssetModal && formatOrphanAssets.length === 0 && (
					<AddAssetModal
						updateState={updateState}
						assets={formatOrphanAssets}
						loading={buttonLoading}
						userType={user && user.UserType}
						success={success}
						onAssetCreationDone={this.onAssetCreationDone}
						createAsset={this.createAsset}
						getModel={getModel}
						manufacturers={manufacturers}
						assetTypes={assetTypes}
						model={model}
						getModelLoading={getModelLoading}
					/>
				)}
			</Fleet>
		);
	}
}
